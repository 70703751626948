import React, {FunctionComponent, useContext, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Dialog, DialogPositionType} from 'primereact/dialog';
import {Accordion, AccordionTab} from 'primereact/accordion';
import MapContext from '../map/MapContext';
import {useLazyGetElementGeoDataQuery} from '../../../redux/rtk/injectedGeoApi';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {ElementTypeItem, getElementTypeIconClass, getElementTypeName, UnitItem} from '../../functions/Units';
import Input from '../../global/Input';
import {useLazyGetFieldsQuery, useLazyGetUrlQuery} from '../../../redux/rtk/injectedUnitsApi';
import {ProgressSpinner} from 'primereact/progressspinner';
import CustomDataTable, {ColumnObject} from '../../global/CustomDataTable';
import {getColumnField} from '../../functions/Global';
import {faList} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'primereact/button';
import {useNavigate} from 'react-router-dom';

type controlProps = {
    projectId: string,
    locationIds?: string[],
    elementTypes: any[]
}

const PopupControl: FunctionComponent<controlProps> = (props) => {
    const {t} = useTranslation(['common', 'geomonitoring']);
    const dialogRef: any = useRef(null);

    const [activeIndex, setActiveIndex] = useState(0);
    // @ts-ignore
    const {map} = useContext(MapContext);
    const [accordionContent, setAccordionContent] = useState([]);
    const nowTemp = new Date();
    const minusThreeDaysTemp = new Date(nowTemp);
    minusThreeDaysTemp.setDate(nowTemp.getDate() - 3);
    const [triggerElements, {data: resultData, isLoading, isError, isSuccess}] = useLazyGetElementGeoDataQuery();
    const [position, setPosition] = useState<DialogPositionType>('right');
    const [screenCoords, setScreenCoords] = useState<any[]>([]);
    const [show, setShow] = useState<boolean>(false);


    const [elementTypes, setElementTypes] = useState<any[]>(props.elementTypes);
    const [triggerGetFields, resultGetFields, lastPromiseGetFields] = useLazyGetFieldsQuery();

    let dataTableArray: JSX.Element[] = [];


    useEffect(() => {
        if (props.elementTypes) {
            setElementTypes(props.elementTypes);
        }
    }, [props.elementTypes]);

    useEffect(() => {
        const temp = [];

        const height = window.innerHeight / 3;
        const width = window.innerWidth / 3;

        let top = 0;
        let bottom = height;
        let left = 0;
        let right = width;

        temp.push({top: top, bottom: bottom, left: left, right: right});

        left = right;
        right += width;

        temp.push({top: top, bottom: bottom, left: left, right: right});

        left = right;
        right += width;

        temp.push({top: top, bottom: bottom, left: left, right: right});

        top = bottom;
        bottom += height;
        left = 0;
        right = width;

        temp.push({top: top, bottom: bottom, left: left, right: right});

        left = right;
        right += width;

        temp.push({top: top, bottom: bottom, left: left, right: right});

        left = right;
        right += width;

        temp.push({top: top, bottom: bottom, left: left, right: right});

        top = bottom;
        bottom += height;
        left = 0;
        right = width;

        temp.push({top: top, bottom: bottom, left: left, right: right});

        left = right;
        right += width;

        temp.push({top: top, bottom: bottom, left: left, right: right});

        left = right;
        right += width;

        temp.push({top: top, bottom: bottom, left: left, right: right});

        setScreenCoords(temp);
    }, []);


    const checkPosition = () => {
        const element = dialogRef.current.getElement();

        const x = element.offsetLeft + (element.offsetWidth / 2);
        const y = element.offsetTop + (element.offsetHeight / 2);

        for (let i = 0; i < screenCoords.length; i++) {
            const coords = screenCoords[i];

            if (x >= coords.left && x <= coords.right && y >= coords.top && y <= coords.bottom) {

                switch (i) {
                    case 0:
                        setPosition('top-left');
                        break;
                    case 1:
                        setPosition('top');
                        break;
                    case 2:
                        setPosition('top-right');
                        break;
                    case 3:
                        setPosition('left');
                        break;
                    case 4:
                        setPosition('center');
                        break;
                    case 5:
                        setPosition('right');
                        break;
                    case 6:
                        setPosition('bottom-left');
                        break;
                    case 7:
                        setPosition('bottom');
                        break;
                    case 8:
                        setPosition('bottom-right');
                        break;
                }
                break;
            }
        }
    };

    const poiFeatureAtPixel = (pixel: any) => {
        dataTableArray = [];

        return new Promise(async (resolve) => {
            await map.forEachFeatureAtPixel(pixel, async function (f: any) {
                console.log(f)
                // await getDataTable(f);
                //console.log('testlog', f.getId());
                if (f.getId() !== 'shadow-layer') {
                    setShow(true);
                    if (f.get('features') && f.get('features').length > 0) {

                        dataTableArray = await getPopupContent(f.get('features'));
                    } else {
                        dataTableArray = await getPopupContent([f]);
                    }
                }
            });
            resolve(dataTableArray);
        });
    };

    const getPopupContent = async (features: any[]) => {
        const result: any[] = [];
        let nothingToShow = true;

        for (let i = 0; i < features.length; i++) {
            const feature = features[i];
            if (feature.getId()) {
                nothingToShow = false;
                const elementQuery = await triggerElements({
                    project_id: props.projectId,
                    mapping: {element_id: feature.getId(), mobile: false}
                });

                if (elementQuery.data && elementQuery.data.length) {
                    const element: UnitItem = {...elementQuery.data[0]};

                    let eTypes: any[] = [];
                    setElementTypes((prevState: any) => {
                        eTypes = prevState;
                        return prevState;
                    });

                    const elementType = eTypes.find((item) => {
                        return item.element_type_id === element.element_type_id;
                    });

                    if (elementType) {
                        const fields = await triggerGetFields({
                            projectId: props.projectId,
                            elementId: element.id
                        }).then((result: any) => {
                            return result.data;
                        });

                        element.fields = fields;
                        const header = (<div className='grid grid-nogutter w-full'><i
                            className={'col-fixed my-auto mr-2 ' + elementType.icon}/>
                            <p className={'col my-auto'}>{`${element.name} (${elementType.name})`}</p>
                            <Button className={'col-fixed p-button-rounded p-button-text p-2'}><FontAwesomeIcon
                                icon={faList} onClick={() => {
                                const url = `/project/${props.projectId}/#unittree_${element.id}`;

                                window.open(url, '_blank');
                                // window.location.reload();
                            }}/></Button>
                        </div>);

                        const values = await getFieldValues(element);

                        result.push(<AccordionTab header={header} key={element.id} headerClassName={'w-full'}>
                            <div className="m-0">
                                {values.map((item: any, index: number) => {
                                    return (<div className={'grid map_popup_content'}>
                                        <div className={'col'}>
                                            {item.label}
                                        </div>
                                        <div className={'col-fixed'}>
                                            {item.value}
                                        </div>
                                    </div>);
                                })}
                            </div>
                        </AccordionTab>);
                    }
                }
            }
        }
        return result;
    };

    const getFieldValues = async (item: UnitItem) => {
        const result: any[] = [];
        if (item && item.fields) {
            for (let i = 0; i < item.fields.length; i++) {
                const field = item.fields[i];

                if (field) {
                    const value = field.display_value ? field.display_value : field.value ? field.value : '';
                    result.push({label: field.display_name, value: value});
                }
            }
        }
        return result;
    };


    const mapClickEvent = async (e: any) => {
        setAccordionContent([]);
        setShow(false);
        let eTypes: any[] = [];
        setElementTypes((prevState: any) => {
            eTypes = prevState;
            return prevState;
        });

        if (eTypes) {
            const poiFeatureTables: any = await poiFeatureAtPixel(e.pixel);
            if (poiFeatureTables.length > 0) {
                setAccordionContent(poiFeatureTables);
            }
        }
    };

    useEffect(() => {
        if (!map || Object.keys(map).length === 0) return;

        map.on('click', async (evt: any) => {
            await mapClickEvent(evt);
        });

    }, [map]);

    return (
        <div className={'popup-controls'}>
            <Dialog
                className={'popup-control-dialog shadow-8'}
                ref={dialogRef}
                header={t('geoM_HeaderPopup')}
                visible={show} modal={false}
                style={{width: '500px', height: '400px'}}
                appendTo={'self'}
                position={position}
                resizable={false}
                onDragEnd={checkPosition}
                onHide={() => {
                    setShow(false);
                    setAccordionContent([]);
                }}>
                {/* popupTable.length <= 0 && <ProgressSpinner className={'m-auto w-full'}/>*/}
                {/* popupTable.length > 0 && <div className="overflow-hidden" style={{'minHeight': '50%'}}>*/}
                <div className="overflow-hidden" style={{'minHeight': '50%'}}>
                    <div className="overflow-auto h-full w-full"
                         style={{'minHeight': '50%'}}>
                        {(accordionContent.length <= 0) &&
                            <div className={'flex justify-content-center h-full w-full mt-5 overflow-hidden'}>
                                <ProgressSpinner
                                    className={'m-auto'}/>
                            </div>}
                        {accordionContent.length > 0 && <Accordion activeIndex={activeIndex}
                                                                   onTabChange={(e) => setActiveIndex(e.index)}>
                            {accordionContent}
                        </Accordion>}
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default PopupControl;
