import React, {useEffect, useState} from 'react';
import LayoutMainContentTabMenu from '../global/LayoutMainContentTabMenu';
import CustomDataTable from '../global/CustomDataTable';
import {getUnitColumns, getUnitFilters, UnitItem} from '../functions/Units';
import {
    useDeleteElementMutation,
    useGetElementTreeQuery,
    useUpdateElementMutation
} from '../../redux/rtk/injectedUnitsApi';
import {useTranslation} from 'react-i18next';
import {CustomConfirmDialog} from '../global/CustomConfirmDialog';
import {deleteUsers} from '../functions/UserManagement';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import {Button} from 'primereact/button';
import {UnitExplorer} from './UnitExplorer';
import CustomDialog from '../global/CustomDialog';
import {useAppDispatch, useAppSelector} from '../../redux/hooks';
import {resetElementChanges} from '../../redux/slices/ElementChangesSlice';

type Props = { project: any }

export const UnitManagementOverview = (props: Props) => {
    const {t} = useTranslation(['common']);

    const elementChanges: any[] = useAppSelector((state: any) => state.elementChangesData.elementChanges);
    const dispatch = useAppDispatch();

    const [updateElement, resultUpdateElement] = useUpdateElementMutation();
    const [deleteElement, resultDeleteElement] = useDeleteElementMutation();

    const elementTreeQuery = useGetElementTreeQuery({projectId: props.project.id});

    const [dataLoaded, setDataLoaded] = useState<boolean>(false);

    const [showUnit, setShowUnit] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<any>(undefined);

    const [showUnsavedDialog, setShowUnsavedDialog] = useState<boolean>(false);

    const [unitTree, setUnitTree] = useState<any[]>([]);

    useEffect(() => {
        if (elementTreeQuery.data) {
            let temp: any[] = [...elementTreeQuery.data];

            for (let i = 0; i < temp.length; i++) {
                let item = {...temp[i]};

                if (!item.internal_elements) {
                    item.internal_elements = [];
                }

                getChildrenAsArray(item, item.internal_elements);

                temp[i] = item;
            }
            setUnitTree(temp);
            setDataLoaded(true);
        }
    }, [elementTreeQuery]);

    const getChildrenAsArray = (item: any, array: string[]) => {
        array.push(item.name);
        if (item.children) {
            for (let i = 0; i < item.children.length; i++) {
                getChildrenAsArray(item.children[i], array);
            }
        }
    }

    const hideDialogs = (checkChanges: boolean = true) => {
        if (checkChanges && elementChanges && elementChanges.length) {
            setShowUnsavedDialog(true);
        } else {
            setShowUnit(false);
            setSelectedItem(undefined);
        }
    };

    const editClick = (item: any) => {
        setSelectedItem([item]);
        setShowUnit(true);
    };

    const removeClick = (dataRow: any) => {
        CustomConfirmDialog({
            header: t('dialogs_removeUnitHeader'),
            message: t('dialogs_removeUnitMessage'),
            translation: t,
            onConfirm: () => {
                deleteElement({element_id: dataRow['id'], project_id: props.project.id});
            }
        });
    };

    const hideUnsavedDialog = () => {
        setShowUnsavedDialog(false);
    };

    const confirmSaveDialog = async () => {
        let error = false;

        for (let i = 0; i < elementChanges.length; i++) {
            const item = {...elementChanges[i]};
            item.project_id = props.project.id;
            await updateElement(item).then((result: any) => {
                if (result.error) {
                    error = true;
                }
            });
        }

        if (!error) {
            showMessageOnSuccess(t('success'), t('units_saved'));
            dispatch(resetElementChanges());
            hideUnsavedDialog();
            hideDialogs(false);
        }
    };

    const cancelSaveDialog = () => {
        dispatch(resetElementChanges());
        hideUnsavedDialog();
        hideDialogs(false);
    };

    return (<div className={'h-full'}>
        <CustomDataTable id={'unit_datatable'} columns={getUnitColumns(t)} filters={getUnitFilters()}
                         editable={true}
                         onEditClick={editClick}
                         onRemoveClick={removeClick}
                         ableToUpdate={true}
                         ableToDelete={true}
                         headerTitle={t('units_overview')}
                         dataloaded={dataLoaded}
                         sortable={true} value={unitTree}
                         globalFilterFields={['id', 'name', 'internal_elements']}/>
        <CustomDialog onCancel={hideDialogs} visible={showUnit} header={selectedItem ? selectedItem[0].name : ''}
                      maximized={true}
                      showFooter={false}
                      customButtons={[<Button
                          type='submit'
                          form={'formNewPlant'}
                          label={t('save')}
                          icon="pi pi-check"
                          className="p-button-text"
                          disabled={false}
                      />]}>
            <UnitExplorer project={props.project} item={selectedItem}/>
            {/* <UnitManagementEditForm project={props.project} plant={undefined} formId={'formNewPlant'} onChange={_setDirty}*/}
            {/*                        onSubmit={onCreateUnit}/>*/}
        </CustomDialog>
        {showUnsavedDialog && <CustomDialog visible={showUnsavedDialog} header={t('units_dialogs_unsavedChanges')}
                                            onCancel={hideUnsavedDialog}
                                            onHide={hideUnsavedDialog}
                                            customButtons={[
                                                <Button className={'p-button p-button-text'}
                                                        icon={'pi pi-check my-auto mr-2'}
                                                        onClick={confirmSaveDialog}>{t('yes')}</Button>,
                                                <Button className={'p-button p-button-text'}
                                                        icon={'pi pi-times my-auto mr-2'}
                                                        onClick={cancelSaveDialog}>{t('no')}</Button>,
                                                <Button className={'p-button p-button-text'}
                                                        onClick={hideUnsavedDialog}>{t('cancel')}</Button>]}>{t('units_dialogs_unsavedChangesMessage')}</CustomDialog>}
    </div>);
};
