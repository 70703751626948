import React, {useCallback, useEffect, useState} from 'react';
import Input from '../global/Input';
import {Button} from 'primereact/button';
import {useTranslation} from 'react-i18next';
import {
    ElementTypeItem,
    getElementType,
    getElementTypeIconClass,
    getElementTypeName,
    UnitItem
} from '../functions/Units';
import {useGetDocumentsQuery,} from '../../redux/rtk/injectedUnitsApi';
import {Nullable} from '../functions/Global';
import {TabPanel, TabView} from 'primereact/tabview';
import {ElementFileManagement} from './ElementFileManagement';
import {useAppDispatch, useAppSelector} from '../../redux/hooks';
import {ProgressSpinner} from 'primereact/progressspinner';
import settings from '../../config/settings';
import {Galleria} from 'primereact/galleria';
import {classNames} from 'primereact/utils';
import GeoData from '../../views/GeoData';
import {useGetInspectionsQuery} from "../../redux/rtk/injectedInspectionApi";
import StatusLight from "../global/StatusLight";
import moment from "moment";
import {UnitObjectInput} from "./UnitObjectInput";
import {UnitName} from "./UnitName";
import {UnitFields, UnitFieldType} from "./UnitFields";
import {UnitSubtype} from "./UnitSubtype";
import {confirmDialog} from "primereact/confirmdialog";
import {InspectionElementTasks} from "../inspectionManagement/InspectionElementTasks";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGlasses} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import {Accordion, AccordionTab} from "primereact/accordion";
import {Tooltip} from "primereact/tooltip";

type Props = {
    project: any,
    item: UnitItem,
    elementTypes: ElementTypeItem[],
    topElements: any[],
    new: boolean,
    showLoading?: boolean,
    geoData?: any,
    onClick: (id: string, scrollIntoView: boolean) => void,
    onAddClick: (parentId: string, object: ElementTypeItem | undefined) => void,
    onConnectClick: (startId: string, targetId: string) => void,
    onDeleteClick?: (id: string) => void,
    saveData: () => void,
}

export const UnitExplorerContent = (props: Props) => {
    const {t} = useTranslation(['common']);

    const navigate = useNavigate();

    const elementChanges: any[] = useAppSelector((state: any) => state.elementChangesData.elementChanges);
    const mapReady: any = useAppSelector((state: any) => state.elementChangesData.mapReady4Saved);
    const apiCalls: any = useAppSelector((state: any) => state.apiCallsData.apiCalls);
    const dispatch = useAppDispatch();

    const documentsQuery = useGetDocumentsQuery({
        projectId: props.project.id,
        elementId: props.item ? props.item.id : undefined
    }, {skip: !props.item});

    const inspectionQuery = useGetInspectionsQuery({
        projectId: props.project.id,
        elementId: props.item ? props.item.id : undefined
    }, {skip: !props.item});

    const [inspections, setInspections] = useState<any[]>([]);

    const [item, setItem] = useState<Nullable<UnitItem>>(props.item);
    const [elementType, setElementType] = useState<Nullable<ElementTypeItem>>(null);
    const [filesCount, setFilesCount] = useState<number>(0);

    const [previewImages, setPreviewImages] = useState<any[]>([]);

    const [disableSubmit, setDisableSubmit] = useState<any>(true);


    const [sortElement, setSortElement] = useState<string>('unit_sortelement_name');

    const sortElements = ['unit_sortelement_name', 'unit_sortelement_status'];
    const [newItem, setNewItem] = useState<boolean>(props.new);

    useEffect(() => {
        // setItem(null);
        setItem(null);
    }, []);

    useEffect(() => {
        setNewItem(props.new);
    }, [props.new]);

    useEffect(() => {
        if (item) {
            console.log('item', item)
            setElementType(getElementType(item.element_type_id, props.elementTypes))
        }
    }, [item]);

    useEffect(() => {
        if (props.item) {
            if (inspectionQuery && inspectionQuery.data) {
                setInspections(inspectionQuery.data.filter((item: any) => {
                    return item.element_id === props.item.id;
                }));
            }
        }
    }, [inspectionQuery]);

    useEffect(() => {
        if (props.item) {
            setItem((prevState) => {
                let temp = prevState ? JSON.parse(JSON.stringify(prevState)) : undefined;

                temp = {...temp, ...props.item};

                return temp;
            });
        }
    }, [props.item]);

    const onClickOpenImage = useCallback((src: string) => {
            window.open(src);
        },
        []);

    const itemTemplate = (item: any) => {
        return (<div className={'relative'}><img src={item.src}
            // onError={(e: any) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}
                                                 alt={item.alt} style={{
            width: 'auto',
            maxWidth: '100%',
            maxHeight: '250px'
        }}/>
            <div className={'mt-2 my-auto flex'}><label className={'my-auto'}>{item.alt}</label>
                <div className={'p-button p-button-text ml-2 pi pi-external-link'} onClick={() => {
                    onClickOpenImage(item.src)
                }}></div>
            </div>
        </div>);
    };


    const thumbnailTemplate = (item: any) => {
        return (<img src={item.src}
            // onError={(e: any) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}
                     alt={item.alt} style={{width: '100%', maxHeight: '40px'}}/>);
    };


    useEffect(() => {
        let disabled = true;
        switch (true) {
            case mapReady:
                disabled = false;
                break;
            case (!elementChanges || !elementChanges.length):
                disabled = true;
                break;
        }
        setDisableSubmit(disabled);

        //  console.log((!elementChanges || !elementChanges.length) , mapReady);
    }, [mapReady, elementChanges]);

    return (
        <>
            <div className={''}>
                <div className={'grid'}>
                    <div className={'col-fixed ue_back-button mb-4'}>
                        <Button icon={'pi pi-arrow-left'} className={'p-button-outlined p-button-rounded'}
                                onClick={() => {
                                    if (item) {
                                        if (item.parent && item.parent.length) {
                                            props.onClick(item.parent[item.parent.length - 1].id, true);
                                        }
                                    }
                                }}/>
                    </div>
                    <div className={'col pl-2'}>
                        {props.topElements && <Input edit={true} name={'top_element'} type={'dropdown'}
                                                     dropdownOptions={props.topElements}
                                                     value={item && item.parent && item.parent.length ? item.parent[0].id : item ? item.id : undefined}
                                                     onChange={(e: any) => {
                                                         props.onClick(e.target.value, true);
                                                     }}/>}
                    </div>
                </div>
            </div>
            {props.showLoading &&
                <div className={'flex justify-content-center w-full'} style={{marginTop: '30vh'}}><ProgressSpinner
                    className={'m-auto'}/>
                </div>}
            {newItem && <div>{<>
                {newItem &&
                    <div className={'mb-3'}>
                        <UnitObjectInput
                            project={props.project}
                            item={item}
                            elementTypes={props.elementTypes}
                            onClick={props.onAddClick}
                            onConnectClick={props.onConnectClick}>
                            {newItem && item && <div className={'mb-3'}>
                                <Button className={'p-button p-button-outlined p-button-danger'} icon={'pi pi-times'}
                                        type={'button'}
                                        label={t('units_remove_this_element')} onClick={() => {
                                    confirmDialog({
                                        header: t('dialogs_removeElementHeader'),
                                        message: t('dialogs_removeElementMessage'),
                                        icon: 'pi pi-exclamation-triangle',
                                        acceptLabel: t('yes'),
                                        rejectLabel: t('no'),
                                        accept: () => {
                                            if (props.onDeleteClick && item != null) {
                                                props.onDeleteClick(item.id);
                                            }
                                        }
                                    });
                                }}/>
                            </div>}
                        </UnitObjectInput></div>
                }
            </>}
            </div>}
            <div className={'grid col'}>
                {item && <TabView renderActiveOnly={false} className={'w-full'}>
                    <TabPanel
                        header={<div className={'flex'}><i className={'mr-2 ' + item.icon}/>{t('units_tabs_info')}
                        </div>}>
                        <br/>
                        {<div className={'grid'}>
                            {elementType && elementType.location_supported &&
                                <div
                                    className={classNames('mb-3 col-12 overflow-hidden', previewImages.length > 0 ? 'lg:col-6' : '')}
                                    style={{height: '450px'}}>
                                    {/*<h5 className="input-label">{t('units_position')}</h5>*/}
                                    {/* <h5 className="input-label">{t('units:position')}</h5>*/}
                                    <div id={'map'} className={'map h-full w-full'}>
                                        {<GeoData divheight={'436px'} item={item} alarm={false}
                                                  configActive={false} onConfigButtonClick={null}
                                                  onConfigClick={null}
                                                  baseControlEnabled={true}
                                                  project={props.project} show={true} mapId={item.id}
                                                  mapActive={false}/>}
                                    </div>
                                </div>}
                            {previewImages.length > 0 &&
                                <div
                                    className={classNames('mb-3 col-12', elementType && elementType.location_supported ? 'lg:col-6' : '')}
                                    style={{height: '450px'}}>
                                    {/*<h5 className="input-label">{t('units_pictures')}</h5>*/}
                                    <Galleria value={previewImages}
                                              className={'h-full'}
                                              thumbnailsPosition={'top'}
                                              showThumbnails={previewImages.length > 1}
                                              item={itemTemplate} thumbnail={thumbnailTemplate}
                                    />
                                </div>}
                            <div className={'col-12'}>
                                <div className={'grid'}>
                                    <div className={'col-12 lg:col-4'}>
                                        <UnitName key={'unitname'} item={item}
                                                  elementTypes={props.elementTypes}/>
                                    </div>
                                    <div className={'col-12 lg:col-4'}>
                                        <UnitSubtype key={'unitsubtype'} item={item}
                                                     elementTypes={props.elementTypes}/>
                                    </div>
                                </div>
                            </div>
                            <div className={'col-12'}>
                                <div className={'grid'}>
                                    <UnitFields project={props.project} fields={item.fields} id={item.id}
                                                type={UnitFieldType.element} className={'col-12 lg:col-4'}/>
                                    <div className={'col-12 flex justify-content-end'}>
                                        <Button className={'p-button p-button-outlined p-button-success'}
                                                icon={'pi pi-check my-auto mr-2'} onClick={props.saveData}
                                                disabled={disableSubmit}>{t('save')}</Button>
                                    </div>
                                </div>
                            </div>

                            {item && item.inspection_task_status > 0 && inspections.length > 0 &&
                                <div className={'col-12'}>
                                    <h2>{t('unit_inspections')}</h2>
                                    <Accordion activeIndex={0}>
                                        {inspections.map((inspection, index) => {
                                            return (
                                                <AccordionTab header={<div className={'grid ml-2'}>
                                                    <div className={'col'}>
                                                        <h5>{t('name')}</h5>
                                                        <p>{inspection.name}</p>
                                                    </div>
                                                    <div className={'col'}>
                                                        <h5>{t('inspection_columns_timestamp')}</h5>
                                                        <p>{moment(inspection.timestamp).format(t('datetimeFormat'))}</p>
                                                    </div>
                                                    <div className={'col-6'}>
                                                        <h5>{t('inspection_columns_inspection_type_name')}</h5>
                                                        <p>{inspection.inspection_type_name}</p>
                                                    </div>
                                                    <div className={'col justify-content-end'}>
                                                        <div className={'flex justify-content-center'}>
                                                            <StatusLight id={'inspection_task_status'}
                                                                         value={item.inspection_task_status}
                                                                         info={''} light={false}
                                                                         horizontal={true}/>
                                                        </div>
                                                        <div className={'flex justify-content-center mt-3'}>
                                                            <Button
                                                                className={'p-button p-button-outlined p-button-text'}
                                                                onClick={() => {
                                                                    const url = `/project/${props.project.id}/#inspections_${inspection.id}`;

                                                                    navigate(url);
                                                                }}>
                                                                <Tooltip target={'#JumpToInspections'}
                                                                         content={t('jump_to_inspections')}
                                                                         position={'top'}/>
                                                                <FontAwesomeIcon id='JumpToInspections'
                                                                                 className="sidebar-icon"
                                                                                 icon={faGlasses}/>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    {/*<div className={'col'}>*/}
                                                    {/*    <h5>{t('inspection_columns_auf_tuev')}</h5>*/}
                                                    {/*    <p>{inspection.auf_tuev}</p>*/}
                                                    {/*</div>*/}
                                                </div>}>
                                                    <InspectionElementTasks project={props.project}
                                                                            inspectionId={inspection.id}
                                                                            elementId={props.item.id}
                                                                            elementName={props.item.name}
                                                                            showAddButton={false}/>
                                                </AccordionTab>);
                                        })}
                                    </Accordion>
                                </div>}

                            {item && item.children && item.children.length > 0 &&
                                <div className={'col-12'}>
                                    <div className={'grid'}>
                                        <div className={'col'}>
                                            <h2>{t('units_elements') + (item.children ? ' (' + item.children.length + ')' : '')}</h2>
                                        </div>
                                        <div className={'col-fixed'}><Input edit={true} name={'sortelement'}
                                                                            type={'dropdown'}
                                                                            dropdownOptions={sortElements.map((item: string) => {
                                                                                return {label: t(item), value: item};
                                                                            })}
                                                                            value={sortElement} onChange={(e: any) => {
                                            setSortElement(e.target.value);
                                        }}/></div>
                                    </div>

                                    <div className={'grid'}>
                                        {item && [...item.children].sort((a: UnitItem, b: UnitItem) => {
                                            switch (sortElement) {
                                                case 'unit_sortelement_name':
                                                    return 1;
                                                case 'unit_sortelement_status':
                                                    const s1 = a.inspection_task_status || 999;
                                                    const s2 = b.inspection_task_status || 999;

                                                    return s1 > s2 ? 1 : s1 < s2 ? -1 : 0;
                                            }
                                            return 1;
                                        }).map((item: any, index: number) => {
                                            return (<div className={'col-12 md:col'}>
                                                <div
                                                    className={'ue_content-item grid text-center p-2 card inspection-status-' + item.inspection_task_status}
                                                    onClick={() => {
                                                        props.onClick(item.id, true);
                                                    }}>
                                                    <div className={'w-full'}><i
                                                        className={'ue_content-item-icon ' + getElementTypeIconClass(item.element_type_id, props.elementTypes)}/>
                                                    </div>
                                                    <div className={'col p-1'}>
                                                        <p>{`${item.name} (${getElementTypeName(item.element_type_id, props.elementTypes)})`}</p>
                                                    </div>
                                                </div>
                                            </div>);
                                        })}
                                    </div>
                                </div>}
                        </div>}
                    </TabPanel>
                    <TabPanel header={<div className={'flex'}><i
                        className={'mr-2 pi pi-file'}/>{t('units_tabs_files') + (filesCount > 0 ? ` (${filesCount})` : '')}
                    </div>}>
                        <br/>
                        <ElementFileManagement project={props.project} id={item.id} type={UnitFieldType.element}
                                               editable={true}
                                               setFilesCount={setFilesCount}
                                               setPreviewImages={setPreviewImages}
                                               showPreview={true}/>
                    </TabPanel>
                </TabView>}
            </div>
        </>
    );
};
