/*
 * SystemSettingsOverview.tsx
 * Author: lnappenfeld
 * Date: 18.08.2023
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import CustomDataTable, {ColumnObject} from '../global/CustomDataTable';
import {getSystemSettings, SystemSetting} from '../functions/System';

type Props = {
    reload: boolean,
    setReload: (reload: boolean) => void,
    setSelectedSystemSetting: (systemSetting: SystemSetting) => void,
    setShowSystemSettingDialog: (data: boolean) => void,
}

export const SystemSettingsOverview = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [systemSettings, setSystemSettings] = useState<SystemSetting[]>([]);

    useEffect(() => {
        _getSystemSettings();
    }, []);

    useEffect(() => {
        if (props.reload) {
            props.setReload(false);
            _getSystemSettings();
        }
    }, [props.reload]);

    const _getSystemSettings = () => {
        getSystemSettings().then(result => {
            setSystemSettings(result);
            setDataLoaded(true);
        });
    };

    const columns: ColumnObject[] = [
        {field: 'key', header: t('sysM_attribs_key'), style: {maxWidth: 400}},
        {field: 'value', header: t('sysM_attribs_value')}
    ];

    return (<CustomDataTable
        id='systemSettings'
        columns={columns}
        editable={true}
        sortable={true}
        value={systemSettings}
        onEditClick={(e: any) => {
            props.setSelectedSystemSetting(e);
            props.setShowSystemSettingDialog(true);
        }}
        ableToUpdate={true}
        dataloaded={dataLoaded}
    />);
};