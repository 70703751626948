import React, {useEffect, useState} from 'react';
import {BreadCrumb} from 'primereact/breadcrumb';
import {Project} from '../../components/functions/Project';
import CustomImage from '../../components/global/CustomImage';
import {useTranslation} from 'react-i18next';
import {Image} from 'primereact/image';
import settings from '../../config/settings';

type Props = {
    project: Project,
    colorScheme: string | null
}
const ProjectLogo = (props: Props) => {
    const {t} = useTranslation(['common']);
    const projectLogo = props.project === null ? null : props.project.logo;

    return (
        <>
            {projectLogo && <>
                <div className="project-logo">
                    <div className='card bg-white justify-content-end'>
                        <CustomImage
                            image={props.project.logo} showPlaceholder={true}
                            maxWidth={'auto'}/>
                    </div>
                </div>
                <div className='project-logo-name'>
                    <div>{props.project.name}</div>
                </div>
            </>}
        </>
    );
};

export default ProjectLogo;

