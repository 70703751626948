/*
 * Input.tsx
 * Author: fwunderlich
 * Date: 01.03.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useRef} from 'react';
import {InputText} from 'primereact/inputtext';
import {InputTextarea} from 'primereact/inputtextarea';
import {Dropdown} from 'primereact/dropdown';
import {MultiSelect} from 'primereact/multiselect';
import {Checkbox} from 'primereact/checkbox';
import {useTranslation} from 'react-i18next';

import {Controller} from 'react-hook-form';
import {classNames} from 'primereact/utils';
import {InputNumber} from 'primereact/inputnumber';
import {SelectButton} from 'primereact/selectbutton';
import {InputSwitch} from 'primereact/inputswitch';
import {TreeSelect, TreeSelectDisplayType} from 'primereact/treeselect';
import {Calendar} from 'primereact/calendar';
import {Tooltip} from 'primereact/tooltip';
import {Button} from 'primereact/button';
import {ViewButton} from './ViewButton';

type Props = {
    edit: boolean,
    label?: string,
    name: string,
    className?: string,
    inputClassName?: string,
    disabled?: boolean,
    dropdownOptions?: any,
    selectButtonOptions?: any,
    id?: string,
    type?: string,
    value?: any,
    options?: any,
    onBlur?: (e: any) => void,
    onFocus?: (e: any) => void,
    onShow?: () => void,
    onHide?: () => void,
    onChange?: (e: any) => void,
    onClick?: (e: any) => void,
    validationControl?: any,
    validationErrorMessage?: (name: string) => void,
    validationRules?: any,
    scrollHeight?: string,
    showClear?: boolean,
    selectionMode?: any, // ["single", "multiple", "checkbox"]
    maxFractionDigits?: number,
    min?: number, // Min-value for InputNumber
    max?: number, // Max-value for InputNumber
    maxLength?: number,
    step?: number, // Step for InputNumber
    useGrouping?: boolean, // Whether to use grouping separators, such as thousands separators for InputNumber
    tooltip?: string,
    placeholder?: string,
    display?: string, // ['comma', chip']
    tabIndex?: number,
    showTime?: boolean,
    minDate?: Date,
    maxDate?: Date,
    noMargin?: boolean
}

const Input = (props: Props): JSX.Element => {
    const {t} = useTranslation('input');

    let component = <></>;

    const ref = useRef<any>(null);

    useEffect(() => {
        if (ref.current) {
            // console.log('ref', ref.current);

            let input = ref.current;

            if (input.getElement) {
                input = input.getElement();
            }

            input.addEventListener('keydown', (e: any) => {
                if (e.keyCode === 13) {
                    e.preventDefault();
                    return false;
                }
            });
        }
    }, [ref.current]);


    // useEffect(() => {
    //     console.log('label: ', props.label);
    //     console.log('input: ', props.value);
    // }, [props.value]);

    let displayStr: TreeSelectDisplayType = 'comma';
    if (props.type === 'treeSelect') {
        if (props.selectionMode === 'multiple' || props.selectionMode === 'checkbox') {
            displayStr = 'chip';
        }
    }

    const groupedItemTemplate = (option: any) => {
        return (
            <div className="flex align-items-center">
                <i className="pi pi-list"></i><span>&nbsp;</span>
                <label><b>{option.label}</b></label>
            </div>
        );
    };

    const className = 'custom-inputfield mt-3' + (props.inputClassName ? ' ' + props.inputClassName : '');
    // Letztes Element hinter den Punkten oder props.name selbst
    const tooltipName = props.name.split('.').pop() || props.name;
    const inputLabel = props.label ?
        (<div className="flex"><h5
            className="input-label">{props.label + (props.validationControl && props.validationRules.required ? '*' : '')}</h5>
        {props.tooltip &&
                <>
                    <Tooltip target={'.' + tooltipName} content={props.tooltip}/>
                    <i className={'pi pi-info-circle pl-2 my-auto ' + tooltipName}/>
                </>}
        </div>) : null;

    // exclude edit from spreadoperator
    const {edit, ...spread} = props;

    delete spread.dropdownOptions;
    delete spread.selectionMode;
    delete spread.dropdownOptions;
    delete spread.showClear;

    // if (props.edit) {
    switch (props.type) {
        case 'number':


            component = props.validationControl ?
                (<div className="field">
                    {inputLabel}
                    <Controller
                        name={props.name} control={props.validationControl}
                        rules={props.validationRules || {required: t('input_required')}}
                        render={({field, fieldState}) => (
                            <InputNumber
                                ref={ref}
                                id={field.name}
                                className={'w-full'}
                                inputClassName={className + ' w-full ' + classNames({'p-invalid': fieldState.invalid})}
                                inputId="locale-german" locale="de-DE"
                                mode="decimal"
                                useGrouping={typeof props.useGrouping === 'boolean' ? props.useGrouping : true}
                                minFractionDigits={0}
                                maxFractionDigits={props.maxFractionDigits ? props.maxFractionDigits : 0}
                                min={props.min} max={props.max} step={props.step} showButtons={!!props.step}
                                buttonLayout={'horizontal'}
                                incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
                                disabled={props.disabled != null ? props.disabled : !props.edit}
                                tabIndex={props.tabIndex}
                                value={props.value || field.value}
                                onBlur={(e: any) => {
                                    if (props.onBlur) {
                                        props.onBlur(e);
                                    }
                                }}
                                onChange={(e: any) => {
                                    field.onChange(e.value);
                                    if (props.onChange) {
                                        props.onChange(e.value);
                                    }
                                }}
                            />
                        )}
                    />
                    {props.validationErrorMessage ? props.validationErrorMessage(props.name) : null}

                </div>)
                : (<div className="field">
                    {inputLabel}
                    <InputNumber
                        ref={ref}
                        className={'w-full'}
                        inputClassName={className + ' w-full'}
                        inputId="locale-german" locale="de-DE"
                        mode="decimal"
                        useGrouping={typeof props.useGrouping === 'boolean' ? props.useGrouping : true}
                        minFractionDigits={0}
                        maxFractionDigits={props.maxFractionDigits ? props.maxFractionDigits : 0}
                        min={props.min} max={props.max} step={props.step} showButtons={!!props.step}
                        buttonLayout={'horizontal'}
                        incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
                        disabled={props.disabled != null ? props.disabled : !props.edit}
                        name={props.name}
                        value={props.value}
                        tabIndex={props.tabIndex}
                        onBlur={(e: any) => {
                            if (props.onBlur) {
                                props.onBlur(e);
                            }
                        }}
                        onChange={(e: any) => {
                            // console.log(e);
                            if (props.onChange) {
                                props.onChange(e.value);
                            }
                        }}/>

                </div>);
            break;

        case 'date':
            component = props.validationControl ?
                (<div className="field">
                    {inputLabel}
                    <Controller
                        name={props.name} control={props.validationControl}
                        rules={props.validationRules || {required: t('input_required')}}
                        render={({field, fieldState}) =>
                            (<Calendar
                                ref={ref}
                                id={field.name}
                                className={className + ' w-full ' + classNames({'p-invalid': fieldState.invalid})}
                                showTime={props.showTime != null ? props.showTime : true} dateFormat="dd.mm.yy"
                                disabled={props.disabled != null ? props.disabled : !props.edit}
                                value={props.value || field.value}
                                minDate={props.minDate}
                                maxDate={props.maxDate}
                                tabIndex={props.tabIndex}
                                onHide={(e: any) => {
                                    if (props.onBlur) {
                                        props.onBlur(e);
                                    }
                                }}
                                onChange={(e: any) => {
                                    field.onChange(e);
                                    if (props.onChange) {
                                        props.onChange(e);
                                    }
                                }}
                                {...props.options}
                            />)}
                    />
                    {props.validationErrorMessage ? props.validationErrorMessage(props.name) : null}
                </div>)
                : (<div className="field">
                    {inputLabel}
                    <Calendar
                        ref={ref}
                        className={className + ' w-full'}
                        showTime={props.showTime != null ? props.showTime : true} dateFormat="dd.mm.yy"
                        disabled={props.disabled != null ? props.disabled : !props.edit}
                        name={props.name}
                        value={props.value}
                        tabIndex={props.tabIndex}
                        onHide={(e: any) => {
                            if (props.onBlur) {
                                props.onBlur(e);
                            }
                        }}
                        onChange={(e: any) => {
                            if (props.onChange) {
                                props.onChange(e);
                            }
                        }}
                        {...props.options}
                    />
                </div>);
            break;

        case 'dropdown':
            component = props.validationControl
                ? (
                    <div className="field">
                        {inputLabel}
                        <Controller
                            name={props.name} control={props.validationControl}
                            rules={props.validationRules || {required: t('input_required')}}
                            render={({field, fieldState}) =>
                                (<Dropdown
                                    {...props.options}
                                    id={field.name}
                                    ref={ref}
                                    className={className + ' w-full ' + classNames({'p-invalid': fieldState.invalid})}
                                    disabled={props.disabled != null ? props.disabled : !props.edit}
                                    name={field.name}
                                    scrollHeight={props.scrollHeight || '200px'} filter
                                    showClear={props.showClear}
                                    options={props.dropdownOptions}
                                    value={props.value !== undefined ? props.value : field.value}
                                    tabIndex={props.tabIndex}
                                    onBlur={(e: any) => {
                                        if (props.onBlur) {
                                            props.onBlur(e);
                                        }
                                    }}
                                    onChange={(e: any) => {
                                        if (typeof e.value === 'undefined') e.value = null; // possible onClear

                                        field.onChange(e.value);
                                        if (props.onChange) {
                                            props.onChange(e);
                                        }
                                    }}
                                />
                                )}
                        />
                        {props.validationErrorMessage ? props.validationErrorMessage(props.name) : null}

                    </div>
                )
                : (<div className="field">
                    {inputLabel}
                    <Dropdown
                        {...props.options}
                        ref={ref}
                        className={className + ' w-full'}
                        disabled={props.disabled != null ? props.disabled : !props.edit} name={props.name}
                        options={props.dropdownOptions} value={props.value}
                        tabIndex={props.tabIndex}
                        scrollHeight={props.scrollHeight || '200px'} filter
                        showClear={props.showClear}
                        onBlur={(e: any) => {
                            if (props.onBlur) {
                                props.onBlur(e);
                            }
                        }}
                        onChange={(e: any) => {
                            if (typeof e.value === 'undefined') e.value = null; // possible onClear

                            if (props.onChange) {
                                props.onChange(e);
                            }
                        }}/>
                </div>);
            break;

        case 'multiselect':
            component = props.validationControl
                ? (<div className="field">
                    {inputLabel}
                    <Controller
                        name={props.name} control={props.validationControl}
                        rules={props.validationRules || {required: t('input_required')}}
                        render={({field, fieldState}) => (
                            <MultiSelect
                                id={field.name}
                                ref={ref}
                                className={className + ' w-full ' + classNames({'p-invalid': fieldState.invalid})}
                                disabled={props.disabled != null ? props.disabled : !props.edit}
                                name={field.name}
                                scrollHeight={props.scrollHeight || '200px'} filter
                                showClear={false}
                                optionLabel={'label'}
                                optionDisabled={'disabled'}
                                display={'chip'}
                                options={props.dropdownOptions}
                                value={props.value || field.value}
                                tabIndex={props.tabIndex}
                                // contentEditable={true}
                                onBlur={(e: any) => {
                                    if (props.onBlur) {
                                        props.onBlur(e);
                                    }
                                }}
                                onChange={(e: any) => {
                                    field.onChange(e.value);
                                    if (props.onChange) {
                                        props.onChange(e);
                                    }
                                }}
                                onShow={() => {
                                    if (props.onShow) {
                                        props.onShow();
                                    }
                                }}
                                onHide={() => {
                                    if (props.onHide) {
                                        props.onHide();
                                    }
                                }}
                            />
                        )}
                    />
                    {props.validationErrorMessage ? props.validationErrorMessage(props.name) : null}

                </div>)
                : (<div className="field">
                    {inputLabel}
                    <MultiSelect
                        ref={ref}
                        className={className + ' w-full'}
                        disabled={props.disabled != null ? props.disabled : !props.edit}
                        name={props.name}
                        options={props.dropdownOptions}
                        value={props.value}
                        tabIndex={props.tabIndex}
                        optionLabel={'label'}
                        display={'chip'}
                        // contentEditable={true}
                        scrollHeight={props.scrollHeight || '200px'} filter
                        showClear={props.showClear}
                        onBlur={(e: any) => {
                            if (props.onBlur) {
                                props.onBlur(e);
                            }
                        }}
                        onChange={(e: any) => {
                            if (props.onChange) {
                                props.onChange(e);
                            }
                        }}
                        onShow={() => {
                            if (props.onShow) {
                                props.onShow();
                            }
                        }}
                        onHide={() => {
                            if (props.onHide) {
                                props.onHide();
                            }
                        }}
                    />
                </div>);
            break;

        case 'groupeddropdown':
            component = props.validationControl ? (<div className="field">
                {inputLabel}
                <Controller
                    name={props.name} control={props.validationControl}
                    rules={props.validationRules || {required: t('input_required')}}
                    render={({field, fieldState}) =>
                        (<Dropdown id={field.name} {...field}
                            ref={ref}
                            className={className + ' w-full ' + classNames({'p-invalid': fieldState.invalid})}
                            disabled={props.disabled != null ? props.disabled : !props.edit} name={field.name}
                            scrollHeight={props.scrollHeight || '200px'} filter
                            options={props.dropdownOptions} value={props.value || field.value}
                            tabIndex={props.tabIndex}
                            optionLabel="label" optionGroupLabel="label" optionGroupChildren="items"
                            optionGroupTemplate={groupedItemTemplate}
                            onBlur={(e: any) => {
                                if (props.onBlur) {
                                    props.onBlur(e);
                                }
                            }}
                            onChange={(e: any) => {
                                field.onChange(e.value);
                                if (props.onChange) {
                                    props.onChange(e);
                                }
                            }}/>)}/>
                {props.validationErrorMessage ? props.validationErrorMessage(props.name) : null}

            </div>) :
                (<div className="field">
                    <Dropdown
                        ref={ref}
                        disabled={props.disabled != null ? props.disabled : !props.edit} value={props.value}
                        tabIndex={props.tabIndex}
                        options={props.dropdownOptions}
                        scrollHeight={props.scrollHeight || '200px'} filter
                        onBlur={(e: any) => {
                            if (props.onBlur) {
                                props.onBlur(e);
                            }
                        }}
                        onChange={(e: any) => {
                            if (props.onChange) {
                                props.onChange(e);
                            }
                        }} optionLabel="label" optionGroupLabel="label" optionGroupChildren="items"
                        optionGroupTemplate={groupedItemTemplate} className={className + ' w-full'}/>
                    {props.label && <h5 className="input-label">{props.label}</h5>}</div>);
            break;

        case 'inputswitch':
            component = props.validationControl
                ? (<div className="field">
                    <h5>ValidationControl not working</h5>
                    {/* <Controller*/}
                    {/*    name={props.name} control={props.validationControl}*/}
                    {/*    rules={props.validationRules || {required: t('input_required')}}*/}
                    {/*    render={({field, fieldState}) => (*/}
                    {/*        <InputSwitch*/}
                    {/*            id={field.name} {...field}*/}
                    {/*            className={className  + classNames({'p-invalid': fieldState.invalid})}*/}
                    {/*            disabled={props.disabled != null ? props.disabled : !props.edit}*/}
                    {/*            name={field.name}*/}
                    {/*            checked={field.value}*/}
                    {/*            onChange={(e: any) => {*/}
                    {/*                if (props.onChange) {*/}
                    {/*                    props.onChange(e);*/}
                    {/*                }*/}
                    {/*            }}*/}
                    {/*        />*/}
                    {/*    )}*/}
                    {/* />*/}
                    {/* {props.validationErrorMessage ? props.validationErrorMessage(props.name) : null}*/}
                    {/* {inputLabel}*/}
                </div>)
                : (<div className="field">
                    {props.label
                        ? <h5 className={'mt-3'}>{props.label}</h5>
                        : null}
                    <InputSwitch
                        ref={ref}
                        className={'mt-3'}
                        disabled={props.disabled != null ? props.disabled : !props.edit}
                        name={props.name}
                        checked={props.value}
                        tabIndex={props.tabIndex}
                        onBlur={(e: any) => {
                            if (props.onBlur) {
                                props.onBlur(e);
                            }
                        }}
                        onChange={(e: any) => {
                            if (props.onChange) {
                                props.onChange(e);
                            }
                        }}/>
                </div>);
            break;
        case 'textarea':
            component = props.validationControl ? (<div className="field">
                {inputLabel}
                <Controller
                    name={props.name} control={props.validationControl}
                    rules={props.validationRules || {required: t('input_required')}}
                    render={({field, fieldState}) =>
                        (<InputTextarea id={field.name} {...field}
                            className={className + ' w-full ' + classNames({'p-invalid': fieldState.invalid})}
                            disabled={props.disabled != null ? props.disabled : !props.edit}
                            name={props.name}
                            autoResize={true}
                            value={props.value || (field.value === null ? '' : field.value)}
                            tabIndex={props.tabIndex}
                            onBlur={(e: any) => {
                                if (props.onBlur) {
                                    props.onBlur(e);
                                }
                            }}
                            onChange={(e: any) => {
                                // Wenn das Inputfeld vom User komplett geleert wird, bleibt ein leerer String zurück.
                                // Das würde dann so in die Datenbank geschrieben werden. Damit Null-Werte gespeichert
                                // werden, wird der Wert auf null gesetzt.
                                let _e = e;
                                if (e.target.value === '') {
                                    _e = {
                                        ...e,
                                        target: {
                                            value: null
                                        }
                                    };
                                }
                                field.onChange(_e);
                                if (props.onChange) {
                                    props.onChange(_e);
                                }
                            }}/>)}/>
                {props.validationErrorMessage ? props.validationErrorMessage(props.name) : null}
            </div>) :
                (<div className="field">
                    {inputLabel}
                    <InputTextarea className={className + ' w-full'}
                        disabled={props.disabled != null ? props.disabled : !props.edit} name={props.name}
                        autoResize={true}
                        value={props.value}
                        tabIndex={props.tabIndex}
                        onBlur={(e: any) => {
                            if (props.onBlur) {
                                props.onBlur(e);
                            }
                        }}
                        onChange={(e: any) => {
                            // Wenn das Inputfeld vom User komplett geleert wird, bleibt ein leerer String zurück.
                            // Das würde dann so in die Datenbank geschrieben werden. Damit Null-Werte gespeichert
                            // werden, wird der Wert auf null gesetzt.
                            let _e = e;
                            if (e.target.value === '') {
                                _e = {
                                    ...e,
                                    target: {
                                        value: null
                                    }
                                };
                            }
                            if (props.onChange) {
                                props.onChange(_e);
                            }
                        }}/>

                </div>);
            break;
        case 'checkbox':
            component = props.validationControl ? (
                <div className={classNames('field', props.noMargin === true ? '' : 'mt-4', props.className)}>
                    <Controller
                        name={props.name} control={props.validationControl}
                        rules={props.validationRules || {required: t('input_required')}}
                        render={({field, fieldState}) =>
                            (<Checkbox
                                ref={ref}
                                inputId={field.name}
                                className={classNames(className, 'mt-2', {'p-invalid': fieldState.invalid}, props.inputClassName)}
                                disabled={props.disabled != null ? props.disabled : !props.edit}
                                checked={field.value}
                                tabIndex={props.tabIndex}
                                onBlur={(e: any) => {
                                    if (props.onBlur) {
                                        props.onBlur(e);
                                    }
                                }}
                                onChange={(e: any) => {
                                    field.onChange(e.checked);
                                    if (props.onChange) {
                                        props.onChange(e);
                                    }
                                }}
                            />)}
                    />
                    {props.label &&
                            <label
                                className={'m-auto pl-2'}
                                style={{'fontWeight': 'bold'}}>{props.label + (props.validationControl && props.validationRules.required ? '*' : '')}</label>}
                    {props.tooltip && <>
                        <Tooltip target={'.' + tooltipName} content={props.tooltip}/>
                        <i className={'pi pi-info-circle pl-2 my-auto ' + tooltipName}/>
                    </>}

                </div>) :
                (<div className={classNames('field col-12', props.noMargin === true ? '' : 'mt-4', props.className)}>
                    <Checkbox
                        ref={ref}
                        className={classNames(className, 'mt-2', props.inputClassName)}
                        inputId={props.name}
                        disabled={props.disabled != null ? props.disabled : !props.edit}
                        checked={props.value}
                        tabIndex={props.tabIndex}
                        onBlur={(e: any) => {
                            if (props.onBlur) {
                                props.onBlur(e);
                            }
                        }}
                        onChange={(e: any) => {
                            if (props.onChange) props.onChange({
                                target: {
                                    name: e.target.name,
                                    value: e.target.checked
                                }
                            });
                        }}/>
                    {props.label &&
                        <label className={'m-auto pl-2'} style={{'fontWeight': 'bold'}}>{props.label}</label>}
                </div>);
            break;
        case 'selectButton':
            component = props.validationControl
                ? (<div className={'field mt-2'}>
                    {props.label &&
                        <h5>{props.label + (props.validationControl && props.validationRules.required ? '*' : '')}</h5>}
                    <Controller
                        name={props.name}
                        control={props.validationControl}
                        rules={props.validationRules || {required: t('input_required')}}
                        render={({field, fieldState}) =>
                            (<SelectButton
                                className={className + ' w-full ' + classNames({'p-invalid': fieldState.invalid})}
                                disabled={props.disabled != null ? props.disabled : !props.edit}
                                value={field.value}
                                tabIndex={props.tabIndex}
                                options={props.selectButtonOptions}
                                optionLabel="name"
                                onBlur={(e: any) => {
                                    if (props.onBlur) {
                                        props.onBlur(e);
                                    }
                                }}
                                onChange={(e: any) => {
                                    field.onChange(e.checked);
                                    if (props.onChange) {
                                        props.onChange(e);
                                    }
                                }}
                            />)}
                    />
                </div>)
                : (<div className={'field mt-2'}>
                    {props.label && <h5>{props.label}</h5>}
                    <SelectButton
                        className={className + ' mt-2'}
                        disabled={props.disabled != null ? props.disabled : !props.edit}
                        value={props.value}
                        tabIndex={props.tabIndex}
                        options={props.selectButtonOptions}
                        optionLabel="name"
                        onBlur={(e: any) => {
                            if (props.onBlur) {
                                props.onBlur(e);
                            }
                        }}
                        onChange={(e: any) => {
                            if (props.onChange) props.onChange(e);
                        }}
                    />
                </div>);
            break;
        case 'treeSelect':
            component = props.validationControl
                ? (<div className="field">
                    {inputLabel}
                    <Controller
                        name={props.name} control={props.validationControl}
                        rules={props.validationRules || {required: t('input_required')}}
                        render={({field, fieldState}) =>
                            (<div className={'relative'}>
                                <TreeSelect
                                    className={className + ' w-full ' + classNames({'p-invalid': fieldState.invalid})}
                                    disabled={props.disabled != null ? props.disabled : !props.edit}
                                    value={field.value}
                                    tabIndex={props.tabIndex}
                                    options={props.dropdownOptions}
                                    selectionMode={props.selectionMode}
                                    display={displayStr}
                                    onBlur={(e: any) => {
                                        if (props.onBlur) {
                                            props.onBlur(e);
                                        }
                                    }}
                                    onChange={(e: any) => {
                                        field.onChange(e);
                                        if (props.onChange) {
                                            props.onChange(e);
                                        }
                                    }}
                                    scrollHeight={props.scrollHeight || '200px'} filter
                                />
                                {/* Den Clear-Button musste man selbst implementieren, weil PrimeReact dies bisher */}
                                {/* (min. bis Version 9.6.0) nicht im TreeSelect anbietet */}
                                {props.showClear && field.value &&
                                    <div className={'tree-select-close'}>
                                        <Button
                                            icon={'pi pi-times'}
                                            className={'p-button-text absolute'}
                                            aria-label={'Cancel'}
                                            onClick={(event) => {
                                                event.preventDefault();
                                                const e = {value: null};

                                                field.onChange(e.value);
                                                if (props.onChange) props.onChange(e);
                                            }}
                                        />
                                    </div>}
                            </div>)}
                    />
                    {props.validationErrorMessage ? props.validationErrorMessage(props.name) : null}
                </div>)
                : (<div className={'field'}>
                    {inputLabel}
                    <TreeSelect
                        className={className + ' w-full'}
                        disabled={props.disabled != null ? props.disabled : !props.edit}
                        value={props.value}
                        tabIndex={props.tabIndex}
                        options={props.dropdownOptions}
                        selectionMode={props.selectionMode}
                        display={displayStr}
                        onBlur={(e: any) => {
                            if (props.onBlur) {
                                props.onBlur(e);
                            }
                        }}
                        onChange={(e: any) => {
                            if (props.onChange) props.onChange(e);
                        }}
                        scrollHeight={props.scrollHeight || '200px'} filter
                    />
                    {/* Den Clear-Button musste man selbst implementieren, weil PrimeReact dies bisher */}
                    {/* (min. bis Version 9.6.0) nicht im TreeSelect anbietet */}
                    {props.showClear && props.value &&
                        <div className={'tree-select-close'}>
                            <Button
                                icon={'pi pi-times'}
                                className={'p-button-text absolute'}
                                aria-label={'Cancel'}
                                onClick={(event) => {
                                    event.preventDefault();
                                    const e = {value: null};
                                    if (props.onChange) props.onChange(e);
                                }}
                            />
                        </div>}
                </div>);
            break;
        case 'button':
            component = props.validationControl
                ? (
                    <div className="field">
                        {inputLabel}
                        <Controller
                            name={props.name} control={props.validationControl}
                            rules={props.validationRules || {required: t('input_required')}}
                            render={({field, fieldState}) =>
                                (<Button
                                    {...props.options}
                                    key={'butt_' + 1}
                                    id={field.name}
                                    style={{backgroundColor: 'rgba(68, 72, 109, 0.07)'}}
                                    className={className + ' w-full p-button-outlined' + classNames({'p-invalid': fieldState.invalid})}
                                    disabled={props.disabled != null ? props.disabled : !props.edit}
                                    name={field.name}
                                    label={props.value}
                                    tabIndex={props.tabIndex}
                                    onClick={(event: any) => {
                                        event.preventDefault();
                                        if (props.onClick) props.onClick(event);
                                    }}
                                />)}
                        />
                        {props.validationErrorMessage ? props.validationErrorMessage(props.name) : null}

                    </div>
                )
                : (<div className="field">
                    {inputLabel}
                    <Button
                        {...props.options}
                        key={'butt_' + 1}
                        style={{backgroundColor: 'rgba(68, 72, 109, 0.07)'}}
                        className={className + ' w-full p-button-outlined'}
                        disabled={props.disabled != null ? props.disabled : !props.edit}
                        name={props.name}
                        label={props.value}
                        tabIndex={props.tabIndex}
                        onClick={(event: any) => {
                            event.preventDefault();
                            if (props.onClick) props.onClick(event);
                        }}
                    />
                </div>);
            break;
        default:
            component = props.validationControl ?
                (<div className="field">
                    {inputLabel}
                    <Controller
                        name={props.name} control={props.validationControl}
                        rules={props.validationRules || {required: t('input_required')}}
                        render={({field, fieldState}) =>
                            (<InputText id={field.name}
                                {...field}{...spread}
                                ref={ref}
                                className={className + ' w-full ' + classNames({'p-invalid': fieldState.invalid})}
                                disabled={props.disabled != null ? props.disabled : !props.edit}
                                value={props.value || (field.value === null ? '' : field.value)}
                                tabIndex={props.tabIndex}
                                onBlur={(e: any) => {
                                    if (props.onBlur) {
                                        props.onBlur(e);
                                    }
                                }}
                                onChange={(e: any) => {
                                    // Wenn das Inputfeld vom User komplett geleert wird, bleibt ein leerer String zurück.
                                    // Das würde dann so in die Datenbank geschrieben werden. Damit Null-Werte gespeichert
                                    // werden, wird der Wert auf null gesetzt.
                                    let _e = e;
                                    if (e.target.value === '') {
                                        _e = {
                                            ...e,
                                            target: {
                                                value: null
                                            }
                                        };
                                    }
                                    field.onChange(_e);
                                    if (props.onChange) {
                                        props.onChange(_e);
                                    }
                                }}
                            />)}/>
                    {props.validationErrorMessage ? props.validationErrorMessage(props.name) : null}

                </div>) : (<div className="field">
                    {inputLabel}
                    <InputText
                        {...spread}
                        ref={ref}
                        className={className + ' w-full'}
                        disabled={props.disabled != null ? props.disabled : !props.edit}
                        name={props.name}
                        value={props.value}
                        tabIndex={props.tabIndex}
                        onBlur={(e: any) => {
                            if (props.onBlur) {
                                props.onBlur(e);
                            }
                        }}
                        onChange={(e: any) => {
                            // Wenn das Inputfeld vom User komplett geleert wird, bleibt ein leerer String zurück.
                            // Das würde dann so in die Datenbank geschrieben werden. Damit Null-Werte gespeichert
                            // werden, wird der Wert auf null gesetzt.
                            let _e = e;
                            if (e.target.value === '') {
                                _e = {
                                    ...e,
                                    target: {
                                        value: null
                                    }
                                };
                            }
                            if (props.onChange) {
                                props.onChange(_e);
                            }
                        }}/>

                </div>);
            break;
    }
    // } else {
    //     component = props.validationControl
    //         ? (<div className="field">
    //             <Controller
    //                 name={props.name}
    //                 control={props.validationControl}
    //                 rules={props.validationRules || {required: t('input_required')}}
    //                 render={({field, fieldState}) =>
    //                     (<>
    //                         <h5 className="p-0 m-0 mt-3 mb-1">{props.label}</h5>
    //                         <div className="ml-2">{props.value || field.value || '-'}</div>
    //                     </>)}
    //             />
    //             {props.validationErrorMessage ? props.validationErrorMessage(props.name) : null}
    //         </div>)
    //         : (<div className="field">
    //             <>
    //                 <div className="flex"><h5 className="p-0 m-0 mt-3 mb-1">{props.label}</h5>
    //                     {props.tooltip &&
    //                     <>
    //                         <Tooltip target=".pi" content={props.tooltip}/>
    //                         <i className="pi pi-info-circle pl-2 my-auto"/>
    //                     </>}
    //                 </div>
    //                 <div className="ml-2">{props.value || '-'}</div>
    //             </>
    //         </div>);
    // }

    return (
        <div className={classNames('custom-input w-full relative', (props.className ? props.className : ''))}>
            {component}
        </div>
    );
};

export default Input;
