/*
 * CustomImage.tsx
 * Author: lnappenfeld
 * Date: 30.05.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useRef} from 'react';
import {Image} from 'primereact/image';
import {type} from 'os';

type Props = {
    image: string | null,
    showPlaceholder: boolean,
    width?: string,
    height?: string,
    maxHeight?: string,
    maxWidth?: string,
    position?: string,
    margin?: string,
    top?: number,
    bottom?: number,
    left?: number,
    right?: number
}


const CustomImage = (props: Props): JSX.Element => {
    const inputImage = useRef<any>(null);

    const getBase64 = (file: any, cb: any) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    };

    const getImageUrl = (image: string | null) => {
        // console.log("image: ", image);
        if (typeof image !== 'undefined' && image) {
            if (image.indexOf('data:') === 0) {
                return image;
            } else {
                if (image.indexOf('/') === 0) {
                    return 'data:image/jpeg;base64,' + image;
                } else if (image.indexOf(('i')) === 0) {
                    return 'data:image/png;base64,' + image;
                }
            }
        }
        console.log('window.location.origin: ', window.location.origin)

        if (props.showPlaceholder)
            return window.location.origin + '/no_image.png';
    };

    return (
        <img
            style={{
                // maxWidth: '100%',
                maxWidth: (props.maxWidth ? props.maxWidth : '100%'),
                maxHeight: (props.maxHeight ? props.maxHeight : '100%'),
                width: (props.width ? props.width : undefined),
                height: (props.height ? props.height : undefined),
                position: (props.position ? props.position : undefined) as 'absolute',
                top: (typeof props.top === 'number' ? props.top : undefined),
                bottom: (typeof props.bottom === 'number' ? props.bottom : undefined),
                left: (typeof props.left === 'number' ? props.left : undefined),
                right: (typeof props.right === 'number' ? props.right : undefined),
                margin: (props.margin ? props.margin : undefined),
            }}
            src={getImageUrl(props.image)}
        />
    );
};

export default CustomImage;
