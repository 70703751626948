import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFieldArray } from 'react-hook-form';
import { Checkbox } from 'primereact/checkbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useLazyGetElementTypesGlobalQuery } from '../../redux/rtk/injectedProjectTypes';
import Input from '../global/Input';
import ProjectTypeSelectList from './ProjectTypeSelectList';
import {Tooltip} from 'primereact/tooltip';

type Props = {
    project: any,
    setCardsData: (data: any[]) => void;
    form: any;
}

const ProjectElementTypeEditForm = (props: Props): JSX.Element => {
    const {t} = useTranslation(['common']);

    const {control, formState: {errors}, handleSubmit, setValue, getValues, watch} = props.form;

    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control: control,
        name: 'items',
    });

    const getFormErrorMessage = (name: string) => {
        if (name.indexOf('.') >= 0) {
            const split = name.split('.');

            if (split.length === 3) {
                // @ts-ignore
                if (errors[split[0]] && errors[split[0]][split[1]] && errors[split[0]][split[1]][split[2]]) {
                    // @ts-ignore
                    const text = errors[split[0]][split[1]][split[2]].message;

                    return <small className="p-error custom">{text}</small>;
                }
            }
        } else {
            // @ts-ignore
            if (errors[name]) {
                // @ts-ignore
                const text = errors[name].message;

                return <small className="p-error custom">{text}</small>;
            }
        }
        return undefined;
    };

    const [cards, setCards] = useState<number[]>([]);
    const [cardStates, setCardStates] = useState<{ id: number, projectTypeId: string, element_type_id: string, checkboxes: { hasParent: boolean, showAsTree: boolean, multiParents: boolean, locationSupport: boolean }, elements: any[], elementList: any[], selectedIcon: string }[]>([]);
    const [nextId, setNextId] = useState(0);
    const [icons, setIcons] = useState<any>([]);
    const [triggerUrl] = useLazyGetElementTypesGlobalQuery();

    useEffect(() => {
        fetch('/fontawesome5/metadata/icons.json').then(r => r.json()).then(result => {
            const iconResult = [];
            for (let i = 0; i < Object.keys(result).length; i++) {
                const key = Object.keys(result)[i];
                const icon = result[key];
                if (icon.styles.indexOf('brands') < 0) {
                    iconResult.push('fa fa-' + key);
                }
            }
            setIcons(iconResult);
        }
        );
    }, []);

    const handleProjectTypeChange = async (id: number, newProjectTypeId: string) => {
        setCardStates(prevStates =>
            prevStates.map(cardState =>
                cardState.id === id
                    ? {...cardState, projectTypeId: newProjectTypeId, elements: [], elementList: []}
                    : cardState
            )
        );

        const result = await triggerUrl({locale: 'en', projectTypeId: newProjectTypeId});

        if (result.data) {
            setCardStates(prevStates =>
                prevStates.map(cardState =>
                    cardState.id === id
                        ? {
                            ...cardState,
                            elementList: result.data.map((item: any) => ({value: item.element_type_id, label: item.name}))
                        }
                        : cardState
                )
            );
        }
    };

    const handleElementChange = (id: number, newElements: any[]) => {
        setCardStates(prevStates =>
            prevStates.map(cardState =>
                cardState.id === id
                    ? {...cardState, elements: newElements}
                    : cardState
            )
        );
    };

    const handleCheckboxChange = (id: number, groupName: 'hasParent' | 'showAsTree' | 'multiParents' | 'locationSupport', value: boolean) => {
        setCardStates(prevStates =>
            prevStates.map(cardState =>
                cardState.id === id
                    ? {...cardState, checkboxes: {...cardState.checkboxes, [groupName]: value}}
                    : cardState
            )
        );
    };

    const handleIconClick = () => {
        const id = nextId;
        setNextId(nextId + 1);
        setCards([...cards, id]);
        setCardStates([...cardStates, {
            id,
            projectTypeId: '',
            element_type_id: '',
            checkboxes: {hasParent: true, showAsTree: true, multiParents: false, locationSupport: true},
            elements: [],
            elementList: [],
            selectedIcon: '',
        }]);
        append({
            id,
            projectTypeId: '',
            element_type_id: '',
            checkboxes: {hasParent: true, showAsTree: true, multiParents: false, locationSupport: true},
            elements: [],
            elementList: [],
            selectedIcon: '', });
        console.log(`Card added with ID: ${id}`);
    };

    const handleRemoveCard = (id: number) => {
        setCards(cards.filter(cardId => cardId !== id));
        setCardStates(cardStates.filter(cardState => cardState.id !== id));
        console.log(`Card removed with ID: ${id}`);
    };

    const handleElementTypeIdChange = (id: number, newElementTypeId: string) => {
        setCardStates(prevStates =>
            prevStates.map(cardState =>
                cardState.id === id
                    ? {...cardState, element_type_id: newElementTypeId}
                    : cardState
            )
        );
    };

    const handleIconChange = (id: number, newIcon: string) => {
        setCardStates(prevStates =>
            prevStates.map(cardState =>
                cardState.id === id
                    ? { ...cardState, selectedIcon: newIcon }
                    : cardState
            )
        );
    };


    useEffect(() => {
        props.setCardsData(cardStates); // Updates the parent with current cards data
    }, [cardStates]); // Runs every time cardStates changes

    const renderCard = (id: number, index: number) => {
        const cardState = cardStates.find(card => card.id === id);
        return (
            <div key={id} className="card"
                style={{flex: '1 1 25%', display: 'flex', flexDirection: 'column', position: 'relative'}}>
                <Tooltip target=".timesIcon" mouseTrack mouseTrackLeft={10} />
                <FontAwesomeIcon className="timesIcon"
                    icon={faTimes}
                    data-pr-tooltip={t('removeElementType')}
                    style={{
                        position: 'absolute',
                        top: '0.5rem',
                        right: '0.5rem',
                        cursor: 'pointer',
                        fontSize: '1.2rem',
                        // color: 'gray'
                        color: '#FC6161'
                    }}
                    onClick={() => handleRemoveCard(id)}
                />
                <div className="text-2xl font-bold mb-3">{t('Elementtyp')}</div>
                <div className="grid">
                    <div className="col-12 md:col-6">
                        <ProjectTypeSelectList
                            setValue={(e) => handleProjectTypeChange(id, e)}
                            label={t('projM_projectTypeName')}
                            name={`items.${index}.projM_projectTypeName`}
                            validationControl={control}
                            validationErrorMessage={getFormErrorMessage}
                            validationRules={{required: t('projM_projectTypeName') + ' ' + t('input_required')}}
                        />
                    </div>
                    <div className="col-12 md:col-6">
                        <Input
                            edit={true} label={t('element_type_id')} name={`element_type_id_${id}`} type={'text'}
                            value={cardState?.element_type_id || ''}
                            onChange={(e) => handleElementTypeIdChange(id, e.target.value)}
                            validationControl={control}
                            validationErrorMessage={getFormErrorMessage}
                            validationRules={{required: t('element_type_id') + ' ' + t('input_required')}}

                        />
                    </div>
                </div>

                <div className="grid">
                    <div className="col-12 md:col-6">
                        <div className="text-xl font-semibold mb-2">{t('element_type_has_parent')}</div>
                        <div className="mb-2">
                            <div className="flex items-center mb-1">
                                <Checkbox inputId={`element_type_has_parent${id}`} name={t('Baumstruktur')}
                                    onChange={() => handleCheckboxChange(id, 'hasParent', !cardState?.checkboxes.hasParent)}
                                    checked={cardState?.checkboxes.hasParent} />
                                <label htmlFor={`element_type_has_parent${id}`}
                                    className="ml-2 font-semibold">{t('Baumstruktur')}</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 md:col-6">
                        <div className="text-xl font-semibold mb-2">{t('element_type_show_as_tree')}</div>
                        <div className="mb-2">
                            <div className="flex items-center mb-1">
                                <Checkbox inputId={`element_type_show_as_tree${id}`} name={t('Ja')}
                                    onChange={() => handleCheckboxChange(id, 'showAsTree', !cardState?.checkboxes.showAsTree)}
                                    checked={cardState?.checkboxes.showAsTree}/>
                                <label htmlFor={`element_type_show_as_tree${id}`}
                                    className="ml-2 font-semibold">{t('Ja')}</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid">
                    <div className="col-12 md:col-6">
                        <div className="text-xl font-semibold mb-2">{t('element_type_multi_parents')}</div>
                        <div className="mb-2">
                            <div className="flex items-center mb-1">
                                <Checkbox inputId={`element_type_multi_parents${id}`} name={t('Ja')}
                                    onChange={() => handleCheckboxChange(id, 'multiParents', !cardState?.checkboxes.multiParents)}
                                    checked={cardState?.checkboxes.multiParents}/>
                                <label htmlFor={`element_type_multi_parents${id}`}
                                    className="ml-2 font-semibold">{t('Ja')}</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 md:col-6">
                        <div className="text-xl font-semibold mb-2">{t('element_type_location_support')}</div>
                        <div className="mb-2">
                            <div className="flex items-center mb-1">
                                <Checkbox inputId={`element_type_location_support${id}`} name={t('Ja')}
                                    onChange={() => handleCheckboxChange(id, 'locationSupport', !cardState?.checkboxes.locationSupport)}
                                    checked={cardState?.checkboxes.locationSupport}/>
                                <label htmlFor={`element_type_location_support${id}`}
                                    className="ml-2 font-semibold">{t('Ja')}</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid">
                    <div className="col-12 md:col-6">
                        <Input
                            dropdownOptions={cardState?.elementList || []}
                            type="multiselect"
                            id={`element_type_children_type_ids_${id}`}
                            edit={true}
                            name={`element_type_children_type_ids_${id}`}
                            label={t('element_type_children_type_ids')}
                            value={cardState?.elements || []}
                            onChange={(e) => handleElementChange(id, e.value)}
                        />
                    </div>

                    <div className="col-12 md:col-6">
                        <Input options={{
                            valueTemplate: (item: any) => {
                                if (item) {
                                    return (<div className={'flex'}><i className={'mr-2 mt-1 ' + item?.value}/>{item?.value}
                                    </div>);}
                                return 0;
                            }, itemTemplate: (item: any) => {
                                return (<div className={'flex'}><i className={'mr-2 mt-1 ' + item.value}/>{item.value}
                                </div>);
                            },
                        }} edit={true} type={'dropdown'} value={cardState?.selectedIcon || ''}  showClear={true} onChange={(e) => {
                            handleIconChange(id, e.target.value);
                        }} dropdownOptions={icons.map((item: any) => {
                            return {value: item, label: item};
                        })}
                        name={`element_type_icon${id}`}
                        label={t('element_type_icon')}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const addCardStyles: React.CSSProperties = {
        flex: '1 1 25%',
        display: 'flex',
        flexDirection: 'column',
        minWidth: '250px',
        minHeight: cards.length === 0 ? '100px' : 'auto',
    };

    const cardContainerStyles: React.CSSProperties = {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '1rem',
        padding: '0 0.5rem',
        overflowY: 'auto',
        height: 'calc(100% - 50px)' // Höhe des Containers für die Karten
    };

    return (
        <>
            <form id="projectForm" style={{height: '100%'}}>
                <div className="grid" style={cardContainerStyles}>
                    {cards.map((id, index) => renderCard(id, index))}
                    <div className="card" style={addCardStyles}>
                        <div className="text-2xl font-bold mb-3">{t('Elementtyp')}</div>
                        <div style={{
                            flex: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}>
                            <FontAwesomeIcon
                                className="mx-2 mb-1"
                                icon={faCirclePlus}
                                style={{fontSize: 30, cursor: 'pointer'}}
                                onClick={handleIconClick}
                            />
                            <span>{t('projM_addElementType')}</span>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};

export default ProjectElementTypeEditForm;
