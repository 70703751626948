import React, {useEffect, useRef, useState} from 'react';
import {OverlayPanel} from "primereact/overlaypanel";
import Input from "../global/Input";
import {Button} from "primereact/button";
import {flattenObjectForPipeline, getElementTypeIconClass, getElementTypeName} from "../functions/Units";
import {useTranslation} from "react-i18next";
import {useGetElementTreeQuery, useGetElementTypesQuery} from "../../redux/rtk/injectedUnitsApi";
import {getUserFromLocalStorage} from "../functions/Global";

type Props = {
    projectId: string,
    onClick: (id: string) => void
    // isPipeline: boolean,
}


export const UnitExplorerSearch = (props: Props) => {
    const {t} = useTranslation(['common']);
    const op = useRef<OverlayPanel>(null);
    const user = getUserFromLocalStorage();

    const elementTreeQuery = useGetElementTreeQuery({projectId: props.projectId});
    const elementTypesQuery = useGetElementTypesQuery({
        projectId: props.projectId,
        locale: user ? user.language_id : 'de'
    });

    const [unitTree, setUnitTree] = useState<any[]>([]);

    const [searchValue, setSearchValue] = useState<any>(undefined);
    const [searchChildren, setSearchChildren] = useState<any>(undefined);

    useEffect(() => {
        if (elementTreeQuery.data) {
            // if (props.isPipeline) {
            //     const temp = JSON.parse(JSON.stringify(elementTreeQuery.data));
            //
            //     const flat = [];
            //     for (let i = 0; i < temp.length; i++) {
            //         const item = temp[i];
            //         flat.push(flattenObjectForPipeline(item));
            //     }
            //     console.log('flat', flat);
            //     setUnitTree(flat);
            // } else {
            const temp = JSON.parse(JSON.stringify(elementTreeQuery.data));

            if (unitTree.length) {
                setUnitTree((prevState) => {
                    let tempState = JSON.parse(JSON.stringify(prevState));

                    tempState = temp;
                    return tempState;
                });
            } else {
                setUnitTree(temp);
            }
            // }
        }
    }, [elementTreeQuery]);

    useEffect(() => {
        if (searchChildren) {
            if (op.current) {
                if (searchChildren) {
                    op.current.show(searchChildren.event, null);
                } else {
                    op.current.hide();
                }
            }
        }
    }, [searchChildren]);

    let timeoutForSearch: any = undefined;

    useEffect(() => {

        if (timeoutForSearch) {
            clearTimeout(timeoutForSearch);
        }

        timeoutForSearch = setTimeout(() => {
            getSearchchildren(searchValue)
        }, 500);
    }, [searchValue]);

    const getSearchchildren = (event: any) => {
        if (event && event.target && event.target.value) {
            const sI: any[] = [];
            for (let i = 0; i < unitTree.length; i++) {
                _getSearchchildren(event.target.value, unitTree[i], sI);
            }
            setSearchChildren({event: event, data: sI});
        } else {
            setSearchChildren(undefined);
        }
    };

    const _getSearchchildren = (value: string, item: any, array: any[]): any => {
        let result: any = array;
        if (item.name.toLowerCase().indexOf(value.toLowerCase()) >= 0) {
            result.push(item);
        } else {
            if (item.children) {
                for (let i = 0; i < item.children.length; i++) {
                    const item1 = item.children[i];
                    result = _getSearchchildren(value, item1, result);
                }
            }
        }
        return result;
    };

    const setSearchEmpty = (e: any) => {
        setSearchValue(undefined);
        setSearchChildren(undefined);
    };


    return <>
        <div className="p-inputgroup">
            <Input className={'m-0'} edit={true} name={'search'}
                   value={searchValue && searchValue.target.value && searchValue.target.value ? searchValue.target.value : ''}
                   onChange={(e) => {
                       setSearchValue(e);
                       if (!e.target.value) {
                           op.current?.hide();
                       }
                   }}
                   placeholder={t('units_explorerSearch')}/>
            <Button icon="pi pi-times" className="p-button-outlined p-button-secondary"
                    onClick={setSearchEmpty}/>
        </div>
        <OverlayPanel
            ref={op}
            // showCloseIcon
            id="overlay_panel"
            style={{width: '450px', maxHeight: '40vh'}}
            className="ue_search-overlay overflow-auto">
            {searchChildren && searchChildren.data.length <= 0 &&
                <div>{t('units_explorerNoSearchResults')}</div>}
            {searchChildren && searchChildren.data.length > 0 && <div>{
                searchChildren.data.map((item: any, index: number) => {
                        console.log(item);

                        let pathname = [...item.pthname];
                        let path = '';
                        if (pathname && pathname.length) {
                            pathname.pop();
                            if (pathname.length >= 1) {
                                path = `[${pathname.join(', ')}]`;
                            }
                        }

                        return (<div>
                            <div className={'ue_search-item'} onClick={() => {
                                props.onClick(item.id);
                                op.current?.hide();
                            }}>
                                <div
                                    className={'flex inspection-status-' + item.inspection_task_status}>
                                    <i className={'mr-2 my-auto text-sm' + (' ' + getElementTypeIconClass(item.element_type_id, elementTypesQuery.data))}/>{`${item.name} (${getElementTypeName(item.element_type_id, elementTypesQuery.data)}) ${path}`}
                                </div>
                            </div>
                            {index < searchChildren.data.length - 1 && <hr className={'m-1'}/>}
                        </div>);
                    }
                )
            }</div>}
        </OverlayPanel>
    </>
}
