/*
 * LocationForm.tsx
 * Author: lnappenfeld
 * Date: 31.08.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useState, useEffect, useRef,} from 'react';
import {useTranslation} from 'react-i18next';
import Input from '../global/Input';
import {useForm} from 'react-hook-form';
import {createLocation, updateLocation, getLocation, Location, Nullable, getLocations} from '../functions/Global';
import {showMessageOnError, showMessageOnSuccess} from './CustomToast';
import EPSGCodeSelectList from './EPSGCodeSelectList';
import SimpleOLMap from './SimpleOLMap';
import {showReloadPageDialog} from './ReloadPageDialog';
import {loadEpsgScripts, transformCoords} from '../../services/SpatialService';


type Props = {
    project: any,
    location: Nullable<Location>,
    onFinished: (success: boolean) => void
    saveAndNewClicked: boolean,
    isProjectCenter?: boolean
}

const LocationForm = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);
    const isProjectCenter = (typeof props.isProjectCenter === 'boolean' && props.isProjectCenter) ? props.isProjectCenter : false;
    const locationId = props.location ? props.location.location_id : null;
    const isProjectCenterOld = props.location && props.location.location_zoom ? true : false; // should be project_center if location_zoom is not null
    // Für die Map, falls sich im Formular was ändert, das relevant für die Darstellung ist (name, Koordinaten, ...)
    const [location, setLocation] = useState<any>(null);
    // Alle bisher definierten Locations im Project
    const [locations, setLocations] = useState<any>(null);
    const [center, setCenter] = useState<any>(null);
    const [changedByInput, setChangedByInput] = useState<boolean>(false);
    const [showMap, setShowMap] = useState<boolean>(true);


    const defaultValues = {
        'location_id': props.location ? props.location.location_id : null,
        'location_name': props.location ? props.location.location_name : null,
        'description': props.location ? props.location.location_description : null,
        'epsg_code': props.location ? props.location.epsg_code : 4326,
        'is_visible': props.location ? props.location.is_visible : true,
        'geo_position_x': props.location ? props.location.geo_position_x : null,
        'geo_position_y': props.location ? props.location.geo_position_y : null,
        'geo_position_z': props.location ? props.location.geo_position_z : 0,
        'is_project_center': isProjectCenter // Dialog wurde in ProjectAdvancedSettingsForm.tsx geöffnet
            ? true
            : props.location && props.location.location_zoom // should be project_center if location_zoom is not null
                ? true
                : false,
        'location_zoom': props.location ? props.location.location_zoom : 14.77
    };

    const {control, formState: {errors}, handleSubmit, reset, setValue, getValues, watch, trigger} = useForm({defaultValues});

    watch('is_visible');
    watch('geo_position_z');
    watch('is_project_center');
    // watch('epsg_code');
    // For Map update
    watch('location_name');
    watch('geo_position_x');
    watch('geo_position_y');
    watch('location_zoom');

    const getFormErrorMessage = (name: string) => {
        // @ts-ignore
        return errors[name] && <small className="p-error custom">{errors[name].message}</small>;
    };

    const _setLocations = () => {
        getLocations(props.project.id).then(result => {
            setLocations(result);
        });
    };

    // update Location for Map
    useEffect(() => {
        setLocation(getValues());
    }, [
        getValues('location_name'),
        // getValues('geo_position_x'), getValues('geo_position_y'), getValues('geo_position_z'),
    ]);

    useEffect(() => {
        if (changedByInput)
            setLocation(getValues());
    }, [
        // getValues('location_name'),
        getValues('geo_position_x'), getValues('geo_position_y'), getValues('geo_position_z'),
    ]);

    useEffect(() => {
        console.log('typeof getValues(location_zoom): ', typeof getValues('location_zoom'));
        console.log('getValues(location_zoom): ', getValues('location_zoom'));
        if (center) {
            setCenter({
                'geo_position_x': center.geo_position_x,
                'geo_position_y': center.geo_position_y,
                'location_zoom': getValues('location_zoom')
            });
        }
    }, [getValues('location_zoom')]);

    useEffect(() => {
        (async () => {
            await loadEpsgScripts();
        })();

        _setLocations();

        if (!props.project.location_id) {
            // If no center is already defined, show Hannover
            const _center = {
                'geo_position_x': 9.7320104,
                'geo_position_y': 52.3758916,
                'epsg_code': 4326,
                'location_zoom': 7
            };
            (async () => {
                const center = await transformCenter(_center);
                setCenter(center);
            })();
        } else {
            getLocation(props.project.id, props.project.location_id).then(result => {
                if (!result['location_zoom'] || result['location_zoom'] === 0) {
                    result['location_zoom'] = 10;
                }
                setValue('location_zoom', parseFloat(result['location_zoom']));
                (async () => {
                    const center = await transformCenter(result);
                    setCenter(center);
                })();
            });
        }
    }, []);

    const transformCenter = async (center: any) => {
        const _center = center;
        if (center.epsg_code) {
            const coords = [center.geo_position_x, center.geo_position_y, center.geo_position_z];
            const transformedCoords = await transformCoords(coords, center.epsg_code, 3857);
            // console.log('srcCode: ', center.epsg_code);
            // console.log('desCode: ', 3857);
            // console.log('coordinates: ', coords);
            // console.log('transformedCoords: ', transformedCoords);

            _center.geo_position_x = transformedCoords[0];
            _center.geo_position_y = transformedCoords[1];
            _center.geo_position_z = transformedCoords[2];
        }
        return _center;
    };


    const saveLocation = (data: any) => {

        // Rename location_name to name
        data['name'] = data['location_name'];
        delete data['location_name'];

        const locationAddProjectId = {'project_id': props.project.id};
        data = {...locationAddProjectId, ...data};
        console.log('data: ', data);
        console.log('values: ', getValues());

        if (locationId) {
            console.log(data);
            updateLocation(data).then(result => {
                if (!result.error) {
                    showMessageOnSuccess(t('success'), t('toasts_locationUpdated'));
                    props.onFinished(true);
                    // Die Seite muss neu geladen werden, wenn der Standort als neuer Projektmittelpunkt bestimmt wurde
                    // oder wenn der Standort als Projektmittelpunkt abgewählt wurde,
                    // weil main.get_project_list() neu aufgerufen werden muss
                    if (getValues('is_project_center') !== isProjectCenterOld) {
                        showReloadPageDialog(t, t('reload_msgProjectCenterChanged'));
                    }
                } else {
                    showMessageOnError(t('error'), result.error);
                }
            });
        } else {
            createLocation(data).then(result => {
                if (!result.error) {
                    showMessageOnSuccess(t('success'), t('toasts_locationCreated'));
                    props.onFinished(true);

                    // Die Seite muss neu geladen werden, wenn der neu erstellte Standort als Projektmittelpunkt
                    // bestimmt wurde, weil main.get_project_list() neu aufgerufen werden muss.
                    if (getValues('is_project_center')) {
                        showReloadPageDialog(t, t('reload_msgProjectCenterChanged'));
                    }

                    if (props.saveAndNewClicked) {
                        // Bei 'Speichern und weitere anlegen':
                        // 1. Um die Karte neu zu mounten: verberge diese
                        setShowMap(false);
                        // 2. Leere die Formularfelder
                        reset();
                        // 3. Lade alle bisher angelegten Locations und die aktuelle Location neu
                        getLocations(props.project.id).then(result => {
                            setLocations(result);
                            setLocation(getValues());

                            // 4. Setze den Center und den Zoom in der Karte neu (Ist nach dem reset() verloren gegangen)
                            getLocation(props.project.id, props.project.location_id).then(result => {
                                if (!result['location_zoom'] || result['location_zoom'] === 0) {
                                    result['location_zoom'] = 10;
                                }
                                setValue('location_zoom', parseFloat(result['location_zoom']));
                                (async () => {
                                    const center = await transformCenter(result);
                                    setCenter(center);

                                    // 5. Ganz am Ende: Mounte die Karte neu
                                    setShowMap(true);
                                })();
                            });
                        });
                    }
                } else {
                    showMessageOnError(t('error'), result.error);
                }
            });
        }
    };

    return (
        <form id='formLocation' onSubmit={handleSubmit(saveLocation)} style={{height: '100%'}}>
            <div className='card grid' style={{height: '100%'}}>
                <div className='card col-12 lg:col-4' style={{height: '100%'}}>
                    <div className='card'>
                        <div className='grid'>
                            <div className='col-6'>
                                <Input
                                    edit={true} label={t('name')} name={'location_name'}
                                    validationControl={control} validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: t('name') + ' ' + t('input_required')}}
                                />
                            </div>
                            <div className='col-6'>
                                <Input
                                    edit={true}
                                    label={t('visible')}
                                    name={'is_visible'}
                                    type={'checkbox'}
                                    validationControl={control} validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: false}}
                                />
                            </div>
                        </div>
                        <Input
                            edit={true} label={t('description')} name={'description'} type={'textarea'}
                            validationControl={control} validationErrorMessage={getFormErrorMessage}
                            validationRules={{required: false}}
                        />
                    </div>
                    <div className='card'>
                        <div className='grid'>
                            <div className='col-6'>
                                <Input
                                    edit={true} label={t('loc_attribs_positionX')} name={'geo_position_x'}
                                    type={'number'} maxFractionDigits={8}
                                    value={getValues('geo_position_x')}
                                    onChange={(e: any) => {
                                        setChangedByInput(true);
                                        setValue('geo_position_x', e.value);
                                    }}
                                    validationControl={control} validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: t('loc_attribs_positionX') + ' ' + t('input_required')}}
                                />
                                <Input
                                    edit={true} label={t('loc_attribs_positionY')} name={'geo_position_y'}
                                    type={'number'} maxFractionDigits={8}
                                    value={getValues('geo_position_y')}
                                    onChange={(e: any) => {
                                        setChangedByInput(true);
                                        setValue('geo_position_y', e.value);
                                    }}
                                    validationControl={control} validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: t('loc_attribs_positionY') + ' ' + t('input_required')}}
                                />
                                <Input
                                    edit={true} label={t('loc_attribs_positionZ')} name={'geo_position_z'}
                                    type={'number'} maxFractionDigits={8}
                                    value={getValues('geo_position_z')}
                                    disabled={false} // 3D-Objekt oder Zoomstufe Projektmittelpunkt
                                    onChange={(e: any) => {
                                        setChangedByInput(true);
                                        setValue('geo_position_z', e.value);
                                    }}
                                    validationControl={control} validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: false}}
                                    // validationRules={{required: t('locations:positionZ') + ' ' + t('input_required')}}
                                />
                            </div>
                            <div className='col-6'>
                                <EPSGCodeSelectList
                                    projectId={props.project.id}
                                    name={'epsg_code'}
                                    label={t('loc_attribs_epsg')}
                                    disabled={false}
                                    onChange={(e: any) => {
                                        const oldEpsgCode = location.epsg_code;
                                        const epsgCode = e.value;
                                        setValue('epsg_code', epsgCode);

                                        (async () => {
                                            // console.log('srcCode: ', oldEpsgCode);
                                            // console.log('desCode: ', epsgCode);
                                            // console.log('z_value: ', getValues('geo_position_z'));

                                            const coordinates = [getValues('geo_position_x'), getValues('geo_position_y'), getValues('geo_position_z')];
                                            if (oldEpsgCode && epsgCode) {
                                                const transformedCoords = await transformCoords(coordinates, oldEpsgCode, epsgCode);

                                                // console.log('coordinates: ', coordinates);
                                                // console.log('transformedCoords: ', transformedCoords);

                                                setChangedByInput(false);
                                                setValue('geo_position_x', transformedCoords[0]);
                                                setValue('geo_position_y', transformedCoords[1]);
                                                setValue('geo_position_z', transformedCoords[2]);

                                                setLocation(getValues());
                                            }
                                        })();
                                    }}
                                    validationControl={control}
                                    validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: t('loc_attribs_epsg') + ' ' + t('input_required')}}
                                />
                                <Input
                                    edit={true}
                                    disabled={isProjectCenter}
                                    label={t('loc_attribs_projectCenter')}
                                    name={'is_project_center'}
                                    type={'checkbox'}
                                    validationControl={control} validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: false}}
                                />
                                {getValues('is_project_center') && <>
                                    <div className={'mt-5'}></div>
                                    <Input
                                        edit={true} label={t('loc_attribs_zoom')} name={'location_zoom'}
                                        type={'number'} maxFractionDigits={2}
                                        value={getValues('location_zoom')}
                                        onChange={(e: any) => {
                                            setValue('location_zoom', e.value);
                                        }}
                                        validationControl={control} validationErrorMessage={getFormErrorMessage}
                                        validationRules={{required: t('loc_attribs_zoom') + ' ' + t('input_required')}}
                                    /></>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card col-12 lg:col-8'>
                    {showMap && center && locations && location &&
                        <SimpleOLMap
                            locations={locations}
                            location={location}
                            center={center}
                            onPositionChange={(coordinates: any) => {
                                (async () => {
                                    const epsgCode = getValues('epsg_code');
                                    if (epsgCode) {
                                        const transformedCoords = await transformCoords(coordinates, 3857, epsgCode);

                                        // console.log('srcCode: ', 3857);
                                        // console.log('desCode: ', epsgCode);
                                        // console.log('coordinates: ', coordinates);
                                        // console.log('transformedCoords: ', transformedCoords);

                                        setChangedByInput(false);
                                        setValue('geo_position_x', transformedCoords[0]);
                                        setValue('geo_position_y', transformedCoords[1]);
                                        setValue('geo_position_z', transformedCoords[2]);
                                    }
                                })();
                            }}
                            onZoomChange={(zoom: number) => {
                                setValue('location_zoom', zoom);
                            }}
                        />}
                </div>
            </div>
        </form>
    );

};

export default LocationForm;

