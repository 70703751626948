import React, {FunctionComponent, useContext, useEffect, useRef, useState} from 'react';
import {Tree} from 'primereact/tree';
import MapContext from '../map/MapContext';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../../redux/hooks';
import {reportChangedLayer, setBaseLayer} from '../../../redux/slices/GeomonitoringSlice';
import {
    fetchChannels,
    generateImageForLegend,
    setValIfUndefined,
    toCamelCase
} from '../../../functions/functionsOpenlayer';
import geoSettings from '../../../config/geoSettings';
import {classNames} from 'primereact/utils';
import {faLayerGroup} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Accordion, AccordionTab} from 'primereact/accordion';
import {SkeletonLayerTree} from '../../skeletons/SkeletonLayerTree';

type controlProps = {
    baseLayer: string,
    onCollapsed?: (collapsed: boolean) => void,
    projectId: number,
    locationIds?: string[]
}
const LayerTree: FunctionComponent<controlProps> = ({baseLayer, onCollapsed, projectId, locationIds}) => {

    // @ts-ignore
    const {map, setMap} = useContext(MapContext);
    const {t} = useTranslation(['geomonitoring']);
    const [nodes, setNodes] = useState<any | string>(null);
    const [selectedKeys, setSelectedKeys] = useState<any | string>(null);
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const updatedDiff = require('updated-obj-diff');
    const [oldBaseLayer, setOldBaseLayer] = useState(baseLayer);
    const [treeSet, setTreeSet] = useState(false);
    const [collapsed, setCollapsed] = useState(true);
    const [baseLayerIndex, setBaseLayerIndex] = useState(0);
    const [selectedLocations, setSelectedLocations] = useState<any[]>([]);

    const [headerText, setHeaderText] = useState('GIS');
    const [headerIcon, setHeaderIcon] = useState(faLayerGroup);

    const currentBaseLayer = useAppSelector((state: any) => state.configLayer.layerData.baseLayer);
    const layerArray = useAppSelector((state: any) => state.configLayer.layerData.layerArray);
    const layerCounter = useAppSelector((state: any) => state.configLayer.layerData.layerCounterArray);
    const styleArray = useAppSelector((state: any) => state.configLayer.layerData.styleArray);
    const geoserverWorkspace = useAppSelector((state: any) => state.configLayer.layerData.geoserverWorkspace);
    const externLegend = useAppSelector((state: any) => state.configLayer.layerData.externLegendArray);
    const externLegendPic = useAppSelector((state: any) => state.configLayer.layerData.externLegendPic);
    const baseWmsWorkspace = useAppSelector((state: any) => state.configLayer.layerData.baseLayerWorkspace);
    const decodeTreeName = useAppSelector((state: any) => state.configLayer.layerData.layerNodeArray);

    const dataArray: any = {};
    const rasterTreeIndex = 1;
    const layerNodeArray: any = [];
    const layerNodeSet: any = [];
    const activeLayers: any = [];
    const keyArray = {};
    const baseRef = useRef(baseLayerIndex);
    const styleRef = useRef(styleArray);
    const externLegendRef = useRef(externLegend);

    const dispatch = useAppDispatch();

    const getLayerName = (layerId: any) => {

        let layerName = layerId;
        switch (layerId) {
            case 'poiLayer':
                layerName = t('geomon_poi_layer');
                break;
            default:
                layerName = layerName ? toCamelCase(layerName.split('XYX').join(' ')) : null;// layername hier ersetzten durch configdaten
            // layerName = (typeof layerName == 'undefined' || layerName == null) ? altTitelName : layerName;
        }
        return layerName;
    };

    const getParentNode = (exisitingParentNodes: any, treeIndex: number, treeName: string, raster: boolean) => {
        let parentNode;
        const nodeIndex = exisitingParentNodes.indexOf(treeIndex.toString());
        switch (true) {
            case (nodeIndex < 0 && raster):
                parentNode = {key: rasterTreeIndex, label: treeName, data: 'rasterData',};
                dataArray[treeIndex] = parentNode;
                break;
            case (nodeIndex < 0):
                parentNode = {key: treeIndex, label: decodeTreeName[treeName]?decodeTreeName[treeName].string:toCamelCase(treeName), data: 'parentNode', children: []};
                dataArray[treeIndex] = parentNode;
                break;
            default:
                parentNode = dataArray[treeIndex];
                break;
        }
        return parentNode;
    };

    const getLayerNode = (key: number, label: string, data: string, originalName: string, children: any, styleId: any, legendTitle: string, visibility: any, divIndex: number) => {
        return {
            key: key,
            label: label,
            data: data,
            originalName: originalName,
            children: children,
            styleId: styleId,
            legendTitle: legendTitle,
            visibility: visibility,
            divIndex: divIndex
        };
    };

    const addChildren = (layer: { layerParts: any; styles: any; id: string; }, keyNumber: number, parentNode: any, layerIndex: number, layerNode: any) => {
        if (layer.layerParts && layer.styles) {
            const childNodes = createChildNodes(layer, keyNumber, layerNode);
            if (childNodes.length > 1) {
                layerNode.children = childNodes;
            }
        }
        return layerNode;
    };

    const sortTreeNode = (treeNode: any) => {
        if (treeNode.length > 0) {
            treeNode.sort((a: { key: number; }, b: { key: number; }) => (a.key < b.key ? 1 : -1));
        }
        return treeNode;
    };

    const buildDataArray = (layer: any, layerId: any, keyNumber: number, indexNum: number) => {
        const layerName = getLayerName(layerId);
        let layerNode: { children: any[] };
        let parentNode: any = [];
        layerNodeSet.push(layerId);
        let treeName = (layer.layerParts ? layer.layerParts.treeName : layerName).toLowerCase();
        let treeIndex = layer.layerParts ? layer.layerParts.treeIndex : keyNumber;
        let layerIndex = layer.layerParts ? layer.layerParts.generatedIndex : keyNumber * 101;
        layerIndex = layerIndex < 100 ? layerIndex * 100 + 55 : layerIndex;

        keyNumber = layer.layerParts ? layer.layerParts.layerIndex : keyNumber;

        if (layer.baseLayer) {// rasterBase
            keyNumber = indexNum + 100;
            treeIndex = rasterTreeIndex;
            treeName = 'raster';
            layerIndex = indexNum + 100;
            layerNode = getLayerNode(layerIndex, t('geomon_baseLayers_' + layer.name.toLowerCase()), layerId, layer.mainLayerId, null, null, '', '', 0);
            parentNode = getParentNode(Object.keys(dataArray), treeIndex, t('projM_baseLayer'), true);
        } else {
            parentNode = getParentNode(Object.keys(dataArray), treeIndex, treeName, false);
            layerNode = getLayerNode(layerIndex, layer.title, layerId, layer.mainLayerId, null, layer.styleId, layer.layerConfig !== null ? layer.layerConfig.layerName : null, layer.visible, layer.layerIndex);
        }

        setTreeKeys(layer, layerIndex, parentNode);
        layerNode = addChildren(layer, keyNumber, parentNode, layerIndex, layerNode);
        layerNodeArray[treeName] = setValIfUndefined(layerNodeArray[treeName], layerNodeArray[treeName], []);

        if (layer.id === 'poiLayer') {
            layerNode.children.forEach((child: any) => {
                layerNodeArray[treeName].push(child);
            });
        } else {
            layerNodeArray[treeName].push(layerNode);
        }

        layerNodeArray[treeName] = sortTreeNode(layerNodeArray[treeName]);
        parentNode.children = layerNodeArray[treeName];
        dataArray[treeIndex] = parentNode;
        return {parent: parentNode, key: keyNumber};
    };

    const sortArrays = () => {
        let layerArrayToWorkWith = JSON.parse(JSON.stringify(layerArray));
        const getAllIndexes = (arr: any[], pred: {
            (o: any): boolean;
            (arg0: any): any;
        }) => arr.flatMap((v, i) => pred(v) ? i : []);
        const newSortedlayerArrayWithStyles = [];
        for (const style in styleArray) {

            const layerIndexe = getAllIndexes(layerArrayToWorkWith, o => o.mainLayerId.toLowerCase() === style);
            if (layerIndexe.length > 0) {
                const parentLayer = JSON.parse(JSON.stringify(layerArrayToWorkWith[layerIndexe[0]]));
                parentLayer.layerParts.generatedIndex = parentLayer.layerParts.generatedIndex - 1;
                const newElem = parentLayer;

                layerIndexe.forEach((index => {
                    Object.keys(styleArray[style]).forEach((styleIndex: string | number) => {
                        if (layerArrayToWorkWith[index].styleId.toLowerCase() === styleIndex) {
                            styleArray[style][styleIndex].layerParts = layerArrayToWorkWith[index].layerParts;
                        }
                    });
                    delete layerArrayToWorkWith[index];
                }));
                newElem.styles = styleArray[style];
                newSortedlayerArrayWithStyles.push(newElem);
            }
        }

        layerArrayToWorkWith = layerArrayToWorkWith.filter((val: any) => val);
        return newSortedlayerArrayWithStyles.concat(layerArrayToWorkWith);
    };

    const iterateThroughStyles = (newSearchArray: any) => {
        let counter = 0;
        for (const i in newSearchArray) {
            const indexNum = parseInt(i);
            const layer = newSearchArray[indexNum];
            const layerId = layer.id;
            const keyNumber = indexNum;

            if (!layer.toogleHidden && layerNodeSet.indexOf(layerId) < 0) {
                buildDataArray(layer, layerId, keyNumber, indexNum);
            }
            counter++;

            if (counter === newSearchArray.length) {
                setKeys();
                const dataArrayValues = Object.values(dataArray);
                dataArrayValues.sort((a: any, b: any) => (a.key < b.key ? 1 : -1));
                // console.log(dataArrayValues, dataArray)
                setNodes(dataArrayValues);
            }
        }
    };

    const createTreeElem = () => {
        if (layerArray.length === 0) return;
        const newSearchArray = sortArrays();
        iterateThroughStyles(newSearchArray);
    };

    const setTreeKeys = (layer: any, childKey: any, parentNode: { key: string | number; }) => {
        const basisLayer = currentBaseLayer !== null ? currentBaseLayer : geoSettings.activeBaseLayer;
        if (layer.visible === true || layer.visible === 'true' || layer.id === basisLayer) {
            Object.assign(keyArray, {[childKey]: {checked: true, partialChecked: false}});
            markActiveLayers(layer, parentNode);
        }
        if (layer.id === basisLayer) {
            setBaseLayerIndex(childKey);
            setOldBaseLayer(layer.id);
        }
    };

    const createChildNodes = (layer: any, keyNumber: number, parentNode: any) => {
        const styles = layer.styles;
        const childArray = [];
        if (Object.keys(styles).length > 1) {
            // vielleicht nachher durch StyleIndex ersetzen
            for (const styleName of Object.keys(styles)) {
                const child = styles[styleName];

                const childKey = child.layerParts.generatedIndex;
                const childNode = getLayerNode(childKey, child.name, styleName, layer.mainLayerId, null, layer.styleId, layer.layerConfig.layerName, layer.visible, childKey);
                childArray.push(childNode);
                setTreeKeys(layer, childKey, parentNode);
            }
            childArray.sort((a, b) => (a.key < b.key ? 1 : -1));
        }
        return childArray;
    };

    const markActiveLayers = (layer: { baseLayer: boolean; }, parentNode: { key: string | number; }) => {
        if (!layer.baseLayer) {
            activeLayers[parentNode.key] = setValIfUndefined(activeLayers[parentNode.key], activeLayers[parentNode.key], 0);
            activeLayers[parentNode.key]++;
        }
    };

    const setKeys = () => {
        Object.keys(dataArray).forEach(key => {
            // @ts-ignore
            const index = parseInt(key);
            const node: any = dataArray[index];
            if (node.children.length === activeLayers[index]) {
                Object.assign(keyArray, {[key]: {checked: true, partialChecked: false}});
            } else if (activeLayers[index] > 0) {
                Object.assign(keyArray, {[key]: {checked: false, partialChecked: true}});
            }
        });
        Object.assign(keyArray, {[rasterTreeIndex]: {checked: false, partialChecked: true}});
        setSelectedKeys(keyArray);
    };

    const iterate = (obj: any, id: number | string, result: { key: number; label: string; data: string; }) => {
        const objectKeys = Object.keys(obj);
        for (let i = 0; i < objectKeys.length; i++) {
            const key = objectKeys[i];
            const subObject = obj[key];
            if (subObject.key === id) {
                result = subObject;
                return subObject;
            } else if (subObject.data === id) {
                result = subObject;
                return subObject;
            } else if (subObject.children && subObject.children.length > 0) {
                result = iterate(subObject.children, id, result);
            }
        }
        return result;
    };

    const getDiffKeys = (eventValue: any) => {

        const diff = updatedDiff(selectedKeys, eventValue);
        // console.log('getDiffKey', selectedKeys,eventValue,diff)
        const diffValue = Object.values(diff);
        const diffKey = Object.keys(diff);
        return ({diff: diff, diffValue: diffValue, diffKey: diffKey});
    };

    const getChangedNodes = (event: any) => {
        const diffs = getDiffKeys(event.value);
        const result: any = [];
        diffs.diffKey.forEach(key => {
            const resultBase = getLayerNode(0, '', '', '', null, '', '', '', 0);
            const preResult = iterate(nodes, parseInt(key), resultBase);
            const obj = {...preResult};

            obj.value = diffs.diff[key];
            result.push(obj);
        });
        return result;
    };

    const toogleLayers = (changedNode: any, cValue: any) => {
        const layerId = changedNode.data;
        const key = changedNode.key;
        if (Object.keys(map).length < 1) return;
        let layerData = null;
        //console.log(layerId, cValue && cValue.checked ? true : false)
        switch (changedNode.originalName) {
            default:
                for (const layer of map.getLayers().getArray()) {
                    if ((layerId && layer.get('id') && layer.get('id').toLowerCase() === layerId.toLowerCase())) {

                        const layerVisible = (cValue && cValue.checked ? true : false);//nicht mit ESLINT verbessern
                        layer.setVisible(layerVisible);

                        if (key < 150) dispatch(setBaseLayer(layerId));
                        layerData = {id: layerId, visible: layerVisible};
                        return (layerData);
                    }
                }
                break;
        }

    };

    const modifyEventValue = (eventValue: any) => {
        const eventKeys = Object.keys(eventValue);
        if (eventKeys.includes('1')) {
            const count = eventKeys.reduce((count, num) => parseInt(num) > 100 && parseInt(num) < 110 ? count + 1 : count, 0);
            eventKeys.forEach((key: any) => {
                const intKey = parseInt(key);
                // wenn 1, dann alle raus ausser aktuellen BaseIndex
                if (intKey > 100 && intKey < 110) {
                    if (intKey !== baseRef.current && count > 2) {
                        delete (eventValue[intKey]);
                    }
                }
            });
        }
        //  console.log(eventValue)
        return (eventValue);
    };

    const changeKeys = (event: any) => {
        if (event.originalEvent.target.className.indexOf('p-checkbox') >= 0) {
            modifyEventValue(event.value);
            const changedNodes = getChangedNodes(event);
            let idArray: any = [];
            const changedMPArray: any = [];
            changedNodes.forEach((node: any) => {
                if (node.originalName === 'poiLayerChilds') {
                    changedMPArray.push({mpType: node.data, value: node.value});
                } else {
                    const layerData = toogleLayers(node, node.value);
                    if (layerData) {
                        idArray = [...idArray, layerData];
                        dispatch(reportChangedLayer(idArray));
                    }
                }

            });
            setSelectedKeys(event.value);
           // if (changedMPArray.length > 0) dispatch(setFilterMp(changedMPArray));

        } else {
            event.originalEvent.preventDefault();
        }
    };

    const toogleNode = (nodeName: any, value: any) => {
        const obj = {key: 149, label: 'Baselayer', data: nodeName, value: value};
        const cValue = value ? {checked: true} : null;
        return toogleLayers(obj, cValue);
    };

    const generateLegendOrLink = (node: any) => {
        const mainLayerId = node.originalName;
        const styleId = node.data;
        const styleIdUnformated = node.styleId;
        const layerId = mainLayerId.toLowerCase();
        if (node.originalName === 'poiLayerChilds') {
            const url = geoSettings.sensorTypeImageUrl + '/legend/' + node.data + '.png';
            return <img src={url} height={'30px'}/>;
        }
        const image = generateImageForLegend(geoserverWorkspace, mainLayerId, externLegendRef.current[layerId], styleIdUnformated, styleId, layerId, externLegendPic[layerId], baseWmsWorkspace);
        return (image);
    };

    const nodeTemplate = (node: any, options: any) => {
        let label = node.label;

        if (node.data !== 'parentNode' && node.data !== 'rasterData' && node.divIndex !== 0 && node.data !== 'poiLayer' && node.children === null) {

            const image = generateLegendOrLink(node);
            label = (<>
                <Accordion className={'w-full'}>
                    <AccordionTab header={node.label}>
                        <div className={node.originalName !== 'poiLayerChilds' ? 'legend-image' : ''}>{image}</div>
                    </AccordionTab>
                </Accordion>
            </>);
        }
        return label;
    };

    const collapsedAction = () => {
        const coll = !collapsed;
        setCollapsed(coll);
        if (onCollapsed) {
            onCollapsed(coll);
        }
    };

    const loaded = (count: number) => {
        const allLoadedNum = 3;
        let collectLoaded = 0;
        const arrayLoaded = [!treeSet, (count === geoSettings.counterLayers), styleArray];
        arrayLoaded.forEach(val => {
            if (val) collectLoaded++;
        });
        return collectLoaded === allLoadedNum;
    };

    useEffect(() => {
        if (!layerArray || Object.keys(layerArray).length === 0) return;
        const count = layerCounter.reduce((count: number, num: number) => num === projectId ? count + 1 : count, 0);
        if (loaded(count)) {
            setTreeSet(true);
            createTreeElem();
        }

    }, [layerArray, layerCounter, treeSet, styleArray]);

    useEffect(() => {
        if (!nodes || Object.keys(nodes).length === 0) return;
        const result = getLayerNode(0, '', '', '', null, '', '', '', 0);
        const oldNode = iterate(nodes, oldBaseLayer, result);
        const newNode = iterate(nodes, currentBaseLayer, result);
        setBaseLayerIndex(newNode.key);
        const newKeyObject = Object.assign({}, selectedKeys);

        delete newKeyObject[oldNode.key];
        newKeyObject[newNode.key] = {checked: true, partialChecked: false};
        toogleNode(oldBaseLayer, null);
        toogleNode(currentBaseLayer, true);
        setOldBaseLayer(currentBaseLayer);
        setSelectedKeys(newKeyObject);
    }, [currentBaseLayer]);

    useEffect(() => {
        setTreeSet(false);
    }, []);

    useEffect(() => {
        baseRef.current = baseLayerIndex;
    }, [baseLayerIndex]);

    useEffect(() => {
        styleRef.current = styleArray;
    }, [styleArray]);

    useEffect(() => {
        externLegendRef.current = externLegend;
    }, [externLegend]);

    useEffect(() => {
     //   console.log(decodeTreeName)
    }, [decodeTreeName]);

    return (
        <div className={classNames('layer-tree', collapsed ? 'collapsed' : '')}>
            <div className="card pr-0 flex justify-content-end flex-wrap">
                <div className={'col content h-full'}>
                    <div className={'flex flex-column h-full'}>
                        <div className={classNames('grid', 'flex-none')}>
                            <h5 className={classNames('col', 'm-0', 'p-0', 'pt-1', 'pb-2', 'mb-1', 'pl-2', 'grid', collapsed ? 'hidden' : '')}>
                                <FontAwesomeIcon className={classNames('mr-2', 'my-auto', 'col-auto', 'header-icon')}
                                                 icon={headerIcon}/>
                                <div
                                    className={classNames('col-auto', 'header-text', 'my-auto', 'pb-1')}>{headerText}</div>
                            </h5>
                        </div>
                        {(!treeSet) &&
                        <SkeletonLayerTree/>}
                        {(treeSet) &&
                        <Tree className={'flex-grow-1 flex overflow-auto h-full'} nodeTemplate={nodeTemplate}
                              style={{'minHeight': '50%'}}
                              value={nodes} selectionMode="checkbox"
                              selectionKeys={selectedKeys}
                              data-testid={'Tree'}
                              onSelectionChange={changeKeys}/>}
                    </div>

                </div>
                <div className={'m-auto'}>
                    <div className="col-auto expand-button pr-2 text-center" onClick={collapsedAction}>
                        <div className={'hide-if-expanded w-full'}>
                            <FontAwesomeIcon className={classNames('header-icon')}
                                             icon={headerIcon}/>
                            <div
                                className={classNames('col-auto', 'header-text', 'my-auto', 'pb-1')}>{headerText}</div>
                        </div>
                        <div className={'hide-if-collapsed w-full'}>
                            <i className="fa fa-caret-up"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LayerTree;
