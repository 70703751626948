import React, {useEffect, useState} from 'react';
import {ElementTypeItem, getElementType, UnitItem} from "../functions/Units";
import Input from "../global/Input";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {addApiCalls} from "../../redux/slices/ApiCallsSlice";
import {changeField} from "../../redux/slices/ElementChangesSlice";
import {useTranslation} from "react-i18next";
import {useLazyGetCustomerTypesQuery} from "../../redux/rtk/injectedUnitsApi";
import {ElementFileManagement} from "./ElementFileManagement";


export enum UnitFieldType {
    element = 'element',
    inspectionTask = 'inspection_task',
}

type Props = {
    project: any,
    fields: any;
    id: string,
    type: UnitFieldType,
    className?: string;
    showOnly?: boolean
}

export const UnitFields = (props: Props) => {
    const {t} = useTranslation(['common']);

    const apiCalls: any = useAppSelector((state: any) => state.apiCallsData.apiCalls);
    const dispatch = useAppDispatch();
    const [triggerGetCustomerTypes, resultCustomerTypes, lastPromiseCustomerTypes] = useLazyGetCustomerTypesQuery();

    const [fields, setFields] = useState<any>(props.fields);
    const [result, setResult] = useState<any[]>([]);

    useEffect(() => {
        setFields(props.fields);
    }, [props.fields]);

    useEffect(() => {
        if (fields) {
            (async () => {
                const n = await getFields();

                Promise.all(n).then((result) => {
                    setResult(result);
                });
            })();
        }
    }, [fields]);

    // useEffect(() => {
    //     if (values) {
    //         setResult((prevState) => {
    //             let temp = [...prevState];
    //
    //             for (let i = 0; i < temp.length; i++) {
    //                 let tempItem = temp[i];
    //
    //                 if (values[tempItem.name] != null) {
    //                     tempItem.value = values[tempItem.name];
    //                 }
    //             }
    //
    //             return temp;
    //         });
    //     }
    // }, [values]);

    const getFields = async () => {
        const result = [];

        if (fields) {
            for (let i = 0; i < fields.length; i++) {
                const field = fields[i];
                if (field) {
                    let type = 'text';
                    let dropdownOptions: any[] = [];
                    let value = field.value ? field.value : '';

                    switch (field.object_type) {
                        case 'textarea':
                            type = 'textarea';
                            break;
                        case 'number':
                            type = 'number';
                            break;

                        case 'boolean':
                            type = 'checkbox';
                            break;

                        case 'file':
                            type = 'file';
                            break;

                        case 'dropdown':
                        case 'multiselect':
                            type = field.object_type;

                            if (field.api_call && apiCalls[field.api_call]) {
                                dropdownOptions = apiCalls[field.api_call];
                            } else {
                                dropdownOptions = await triggerGetCustomerTypes({
                                    customerType: field.api_call
                                }).unwrap()
                                    .then((result: any) => {
                                        const returnResult =
                                            result.map((item: any) => {
                                                return {value: item.id, label: item.name};
                                            });

                                        if (field.api_call) {
                                            if (!apiCalls[field.api_call]) {
                                                dispatch(addApiCalls({key: field.api_call, value: returnResult}));
                                            }
                                        }

                                        return returnResult;
                                    });
                            }

                            if (type === 'multiselect') {
                                // if (value && typeof value === 'string') {
                                //     value = JSON.parse(value);
                                // }
                                if (!props.showOnly) {
                                    if (value && typeof value === 'string') {
                                        value = value.replace('[', '').replace(']', '').split(',').map((item: any) => {
                                            return parseInt(item);
                                        });
                                    }
                                }
                            } else {
                                if (props.showOnly) {
                                    let find = dropdownOptions.find((item: any) => {
                                        return item.value === value
                                    });

                                    if (find) {
                                        value = find.map((item: any) => {
                                            return item.label
                                        });
                                    }

                                } else {

                                    value = parseInt(value);
                                }
                            }

                            break;
                    }

                    let temp = {
                        value: value,
                        label: field.display_name,
                        name: field.name,
                        type: type,
                        fieldName: field.name,
                        dropdownOptions: dropdownOptions,
                    }

                    if (props.id || type !== 'file') {
                        result.push(temp);
                    }
                }
            }
        }
        return result;
    };

    const onChangeField = (value: string, fieldName: string) => {
        dispatch(changeField({
            element_id: props.type === UnitFieldType.element ? props.id : undefined,
            inspection_task_id: props.type === UnitFieldType.inspectionTask ? props.id : undefined,
            key: fieldName,
            value: value
        }));

        setResult((prevState) => {
            let temp = [...prevState];

            for (let i = 0; i < temp.length; i++) {
                let tempItem = temp[i];

                if (tempItem.name === fieldName) {
                    tempItem.value = value;
                }
            }

            return temp;
        });

        // setValues((prevState: any) => {
        //     const temp = JSON.parse(JSON.stringify(prevState));
        //     return {...temp, [fieldName]: value};
        // });
    };

    return <>{props.showOnly && <div>
        {result && result.map((itemResult: any) => {
            switch (itemResult.type) {
                // case 'file':
                //     return <div className={props.className}>
                //         <ElementFileManagement project={props.project} id={props.id} type={props.type}
                //                                editable={false}
                //                                header={itemResult.label}
                //                                setFilesCount={() => {
                //                                }} showPreview={false} skeletonCount={1}/></div>;

                case 'checkbox':
                    return <div className={'grid' + (props.className ? ' ' + props.className : '')}>
                        <div className={'col'}>{itemResult.label}</div>
                        <div className={'col flex justify-content-end font-bold'}>{itemResult.value ?
                            <i className={'pi pi-check-square'}/> : <i className={'pi pi-stop'}/>}</div>
                    </div>

                default:
                    return <div className={'grid' + (props.className ? ' ' + props.className : '')}>
                        <div className={'col'}>{itemResult.label}</div>
                        <div
                            className={'col flex justify-content-end font-bold'}>{itemResult.value ? itemResult.value : '-'}</div>
                    </div>
            }
        })}
    </div>}
        {!props.showOnly && result && result.map((itemResult: any) => {
            switch (itemResult.type) {
                // case 'file':
                //     return <div className={props.className}>
                //         <ElementFileManagement project={props.project} id={props.id} type={props.type}
                //                                editable={true}
                //                                header={itemResult.label}
                //                                setFilesCount={() => {
                //                                }} showPreview={false} skeletonCount={1}/></div>;

                default:
                    return <div className={props.className}>
                        <Input edit={true} name={itemResult.fieldName}
                               value={itemResult.value}
                               label={itemResult.label} type={itemResult.type}
                               dropdownOptions={itemResult.dropdownOptions}
                               showClear={true}
                               onChange={(e) => {
                                   if (fields) {
                                       if (itemResult.type === 'number') {
                                           onChangeField(e, itemResult.fieldName);
                                       } else {
                                           onChangeField(e.target.value || '', itemResult.fieldName);
                                       }
                                   }
                               }}/></div>
            }
        })}
    </>
}
