import {FunctionComponent, useContext, useEffect, useRef, useState} from 'react';
import MapContext from '../map/MapContext';
import {Vector as VectorSource} from 'ol/source';
import Cluster from 'ol/source/Cluster';
import VectorLayer from 'ol/layer/Vector';
import geoSettings from '../../../config/geoSettings';
import {useLazyGetElementGeoDataQuery} from '../../../redux/rtk/injectedGeoApi';
import {
    createFeatures,
    pinImageStyle,
} from '../../functions/Geo';

type poiProps = {
    projectId: number,
    itemId?: any,
    zoomMap: number
}

const PoiModifyLayer: FunctionComponent<poiProps> = ({projectId, itemId, zoomMap}) => {
    // @ts-ignore

    const {map, setMap} = useContext(MapContext);
    const [vLayer, setVlayer] = useState<any>({});
    const [triggerElements, {data: resultData, isLoading, isError, isSuccess}] = useLazyGetElementGeoDataQuery();
    const [nSource, setNSource] = useState({
        removeFeature(feature: any) {
        },
        addFeature(mp: any) {
        },
        clear() {
        },
        addFeatures(features: any[]) {
        }
    });
    const refVLayer = useRef(vLayer);
    const refNSource = useRef(nSource);

    const getVectorLayerObject = (source: any, layerId: string, layerVisibility: boolean) => {

        const vectorLayer = new VectorLayer({
            source: source
        });
        vectorLayer.set('name', layerId);
        vectorLayer.set('id', layerId);
        vectorLayer.set('type', 'vector');
        vectorLayer.set('zIndex', geoSettings.poiLayerKey);
        vectorLayer.set('visible', layerVisibility);
        vectorLayer.setStyle(getMpStyles);

        return vectorLayer;
    };

    const getMpStyles = (feature: any, resolution: number) => {
            return pinImageStyle(feature, null);
    };

    const createLayer = async (map: any) => {
        const layerId = 'poiModifyLayer';
        const source = new VectorSource({});
        const vectorLayer = getVectorLayerObject(source, layerId, true);
        map.addLayer(vectorLayer);
        // @ts-ignore
        setVlayer(vectorLayer);
        setMap(map);
    };

    useEffect(() => {
        if (!map || Object.keys(map).length === 0) return;
        // @ts-ignore
        if (vLayer !== null) {
            refVLayer.current = vLayer;
        }

        if (isSuccess && vLayer !== null) {
            if (resultData && resultData.coords_3857 !== null) {
                createFeatures(resultData, null).then(result => {
                    if (result.features.length > 0) {
                        if (itemId&&itemId!==null) {
                            const poi = result.features[0];
                            refVLayer.current.getSource().addFeature(poi);
                            map.getView().setCenter(poi.getGeometry().getCoordinates())
                           // console.log('add', poi)
                        }
                    }
                });
            }
        }
    }, [isSuccess, vLayer]);


    useEffect(() => {
        if (!map || Object.keys(map).length === 0) return;
        // eslint-disable-next-line no-console

        createLayer(map).then(r => console.log('PoiModifyLayer erstellt'));

    }, [map]);


    useEffect(() => {
        if (itemId&&itemId!==null)  {
            triggerElements({project_id:projectId,mapping: {element_id: itemId, replace_coords: true}});
        }
    }, []);
    return null;
};

export default PoiModifyLayer;
