import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {
    useGetInspectionElementsQuery
} from "../../redux/rtk/injectedInspectionApi";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import {InspectionElementTasks} from "./InspectionElementTasks";
import Input from "../global/Input";
import {Button} from "primereact/button";

type Props = {
    project: any,
    inspectionId: string
}

export const InspectionData = (props: Props) => {
    const {t} = useTranslation(['common']);
    const navigate = useNavigate();

    const [values, setValues] = useState<any>(undefined);
    const [selectedValue, setSelectedValue] = useState<string>('');
    const [selectedValueName, setSelectedValueName] = useState<string>('');

    const inspectionDataQuery = useGetInspectionElementsQuery({
        projectId: props.project.id,
        inspectionId: props.inspectionId
    });

    useEffect(() => {
        if (inspectionDataQuery && inspectionDataQuery.data) {
            setValues(inspectionDataQuery.data);
        }
    }, [inspectionDataQuery]);

    useEffect(() => {
        if (values && values.length) {
            setSelectedValue(values[0].element_id);
        }
    }, [values]);

    useEffect(() => {
        if (values) {
            let temp = values.find((item: any) => {
                return item.element_id === selectedValue;
            });

            if (temp) {
                setSelectedValueName(temp.element_name);
            }
        }
    }, [selectedValue]);

    const onSelectionChanged = (e: any) => {
        setSelectedValue(e.value);
    }

    return (<div style={{overflowX: 'hidden', overflowY: 'auto'}}>
        <div className={'grid h-full'}>
            <div className={'col-12'} style={{maxWidth: '400px'}}>
                {values && <div className={'flex'}>
                    <Input edit={true} name={'inspection_task_element'} type={'dropdown'}
                           label={`${t('inspection_elements_with_task')} (${values.length})`}
                           dropdownOptions={values.map((item: any) => {
                               return {
                                   label: `${item.element_name} (${item.element_type_name})`,
                                   value: item.element_id
                               }
                           })} value={selectedValue} onChange={onSelectionChanged}/>
                    {selectedValue &&
                        <Button className={'p-button p-button-text p-button-outlined ml-3 mt-2'} onClick={() => {
                            const url = `/project/${props.project.id}/#unittree_${selectedValue}`;

                            navigate(url);
                        }}><FontAwesomeIcon icon={faList}/>
                        </Button>}
                </div>}
            </div>
            <div className={'col-12'}>
                {selectedValue && <InspectionElementTasks project={props.project}
                                                          inspectionId={props.inspectionId}
                                                          elementId={selectedValue}
                                                          elementName={selectedValueName}/>}
            </div>
        </div>
    </div>)
}


