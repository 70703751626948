import CustomDialog, {CustomDialogHeaderType} from './CustomDialog';
import LocationForm from './LocationForm';
import React, {useEffect, useState} from 'react';
import {Location} from '../functions/Global';
import {useTranslation} from 'react-i18next';
import {Button} from 'primereact/button';

type Props = {
    project: any,
    setSelectedLocations: (data: Location[]) => void
    selectedLocations: Location[],
    editLocation?: boolean,
    setEditLocation?: (data: boolean) => void,
    showSaveAndNewButton: boolean,
    setReloadLocations?: (data: boolean) => void,
    isProjectCenter?: boolean
}

export const LocationDialogEditForm = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common', 'metrology']);
    const [saveAndNewClicked, setSaveAndNewClicked] = useState<boolean>(false);

    const customButtons = [
        <Button
            type='submit'
            form={'formLocation'}
            label={t('saveAndNew')}
            icon="pi pi-plus"
            className="p-button-text"
            disabled={false}
            onClick={() =>{
                setSaveAndNewClicked(true);
            }}
        />
    ];

    return (<CustomDialog
        header={props.selectedLocations.length > 0 ? t('loc_editLocationDialog_header') : t('loc_createLocationDialog_header')}
        headerType={props.selectedLocations.length > 0 ? CustomDialogHeaderType.Update : CustomDialogHeaderType.Create}
        formName={'formLocation'}
        onCancel={() => {
            if (props.setSelectedLocations) props.setSelectedLocations([]);
            if (props.setEditLocation) props.setEditLocation(false);
        }}
        visible={typeof props.editLocation === 'boolean' ? props.editLocation : false}
        maximized={true}
        customButtons={props.showSaveAndNewButton ? customButtons : null}
    >
        <LocationForm
            project={props.project}
            location={props.selectedLocations.length > 0 ? props.selectedLocations[0] : null}
            saveAndNewClicked={saveAndNewClicked}
            onFinished={() => {
                if (props.setReloadLocations) props.setReloadLocations(true);
                if (saveAndNewClicked) {
                    // Setze lokalen state zurück
                    setSaveAndNewClicked(false);
                } else {
                    // Zerstöre den Dialog wenn auf Speichern (nicht auf 'Speichern und weitere anlegen') geklickt wurde
                    if (props.setEditLocation) props.setEditLocation(false);
                }
            }}
            isProjectCenter={props.isProjectCenter}
        />
    </CustomDialog>);
}
