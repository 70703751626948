import {createSlice, Draft, PayloadAction} from '@reduxjs/toolkit';

// Define a type for the slice state
interface ConfigLayers {
    layerData: any
}

// Define the initial state using that type
const initialState: ConfigLayers = {
    layerData: {
        baseLayer: '',
        baseLayerWorkspace: '',
        changedLayer: '',
        filterMp: null,
        geoserverWorkspace: '',
        layerArray: [],
        layerCounterArray: [],
        layerDoneCounter: [],
        layerNameArray: [],
        layerNodeArray: [],
        selection: [],
        clearSelection:false,
        equipmentArray:[],
        modifyState:false,
    }
};

export const configLayersSlice = createSlice({
    name: 'layerArray',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        addLayerToCounterArray: (state, action: PayloadAction<any>) => {
            state.layerData.layerCounterArray.push(action.payload);
        },

        reportChangedLayer: (state, action: PayloadAction<any>) => {
            state.layerData.changedLayer = action.payload;
        },
        resetData: (state) => {
            state.layerData.layerDoneCounter = 0;
            state.layerData.layerCounterArray = [];
            state.layerData.baseLayer = '';
            state.layerData.geoserverWorkspace = '';
            state.layerData.formatLayerAttributesArray = [];
            state.layerData.isPermaLink = false;
            state.layerData.styleArray = [];
            state.layerData.layerArray = [];
            state.layerData.permaLinkLayer = [];
            state.layerData.layerNameArray = [];
            state.layerData.layerAttributesArray = [];
            state.layerData.editAttributesArray = [];
            state.layerData.layerfeatureInfosArray = [];
            state.layerData.externLegendArray = [];
            state.layerData.externLegendPic = [];
        },
        setBaseLayer: (state, action: PayloadAction<any>) => {
            state.layerData.baseLayer = (action.payload !== null && typeof action.payload !== 'undefined')
                ? action.payload.toLowerCase()
                : null;
        },
        setBaseWorkspace: (state, action: PayloadAction<any>) => {
           // console.log('setBaseWorkspace', action.payload);
            state.layerData.baseLayerWorkspace = action.payload;
        },
        setConfigData: (state, action: PayloadAction<any>) => {
            const payload = action.payload;
            state.layerData.layerCounterArray.push(payload.layerCounter);
            state.layerData.styleArray = payload.styleArray;
            state.layerData.styleArraySet = true;
            state.layerData.layerNameArray = payload.layerNames;
            state.layerData.layerAttributesArray = payload.layerAttributes;
            state.layerData.formatLayerAttributesArray = payload.attributesFormat;
            state.layerData.editAttributesArray = payload.editAttributes;
            state.layerData.layerfeatureInfosArray = payload.layerFeatures;
            state.layerData.externLegendArray = payload.externLegend;
            state.layerData.externLegendPic = payload.externLegendPic;
            state.layerData.layerNodeArray = payload.treeName;
        },
        setLayerArray: (state, action: PayloadAction<any>) => {
            state.layerData.layerArray.push(action.payload);
        },
        setLayerNodes: (state, action: PayloadAction<any>) => {
            state.layerData.layerNodeArray = action.payload;
        },
        setEquipmentArray: (state, action: PayloadAction<any>) => {
            state.layerData.equipmentArray = action.payload;
        },
        setModifyState: (state, action: PayloadAction<any>) => {
            state.layerData.modifyState = action.payload;
        },
        setWorkspace: (state, action: PayloadAction<any>) => {
            state.layerData.geoserverWorkspace = action.payload;
        },
    },
});

export const {
    addLayerToCounterArray,
    reportChangedLayer,
    resetData,
    setBaseLayer,
    setBaseWorkspace,
    setConfigData,
    setLayerArray,
    setLayerNodes: setLayerNodes,
    setWorkspace,
    setEquipmentArray,
    setModifyState
} = configLayersSlice.actions;

export default configLayersSlice.reducer;
