import {baseApi} from './baseApi';

export const injectedUnitsApi = baseApi.injectEndpoints({
    addTagTypes: ['Units'],
    endpoints: (builder) => ({
        getObjectTypes: builder.query({
            query: (args) => {
                const {projectTypeId} = args;

                const url = 'getObjectTypes?project_type_id=' + projectTypeId;

                return {url: url};
            },
            providesTags: ['Units']
        }),
        getElementTypes: builder.query({
            query: (args) => {
                const {projectId, locale} = args;

                const url = `getElementTypes?project_id=${projectId}&locale=${locale}`;

                return {url: url};
            },
            providesTags: ['Units']
        }),
        getElementTree: builder.query({
            query: (args) => {
                const {projectId} = args;

                const url = 'getElementTree?project_id=' + projectId;

                return {url: url};
            },
            providesTags: ['Units']
        }),
        getFields: builder.query({
            query: (args) => {
                const {projectId, elementId} = args;

                const url = 'getFields?project_id=' + projectId + '&element_id=' + elementId;

                return {url: url};
            },
            providesTags: ['Units']
        }),
        getUrl: builder.query({
            query: (args) => {
                const {url, params} = args;
                return {url: url + params};
            },
            providesTags: ['Units']
        }),
        getCustomerTypes: builder.query({
            query: (args) => {
                const {customerType} = args;
                return {url: 'getCustomerTypes?customer_type=' + customerType};
            },
            providesTags: ['Units']
        }),
        getFiles: builder.query({
            query: (args) => {
                const {params} = args;
                const url = 'getFiles';
                return {url: url + params};
            },
            providesTags: ['Units']
        }),
        createUnit: builder.mutation({
            query: (unit) => ({
                url: 'createUnit',
                method: 'POST',
                body: unit,
            }),
            invalidatesTags: ['Units']
        }),
        addElement: builder.mutation({
            query: (unit) => ({
                url: 'addElement',
                method: 'POST',
                body: unit,
            }),
            invalidatesTags: ['Units']
        }),
        updateElement: builder.mutation({
            query: (unit) => ({
                url: 'updateElement',
                method: 'PUT',
                body: unit,
            }),
            invalidatesTags: ['Units']
        }),
        updateElementConnection: builder.mutation({
            query: (unit) => ({
                url: 'updateElement',
                method: 'PUT',
                body: unit,
            }),
            invalidatesTags: ['Units']
        }),
        deleteElement: builder.mutation({
            query: (unit) => ({
                url: 'deleteElement',
                method: 'DELETE',
                body: unit,
            }),
            invalidatesTags: ['Units']
        }),
        getFileType: builder.query({
            query: (args) => {
                const {projectTypeId} = args;

                const url = 'getFileType?project_type_id=' + projectTypeId;

                return {url: url};
            },
            providesTags: ['Units']
        }),
        getDocumentType: builder.query({
            query: (args) => {
                const {projectTypeId} = args;

                const url = 'getDocumentType?project_type_id=' + projectTypeId;

                return {url: url};
            },
            providesTags: ['Units']
        }),
        getDocuments: builder.query({
            query: (args) => {
                const {projectId, elementId} = args;

                const url = `getDocuments?project_id=${projectId}&element_id=${elementId}`;

                return {url: url};
            },
            providesTags: ['Units']
        }),
        createDocument: builder.mutation({
            query: (document) => ({
                url: 'createDocument',
                method: 'POST',
                body: document,
            }),
            invalidatesTags: ['Units']
        }),
        deleteDocument: builder.mutation({
            query: (document) => ({
                url: 'deleteDocument',
                method: 'DELETE',
                body: document,
            }),
            invalidatesTags: ['Units']
        }),
        setDocumentPreview: builder.mutation({
            query: (document) => ({
                url: 'setDocumentPreview',
                method: 'PUT',
                body: document,
            }),
            invalidatesTags: ['Units']
        }),
        getPossibleParentElements: builder.query({
            query: (args) => {
                const {projectId, elementId, elementTypeId} = args;

                let url = `getPossibleParentElements?project_id=${projectId}`;

                if (elementId) {
                    url += `&element_id=${elementId}`;
                }

                if (elementTypeId) {
                    url += `&element_type_id=${elementTypeId}`;
                }

                return {url: url};
            },
            providesTags: ['Units']
        }),
    }),
});

export const {
    useGetObjectTypesQuery,
    useGetElementTypesQuery,
    useGetElementTreeQuery,
    useLazyGetFieldsQuery,
    useLazyGetUrlQuery,
    useLazyGetCustomerTypesQuery,
    useGetFilesQuery,
    useCreateUnitMutation,
    useAddElementMutation,
    useUpdateElementMutation,
    useUpdateElementConnectionMutation,
    useDeleteElementMutation,
    useGetFileTypeQuery,
    useGetDocumentTypeQuery,
    useGetDocumentsQuery,
    useCreateDocumentMutation,
    useDeleteDocumentMutation,
    useSetDocumentPreviewMutation,
    useGetPossibleParentElementsQuery
} = injectedUnitsApi;
