import {baseApi} from './baseApi';

export const injectedGeoApi = baseApi.injectEndpoints({
    addTagTypes: ['Geo'],
    endpoints: (builder) => ({
        getElementGeoData: builder.query({
            query: (args) => {

                const {project_id,mapping} = args;
                let url = `getElement?mobile=false&project_id=${project_id}`;
                if (mapping) {
                    let keys = Object.keys(mapping);
                    keys.forEach(function(key){
                        let value = mapping[key];
                        url += `&${key}=${value}`;
                    })

                }
                return {url: url};
            },
            providesTags: ['Geo']
        }),
        getPolylineData: builder.query({
            query: (args) => {

                const {project_id,mapping} = args;
                let url = `getPolylines?project_id=${project_id}`;
                if (mapping) {
                    let keys = Object.keys(mapping);
                    keys.forEach(function(key){
                        let value = mapping[key];
                        url += `&${key}=${value}`;
                    })

                }
                return {url: url};
            },
            providesTags: ['Geo']
        }),

    }),
});

export const {
    useLazyGetElementGeoDataQuery,
    useLazyGetPolylineDataQuery,
} = injectedGeoApi;
