/*
 * UserEditForm.tsx
 * Author: amusiol
 * Date: 22.02.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React from 'react';
import RolesSelectList from './RolesSelectList';
import FunctionsSelectList from './FunctionsSelectList';
import LanguageSelectList from '../global/LanguageSelectList';
import {Trans, useTranslation} from 'react-i18next';
import Input from '../global/Input';
import functionLibrary from '../../functions/functionLibrary';
import {useForm} from 'react-hook-form';
import {checkUserLogin, createUser, updateUser, User} from '../functions/UserManagement';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import {parseJwt} from '../../services/KeycloakService';
import {showReloadPageDialog} from '../global/ReloadPageDialog';
import TimezoneSelectList from '../global/TimezoneSelectList';
import {confirmDialog} from 'primereact/confirmdialog';


type Props = {
    userData: User,
    projectId: string,
    onFinished: (success: boolean) => void
    setVisible: any
    type: string // 'user' or 'admin'

}

/**
 * Form for editing and creating of users
 * @param props
 * @constructor
 */
const UserEditForm = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common', 'users', 'input']);

    const userId = (props.userData && typeof props.userData.id !== 'undefined') ? props.userData.id : null;
    const disabled = (props.userData && typeof props.userData.id !== 'undefined');

    const checkAndSetSettings = (setting: string, value: any) => {
        if (value.length > 3) {
            checkUserLogin( setting, value).then((result) => {
                if (result.userExists === 'true')
                    showMessageOnError(t('error'), setting + ' vorhanden');
            });
        }
    };

    const defaultValues = {
        'first_name': props.userData ? props.userData.first_name : '',
        'last_name': props.userData ? props.userData.last_name : '',
        'email': props.userData ? props.userData.email : '',
        'enabled': props.userData ? props.userData.enabled : true,
        'username': props.userData ? props.userData.username : '',
        'sms': props.userData ? (props.userData.sms || '') : '',
        'company': props.userData ? (props.userData.company || '') : '',
        'language_id': props.userData ? (props.userData.language_id || navigator.language || 'de') : (navigator.language || 'de'),
        'role_id': props.userData ? props.userData.role_id : '',
        'password': '',
        'timezone': props.userData ? (props.userData.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone || 'Europe/Berlin') : (Intl.DateTimeFormat().resolvedOptions().timeZone || 'Europe/Berlin'),
        'global_role_id': props.userData ? props.userData.global_role_id : '',
    };

    const {control, formState: {errors}, handleSubmit, reset} = useForm({defaultValues});

    const getFormErrorMessage = (name: string) => {
        // @ts-ignore
        return errors[name] && <small className="p-error custom">{errors[name].message}</small>;
    };

    const _updateUser = (userId: string, data: any) => {
        const projectId = props.type === 'admin' ? 'none' : props.projectId;
        updateUser(projectId, userId, props.type === 'admin', data).then(result => {
            if (result.error) {
                showMessageOnError(t('error'), result.error);
            } else {
                showMessageOnSuccess( t('success'), props.type === 'admin' ? t('userMgmt_adminUpdated') : t('userMgmt_userUpdated'));
                props.setVisible(false);

                // Wenn man sich selbst über die Benutzerliste eines Projektes oder über die Adminsliste editiert
                // und dann editProfile aufruft, sieht man noch die alten Angaben, da der localStorage nicht
                // aktualisert wurde. Außerdem müssen die Rechte neu geladen werden, wenn man seine eigene Rolle
                // ändert. Deswegen wird hier ein reload veranlasst.
                const token = localStorage.getItem('keycloak-token') || '';
                const tokenPayload = parseJwt(token);
                const tokenUserId = tokenPayload.sub;
                if (userId === tokenUserId) {
                    showReloadPageDialog(t, t('reloadPage.msgOwnUserChanged'));
                } else {
                    props.onFinished(true);
                }
            }
        });
    };

    const getAdminWarnMessage = (
        <React.Fragment>
            <Trans>
                <p>{t('userM_addAdminDialog_warnMessage')}</p>
            </Trans>
        </React.Fragment>
    );

    const saveData = (data: any) => {
        if (userId !== null) {

            // Nur wenn ein Benutzer Administrator werden soll, werden alle Verknüpfungen zu den Projekten aufgelöst
            // und der Warnhinweis im Dialog angezeigt. Bei Projektersteller und Beobachter bleiben die Verknüpfungen
            if (data.role_id === 'bef4b0b6-2c04-432c-937d-b7790e88a4bc') {
                confirmDialog({
                    message: getAdminWarnMessage,
                    header: t('warning'),
                    icon: 'pi pi-exclamation-triangle',
                    acceptLabel: t('yes'),
                    rejectLabel: t('no'),
                    accept: () => {
                        _updateUser(userId, data);
                    },
                });
            } else {
                _updateUser(userId, data);
            }


        } else {
            createUser(props.projectId, data).then(result => {
                if (result.error) {
                    showMessageOnError(t('error'), result.error);
                } else {
                    showMessageOnSuccess( t('success'), props.type === 'admin' ? t('userMgmt_toasts_adminCreated') : t('userMgmt_toasts_userCreated'));
                    props.onFinished(true);
                    props.setVisible(false);
                }
            });
        }
    };

    return (
        <form id='formDialog' onSubmit={handleSubmit(saveData)}>
            <div className='grid'>
                <div className='col-12 lg:col-6'>
                    <Input
                        edit={true} label={t('userM_attribs_firstname')} name={'first_name'}
                        validationControl={control} validationErrorMessage={getFormErrorMessage}
                        validationRules={{required: t('userM_attribs_firstname') + ' ' + t('input_required')}}
                    />
                    <Input
                        edit={true} label={t('userM_attribs_lastname')} name={'last_name'}
                        validationControl={control} validationErrorMessage={getFormErrorMessage}
                        validationRules={{required: t('userM_attribs_lastname') + ' ' + t('input_required')}}
                    />
                    <Input
                        edit={true} label={t('userM_attribs_username')} disabled={disabled} name={'username'}
                        onChange={(e: any) => checkAndSetSettings('username', e.target.value)}
                        validationControl={control} validationErrorMessage={getFormErrorMessage}
                        validationRules={{required: (userId === null) ? (t('user.username') + ' ' + t('input_required')) : false}}
                    />
                    <Input
                        edit={true} label={t('userM_attribs_password')} disabled={disabled} name={'password'}
                        validationControl={control} validationErrorMessage={getFormErrorMessage}
                        validationRules={{required: (userId === null) ? (t('userM_attribs_password') + ' ' + t('input_required')) :false}}
                    />
                    <Input
                        edit={true} label={'Email'} disabled={disabled} name={'email'}
                        tooltip={t('userM_tooltip_inputEmail')}
                        onChange={(e: any) => checkAndSetSettings('email', e.target.value)}
                        validationControl={control}
                        validationErrorMessage={getFormErrorMessage}
                        validationRules={functionLibrary.emailValidation(t)}
                    />
                    <RolesSelectList
                        label={t('userM_role')}
                        projectId={props.type === 'admin' ? 'none' : props.projectId}
                        validationControl={control}
                        validationErrorMessage={getFormErrorMessage}
                        validationRules={{required: t('role') + ' ' + t('input_required')}}
                    />
                    {
                        (window.location.href.includes('userManagement_0') ||
                            (window.location.href.includes('userManagement') && !window.location.href.includes('userManagement_2'))) && (
                            <FunctionsSelectList
                                label={t('userM_function')}
                                projectId={props.type === 'admin' ? 'none' : props.projectId}
                                validationControl={control}
                                validationErrorMessage={getFormErrorMessage}
                                validationRules={{ required: t('function') + ' ' + t('input_required') }}
                            />
                        )
                    }
                </div>
                <div className='col-12 lg:col-6'>
                    <Input
                        edit={true} label={'SMS'} name={'sms'}
                        validationControl={control} validationRules={{required: false}}
                    />
                    <Input
                        edit={true} label={t('userM_attribs_company')} name={'company'}
                        validationControl={control} validationRules={{required: false}}
                    />
                    <LanguageSelectList
                        label={t('language')}
                        validationControl={control}
                        validationErrorMessage={getFormErrorMessage}
                        validationRules={{required: t('language') + ' ' + t('input_required')}}
                    />
                    <TimezoneSelectList
                        label={t('timezone')}
                        validationControl={control}
                        validationErrorMessage={getFormErrorMessage}
                        validationRules={{required: t('timezone') + ' ' + t('input_required')}}
                    />
                    <Input
                        edit={true} label={t('userM_attribs_ableToLogin')} name={'enabled'}
                        type={'checkbox'} validationControl={control} validationRules={{required: false}}
                    />
                </div>
            </div>
        </form>
    );

};

export default UserEditForm;
