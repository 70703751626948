import React, {ElementType, FC, PropsWithChildren, useEffect, useRef, useState} from 'react';
import {Map, View} from 'ol';
import 'ol/ol.css';
import MapContext from './MapContext';
import LayerTree from '../panel/LayerTree';
import ToolkitControl from '../controls/ToolkitControl';
import {Controls, ScaleLineControl} from '../controls';
import {Interactions, PoiClusterInteraction} from '../interactions';
import {useTranslation} from 'react-i18next';
import {classNames} from 'primereact/utils';
import PoiTypeInteraction from '../interactions/PoiTypeInteraction';
import PopupControl from '../controls/PopupControl';
import {getUserFromLocalStorage} from '../../functions/Global';
import {useGetElementTypesQuery} from '../../../redux/rtk/injectedUnitsApi';
import PlaceLocation from '../controls/PlaceLocationControl';

type Props = {
    // Map:Record<string, unknown>
    project: any,
    zoom: number
    center: any,
    propElem: any
    toolkitEnabled?: boolean
    divHeight?: any,
    position?: any,
    mapActive?: boolean,
    baseControlEnabled?:boolean
}
const MapPoiOl: FC<PropsWithChildren<Props>> = ({
    project,
    children,
    zoom,
    center,
    propElem,
    toolkitEnabled,
    divHeight,
    position,
    mapActive,
    baseControlEnabled
}) => {
    const user = getUserFromLocalStorage();
    const {t} = useTranslation(['common']);
    const [map, setMap] = useState<Map | undefined>(Object);
    const [layerTreeCollapsed, setLayerTreeCollapsed] = useState(false);
    const [measuringPointIds, setMeasuringPointIds] = useState<string[]>([]);
    const [mapSetted, setMapSetted] = useState(false);
    const [isMapActive, setIsMapActive] = useState<boolean>(mapActive != null ? mapActive : true);
    const [elementTypes, setElementTypes] = useState<ElementType[]>([]);

    const elementTypesQuery = useGetElementTypesQuery({
        projectId: project.id,
        locale: user ? user.language_id : 'de'
    });

    const mapElement = useRef<HTMLDivElement>(null);
    const refMap = useRef(map);
    refMap.current = map;

    useEffect(() => {
        setElementTypes(elementTypesQuery.data);
    }, [elementTypesQuery.data]);

    const tooltipDiv =
        (<div id="tooltip" className="ol-popup" style={{
            minHeight: '20px',
            maxHeight: '60px',
            display: 'none',
            backgroundColor: '#ffffff',
            color: '#000000'
        }}>
            <div id="tooltip-content" className="popup-content"></div>
            <div id="marker" title="Marker"></div>
        </div>);

    const getMap = () => {
        if (!mapElement.current) return;
        if (propElem.project && propElem.project.spatialBookmark) {
            const bookmark = propElem.project.spatialBookmark;
            center = [bookmark.position_x, bookmark.position_y]; // Umformatieren
            zoom = bookmark.zoom;
        }
        const initialMap = new Map({
            target: mapElement.current,
            pixelRatio: 1,
            layers: [],
            view: new View({
                projection: 'EPSG:3857',
                center: center,
                zoom: zoom
            }),
        });
        setMap(initialMap);
    };

    useEffect(() => {
        if ((center[0] !== 0 || center[1] !== 0) && !mapSetted) {
            getMap();
            setMapSetted(true);
        }

        return () => {
            setMap(undefined);
        };
    }, [zoom, center]);

    useEffect(() => {
        if (map && map.on) {

            map.on('click', async (evt: any) => {
                setMeasuringPointIds([]);
                const mp: string[] = [];
                map.forEachFeatureAtPixel(evt.pixel, function (f: any, parentLayer: any) {
                    if (f.get('layerKind') === 'poiLayer') {
                        mp.push(f.get('id'));
                    }
                });
                setMeasuringPointIds(mp);
            });
        }
    }, [map]);

    return (<div
        className={classNames('map-panel h-full w-full')}
        onClick={() => {
            setIsMapActive(true);
        }}><MapContext.Provider value={{map, setMap}}> {tooltipDiv}
            <div className={isMapActive ? '' : 'pointer-events-none'}>
                <div className={'map-info-active'} style={{color: 'black'}}>{t('geomon_mapInfoClick')}</div>
                <div style={{
                    height: divHeight ? divHeight : '100vh',
                    width: '100%',
                    position: position ? position : 'absolute'
                }} ref={mapElement}
                className="map-container"><>{children}
                        <Controls key={propElem.project.id + '_controlsMaps'}>
                            {toolkitEnabled &&
                        <ToolkitControl projectId={propElem.project.id}
                            projectBaseLayer={propElem.project.base_layer}/>}
                            {baseControlEnabled &&<PlaceLocation projectId={propElem.project.id} item={propElem.item} modifyLayer={'poiModifyLayer'} itemId={propElem.item.id}/>}
                            <ScaleLineControl/>
                        </Controls></>
                    {!baseControlEnabled &&<Interactions key={propElem.project.id + '_interactionsMaps'}>
                        <PoiClusterInteraction/>
                        <PoiTypeInteraction  projectId={propElem.project.id}/>
                        {/* eslint-disable-next-line react/jsx-no-undef */}
                        <PopupControl projectId={propElem.project.id} locationIds={measuringPointIds}
                            elementTypes={elementTypes}/>
                    </Interactions>}

                </div>
            </div>
            {toolkitEnabled &&
            <LayerTree baseLayer={propElem.project.base_layer} projectId={propElem.project.id}
                onCollapsed={(collapsed) => {
                    setLayerTreeCollapsed(collapsed);
                }} locationIds={measuringPointIds}/>}
        </MapContext.Provider>
    </div>);

};
export default MapPoiOl;
