import React, {useState, useEffect, createRef} from 'react';
import {
    Routes,
    Route
} from 'react-router-dom';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './style/custom-layout.scss';
import './style/custom-openlayers.scss';
import {useTranslation} from 'react-i18next';

import settings from './config/settings';
import keycloakfetch from './functions/keycloakfetch';
import {Button} from 'primereact/button';
import {Toast} from 'primereact/toast';
import {ConfirmDialog} from 'primereact/confirmdialog';
import {KeycloakService} from './services/KeycloakService';

import {Access} from './views/pages/Access';
import {NotFound} from './views/pages/NotFound';
import Main from './views/Main';
import {updateToastRef} from './components/global/CustomToast';
import {ProgressSpinner} from 'primereact/progressspinner';
import {hideWaitAnimation, showWaitAnimation, updateWaitAnimationRef} from './components/global/CustomWaitAnimation';

const App = () => {

    const {t, i18n} = useTranslation(['common']);

    const [confirmDialogVisible, setConfirmDialogVisible] = useState<boolean>(false);
    const [keycloakToken, setKeycloakToken] = useState<string>('');
    const [user, setUser] = useState(null);
    const toast: any = createRef();
    const waitAnimation: any = createRef();

    useEffect(() => {
        updateToastRef(toast);
    });

    useEffect(() => {
        updateWaitAnimationRef(waitAnimation);
    });

    useEffect(() => {
        if (keycloakToken !== '') {
            getLoggedInUserFetch().then();
        }
    }, [keycloakToken]);

    const logout = () => {
        showWaitAnimation();
        const currentUrl = window.location.href;
        const keycloakIdToken = localStorage.getItem('keycloak-id-token');
        window.location.href = settings.logoutUrl
            + '?id_token_hint=' + keycloakIdToken
            + '&post_logout_redirect_uri=' + currentUrl;
        hideWaitAnimation();
    };

    const getLoggedInUserFetch = async () => {
        await keycloakfetch.get(settings.apiUrl + '/getMe').then(result => {
            // Set i18next language
            i18n.changeLanguage(result.language_id);
            localStorage.setItem('user', JSON.stringify(result));
            setUser(result);
        });
    };

    const onSessionExpired = () => {
        setConfirmDialogVisible(true);
    };

    const sessionExpiredDialogFooter = (
        <React.Fragment>
            <Button label={'Ok'} icon="pi pi-check" className="p-button-text" onClick={logout}/>
        </React.Fragment>
    );

    return (
        <>
            {keycloakToken && user !== null && <Routes>
                <Route path={'/'} element={<Main keycloakToken={keycloakToken} currentUser={user}/>}/>
                <Route path={'/project/:projectId'} element={<Main keycloakToken={keycloakToken} currentUser={user}/>}/>
                <Route path={'/project/:projectId/:bookmarkId/'}
                       element={<Main keycloakToken={keycloakToken} currentUser={user}/>}/>
                <Route path='/accessdenied' element={<Access/>}/>
                <Route path='*' element={<NotFound/>}/>
            </Routes>
            }
            <KeycloakService onAuth={setKeycloakToken} onSessionExpired={onSessionExpired}/>
            <ConfirmDialog
                className={'hide-x'}
                visible={confirmDialogVisible}
                message={t('sessionExpired_message')}
                header={t('sessionExpired_header')}
                icon="pi pi-exclamation-triangle"
                footer={sessionExpiredDialogFooter}
            />
            <Toast ref={toast}/>
            {<div ref={waitAnimation}
                  style={{
                      position: 'absolute',
                      height: '100%',
                      width: '100%',
                      zIndex: '9999999',
                      display: 'none',
                      top: 'calc(50% - 50px)',
                      left: 'calc(50% - 50px)'
                  }}>
                <ProgressSpinner style={{width: '100px', height: '100px'}} strokeWidth={'5px'}/></div>}
        </>
    );
};

export default App;
