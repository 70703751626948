import React, {FunctionComponent, useContext, useEffect, useRef, useState} from 'react';
import MapContext from '../map/MapContext';
import {useTranslation} from 'react-i18next';
import {transformCoords} from '../../../services/SpatialService';
import {Feature} from 'ol';
import { RegularShape, Stroke, Style, Text} from 'ol/style';
import {Point} from 'ol/geom';
import {Control} from 'ol/control';
import {Draw, Modify, Snap} from 'ol/interaction';
import {getLayerById} from '../../functions/Geo';
import Collection from 'ol/Collection.js';
import {changeField, mapReady, resetElementChanges} from '../../../redux/slices/ElementChangesSlice';
import {setModifyState} from '../../../redux/slices/GeomonitoringSlice';
import {useAppDispatch} from '../../../redux/hooks';

type geocodingProps = {
    projectId: any,
    item: any,
    modifyLayer:string,
    itemId?:any
}

const PlaceLocationControl: FunctionComponent<geocodingProps> = (props) => {
    // @ts-ignore
    const {map} = useContext(MapContext);
    const {t} = useTranslation(['geomonitoring']);
    const dispatch = useAppDispatch();

    const [overlayLayer, setOverlayLayer] = useState({});
    const [modify, setModify] = useState<Modify>();
    const [draw, setDraw] = useState<Draw>();
    const [snap, setSnap] = useState<Snap>();
    const [overlaySource, setOverlaySource] = useState({
        length: undefined,
        clear() {
        },
        addFeature(feature: any) {
        },
        getFeatures(): any {
        },
        addEventListener(feature: any) {
        }
    });
    const [toogleAllowed, setToogleAllowed] = useState(false);
    const [originalCoords, setOriginalCoords] = useState([]);
    const [searchedFeature, setSearchedFeature] = useState([]);
    const toogleRef = useRef(toogleAllowed);
    const refOverlayLayer = useRef(overlayLayer);
    const refOverlaySource = useRef(overlaySource);
    const refModify = useRef(modify);
    const refDraw = useRef(draw);
    const refSnap = useRef(snap);
    const refOriginalCoords = useRef(originalCoords);
    const editIconBase = '<span style="font-size:large"><i class="fas fa-edit"></i></span>';
    const editIconActive = '<span style="font-size:large"><i class="fa fa-save"></i></span>';

    const createBaseMapButton = () => {
        const buttonLocation = document.createElement('button');
        buttonLocation.id = 'buttonLocation';
        buttonLocation.title = t('geoMon_LocationPositionTitle');
        buttonLocation.innerHTML = editIconBase;
        buttonLocation.addEventListener('click', placeLocation, false);
        return buttonLocation;
    };

    const createCleanMapButton = () => {
        const buttonCleanMap = document.createElement('button');
        buttonCleanMap.id = 'buttonCleanLocation';
        buttonCleanMap.title = t('geoMon_cleanMapTitle');
        buttonCleanMap.style.display='none';
        buttonCleanMap.style.backgroundColor = '#FF9900';
        buttonCleanMap.innerHTML = '<span style="font-size:large; display: block"><i class="fas fa-trash"></i></span>';
        buttonCleanMap.addEventListener('click', cleanLocation, false);
        return buttonCleanMap;
    };

    const cleanLocation = (e: any) => {
        cleanMap();
    };

    const placeLocation = (e: any) => {
        toogleFnc(!toogleRef.current);
    };

    const toogleFnc = (val: boolean) => {
        setToogleAllowed(val);
        toogleRef.current = val;
        const cleanMapButton=document.getElementById('buttonCleanLocation');
        // @ts-ignore
        cleanMapButton.style.display=val?'block':'none';
    };

    const getOverlayLayer = () => {
        getLayerById(map, props.modifyLayer).then(layerById => {
            if (layerById.found) {
                // @ts-ignore
                setOverlayLayer(layerById.layer);
                // @ts-ignore
                setOverlaySource(layerById.layer.getSource());
                // @ts-ignore
                refOverlayLayer.current = layerById.layer;
                // @ts-ignore
                refOverlaySource.current = layerById.layer.getSource();
            }
        });
    };

    const addInteractions = () => {
        const features = refOverlaySource.current.getFeatures();
        if (features.length === 0) {
            getDraw();
        } else if (features && features.length > 0) {
            // @ts-ignore
            const sFeature=refOverlaySource.current.getFeatureById(props.itemId);
            setSearchedFeature(sFeature)
            const coords = sFeature.getGeometry().getCoordinates();
            setOriginalCoords(coords);
            refOriginalCoords.current = coords;
            getModify();
        }

        getSnap();
    };

    const removeInteractions = () => {
        map.removeInteraction(refModify.current);
        map.removeInteraction(refDraw.current);
        map.removeInteraction(refSnap.current);
    };

    const getControls = () => {
        const buttonLocation = createBaseMapButton();
        const buttonClean = createCleanMapButton();
        const element = document.createElement('div');
        element.className = 'ctrl-base-map ol-unselectable ol-control';
        element.id = 'olControls';
        element.appendChild(buttonLocation);
        element.appendChild(buttonClean);
        const location = new Control({
            element: element
        });
        return [location];
    };

    const positionCrossStyle=()=> {
        const strokeFront = new Stroke({color: '#fa3746', width: 4});
        return new Style({
            image: new RegularShape({
                stroke: strokeFront,
                points: 4,
                radius: 7,
                radius2: 0,
                angle: 0,
            }),
        });
    };

    const getDraw = () => {

        const drawElem = new Draw({
            // @ts-ignore
            source: refOverlaySource.current,
            type: 'Point',
            style: positionCrossStyle
        });
        setDraw(drawElem);
        refDraw.current = drawElem;
        map.addInteraction(drawElem);
    };

    const getSnap = () => {
        // @ts-ignore
        const snapElem = new Snap({source: refOverlaySource.current, pixelTolerance:50});
        setSnap(snapElem);
        refSnap.current = snapElem;
        map.addInteraction(snapElem);
    };

    const getModify = () => {
        // @ts-ignore

        // @ts-ignore

        const searchedFeature=refOverlaySource.current.getFeatureById(props.itemId);
        const modifyElem = new Modify(
            {
                // @ts-ignore
                // source: refOverlaySource.current,
                style:positionCrossStyle,
                // @ts-ignore
                features:new Collection([searchedFeature]),
                insertVertexCondition: function () {
                    // prevent new vertices to be added to the polygons
                    return false;
                },
            });
        setModify(modifyElem);
        refModify.current = modifyElem;
        map.addInteraction(refModify.current);
    };

    const sentDispatch = (id: any, key: any, value: any) => {
        dispatch(changeField({
            element_id: id,
            key: key,
            value: value
        }));
    };

    const getCoordinatesOfFeatureAndSent = (coordinates: any[]) => {
        //console.log('getCoordinatesOfFeatureAndSent',coordinates)
        const newCoords = transformCoords(coordinates, 3857, 4326);

        //const reversedCoords = newCoords.reverse();
       // console.log(newCoords)
        sentDispatch(props.item.id, 'x', newCoords[0]);
        sentDispatch(props.item.id, 'y', newCoords[1]);
        sentDispatch(props.item.id, 'z', 0);
        sentDispatch(props.item.id, 'epsg', 4326);

    };

    const drawFunc = (oldFeature: {
        getGeometry: () => { (): any; new(): any; getCoordinates: { (): any; new(): any; }; };
    }) => {
        const coordinates = oldFeature.getGeometry().getCoordinates();
        const feature = new Feature({
            geometry: new Point(coordinates),
            id: 'drawFeature',
            layerKind: 'drawLayer',
            // eslint-disable-next-line camelcase
            element_type_id: props.item.element_type_id,
            icon: props.item.icon,
            cluster: false
        });

        refOverlaySource.current.addFeature(feature);
        getCoordinatesOfFeatureAndSent(coordinates);
        map.removeInteraction(refDraw.current);
        getModify();
    };

    const modFunc = (oldFeature: {
        getGeometry: () => { (): any; new(): any; getCoordinates: { (): any; new(): any; }; };
    }) => {
        const coordinates = oldFeature.getGeometry().getCoordinates();
        getCoordinatesOfFeatureAndSent(coordinates);
        dispatch(setModifyState({state:false, coords:coordinates}));

    };

    const cleanMap=()=>{
        const features = refOverlaySource.current.getFeatures();
        if (features && features.length > 0) {
            // @ts-ignore
            if (refOriginalCoords.current.length > 0) {
                features[0].getGeometry().setCoordinates(refOriginalCoords.current);
            } else {
                refOverlaySource.current.clear();
            }
        }
        toogleFnc(false);
        dispatch(resetElementChanges());
    };

    const contextMenuListener = () => {
        map.getViewport().addEventListener('contextmenu', function (evt: { preventDefault: () => void; }) {
            evt.preventDefault();
            cleanMap();
        });
    };

    useEffect(() => {
        if(modify){
            modify.on('modifyend',(event)=>{
              //  console.log('modifyend')

                // @ts-ignore
                const features=event.features.getArray();
              //  console.log(event.features.getArray())
                // @ts-ignore
                modFunc(features[0]);
                //hier ein dispatch, worauf die aktualisierung der polyline horcht
            });
            modify.on('modifystart',(event)=>{
              //  console.log('modifystart')
                dispatch(setModifyState({state:true}))
                //hier ein dispatch, worauf die aktualisierung der polyline horcht
            });
        }
    }, [modify]);

    useEffect(() => {
        if (!map || Object.keys(map).length === 0) return;
        getOverlayLayer();

        const locationControl = getControls();
        map.addControl(locationControl[0]);
        contextMenuListener();

        return () => map.controls.remove(locationControl[0]);
    }, [map]);

    useEffect(() => {
        if (draw) {

            draw.on('drawstart', (event) => {
                overlaySource.clear();
                refOverlaySource.current.clear();
            });
            // @ts-ignore
            draw.on('drawend', (event) => {
                // @ts-ignore
                drawFunc(event.feature);
            //    console.log('drawend')
            });
        }
    }, [draw]);


    useEffect(() => {
        if (!map || Object.keys(map).length === 0) return;
        const button = document.getElementById('buttonLocation');
        if (toogleAllowed) {
            addInteractions();
            // @ts-ignore
            button.innerHTML = editIconActive;
            // @ts-ignore
            button.style.backgroundColor = '#4AB248';
            dispatch(mapReady(false));

        } else {
            removeInteractions();
            // @ts-ignore
            button.innerHTML = editIconBase;
            // @ts-ignore
            button.style.backgroundColor = '#333341';
            const features = refOverlaySource.current.getFeatures();

            if (features && features.length === 1) {
                const coordinates = features[0].getGeometry().getCoordinates();
                getCoordinatesOfFeatureAndSent([coordinates[0], coordinates[1]]);
            }
            dispatch(mapReady(true));
        }
    }, [toogleAllowed]);

    return (
        // @ts-ignore
        <div>
        </div>);
};

export default PlaceLocationControl;
