import {
    faCalculator,
    faCheck, faClock, faCopy, faDashboard, faDatabase,
    faEye,
    faEyeSlash, faFileExport, faFileImport, faGamepad, faHatWizard, faLink, faLock, faLockOpen, faMagnifyingGlassChart,
    faPencil,
    faPlus, faSearchLocation,
    faStopwatch,
    faTimes,
    faTrashCan, faUserPlus, faUsersRectangle, faUserTag
} from '@fortawesome/free-solid-svg-icons';
import {faClone, faFloppyDisk, faSave} from '@fortawesome/free-regular-svg-icons';

/** Project Management *****************************************************************/
export const buttonCreateProject = {
    label: 'btn_projM_createProject',
    className: '',
    icon: faPlus,
    submit: 'projectForm'
};
export const buttonSaveProjectSettings = {
    label: 'save',
    className: '',
    icon: faFloppyDisk,
    submit: 'projectForm'
};
export const buttonCreateProjectGroup = {
    label: 'btn_projM_createProjectGroup',
    className: '',
    icon: faPlus,
    submit: 'true'
};
export const buttonEnableProject = {
    label: 'btn_projM_enable',
    className: '',
    icon: faLockOpen,
};
export const buttonDisableProject = {
    label: 'btn_projM_disable',
    className: '',
    icon: faLock,
};
export const buttonSaveAdvancedProjectSettings = {
    label: 'save',
    className: '',
    icon: faFloppyDisk,
    submit: 'projectForm'
};
export const buttonCreateGrafanaDashboard = {
    label: 'userMgmt_addGrafanaDashboard',
    className: '',
    icon: faPlus,
};
export const buttonDuplicateProject = {
    label: 'btn_duplicateProject',
    className: '',
    icon: faClone,
};
export const buttonImportProject = {
    label: 'projectManagement:buttons.importProject',
    className: '',
    icon: faFileImport,
};
export const buttonExportProject = {
    label: 'projectManagement:buttons.exportProject',
    className: '',
    icon: faFileExport,
};

export const buttonSaveProjectType = {
    label: 'save',
    className: '',
    icon: faCheck,
};

/** User Management *****************************************************************/
export const buttonCreateAction = {
    label: 'userManagement:buttons.createAction',
    className: '',
    icon: faPlus,
};
export const buttonRemoveActions = {
    label: 'userManagement:buttons.deleteActions',
    className: 'p-button-danger',
    icon: faTrashCan,
};
export const buttonAddAdmin = {
    label: 'btn_userM_addAdmin',
    className: '',
    icon: faUsersRectangle,
};
export const buttonCreateAdmin = {
    label: 'btn_userM_createAdmin',
    className: '',
    icon: faUserPlus,
};
export const buttonAddUser = {
    label: 'btn_userM_addUser',
    className: '',
    icon: faUsersRectangle,
};
export const buttonMoveUsersToRole = {
    label: 'btn_userM_moveUsersToRole',
    className: '',
    icon: faUserTag,
};
export const buttonCreateUser = {
    label: 'btn_userM_createUser',
    className: '',
    icon: faUserPlus,
};
export const buttonCreateRole = {
    label: 'btn_userM_createRole',
    className: '',
    icon: faPlus,
};
export const buttonRemoveUsersFromProject = {
    label: 'btn_userM_removeUsers',
    className: 'p-button-danger',
    icon: faTrashCan,
};

export const buttonRemoveGlobalUsers = {
    label: 'btn_userM_removeGlobalUsers',
    className: 'p-button-danger',
    icon: faTrashCan,
};

/** Notification Management *****************************************************************/
export const buttonNotificationWizard = {
    label: 'alarmManagement:buttons.notificationWizard',
    className: '',
    icon: faHatWizard, // pi-bolt
};
export const buttonCreateNotification = {
    label: 'alarmManagement:buttons.createNotification',
    className: '',
    icon: faPlus,
};
export const buttonDeleteNotification = {
    label: 'alarmManagement:buttons.deleteNotifications',
    className: 'p-button-danger',
    icon: faTrashCan,
};
export const buttonAddActionsToNotifications = {
    label: 'alarmManagement:buttons.addActionsToNotifications',
    className: '',
    icon: faPlus,
};
export const buttonRemoveActionsFromNotifications = {
    label: 'alarmManagement:buttons.removeActionsFromNotifications',
    className: 'p-button-danger',
    icon: faTrashCan,
};
export const buttonActivateNotification = {
    label: 'alarmManagement:buttons.activateNotification',
    className: '',
    icon: faCheck,
};
export const buttonDeactivateNotification = {
    label: 'alarmManagement:buttons.deactivateNotification',
    className: '',
    icon: faTimes,
};
// +++ Notification Action +++
export const buttonCreateNotificationAction = {
    label: 'alarmManagement:buttons.createNotificationAction',
    className: '',
    icon: faPlus,
};
export const buttonDeleteNotificationAction = {
    label: 'alarmManagement:buttons.deleteNotificationAction',
    className: 'p-button-danger',
    icon: faTrashCan,
};
export const buttonActivateNotificationAction = {
    label: 'alarmManagement:buttons.activateNotificationAction',
    className: '',
    icon: faCheck,
};
export const buttonDeactivateNotificationAction = {
    label: 'alarmManagement:buttons.deactivateNotificationAction',
    className: '',
    icon: faTimes,
};
export const buttonDeactivateNotificationActionTemporary = {
    label: 'alarmManagement:buttons.deactivateNotificationActionTemporary',
    className: '',
    icon: faStopwatch,
};
// +++ Notification Ranges +++
export const buttonDeleteNotificationRange = {
    label: 'alarmManagement:buttons.deleteNotificationRange',
    className: 'p-button-danger',
    icon: faTrashCan,
};
export const buttonCreateNotificationRange = {
    label: 'alarmManagement:buttons.createNotificationRange',
    className: '',
    icon: faPlus
};
export const buttonUpdateNotificationRanges = {
    label: 'alarmManagement:buttons.updateNotificationRanges',
    className: '',
    icon: faPencil
};
// +++ Characteristic Monitoring Tasks +++
export const buttonDeleteCharacteristicMonitoringTasks = {
    label: 'alarmManagement:buttons.deleteCharacteristicMonitoringTasks',
    className: 'p-button-danger',
    icon: faTrashCan,
};
export const buttonCreateCharacteristicMonitoringTask = {
    label: 'alarmManagement:buttons.createCharacteristicMonitoringTask',
    className: '',
    icon: faPlus
};
export const buttonActivateCharacteristicMonitoringTasks = {
    label: 'alarmManagement:buttons.activateCharacteristicMonitoringTasks',
    className: '',
    icon: faCheck,
};
export const buttonDeactivateCharacteristicMonitoringTasks = {
    label: 'alarmManagement:buttons.deactivateCharacteristicMonitoringTasks',
    className: '',
    icon: faTimes,
};
// +++ Monitoring Tasks +++
export const buttonDeleteMonitoringTasks = {
    label: 'alarmManagement:buttons.deleteMonitoringTasks',
    className: 'p-button-danger',
    icon: faTrashCan,
};
export const buttonCreateMonitoringTask = {
    label: 'alarmManagement:buttons.createMonitoringTask',
    className: '',
    icon: faPlus
};
export const buttonActivateMonitoringTasks = {
    label: 'alarmManagement:buttons.activateMonitoringTasks',
    className: '',
    icon: faCheck,
};
export const buttonDeactivateMonitoringTasks = {
    label: 'alarmManagement:buttons.deactivateMonitoringTasks',
    className: '',
    icon: faTimes,
};

// +++ Monitoring Task Limits +++
export const buttonDeleteMonitoringTaskLimits = {
    label: 'alarmManagement:buttons.deleteMonitoringTaskLimits',
    className: 'p-button-danger',
    icon: faTrashCan,
};
export const buttonCreateMonitoringTaskLimit = {
    label: 'alarmManagement:buttons.createMonitoringTaskLimit',
    className: '',
    icon: faPlus
};
export const buttonCalculateMonitoringTaskLimit = {
    label: 'alarmManagement:buttons.calculateMonitoringTaskLimit',
    className: '',
    icon: faCalculator,
};
export const buttonOpenDiagram = {
    label: 'alarmManagement:buttons.openDiagram',
    className: '',
    icon: faMagnifyingGlassChart
};

/** Measurement Management *****************************************************************/
// ---------------------------------------------------------------------------------------------------------------------
// Channel
// ---------------------------------------------------------------------------------------------------------------------
export const buttonCreateChannel = {
    label: 'metrologyManagement:buttons.createChannel',
    className: '',
    icon: faPlus,
};
export const buttonDeleteChannel = {
    label: 'metrologyManagement:buttons.deleteChannel',
    className: 'p-button-danger',
    icon: faTrashCan,
};
export const buttonChangeTimezone = {
    label: 'metrologyManagement:buttons.changeTimezone',
    className: '',
    icon: faClock,
};
export const buttonAddDataLink = {
    label: 'metrologyManagement:buttons.AddDataLink',
    className: '',
    icon: faLink,
};
export const buttonActivateChannel = {
    label: 'metrologyManagement:buttons.activateChannel',
    className: '',
    icon: faCheck,
};
export const buttonDeactivateChannel = {
    label: 'metrologyManagement:buttons.deactivateChannel',
    className: '',
    icon: faTimes,
};
export const buttonShowChannel = {
    label: 'metrologyManagement:buttons.showChannel',
    className: '',
    icon: faEye,
};
export const buttonHideChannel = {
    label: 'metrologyManagement:buttons.hideChannel',
    className: '',
    icon: faEyeSlash,
};
export const buttonShowChannelIds = {
    label: 'metrologyManagement:buttons.showChannelIds',
    className: '',
    icon: faEye,
};
export const buttonCreateGrafanaPanel = {
    label: 'metrologyManagement:buttons.createGrafanaPanel',
    className: '',
    icon: faDashboard,
};

// ---------------------------------------------------------------------------------------------------------------------
// Sensor
// ---------------------------------------------------------------------------------------------------------------------
export const buttonCreateSensor = {
    label: 'metrologyManagement:buttons.createSensor',
    className: '',
    icon: faPlus,
};
export const buttonDeleteSensor = {
    label: 'metrologyManagement:buttons.deleteSensor',
    className: 'p-button-danger',
    icon: faTrashCan,
};
export const buttonChangeLocation = {
    label: 'metrologyManagement:buttons.changeLocation',
    className: '',
    icon: faSearchLocation,
};
export const buttonActivateSensor = {
    label: 'metrologyManagement:buttons.activateSensor',
    className: '',
    icon: faCheck,
};
export const buttonDeactivateSensor = {
    label: 'metrologyManagement:buttons.deactivateSensor',
    className: '',
    icon: faTimes,
};
export const buttonShowSensor = {
    label: 'metrologyManagement:buttons.showSensor',
    className: '',
    icon: faEye,
};
export const buttonHideSensor = {
    label: 'metrologyManagement:buttons.hideSensor',
    className: '',
    icon: faEyeSlash,
};

// ---------------------------------------------------------------------------------------------------------------------
// MeasuringDevice
// ---------------------------------------------------------------------------------------------------------------------
export const buttonDuplicateMeasuringDevice = {
    label: 'metrologyManagement:buttons.duplicateMeasuringDevice',
    className: '',
    icon: faCopy,
};
export const buttonCreateMeasuringDevice = {
    label: 'metrologyManagement:buttons.createMeasuringDevice',
    className: '',
    icon: faPlus,
};
export const buttonDeleteMeasuringDevice = {
    label: 'metrologyManagement:buttons.deleteMeasuringDevice',
    className: 'p-button-danger',
    icon: faTrashCan,
};
export const buttonShowMeasuringDevice = {
    label: 'metrologyManagement:buttons.showMeasuringDevice',
    className: '',
    icon: faEye,
};
export const buttonHideMeasuringDevice = {
    label: 'metrologyManagement:buttons.hideMeasuringDevice',
    className: '',
    icon: faEyeSlash,
};
export const buttonCreateMeasuringManager = {
    label: 'metrologyManagement:buttons.createMeasuringManager',
    className: '',
    icon: faPlus,
};
export const buttonDeleteMeasuringManagers = {
    label: 'metrologyManagement:buttons.deleteMeasuringManagers',
    className: 'p-button-danger',
    icon: faTrashCan,
};

// ---------------------------------------------------------------------------------------------------------------------
// Location
// ---------------------------------------------------------------------------------------------------------------------
export const buttonCreateLocation = {
    label: 'locations:buttons.createLocation',
    className: '',
    icon: faPlus,
};
export const buttonDeleteLocation = {
    label: 'locations:buttons.deleteLocation',
    className: 'p-button-danger',
    icon: faTrashCan,
};
export const buttonShowLocation = {
    label: 'locations:buttons.showLocation',
    className: '',
    icon: faEye,
};
export const buttonHideLocation = {
    label: 'locations:buttons.hideLocation',
    className: '',
    icon: faEyeSlash,
};
export const buttonCreateMetrology = {
    label: 'metrologyManagement:buttons.metrologyWizard',
    className: '',
    icon: faHatWizard,
};
export const buttonCreateMetrologyFromCSV = {
    label: 'metrologyManagement:buttons.metrologyWizardFromCSV',
    className: '',
    icon: faHatWizard,
};
export const buttonCreateVirtualChannel = {
    label: 'metrologyManagement:buttons.createVirtualChannel',
    className: '',
    icon: faPlus,
};
// ---------------------------------------------------------------------------------------------------------------------
// ControlDevice
// ---------------------------------------------------------------------------------------------------------------------
export const buttonCreateControlDevice = {
    label: 'metrologyManagement:buttons.createControlDevice',
    className: '',
    icon: faPlus,
};
export const buttonDeleteControlDevice = {
    label: 'metrologyManagement:buttons.deleteControlDevice',
    className: 'p-button-danger',
    icon: faTrashCan,
};
export const buttonActivateControlDevice = {
    label: 'metrologyManagement:buttons.activateControlDevice',
    className: '',
    icon: faCheck,
};
export const buttonDeactivateControlDevice = {
    label: 'metrologyManagement:buttons.deactivateControlDevice',
    className: '',
    icon: faTimes,
};
export const buttonControlDevice = {
    label: 'metrologyManagement:buttons.controlDevices',
    className: '',
    icon: faGamepad,
};
// ---------------------------------------------------------------------------------------------------------------------
// OperatingState
// ---------------------------------------------------------------------------------------------------------------------
export const buttonCreateOperatingState = {
    label: 'metrologyManagement:buttons.createOperatingState',
    className: '',
    icon: faPlus,
};
export const buttonDeleteOperatingStates = {
    label: 'metrologyManagement:buttons.deleteOperatingStates',
    className: 'p-button-danger',
    icon: faTrashCan,
};
export const buttonCreateOperatingStateCondition = {
    label: 'metrologyManagement:buttons.createOperatingStateCondition',
    className: '',
    icon: faPlus,
};
export const buttonDeleteOperatingStateConditions = {
    label: 'metrologyManagement:buttons.deleteOperatingStateConditions',
    className: 'p-button-danger',
    icon: faTrashCan,
};

/** Reporting *****************************************************************/
export const buttonCreateReport = {
    label: 'reportingManagement:buttons.createReport',
    className: '',
    icon: faPlus,
};
export const buttonDuplicateReport = {
    label: 'reportingManagement:buttons.duplicateReport',
    className: '',
    icon: faCopy,
};
export const buttonRemoveReports = {
    label: 'reportingManagement:buttons.deleteReports',
    className: 'p-button-danger',
    icon: faTrashCan,
};
export const buttonCreateReportTemplate = {
    label: 'reportingManagement:buttons.createReportTemplate',
    className: '',
    icon: faPlus,
};
export const buttonDuplicateReportTemplate = {
    label: 'reportingManagement:buttons.duplicateReportTemplate',
    className: '',
    icon: faCopy,
};
export const buttonRemoveReportTemplates = {
    label: 'reportingManagement:buttons.deleteReportTemplates',
    className: 'p-button-danger',
    icon: faTrashCan,
};

/** System *****************************************************************/
export const buttonCreateSystemSetting = {
    label: 'btn_sysM_createSysSett',
    className: '',
    icon: faPlus,
};

/** Translation *****************************************************************/
export const buttonCreateTranslation = {
    label: 'btn_langM_createTranslation',
    className: '',
    icon: faPlus,
};

/** Units *****************************************************************/

export const buttonAddNewPlant = {
    label: 'units_new',
    className: '',
    icon: faPlus,
}

export function makeButtonItem(item: any, t: any, onClick: any, visible: boolean = true, disabled: boolean = false) {
    const tmpItem = JSON.parse(JSON.stringify(item));
    tmpItem.onClick = onClick;
    tmpItem.label = t(item.label);
    tmpItem.visible = visible;
    tmpItem.disabled = disabled;
    return tmpItem;
}
