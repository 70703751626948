import React from 'react';

import {getColumnField, getFilterField, Nullable} from './Global';
import {FilterMatchMode, FilterOperator} from 'primereact/api';
import {filterTriStateCheckBox} from '../global/filter';
import {ColumnObject} from '../global/CustomDataTable';
import Input from '../global/Input';

export type Field = {
    id: number,
    name: string,
    api_call: string,
    data_type: string,
    object_type: string,
    display_name: string,
    display_value?: string,
    element_subtype_id?: string,
    value?: any
}

export type ElementTypeItem = {
    api_call_get_name: any,
    api_call_get_subtype: any,
    element_type_id: number,
    element_subtype_id: number,
    name: string,
    has_parent: boolean,
    project_type_id: number,
    fields: Field[],
    name_field_label: string,
    icon: string,
    children_type_ids: number[],
    sibling_type_ids: number[],
    location_supported: boolean,
    show_as_tree: boolean
}

export type UnitItem = {
    id: string,
    element_type_id: number,
    element_subtype_id: number,
    object_type_name: string,
    children_type_ids: number[],
    inspection_is_active: boolean,
    inspection_task_status: number,
    name: string,
    fields: Field[],
    children: Nullable<UnitItem[]>,
    expanded: boolean,
    parent: any[],
    pthname: string[],
    internal_elements: string[],
    icon: string,
    lastItem?: boolean
}

export type UnitFile = {
    action: string,
    name: string,
    size: number,
    sizeName: string,
    file: string,
    is_preview: boolean
}

export const getElementType = (element_type_id: number, elementTypes: ElementTypeItem[]) => {
    let result: Nullable<ElementTypeItem> = null;
    if (elementTypes) {
        const resultTemp = elementTypes.find((x: ElementTypeItem) => {
            return x.element_type_id === element_type_id;
        });

        if (resultTemp) {
            result = resultTemp;
        }
    }
    return result;
};

export const getElementTypeName = (element_type_id: number, elementTypes: ElementTypeItem[]) => {
    let result = '';
    if (elementTypes) {
        const resultTemp = elementTypes.find((x: ElementTypeItem) => {
            return x.element_type_id === element_type_id;
        });

        if (resultTemp) {
            result = resultTemp.name;
        }
    }
    return result;
};

export const getElementTypeIconClass = (element_type_id: number, elementTypes: ElementTypeItem[]) => {
    let result = '';
    if (elementTypes) {
        const resultTemp = elementTypes.find((x: ElementTypeItem) => {
            return x.element_type_id === element_type_id;
        });

        if (resultTemp) {
            result = resultTemp.icon;
        }
    }
    return result;
};

export const getUnitTreeItemById = (item: UnitItem | undefined, id: string): UnitItem | undefined => {
    let result = undefined;
    if (item) {
        if (item.id === id) {
            result = item;
        }

        if (!result) {
            if (item.children) {
                for (let i = 0; i < item.children.length; i++) {
                    const item1 = item.children[i];

                    result = getUnitTreeItemById(item1, id);
                    if (result) {
                        break;
                    }
                }
            }
        }
    }
    return result;
};

export const setUnitTreeItemById = (id: string, key: string, value: any, inFields: boolean, setUnitTree: any, onChange: any) => {
    setUnitTree((prevState: any) => {
        if (prevState) {
            const temp = JSON.parse(JSON.stringify(prevState));
            let item = undefined;
            for (let i = 0; i < temp.length; i++) {
                item = getUnitTreeItemById(temp[i], id);
                if (item) {
                    break;
                }
            }

            if (item) {
                if (inFields) {
                    for (let i = 0; i < item.fields.length; i++) {
                        const field = item.fields[i];

                        if (field.name === key) {
                            field.value = value;
                            break;
                        }
                    }
                } else {
                    // @ts-ignore
                    item[key] = value;
                }
            }

            return temp;
        }
    });

    if (onChange) {
        onChange();
    }
};

export const setUnitField = (key: string, value: any, setActiveFields: any, onChange: any) => {
    setActiveFields((prevState: any) => {
        if (prevState) {
            const temp = JSON.parse(JSON.stringify(prevState));

            for (let i = 0; i < temp.length; i++) {
                const field = temp[i];

                if (field.name === key) {
                    field.value = value;
                    break;
                }
            }
            return temp;
        }
    });

    if (onChange) {
        onChange();
    }
};

export const getUnitColumns = (t: any) => {
    const filterCheckboxIsVisible = (options: any) => {
        return filterTriStateCheckBox(options, t('showAll'), t('yes'), t('no'));
    };

    let childrenArray: string[] = [];

    const getChildrenAsArray = (item: any, array: string[]) => {

        array.push(item.name);
        if (item.children) {
            for (let i = 0; i < item.children.length; i++) {
                getChildrenAsArray(item.children[i], array);
            }
        }
    }


    const result: ColumnObject[] = [];

    result.push(getColumnField(t, 'id', 'units_columns_units_', true));
    result.push(getColumnField(t, 'name', 'units_columns_units_', true));
    result.push(getColumnField(t, 'internal_elements', 'units_columns_units_', false));
    return result;
};

export const getUnitFilters = () => {
    return {
        ...getFilterField('name'),
        ...getFilterField('internal_elements')
    };
};

export const getElementFileColumns = (t: any, documentTypes: any[], project_id: string, showPreview: boolean, setDocumentPreview: (obj: any) => void) => {
    const filterCheckboxIsVisible = (options: any) => {
        return filterTriStateCheckBox(options, t('showAll'), t('yes'), t('no'));
    };

    const result: ColumnObject[] = [];

    result.push(getColumnField(t, 'name', 'units_columns_files_', true));
    // result.push(getColumnField(t, 'sizeName', 'units:columns.files.', true));
    result.push(getColumnField(t, 'document_type_id', 'units_columns_files_', true, {
        body: (dataRow: any) => {
            let result = '';

            const dT = documentTypes.find((x: any) => {
                return x.id === dataRow['document_type_id'];
            });

            if (dT) {
                result = dT.name;
            }

            return result;
        },
        filterField: 'document_type_id',
        filterDropdownValues: documentTypes ? documentTypes.map((item) => {
            return {value: item.id, name: item.name};
        }) : [],
    }));
    if (showPreview) {
        result.push(getColumnField(t, 'preview', 'units_columns_files_', true, {
            body: (rowData: any) => {
                if (rowData['document_type_id'] === 4) {
                    return (
                        <Input edit={true} name={'is_preview'} type={'checkbox'} noMargin={true}
                               className={'m-auto p-0'}
                               value={rowData['is_preview']} onChange={(e: any) => {
                            setDocumentPreview({
                                file_id: rowData['file_id'],
                                element_id: rowData['element_id'],
                                inspection_task_id: rowData['inspection_task_id'],
                                project_id: project_id,
                                is_preview: e.target.value
                            });
                        }}/>);
                }
            }
        }));
    }
    return result;
};

export const getElementFileFilters = () => {
    return {
        ...getFilterField('name'),
        'document_type_id': {
            operator: FilterOperator.OR,
            constraints: [{value: null, matchMode: FilterMatchMode.IN}]
        }
    };
};

export const flattenObjectForPipeline = (json: any) => {
    const result = {...json};
    result.children = [];

    if (json.children) {
        for (let i = 0; i < json.children.length; i++) {
            const item = json.children[i];

            flattenObject(item, result);
        }
    }

    return result;
};

export const flattenObject = (parent: any, firstParent: any) => {
    const result = {...parent};
    result.parent = [{id: firstParent.id, name: firstParent.name}];

    // delete result.children;
    firstParent.children.push(result);
    if (parent.children) {
        for (let i = 0; i < parent.children.length; i++) {
            const item = parent.children[i];
            flattenObject(item, firstParent);
        }
    }
};
