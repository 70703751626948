/*
 * ProjectManagement.tsx
 * Author: lnappenfeld
 * Date: 16.02.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';

import {useTranslation} from 'react-i18next';
import {checkPermission} from '../functions/functionLibrary';
import {permissions} from '../config/permissions';
import {CustomConfirmDialog} from '../components/global/CustomConfirmDialog';
import {FormType, getProjects, toggleProjectStatus} from '../components/functions/Project';
import {showMessageOnError, showMessageOnSuccess} from '../components/global/CustomToast';
import ProjectEditForm from '../components/projectManagement/ProjectEditForm';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDiagramProject, faFolderPlus, faGears, faProjectDiagram} from '@fortawesome/free-solid-svg-icons';
import LayoutMainContentTabMenu from '../components/global/LayoutMainContentTabMenu';
import {faFolderOpen} from '@fortawesome/free-regular-svg-icons';
import {useNavigate} from 'react-router-dom';
import ViewButtons from '../components/global/ViewButtons';
import {
    buttonCreateProject,
    buttonDisableProject,
    buttonEnableProject,
    buttonSaveProjectSettings,
    makeButtonItem
} from '../components/global/viewButtonSelection';
import ProjectAdvancedSettingsForm from '../components/projectManagement/ProjectAdvancedSettingsForm';
import CreateProjectTypeForm from '../components/projectManagement/CreateProjectTypeForm';


type Props = {
    project: any,
    setProject: (e: any) => void,
}

const ProjectManagement = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);
    const navigate = useNavigate();

    const mayEditProject = checkPermission(permissions.editProject);
    const mayCreateProject = checkPermission(permissions.createProject);

    const [confirmDialogVisible, setConfirmDialogVisible] = useState<boolean>(false);

    useEffect(() => {
        console.log('props.project 1: ', props.project);
    }, []);


    useEffect(() => {
        if (confirmDialogVisible) {
            CustomConfirmDialog({
                translation: t,
                header: t('confirmation'),
                message: props.project.is_active ? t('projM_disableProject_message') : t('btn_projM_enable'),
                onConfirm: changeProjectStatusAccept,
                onCancel: () => setConfirmDialogVisible(false)
            });
        }
    }, [confirmDialogVisible]);


    const changeProjectStatusAccept = () => {
        toggleProjectStatus(props.project.id).then(result => {
            if (result.error) {
                showMessageOnError(t('error'), result.error);
            } else {
                if (props.setProject) {
                    const _project = props.project;
                    // result is new is_active value
                    _project['is_active'] = result;
                    props.setProject(_project);
                }
                showMessageOnSuccess(t('success'), t('toasts_statusChanged'));
            }
            setConfirmDialogVisible(false);
        });
    };

    const menuItems = [
        {
            id: 'settings',
            label: t('projM_generalInformation'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faFolderOpen}/>,
            component: <ProjectEditForm
                project={props.project}
                setProject={props.setProject}
                mayEditProject={mayEditProject}
                onFinished={(project) => {
                    // update project data
                    console.log('updateProject: ', project)
                    if (project) {
                        getProjects().then(projects => {
                            console.log('projects: ', projects)
                            let fullProject: null;
                            // eslint-disable-next-line prefer-const
                            fullProject = projects.filter((x: any) => {
                                return x.id === project.id;
                            })[0];
                            if (fullProject && props.setProject) {
                                props.setProject(fullProject);
                                // navigate('/project/' + project.id + '/#project_0');
                            }
                        });
                    }
                }}
                type={FormType.Edit}
            />,
            projectRequired: true,
            visible: props.project !== null,
            // visible: props.project !== null && ((checkPermission(permissions.isAdmin) || checkPermission(permissions.editProject))),
            buttons: mayEditProject ? (
                <ViewButtons
                    title=''
                    items={[
                        makeButtonItem(buttonSaveProjectSettings, t, () => {}),
                        makeButtonItem(buttonEnableProject, t, () => {
                            setConfirmDialogVisible(true);
                        }, props.project && !props.project.is_active),
                        makeButtonItem(buttonDisableProject, t, () => {
                            setConfirmDialogVisible(true);
                        }, props.project && props.project.is_active),
                    ]}
                />
            ) : null
        },
        {
            id: 'advancedSettings',
            label: t('projM_advancedSettings'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faGears}/>,
            component: <ProjectAdvancedSettingsForm
                project={props.project}
                onFinished={(project) => {
                    // update project data
                    if (project) {
                        getProjects().then(projects => {
                            let fullProject: null;
                            // eslint-disable-next-line prefer-const
                            fullProject = projects.filter((x: any) => {
                                return x.id === project.id;
                            })[0];
                            if (fullProject && props.setProject) {
                                props.setProject(fullProject);
                                // navigate('/project/' + project.id + '/#project_0');
                            }
                        });
                    }
                }}

            />,
            projectRequired: true,
            visible: props.project !== null && ((checkPermission(permissions.isAdmin) || checkPermission(permissions.editProject))),
            buttons: mayEditProject ? (
                <ViewButtons
                    title={''}
                    items={[
                        makeButtonItem(buttonSaveProjectSettings, t, () => {}),
                    ]}
                />
            ) : null
        },
        {
            id: 'createProject',
            label: t('projM_createProject'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faFolderPlus}/>,
            component: <ProjectEditForm
                project={null}
                onFinished={(project) => {
                    // set new current project and navigate to them
                    if (project) {
                        getProjects().then(projects => {
                            let fullProject: null;
                            // eslint-disable-next-line prefer-const
                            fullProject = projects.filter((x: any) => {
                                return x.id === project.id;
                            })[0];
                            if (fullProject && props.setProject) {
                                props.setProject(fullProject);
                                navigate('/project/' + project.id + '/#project_0');
                            }
                        });
                    }
                }}
                type={FormType.Create}
            />,
            projectRequired: false,
            visible: (checkPermission(permissions.isAdmin) || checkPermission(permissions.createProject)),
            buttons: mayCreateProject ? (
                <ViewButtons
                    title=''
                    items={[
                        makeButtonItem(buttonCreateProject, t, () => {}),
                    ]}
                />
            ) : null
        },
        {
            id: 'createProjectType',
            label: t('projM_createProjectType'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faProjectDiagram}/>,
            component: <CreateProjectTypeForm project={null} />,
            projectRequired: false,
            visible: props.project === null,
        },

    ];

    {/* activeIndex -> 0: General Information, 2: Create Project*/
    }
    return (<LayoutMainContentTabMenu items={menuItems} activeIndex={props.project ? 0 : 2}/>);
};

export default ProjectManagement;
