/*
 * GlobalRolesOverview.tsx
 * Author: lnappenfeld
 * Date: 18.08.2023
 *
 * Copyright: DMT GmbH & Co. KG
 */

import CustomDataTable from '../global/CustomDataTable';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {getGlobalRoles, getPermissionTypes, Role} from '../functions/UserManagement';
import {Chip} from 'primereact/chip';

export const GlobalRolesOverview = () => {

    const {t} = useTranslation(['common']);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [roles, setRoles] = useState<Role[]>([]);

    useEffect(() => {
        getGlobalRoles().then(roles => {
            getPermissionTypes('none', t).then(permissionTypes => {
                for (let i = 0; i < roles.length; i++) {
                    const role = roles[i];

                    const itemPermissionTypes = permissionTypes.filter(function (e: any) {
                        // @ts-ignore
                        return this.indexOf(e.key) >= 0;
                    }, role.permissions);

                    role['expandableElement'] = (<div className="grid pt-3">
                        {itemPermissionTypes.map((permission: any) => {
                            return (
                                <Chip
                                    key={permission.key}
                                    label={permission.label}
                                    className="col-auto mr-2 mb-2"
                                    icon={permission.icon}
                                />
                            );
                        })}
                    </div>);
                }
                setRoles(roles);
                setDataLoaded(true);
            });
        });
    }, []);


    return (<CustomDataTable
        id='global-roles-role'
        columns={[{field: 'name', header: t('userM_globalRole'), style: {minWidth: '200px'}}]}
        editable={false}
        sortable={true}
        value={roles}
        expandable={true}
        ableToUpdate={false}
        ableToDelete={false}
        dataloaded={dataLoaded}
    />);
};
