import {FunctionComponent, useContext, useEffect, useRef, useState} from 'react';
import MapContext from '../map/MapContext';
import {Vector as VectorSource} from 'ol/source';
import Cluster from 'ol/source/Cluster';
import VectorLayer from 'ol/layer/Vector';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../../redux/hooks';
import geoSettings from '../../../config/geoSettings';
import {useLazyGetElementGeoDataQuery} from '../../../redux/rtk/injectedGeoApi';
import {
    createFeatures,
    getClusterStyle,
    getElementStyles,
    hoverStyle,
    pinImageStyle,
    pointImageStyle
} from '../../functions/Geo';
import {addLayerToCounterArray, setLayerArray} from '../../../redux/slices/GeomonitoringSlice';

type poiProps = {
    projectId: number,
    itemId?: any,
    zoomMap: number
}

const PoiLayer: FunctionComponent<poiProps> = ({projectId, itemId, zoomMap}) => {
    // @ts-ignore
    const {map, setMap} = useContext(MapContext);
    const [zoom, setZoom] = useState(zoomMap);
    const refZoom = useRef(zoom);
    const {t} = useTranslation(['geomonitoring']);
    const dispatch = useAppDispatch();
    const [vLayer, setVlayer] = useState(null);
    const [triggerElements, {data: resultData, isLoading, isError, isSuccess}] = useLazyGetElementGeoDataQuery();
    const [cSource, setCSource] = useState({
        removeFeature(feature: any) {
        },
        addFeature(mp: any) {
        },
        clear() {
        },
        addFeatures(features: any[]) {
        }
    });
    const [nSource, setNSource] = useState({
        removeFeature(feature: any) {
        },
        addFeature(mp: any) {
        },
        clear() {
        },
        addFeatures(features: any[]) {
        }
    });
    const refVLayer = useRef(vLayer);
    const refCSource = useRef(cSource);
    const refNSource = useRef(nSource);

    const pointerMoveEvent = () => {
        let highlightedFeatures: any[] = [];

        map.on('pointermove', function (e: { pixel: any; coordinate: any; }) {
            highlightedFeatures.forEach(f => f.setStyle(null));
            highlightedFeatures = [];
            map.forEachFeatureAtPixel(e.pixel, (f: any) => {
                if (typeof f.get('features') === 'object' && f.get('features').length === 1) {
                   const feature = f.get('features')[0];
                    if (feature.get('layerKind') && feature.get('layerKind') === 'poiLayer' && (feature.get('overlay') !== true)) {
                        feature.setStyle(hoverStyle);
                        f.set('features', [feature]);
                        //  console.log(f);
                        highlightedFeatures.push(f);
                    }
                }
                if (f.getId()) {
                    if (f.get('layerKind') && f.get('layerKind') === 'poiLayer') {
                        if (f.get('overlay') !== true) {
                            f.setStyle(hoverStyle);
                        }
                        highlightedFeatures.push(f);
                    }
                }
            },
            {
                hitTolerance: 5
            });
        });
    };

    const getVectorLayerSources = () => {
        const source = new VectorSource({});
        const clusterSource = new Cluster({
            distance: 50,
            minDistance: 30,
            source: source
        });
        return ({vSource: source, cSource: clusterSource});
    };

    const getVectorLayerObject = (source: any, layerId: string, layerVisibility: boolean) => {

        const vectorLayer = new VectorLayer({
            source: refZoom.current > 17 ? source.vSource : source.cSource
        });
        vectorLayer.set('name', layerId);
        vectorLayer.set('id', layerId);
        vectorLayer.set('type', 'vector');
        vectorLayer.set('zIndex', geoSettings.poiLayerKey);
        vectorLayer.set('visible', layerVisibility);
        vectorLayer.setStyle(getMpStyles);

        return vectorLayer;
    };

    const getMpStyles = (feature: any, resolution: number) => {
        if (zoom !== undefined && zoom <= 17) {
            if (feature.get('features') && feature.get('features').length === 1) {
                const features = feature.get('features');
                return pinImageStyle(features[0], null);
            }
            return getClusterStyle(feature, map);
        } else {
            return pinImageStyle(feature, null);
        }
    };

    const createLayer = async (map: any) => {
        const layerId = 'poiLayer';
        const source = getVectorLayerSources();
        const vectorLayer = getVectorLayerObject(source, layerId, true);
        map.addLayer(vectorLayer);
        poiDispatches(layerId, true);
        setNSource(source.vSource);
        setCSource(source.cSource);
        // @ts-ignore
        setVlayer(vectorLayer);
        setMap(map);
    };

    const poiDispatches = (layerId: string, toogleVisibility: boolean) => {
        dispatch(setLayerArray({
            name: layerId,
            mainLayerId: layerId,
            id: layerId,
            type: 'vector',
            parentLayer: null,
            toogleHidden: toogleVisibility,
            visible: true,
            styleId: null,
            simpleLayer: true,
            layerParts: null,
            layerConfig: null,
            styles: null,
            title: 'POI'
        }));
        dispatch(addLayerToCounterArray(projectId));
    };


    useEffect(() => {
        // @ts-ignore
        if (vLayer !== null) {
            refVLayer.current = vLayer;
            refCSource.current = cSource;
            refNSource.current = nSource;
        }

        if (isSuccess && vLayer !== null) {
            if (resultData && resultData.coords_3857 !== null) {
                createFeatures(resultData, null).then(result => {
                    if (result.features.length > 0) {
                        if (itemId&&itemId!==null) {
                            const poi = result.features[0];
                            refNSource.current.addFeature(poi);
                            refCSource.current.addFeatures(poi);
                        } else {
                            refNSource.current.addFeatures(result.features);
                            refCSource.current.addFeatures(result.features);
                            const zoomMap=map.getView().getZoom();
                            map.getView().setZoom(zoomMap + 0.1);
                        }
                    }
                });
            }
        }
    }, [isSuccess, vLayer]);

    useEffect(() => {
        if (zoom > 17) {
            // @ts-ignore
            vLayer?.setSource(refNSource.current);
        } else {
            // @ts-ignore
            vLayer?.setSource(refCSource.current);
        }
        // @ts-ignore
        vLayer?.setStyle(getMpStyles);
        // @ts-ignore
        setVlayer(vLayer);
        refZoom.current = zoom;
    }, [zoom]);


    useEffect(() => {
        if (!map || Object.keys(map).length === 0) return;
        // eslint-disable-next-line no-console

        createLayer(map).then(r => console.log('PoiLayer erstellt'));
        pointerMoveEvent();
        map.on('moveend', function () {
            const newZoom = map.getView().getZoom();
            if (zoom !== newZoom) {
                setZoom(newZoom);
            }
        });

    }, [map]);


    useEffect(() => {
        if (itemId&&itemId!==null)  {
            triggerElements({project_id:projectId,mapping: {element_id: itemId, replace_coords: true}});
        } else {
            triggerElements({project_id:projectId,mapping: {element_type_id: 1}});
        }
    }, []);
    return null;
};

export default PoiLayer;
