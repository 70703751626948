import React, { useEffect, useState } from 'react';
import keycloakfetch from '../../functions/keycloakfetch';
import settings from '../../config/settings';
import Input from '../global/Input';

type Props = {
    label: string,
    projectId: string,
    value?: any,
    setValue?: (e: any) => void,
    validationControl?: any,
    validationErrorMessage?: (name: string) => void,
    validationRules?: any,
    showClear?: boolean
}

const FunctionsSelectList = (props: Props): JSX.Element => {
    const [list, setList] = useState([]);

    useEffect(() => {
        if (isUserManagementPage()) {
            getFunctions();
        }
    }, []);

    const isUserManagementPage = () => {
        const currentUrl = window.location.href;
        return currentUrl.includes('userManagement') || currentUrl.includes('userManagement_0');
    };

    const getFunctions = () => {
        keycloakfetch.get(settings.apiUrl + '/getGlobalRoles?project_id=' + props.projectId).then(result => {
            const tmp: any = [];
            result.forEach((key: any) => {
                if (key.name !== 'Administrator') {
                    tmp.push({
                        value: key.id,
                        label: key.name,
                    });
                }
            });
            setList(tmp);
        });
    };


    return (
        <Input
            name={'global_role_id'}
            edit={true}
            label={props.label}
            value={props.value}
            showClear={props.showClear}
            dropdownOptions={list}
            type='dropdown'
            onChange={(e: any) => {
                console.log(e);
                if (props.setValue) props.setValue(e.value);
            }}
            validationControl={props.validationControl}
            validationErrorMessage={props.validationErrorMessage}
            validationRules={props.validationRules}
        />
    );
};

export default FunctionsSelectList;
