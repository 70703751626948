import React, {FunctionComponent, useContext, useEffect, useRef, useState} from 'react';
import MapContext from '../map/MapContext';
import {useTranslation} from 'react-i18next';
import {InputText} from 'primereact/inputtext';
import {OverlayPanel} from 'primereact/overlaypanel';
import {Feature, Overlay} from 'ol';
import {Point} from 'ol/geom';

import {Toast} from 'primereact/toast';
import {useLazyGetElementGeoDataQuery} from '../../../redux/rtk/injectedGeoApi';
import {createPoiPoint, getLayerById} from '../../functions/Geo';
import {UnitExplorerSearch} from '../../unitManagement/UnitExplorerSearch';

type geocodingProps = {
    projectId: any
}

const SearchFeatureControl: FunctionComponent<geocodingProps> = (props) => {
    // @ts-ignore
    const {map} = useContext(MapContext);
    const {t} = useTranslation();
    const [value, setValue] = useState('');
    const op = useRef(null);
    const [toogleAllowed, setToogleAllowed] = useState(false);
    const valueRef = useRef(value);
    const toast = useRef(null);
    const projectId = props.projectId;
    const [overlayLayer, setOverlayLayer] = useState({});
    const [idToSearch, setIdToSearch] = useState<any>(null);
    const [poiLayer, setPoiLayer] = useState({
        getSource(): any {
            function getFeatures() {

            }
        }
    });
    const [triggerElements, {data: resultData, isLoading, isError, isSuccess}] = useLazyGetElementGeoDataQuery();


    const [show, setShow] = useState(false);

    const poiLayerRef = useRef(poiLayer);
    const toogleRef = useRef(toogleAllowed);

    const [overlaySource, setOverlaySource] = useState({
        clear() {
        },
        addFeature(feature: any) {
        }
    });
    const refOverlayLayer = useRef(overlayLayer);
    const refOverlaySource = useRef(overlaySource);

    const getOverlayLayer = () => {
        getLayerById(map, 'overlaylayer').then(layerById => {
            if (layerById.found) {
                // @ts-ignore
                setOverlayLayer(layerById.layer);
                // @ts-ignore
                setOverlaySource(layerById.layer.getSource());
                // @ts-ignore
                refOverlayLayer.current = layerById.layer;
                // @ts-ignore
                refOverlaySource.current = layerById.layer.getSource();
            }
        });
    };

    const getPoiLayer = () => {
        getLayerById(map, 'poilayer').then(layerById => {
            if (layerById.found) {
                // @ts-ignore
                setPoiLayer(layerById.layer);
                // @ts-ignore
                poiLayerRef.current = layerById.layer;
            }
        });
    };

    const createBaseMapButton = () => {
        const buttonBookmarkMap = document.createElement('button');
        buttonBookmarkMap.id = 'buttonSearchFeature';
        buttonBookmarkMap.title = t('search_feature');
        buttonBookmarkMap.innerHTML = '<span style="color: black"><i class="fas fa-search"/></span>';
        buttonBookmarkMap.addEventListener('click', showSearch, false);
        return buttonBookmarkMap;
    };

    const showSearch = (e: any) => {
        // @ts-ignore
        op.current?.toggle(e);
        setShow((prevState) => {
            return !prevState;
        });
        toogleFnc(true);
    };

    const toogleFnc = (val: boolean) => {
        setToogleAllowed(val);
        toogleRef.current = val;
    };

    const addControl = () => {

        const geocodingElem = document.getElementById('buttonGeocoding');
        if (geocodingElem === null) {
            const buttonBookmarkMap = createBaseMapButton();
            const elementLegende = document.getElementById('olToolkitControls');
            elementLegende?.appendChild(buttonBookmarkMap);
        }
        return [geocodingElem];
    };

    const clearSource = () => {
        refOverlaySource.current.clear();
        overlaySource.clear();
    };

    const contextMenuListener = () => {
        map.getViewport().addEventListener('contextmenu', function (evt: { preventDefault: () => void; }) {
            evt.preventDefault();
            clearSource();

            if (toogleRef.current) {
                // @ts-ignore
                op.current?.toggle({currentTarget: null});
                toogleFnc(false);
            }
        });
    };

    const showToast = (text: string) => {
        // @ts-ignore
        toast.current.show({
            severity: 'info',
            summary: 'Info',
            detail: text,
            life: 3000
        });
    };

    const createAndPlacePoint = (data: any) => {
        const coords = data.coords_3857.coordinates;
        map.getView().setCenter(coords);
        map.getView().setZoom(22);
        const featureOnPoiLayer = poiLayerRef.current.getSource().getFeatureById(data.id);
        if (!featureOnPoiLayer) {
            createPoiPoint(data, coords, false).then((point) => refOverlaySource.current.addFeature(point));
        }
    };

    useEffect (() => {
        if (idToSearch!==null) {
            clearSource();
            // eslint-disable-next-line camelcase
            triggerElements({
                project_id:props.projectId,
                mapping: {
                    element_id: idToSearch,
                    replace_coords: true
                }
            }).then((result: any) => {
                if (result && result.status === 'fulfilled' && result.data.length > 0) {
                    const data = result.data[0];
                    if (data.coords_3857 !== null) {
                        createAndPlacePoint(data);
                    } else {
                        showToast(t('geomon_noCoords'));
                    }
                } else {
                    showToast(t('units_explorerNoSearchResults'));
                }
            });
        }

    },[idToSearch]) ;


    useEffect(() => {
        if (!map || Object.keys(map).length === 0) return;
        getOverlayLayer();// DrawingLayer
        getPoiLayer();
        contextMenuListener();
        const geocodingControl = addControl();
        return () => map.controls.remove(geocodingControl);
    }, [map]);

    useEffect(() => {
        valueRef.current = value;
    }, [value]);


    return (
        <div>
            <div className={'card map-search p-0' + (show ? '' : ' hidden')}>
                <UnitExplorerSearch projectId={props.projectId} onClick={(id) => {setIdToSearch(id);
                }}/>
            </div>
            {/* <div>*/}
            {/*    <InputText value={value} onChange={(e) => setValue(e.target.value)} onKeyDown={handleKeyDown}*/}
            {/*               onFocus={handleMouseEnter} onMouseLeave={handleMouseLeave}*/}
            {/*               placeholder={t('units_explorer_search')}/>*/}
            {/* </div>*/}
            <Toast ref={toast}/>
        </div>);
};

export default SearchFeatureControl;
