import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFieldArray } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCirclePlus, faListCheck, faTimes} from '@fortawesome/free-solid-svg-icons';
import Input from '../global/Input';
import ProjectStyleType from './ProjectStyleType';
import keycloakfetch from '../../functions/keycloakfetch';
import settings from '../../config/settings';
import { Tooltip } from 'primereact/tooltip';

type Props = {
    project: any;
    setCardsData: (data: any[]) => void;
    form: any;
    setLoadedProjectTypes: (loadedProjectTypes:any[]) => void;
    triggerLoadProjectTypes: boolean;
};

type ProjectType = {
    id: number;
    name: string;
    projectStyleTypes: string;
    isManual?: boolean;
};

const ProjectTypeForm = (props: Props): JSX.Element => {
    const { t } = useTranslation(['common']);
    const [cards, setCards] = useState<number[]>([]);
    const [cardStates, setCardStates] = useState<{ id: number, projectTypeName: string, projectStyleTypes: string, isManual?: boolean }[]>([]);
    const [projectsLoaded, setProjectsLoaded] = useState<boolean>(false);

    const {control, formState: {errors}, handleSubmit, setValue, getValues, watch} = props.form;

    const { fields, append, prepend, remove, swap, move, insert,  } = useFieldArray({
        control: control,
        name: 'items',
    });

    const getFormErrorMessage = (name: string) => {
        if (name.indexOf('.') >= 0) {
            const split = name.split('.');

            if (split.length === 3) {
                // @ts-ignore
                if (errors[split[0]] && errors[split[0]][split[1]] && errors[split[0]][split[1]][split[2]]) {
                    // @ts-ignore
                    const text = errors[split[0]][split[1]][split[2]].message;

                    return <small className="p-error custom">{text}</small>;
                }
            }
        } else {
            // @ts-ignore
            if (errors[name]) {
                // @ts-ignore
                const text = errors[name].message;

                return <small className="p-error custom">{text}</small>;
            }
        }
        return undefined;
    };

    const loadProjectTypes = () => {
        remove();
        setCards([]);
        setCardStates([]);

        keycloakfetch.get(settings.apiUrl + '/getProjectTypes')
            .then(result => {
                if (result) {
                    const loadedProjectTypes: ProjectType[] = result.map((key: any) => ({
                        id: key.id,
                        name: key.name,
                        projectStyleTypes: key.style_type,
                        isManual: false,
                    }));

                    loadedProjectTypes.forEach((type) => {
                        append({ id: type.id, projectTypeName: type.name, projectStyleTypes: type.projectStyleTypes });
                        setCards(prev => [...prev, type.id]);
                        setCardStates(prev => [...prev, { id: type.id, projectTypeName: type.name, projectStyleTypes: type.projectStyleTypes }]);
                    });

                    props.setLoadedProjectTypes(loadedProjectTypes);
                }
            })
            .catch(error => {
                console.error('Fehler beim Laden der Projekttypen:', error);
            });
    };

    useEffect(() => {
        if (projectsLoaded) {
            loadProjectTypes();
        }
    }, [props.triggerLoadProjectTypes]);

    const handleLoadIconClick = () => {
        loadProjectTypes();
        setProjectsLoaded(true);
    };

    const handleIconClick = () => {
        const newId = cards.length > 0 ? Math.max(...cards) + 1 : 0;
        console.log(cards);

        setCards([...cards, newId]);
        setCardStates([...cardStates, { id: newId, projectTypeName: '', projectStyleTypes: '', isManual: true }]);

        append({ id: newId, projectTypeName: '' });

        console.log(`Card added with ID: ${newId}`);
    };

    const handleRemoveCard = (id: number) => {
        const index = cards.findIndex(cardId => cardId === id);
        setCards(cards.filter(cardId => cardId !== id));
        setCardStates(cardStates.filter(cardState => cardState.id !== id));
        if (index >= 0) {
            remove(index);
        }

        console.log(`Card removed with ID: ${id}`);
    };

    const handleInputChange = (id: number, value: string) => {
        setCardStates(prevStates =>
            prevStates.map(cardState =>
                cardState.id === id
                    ? { ...cardState, projectTypeName: value }
                    : cardState
            )
        );
    };

    const handleFieldChange = (id: number, field: string, value: string) => {
        setCardStates(prevStates =>
            prevStates.map(cardState =>
                cardState.id === id
                    ? { ...cardState, [field]: value }
                    : cardState
            )
        );
    };

    useEffect(() => {
        props.setCardsData(cardStates);
    }, [cardStates]);

    const renderCard = (id: number, index: number) => {
        const cardState = cardStates.find(card => card.id === id);
        return (
            <div
                key={id}
                className="card"
                style={{ flex: '1 1 25%', display: 'flex', flexDirection: 'column', position: 'relative' }}
            >
                {cardState?.isManual && (
                    <>
                        <Tooltip target=".timesIcon" mouseTrack mouseTrackLeft={10} />
                        <FontAwesomeIcon className="timesIcon"
                            icon={faTimes}
                            data-pr-tooltip={t('removeProjectType')}
                            style={{
                                position: 'absolute',
                                top: '0.5rem',
                                right: '0.5rem',
                                cursor: 'pointer',
                                fontSize: '1.2rem',
                                // color: 'gray',
                                color: '#FC6161'
                            }}
                            onClick={() => handleRemoveCard(id)}
                        />
                    </>
                )}
                <div className="text-2xl font-bold mb-3">{t('projM_projectTypeName')}</div>
                <div className="grid">
                    <div className="col-12 md:col-6">
                        <Input
                            value={cardState?.projectTypeName || ''}
                            onChange={(e) => handleInputChange(id, e.target.value)}
                            edit={true}
                            label={t('projM_projectTypeName')}
                            name={`items.${index}.projectTypeName`}
                            type={'text'}
                            validationControl={control}
                            validationErrorMessage={getFormErrorMessage}
                            validationRules={{ required: t('projM_projectTypeName') + ' ' + t('input_required') }}
                        />
                    </div>
                    <div className="col-12 md:col-6">
                        <ProjectStyleType
                            value={cardState?.projectStyleTypes || ''}
                            setValue={(e) => handleFieldChange(id, 'projectStyleTypes', e)}
                            label={t('project_style_type')}
                            validationControl={control}
                            validationErrorMessage={getFormErrorMessage}
                            validationRules={{ required: false}}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const addCardStyles: React.CSSProperties = {
        flex: '1 1 25%',
        display: 'flex',
        flexDirection: 'column',
        minWidth: '250px',
        minHeight: cards.length === 0 ? '100px' : 'auto',
    };

    const cardContainerStyles: React.CSSProperties = {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '1rem',
        padding: '0 0.5rem',
        overflowY: 'auto',
        height: 'calc(100% - 50px)'
    };

    return (
        <>
            <form id="projectForm" style={{ height: '100%' }}>
                <div className="grid" style={cardContainerStyles}>
                    {/* Vorhandene Karten rendern */}
                    {cards.map((id, index) => renderCard(id, index))}

                    {/* Karte mit dem Icon */}
                    <div className="card" style={addCardStyles}>
                        <div className="text-2xl font-bold mb-3">{t('projM_projectTypeName')}</div>
                        <div
                            style={{
                                flex: 1,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'row',
                            }}
                        >
                            {/* Icon für "Projekttypen laden" */}
                            {cards.length === 0 && (

                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        marginRight: '1rem',
                                        cursor: 'pointer'
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={faListCheck}
                                        style={{ fontSize: 34, cursor: 'pointer' }}
                                        onClick={handleLoadIconClick}
                                    />
                                    <span>{t('Projekttypen laden')}</span>
                                </div>
                            )}
                            {/* Icon für "Projekttyp hinzufügen" */}
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    cursor: 'pointer'
                                }}
                            >
                                <FontAwesomeIcon
                                    className="mx-2 mb-1"
                                    icon={faCirclePlus}
                                    style={{ fontSize: 30, cursor: 'pointer' }}
                                    onClick={handleIconClick}
                                />
                                <span>{t('projM_addProjectType')}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};

export default ProjectTypeForm;
