import {createSlice, Draft, PayloadAction} from '@reduxjs/toolkit';
import {useSelector} from 'react-redux';
import {showMessageOnSuccess} from "../../components/global/CustomToast";

// Define a type for the slice state
interface ApiCallsData {
    apiCalls: any
}

// Define the initial state using that type
const initialState: ApiCallsData = {
    apiCalls: {}
};

export const apiCallsSlice: any = createSlice({
    name: 'apiCalls',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        resetApiCalls: (state) => {
            //  console.log('reset mapping');
            state.apiCalls = {};
        },
        addApiCalls: (state, action: PayloadAction<any>) => {
            let temp: any = JSON.parse(JSON.stringify(state.apiCalls));

            temp = {...temp, [action.payload.key]: action.payload.value};

            state.apiCalls = temp;
        },
    },
});

export const {
    resetApiCalls,
    addApiCalls
} = apiCallsSlice.actions;

export default apiCallsSlice.reducer;
