import {updateAccessToken} from '../services/KeycloakService';
import store from "../redux/store";

const keycloakfetch = {
    get: (url) => {
        const state = store.getState();
        let refresh = true;
        return updateAccessToken(url).then((result) => {
            return new Promise(function (resolve, reject) {
                const getRecall = () => {
                    if (refresh === true) {
                        refresh = false;
                        resolve(keycloakfetch.get(url).then(r => console.log('but keycloak succesfully recalled')));
                    } else {
                        reject(false)
                    }
                }
                try {
                    fetch(url, {
                        headers: {
                            authorization: 'Bearer ' + state.keycloakData.keycloak.keycloakToken
                        }
                    }).then(res => {
                        const contentType = res.headers.get('content-type');
                        let value;
                        if (contentType && contentType.indexOf('application/json') !== -1) {
                            value = res.json();
                        } else if (contentType && contentType.indexOf('application/vnd.openxmlformats-officedocument.wordprocessingml.document') !== -1) {
                            value = res.blob();
                        } else {
                            value = res.text();
                        }
                        resolve(value)
                    }).catch(e => {
                        console.log(e);
                        if (refresh === true)
                            getRecall();

                    });
                } catch (e) {
                    getRecall();
                }
                // console.log(url);


            });
        })
    },
    post: (url, data) => {
        const state = store.getState();
        let refresh = true;

        return updateAccessToken(url).then(() => {
            return new Promise(function (resolve, reject) {

                const postRecall = () => {
                    if (refresh === true) {
                        refresh = false;
                        resolve(keycloakfetch.post(url, data).then(r => console.log('succesful recalled')));
                    } else {
                        reject(false)
                    }
                }

                try {
                    resolve(fetch(url, {
                        method: 'POST',
                        headers: {
                            authorization: 'Bearer ' + state.keycloakData.keycloak.keycloakToken,
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(data)
                    }).then(res => {
                        const contentType = res.headers.get('content-type');
                        if (contentType && contentType.indexOf('application/json') !== -1) {
                            return res.json();
                        } else {
                            return res.text();
                        }
                    }).catch(e => {
                        console.log(e);
                        postRecall();

                    }));
                } catch (e) {
                    postRecall();
                }

            });
        })
    },
    put: (url, data) => {
        const state = store.getState();
        let refresh = true;

        return updateAccessToken(url).then(() => {
            return new Promise(function (resolve, reject) {
                const putRecall = () => {
                    if (refresh === true) {
                        refresh = false;
                        resolve(keycloakfetch.put(url, data).then(r => console.log('succesful recalled')));
                    } else {
                        reject(false)
                    }
                }
                try{
                    resolve(fetch(url, {
                        method: 'PUT',
                        headers: {
                            authorization: 'Bearer ' + state.keycloakData.keycloak.keycloakToken,
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(data)
                    }).then(res => {
                        const contentType = res.headers.get('content-type');
                        if (contentType && contentType.indexOf('application/json') !== -1) {
                            return res.json();
                        } else {
                            return res.text();
                        }
                    }).catch(e => {
                        console.log(e);
                        putRecall();

                    }));
                }catch(e){
                    console.log(e);
                    putRecall();
                }

            });
        })
    },
    delete: (url, data) => {
        const state = store.getState();
        let refresh = true;
        return updateAccessToken().then(() => {
            return new Promise(function (resolve, reject) {
                const deleteRecall = () => {
                    if (refresh === true) {
                        refresh = false;
                        resolve(keycloakfetch.delete(url, data).then(r => console.log('succesful recalled')));
                    } else {
                        reject(false)
                    }
                }
                try{
                    resolve(fetch(url, {
                        method: 'DELETE',
                        headers: {
                            authorization: 'Bearer ' + state.keycloakData.keycloak.keycloakToken,
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(data)
                    }).then(res => {
                        const contentType = res.headers.get('content-type');
                        if (contentType && contentType.indexOf('application/json') !== -1) {
                            return res.json();
                        } else {
                            return res.text();
                        }
                    }).catch(e => {
                        console.log(e);
                        deleteRecall();

                    }));
                }catch(e){
                    console.log(e);
                    deleteRecall();
                }

            });
        })
    },
};

export default keycloakfetch;
