import {FunctionComponent, useContext, useEffect} from 'react';
import MapContext from '../map/MapContext';
import settings from '../../../config/settings';
import geoSettings from '../../../config/geoSettings';
import {getBaseWMSLayers, getUrlBasedByLayer} from '../../../functions/functionsOpenlayer';
import {useAppSelector, useAppDispatch} from '../../../redux/hooks';
import {addLayerToCounterArray, setBaseLayer, setLayerArray} from '../../../redux/slices/GeomonitoringSlice';

type baseProps = {
    mapDataWorkBench: any,
    baseLayer: any,
    projectId:number
}

const BaseLayers: FunctionComponent<baseProps> = ({mapDataWorkBench, baseLayer, projectId}) => {
    // @ts-ignore
    const {map, setMap} = useContext(MapContext);
    const dispatch = useAppDispatch();
    const permaLinkArray=useAppSelector((state:any) => state.configLayer.layerData.permaLinkLayer);
    const isPermaLink=useAppSelector((state:any) => state.configLayer.layerData.isPermaLink);
    const baseWmsWorkspace=useAppSelector((state:any)=>state.configLayer.layerData.baseLayerWorkspace);

    const callLayersByWorkspace = (wms: boolean, workspace: string, layerKind: string, mapDataWorkBench: any) => {
        const url = getUrlBasedByLayer(workspace, layerKind, mapDataWorkBench);
        const requestOptions = {
            method: 'GET',
            headers: geoSettings.geoserverAuthentification,
        };

        return new Promise((resolve, reject) => {
            fetch(url, requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        resolve({
                            success: true,
                            data: result
                        });
                    },
                    (error) => {
                        reject({
                            success: false,
                            info: 'GET failed: ' + error
                        });
                    });
        });
    };

    useEffect(() => {
        if (!map || Object.keys(map).length === 0) return;
        callLayersByWorkspace(true, baseWmsWorkspace, 'rasterData', mapDataWorkBench).then((res: any) => {

            if (res.success) {
                const wmsLayers = res.data.wmsLayers.wmsLayer;
                let counter=0;
                getBaseWMSLayers(wmsLayers, baseLayer, isPermaLink, permaLinkArray, baseWmsWorkspace).then(function (res) {
                    for (const layer of res.layers) {
                        if(layer.get('visible')){
                            dispatch(setBaseLayer(layer.get('id')));
                        }
                        counter++;
                        dispatch(setLayerArray({name:layer.get('id'), mainLayerId:layer.get('id'),
                            id:layer.get('id'), type:layer.get('type'), parentLayer:null, baseLayer:layer.get('baseLayer'), visible:layer.get('visible')}));
                        map.addLayer(layer);

                        if(counter==res.layers.length){
                            dispatch(addLayerToCounterArray(projectId));
                        }
                    }
                    setMap(map);
                });
            }
        });

    }, [map]);
    return null;
};

export default BaseLayers;
