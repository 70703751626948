const permissions = {
    "isAdmin": 0,
    "seeUM": 1,
    "editUM": 2,
    "seeAllUsers": 3,
    "editProject": 4,
    "createProject": 5,
    "seeAllProjects": 6,
    "seeLayer": 7,
    "importInspections": 10,
    "saveProjektBookmark": 11,
    "seeElements":12,
    "editElements":13,
    "editLocation": 16,
    "seeInspections": 14,
    "editInspections": 15
};

const permissionsIcon = {
    "0":  'pi pi-shield', // isAdmin
    "1":  'pi pi-eye', // seeUM
    "2":  'pi pi-pencil', // editUM
    "3":  'pi pi-eye', // seeNotification
    "4":  'pi pi-pencil', // editNotification
    "5":  'pi pi-pencil', // editNotificationTemplates
    "6":  'pi pi-eye', // editProject
    "7":  'pi pi-pencil', // seeAllUsers
    "8":  'pi pi-shield', // editProject
    "10":  'pi pi-shield', // importData
};

// dependencies:
// Ein Benutzer der Reports editieren kann, muss auch grafana dashboards sehen können
// Ein Benutzer der das Projekt editieren kann, muss auch Locations (Messtechnik) sehen können
// Ein Benutzer der Benachrichtiguen editieren kann, muss auch die Messtechnik sehen können
// Ein Benutzer der die Karte sehen kann, muss auch die Alarme sehen können
// ...
const dependPermissions = {
    '0': [],
    '1': [],
    '2': [1],              // editUM
    '3': [1],               // seeAllUsers
    '10':[14,15]
};

export {
    permissions,
    permissionsIcon,
    dependPermissions
}