// -------------------------------------------------------------------------------------------------------
// Entwicklungsserver 2024 [DMZ1]
/*
export const keycloakConfig = {
    "realm": "digital-inspection-tool",
    "url": "https://security.dev.dmt-group.com/",
    "clientId": "dit-webapp",
};
const settings = {
     apiUrl: process.env.NODE_ENV === 'development' ? 'http://localhost:8182' : 'https://dit.api.dev.dmt-group.com',
    //apiUrl: 'https://dit.api.dev.dmt-group.com',
    keycloakAuthUrl: keycloakConfig.url + '/realms/' + keycloakConfig.realm + '/protocol/openid-connect/token',
    logoutUrl: keycloakConfig.url + '/realms/' + keycloakConfig.realm + '/protocol/openid-connect/logout',
    logoutRedirect: process.env.NODE_ENV === 'development' ? 'http://localhost:1841/' : 'https://dit.dev.dmt-group.com/',
    filePath: 'https://dit.dev.dmt-group.com/',
    ooApiUrl: 'https://dit.api.dev.dmt-group.com',
}
*/


// -------------------------------------------------------------------------------------------------------
// Entwicklungsserver Maschinenraum

export const keycloakConfig = {
    "realm": "digital-inspection-tool",
    "url": "https://security.dmt-group.dev/",
    "clientId": "dit-webapp",
};
const settings = {
    apiUrl: process.env.NODE_ENV === 'development' ? 'http://localhost:8182' : 'https://api.dit.dmt-group.dev',
    //apiUrl: 'https://api.dit.dmt-group.dev',
    keycloakAuthUrl: keycloakConfig.url + '/realms/' + keycloakConfig.realm + '/protocol/openid-connect/token',
    logoutUrl: keycloakConfig.url + '/realms/' + keycloakConfig.realm + '/protocol/openid-connect/logout',
    logoutRedirect: process.env.NODE_ENV === 'development' ? 'http://localhost:1841/' : 'https://dit.dmt-group.dev/',
    filePath: 'https://dit.dmt-group.dev/',
    ooApiUrl: 'https://api.dit.dmt-group.dev',
}


export default settings;
