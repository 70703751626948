import { BaseQueryApi, createApi, FetchArgs, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import settings from '../../config/settings';
import {keycloak} from '../../functions/keycloak';
import store from '../store';
import {setKeycloakRefreshToken, setKeycloakToken} from '../slices/KeycloakSlice';

const baseQuery = fetchBaseQuery({
    baseUrl: settings.apiUrl,
    prepareHeaders: (headers, {getState}) => {
        const state=store.getState();
        // @ts-ignore
        const token = state.keycloakData.keycloak.keycloakToken;
        if (!headers.has('Authorization') && token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    }
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    if (result.error) {
        console.log('trying to get a new token')
        // try to get a new token
        const state=store.getState();
        const refreshToken = state.keycloakData.keycloak.keycloakRefreshToken;
        const paramsBody = 'client_id=' + keycloak.clientId + '&grant_type=refresh_token&refresh_token=' + refreshToken;

        const requestOptions = {
            method: 'POST',
            useDefaultXhrHeader: false,
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            body: paramsBody
        };

        const response = await fetch(settings.keycloakAuthUrl, requestOptions);

        if (response.status === 200) {
            console.log('Token updated!');
            const data = await response.json();
            store.dispatch(setKeycloakToken(data.access_token));
            store.dispatch(setKeycloakRefreshToken(data.refresh_token));
            result = await baseQuery(args, api, extraOptions);
        }
    }
    return result;
};

export const baseApi = createApi({
    baseQuery: baseQueryWithReauth,
    endpoints: () => ({})
});
