import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFieldArray } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { getUserFromLocalStorage } from '../functions/Global';
import Input from '../global/Input';
import ProjectElementFieldTypeData from './ProjectElementFieldTypeData';
import ProjectElementFieldTypeObject from './ProjectElementFieldTypeObject';
import ProjectElementFieldSubtype from './ProjectElementFieldSubtype';
import ProjectTypeSelectList from './ProjectTypeSelectList';
import ProjectElementTypeSelectList from './ProjectElementTypeSelectList';
import {Tooltip} from 'primereact/tooltip';

type Props = {
    project: any,
    setCardsData: (data: any[]) => void;
    form: any;
}

const ProjectElementFieldTypeEditForm = (props: Props): JSX.Element => {
    const { t } = useTranslation(['common']);
    const user = getUserFromLocalStorage();
    const [cards, setCards] = useState<number[]>([]);
    const [cardStates, setCardStates] = useState<{ id: number, projectTypeId: string, projectElementTypeId: string, elementFieldTypeName: string, elementFieldTypeCount: string, fieldTypeData: any, fieldTypeObject: any, checkValue: string, fieldSubtype: string }[]>([]);
    const [nextId, setNextId] = useState(0);

    const {control, formState: {errors}, handleSubmit, setValue, getValues, watch} = props.form;

    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control: control,
        name: 'items',
    });

    const getFormErrorMessage = (name: string) => {
        if (name.indexOf('.') >= 0) {
            const split = name.split('.');

            if (split.length === 3) {
                // @ts-ignore
                if (errors[split[0]] && errors[split[0]][split[1]] && errors[split[0]][split[1]][split[2]]) {
                    // @ts-ignore
                    const text = errors[split[0]][split[1]][split[2]].message;

                    return <small className="p-error custom">{text}</small>;
                }
            }
        } else {
            // @ts-ignore
            if (errors[name]) {
                // @ts-ignore
                const text = errors[name].message;

                return <small className="p-error custom">{text}</small>;
            }
        }
        return undefined;
    };

    const handleProjectTypeChange = (id: number, newProjectTypeId: string) => {
        setCardStates(prevStates =>
            prevStates.map(cardState =>
                cardState.id === id
                    ? { ...cardState, projectTypeId: newProjectTypeId }
                    : cardState
            )
        );
    };

    const handleProjectElementTypeChange = (id: number, newProjectElementTypeId: string) => {
        setCardStates(prevStates =>
            prevStates.map(cardState =>
                cardState.id === id
                    ? { ...cardState, projectElementTypeId: newProjectElementTypeId }
                    : cardState
            )
        );
    };

    const handleFieldChange = (id: number, field: string, value: string) => {
        setCardStates(prevStates =>
            prevStates.map(cardState =>
                cardState.id === id
                    ? { ...cardState, [field]: value }
                    : cardState
            )
        );
    };

    const handleIconClick = () => {
        const id = nextId;
        setNextId(nextId + 1);
        setCards([...cards, id]);
        setCardStates([...cardStates, { id, projectTypeId: '', projectElementTypeId: '', elementFieldTypeName: '', elementFieldTypeCount: '', fieldTypeData: null, fieldTypeObject: null, checkValue: '', fieldSubtype: '' }]);
        append({ id, projectTypeId: '', projectElementTypeId: '', elementFieldTypeName: '', elementFieldTypeCount: '', fieldTypeData: null, fieldTypeObject: null, checkValue: '', fieldSubtype: '' });
        console.log(`Card added with ID: ${id}`);
    };

    const handleRemoveCard = (id: number) => {
        setCards(cards.filter(cardId => cardId !== id));
        setCardStates(cardStates.filter(cardState => cardState.id !== id));
        console.log(`Card removed with ID: ${id}`);
    };

    useEffect(() => {
        props.setCardsData(cardStates); // Updates the parent with current cards data
    }, [cardStates]); // Runs every time cardStates changes


    const renderCard = (id: number, index: number) => {
        const cardState = cardStates.find(card => card.id === id);
        return (
            <div key={id} className="card" style={{ flex: '1 1 25%', display: 'flex', flexDirection: 'column', position: 'relative' }}>
                <Tooltip target=".timesIcon" mouseTrack mouseTrackLeft={10} />
                <FontAwesomeIcon className="timesIcon"
                    icon={faTimes}
                    data-pr-tooltip={t('removeElementFieldType')}
                    style={{
                        position: 'absolute',
                        top: '0.5rem',
                        right: '0.5rem',
                        cursor: 'pointer',
                        fontSize: '1.2rem',
                        // color: 'gray'
                        color: '#FC6161'
                    }}
                    onClick={() => handleRemoveCard(id)}
                />
                <div className="text-2xl font-bold mb-3">{t('Elementfeldtyp')}</div>
                <div className="grid">
                    <div className="col-12 md:col-6">
                        <ProjectTypeSelectList
                            setValue={(e) => handleProjectTypeChange(id, e)}
                            label={t('projM_projectTypeName')}
                            name={`items.${index}.projM_projectTypeName`}
                            validationControl={control}
                            validationErrorMessage={getFormErrorMessage}
                            validationRules={{ required: t('projM_projectTypeName') + ' ' + t('input_required') }}
                        />
                    </div>
                    <div className="col-12 md:col-6">
                        <ProjectElementTypeSelectList
                            setValue={(e) => handleProjectElementTypeChange(id, e)}
                            locale={user.language_id}
                            projectTypeId={cardState?.projectTypeId || ''}
                            // projectTypeId={projectTypeId}
                            label={t('project_element_type_id')}
                            name={`project_element_type_id${id}`}
                            validationControl={control}
                            validationErrorMessage={getFormErrorMessage}
                            validationRules={{ required: t('project_element_type_id') + ' ' + t('input_required') }}
                        />
                    </div>
                    <div className="col-12 md:col-6">
                        <Input
                            value={cardState?.elementFieldTypeName || ''} onChange={(e) => handleFieldChange(id, 'elementFieldTypeName', e.target.value)}
                            edit={true} label={t('element_field_type_name')} name={`element_field_type_name_${id}`} type={'text'}
                            validationControl={control} validationErrorMessage={getFormErrorMessage}
                            validationRules={{ required: t('element_field_type_name') + ' ' + t('input_required') }}

                        />
                    </div>
                    <div className="col-12 md:col-6">
                        <Input
                            value={cardState?.elementFieldTypeCount} onChange={(e) => handleFieldChange(id, 'elementFieldTypeCount', e.target.value)}
                            edit={true} label={t('element_field_type_count')} name={`element_field_type_count_${id}`} type={'text'}
                            validationControl={control} validationErrorMessage={getFormErrorMessage}
                            validationRules={{ required: t('element_field_type_count') + ' ' + t('input_required') }}

                        />
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12 md:col-6">
                        <ProjectElementFieldTypeData
                            value={cardState?.fieldTypeData}
                            setValue={(e) => handleFieldChange(id, 'fieldTypeData', e)}
                            label={t('element_field_type_data_type')}
                            validationControl={control}
                            validationErrorMessage={getFormErrorMessage}
                            validationRules={{ required: t('element_field_type_data_type') + ' ' + t('input_required') }}
                        />
                    </div>
                    <div className="col-12 md:col-6">
                        <ProjectElementFieldTypeObject
                            value={cardState?.fieldTypeObject}
                            setValue={(e) => handleFieldChange(id, 'fieldTypeObject', e)}
                            label={t('element_field_type_object_type')}
                            validationControl={control}
                            validationErrorMessage={getFormErrorMessage}
                            validationRules={{ required: t('element_field_type_object_type') + ' ' + t('input_required') }}
                        />
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12 md:col-6">
                        <Input
                            value={cardState?.checkValue || ''} onChange={(e) => handleFieldChange(id, 'checkValue', e.target.value)}
                            edit={true} label={t('element_field_type_check_value')} name={`element_field_type_check_value_${id}`} type={'text'}
                            validationControl={control} validationErrorMessage={getFormErrorMessage}
                            validationRules={{ required: false }}
                        />
                    </div>
                    <div className="col-12 md:col-6">
                        <ProjectElementFieldSubtype
                            projectElementTypeId={cardState?.projectElementTypeId || ''}
                            locale={user.language_id}
                            value={cardState?.fieldSubtype || ''}
                            setValue={(e) => handleFieldChange(id, 'fieldSubtype', e)}
                            label={t('element_field_subtype')}
                            validationControl={control}
                            validationErrorMessage={getFormErrorMessage}
                            validationRules={{ required: false }}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const addCardStyles: React.CSSProperties = {
        flex: '1 1 25%',
        display: 'flex',
        flexDirection: 'column',
        minWidth: '250px',
        minHeight: cards.length === 0 ? '100px' : 'auto',
    };

    const cardContainerStyles: React.CSSProperties = {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '1rem',
        padding: '0 0.5rem',
        overflowY: 'auto',
        height: 'calc(100% - 50px)' // Höhe des Containers für die Karten
    };

    return (
        <>
            <form id="projectForm" style={{ height: '100%' }}>
                <div className="grid" style={cardContainerStyles}>
                    {cards.map((id, index) => renderCard(id, index))}
                    <div className="card" style={addCardStyles}>
                        <div className="text-2xl font-bold mb-3">{t('Elementfeldtyp')}</div>
                        <div style={{
                            flex: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}>
                            <FontAwesomeIcon
                                className="mx-2 mb-1"
                                icon={faCirclePlus}
                                style={{ fontSize: 30, cursor: 'pointer' }}
                                onClick={handleIconClick}
                            />
                            <span>{t('projM_addElementFieldType')}</span>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};

export default ProjectElementFieldTypeEditForm;
