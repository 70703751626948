import React, {useEffect, useState} from 'react';
import LayoutMainContentTabMenu from "../components/global/LayoutMainContentTabMenu";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faIndustry, faUser, faUserSecret, faUserTag} from "@fortawesome/free-solid-svg-icons";
import UsersComponents from "../components/userManagement/UsersComponents";
import ViewButtons from "../components/global/ViewButtons";
import {
    buttonAddNewPlant,
    makeButtonItem
} from "../components/global/viewButtonSelection";
import {useTranslation} from "react-i18next";
import {UnitManagementOverview} from "../components/unitManagement/UnitManagementOverview";
import CustomDialog from "../components/global/CustomDialog";
import {Button} from "primereact/button";
import {CustomConfirmDialog} from "../components/global/CustomConfirmDialog";
import {showMessageOnError, showMessageOnSuccess} from "../components/global/CustomToast";
import {useCreateUnitMutation} from "../redux/rtk/injectedUnitsApi";
import {UnitItem} from "../components/functions/Units";
import {UnitExplorer} from "../components/unitManagement/UnitExplorer";

type Props = {
    project: any
}

export const UnitManagement = (props: Props) => {
    const {t} = useTranslation(['common']);

    const [viewButtonsRerender, setViewButtonsRerender] = useState<boolean>(true);
    const [showNewUnit, setShowNewUnit] = useState<boolean>(false);
    const [dirty, setDirty] = useState<boolean>(false);

    const [createUnit, resultCreateUnit] = useCreateUnitMutation();

    const menuItems = [
        {
            id: 'unitmanagementoverview',
            label: t('units_overview'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faIndustry}/>,
            component: <UnitManagementOverview project={props.project}/>,
            visible: props.project !== null,
            buttons: (
                <ViewButtons title={''} items={[
                    makeButtonItem(buttonAddNewPlant, t, () => {
                        setShowNewUnit(true);
                    }, true, false),
                ]} rerender={viewButtonsRerender}
                />
            )
        }
    ];

    const hideDialogs = () => {
        if (dirty) {
            CustomConfirmDialog({
                message: t('units_dialogs_unsavedChangesMessage'),
                header: t('confirmation'),
                translation: t,
                onConfirm: () => {
                    setShowNewUnit(false);
                    setDirty(false);
                }
            });
        } else {
            setShowNewUnit(false);
        }
    }

    const _setDirty = () => {
        setDirty(true);
    }

    const onCreateUnit = (unit: UnitItem | undefined) => {
        if (unit) {
            createUnit(unit).then((result: any) => {
                if (result.error) {
                    showMessageOnError(t('error'), JSON.stringify(result));
                }
            });
        }
    }

    return <div>
        <LayoutMainContentTabMenu items={menuItems} activeIndex={0} onTabChange={() => {
            setViewButtonsRerender(!viewButtonsRerender);
        }}/>
        <CustomDialog onCancel={hideDialogs} visible={showNewUnit} header={t('units_new')}
                      maximized={true} showFooter={false}>

            <UnitExplorer project={props.project} item={[]}/>
            {/*<UnitManagementEditForm project={props.project} plant={undefined} formId={'formNewPlant'} onChange={_setDirty}*/}
            {/*                        onSubmit={onCreateUnit}/>*/}
        </CustomDialog>
    </div>
}
