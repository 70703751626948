import {baseApi} from './baseApi';

export const injectedTranslationApi = baseApi.injectEndpoints({
    addTagTypes: ['Units'],
    endpoints: (builder) => ({
        getTranslation: builder.query({
            query: (args) => {
                // const {projectTypeId} = args;

                const url = 'getTranslation';

                return {url: url};
            },
            providesTags: ['Translation']
        }),
        createTranslation: builder.mutation({
            query: (unit) => ({
                url: 'createTranslation',
                method: 'POST',
                body: unit,
            }),
            invalidatesTags: ['Translation']
        }),
        updateTranslation: builder.mutation({
            query: (unit) => ({
                url: 'updateTranslation',
                method: 'PUT',
                body: unit,
            }),
            invalidatesTags: ['Translation']
        }),
        deleteTranslation: builder.mutation({
            query: (unit) => ({
                url: 'deleteTranslation',
                method: 'DELETE',
                body: unit,
            }),
            invalidatesTags: ['Translation']
        }),
        translateText: builder.mutation({
            query: ({text, targetLang, sourceLang}) => ({
                url: 'translate',
                method: 'POST',
                body: {text, targetLang, sourceLang},
            }),
        }),
    }),
});

export const {
    useGetTranslationQuery,
    useCreateTranslationMutation,
    useUpdateTranslationMutation,
    useDeleteTranslationMutation,
    useTranslateTextMutation,
} = injectedTranslationApi;
