import React, {useEffect, useState} from 'react';
import CustomDataTable, {ColumnObject} from '../global/CustomDataTable';
import {Language} from '../functions/Language';
import {useDeleteTranslationMutation, useGetTranslationQuery} from '../../redux/rtk/injectedTranslationApi';
import {FilterMatchMode, FilterOperator} from 'primereact/api';
import {useTranslation} from 'react-i18next';
import {CustomConfirmDialog} from '../global/CustomConfirmDialog';

type Props = {
    reload: boolean,
    setReload: (reload: boolean) => void,
    setSelectedLanguage: (language: Language) => void,
    setShowLanguageDialog: (data: boolean) => void,
}

export const LanguageOverview = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);

    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [language, setLanguage] = useState<Language[]>([]);
    const translationQuery = useGetTranslationQuery({});
    const [deleteTranslation] = useDeleteTranslationMutation();

    useEffect(() => {
        if (translationQuery.data) {
            setLanguage(translationQuery.data);
            setDataLoaded(true);
        }
    }, [translationQuery.data]);

    const columns: ColumnObject[] = [
        {field: 'key', header: t('langM_attribs_key'), style: {maxWidth: 400}, filter: true},
        {field: 'de', header: t('langM_language_de'), filter: true},
        {field: 'en', header: t('langM_language_en'), filter: true},
        {field: 'es', header: t('langM_language_es'), filter: true, display: false}
    ];

    const filters: any = {
        key: {
            operator: FilterOperator.AND,
            constraints: [{
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            }]
        },
        de: {
            operator: FilterOperator.AND,
            constraints: [{
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            }]
        },
        en: {
            operator: FilterOperator.AND,
            constraints: [{
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            }]
        },
        es: {
            operator: FilterOperator.AND,
            constraints: [{
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            }]
        },
    };

    return (<CustomDataTable
        id='language'
        columns={columns}
        editable={true}
        ableToDelete={true}
        displayColumnFilter={true}
        onRemoveClick={(e: any) => {
            CustomConfirmDialog({
                message: t('langM_removeTranslation'),
                header: t('langM_removeTranslation_header'),
                translation: t,
                onConfirm: () => {
                    deleteTranslation({key:e.key});
                },
            });
        }}
        sortable={true}
        value={language}
        filters={filters}
        paginator={true}
        onEditClick={(e: any) => {
            props.setSelectedLanguage(e);
            props.setShowLanguageDialog(true);
        }}
        ableToUpdate={true}
        dataloaded={dataLoaded}
    />);
};