/*
 * MoveUsersToRoleForm.tsx
 * Author: lnappenfeld
 * Date: 17.11.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React from 'react';
import RolesSelectList from './RolesSelectList';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {moveUserToRole, User} from '../functions/UserManagement';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import {showReloadPageDialog} from '../global/ReloadPageDialog';

type Props = {
    selectedUsers: User[],
    projectId: string,
    onReady: (success: boolean) => void
}

/**
 * Form for editing and creating of users
 * @param props
 * @constructor
 */
export const MoveUsersToRoleForm = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common', 'users', 'input']);
    const defaultValues = {'role_id': ''};
    const {control, formState: {errors}, handleSubmit, getValues} = useForm({defaultValues});

    const getFormErrorMessage = (name: string) => {
        // @ts-ignore
        return errors[name] && <small className="p-error custom">{errors[name].message}</small>;
    };

    const acceptChangeRoleOfUsers = () => {
        moveUserToRole(props.projectId, getValues('role_id'), props.selectedUsers).then(result => {
            if (result.error) {
                showMessageOnError(t('error'),result.error);
            } else {
                showMessageOnSuccess(t('success'), t('toasts_roleOfUsersChanged'));
                // Ansicht updaten
                props.onReady(true);
                // Prüfe ob die eigene Rolle geändert wurde: dann lade die Seite neu
                if (result.ownUserMoved) {
                    showReloadPageDialog(t, t('reloadPage_msgOwnUserMoved'));
                }
            }
        });
    };

    return (
        <form id='moveUsersToRoleDialog' onSubmit={handleSubmit(acceptChangeRoleOfUsers)}>
            <RolesSelectList
                label={t('userM_role')}
                projectId={props.projectId}
                validationControl={control}
                validationErrorMessage={getFormErrorMessage}
                validationRules={{required: t('userM_role') + ' ' + t('input_required')}}
            />
        </form>
    );
};

