/*
 * ProjectAdvancedSettingsForm.tsx
 * Author: lnappenfeld
 * Date: 24.01.2023
 *
 * Copyright: DMT GmbH & Co. KG
 */


import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import {
    Project, updateAdvancedProjectSettings,
} from '../functions/Project';
import {Button} from 'primereact/button';
import {getLocation, getLocations, Location, Nullable} from '../functions/Global';
import LocationSelectList from '../global/LocationSelectList';
import BaseLayerSelectList from './BaseLayerSelectList';
import {LocationDialogEditForm} from '../global/LocationDialogEditForm';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLocationDot} from '@fortawesome/free-solid-svg-icons';
import Input from '../global/Input';
import {checkPermission, checkProjectType, ProjectType, urlValidation} from '../../functions/functionLibrary';
import {permissions} from '../../config/permissions';
import {callBaseLayerMap} from '../../functions/functionsOpenlayer';

type Props = {
    project: Project,
    onFinished?: (project: Nullable<Project>) => void,
}

/**
 * Form for editing and creating of projects
 * @param props
 * @constructor
 */
const ProjectAdvancedSettingsForm = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);

    const [locationCenter, setLocationCenter] = useState<Nullable<Location>>(null);
    const [locations, setLocations] = useState<Location[]>([]);
    const [selectedLocations, setSelectedLocations] = useState<Location[]>([]);
    const [editLocation, setEditLocation] = useState<boolean>(false);
    const [projectId, setProjectId] = useState<string | null>(null);
    const [workspaceBaseLayers, setWorkspaceBaseLayers] = useState<any>([]);

    const defaultValues = {
        'location_id': props.project ? props.project.location_id : null,
        'location_zoom': props.project && props.project.location_zoom ? props.project.location_zoom : 10.0,
        'geoserver_workspace': props.project ? props.project.geoserver_workspace : null,
        'baselayer_workspace': props.project ? props.project.baselayer_workspace : null,
        'base_layer': props.project ? props.project.base_layer : null,
        'wiki_url': props.project ? props.project.wiki_url : null,
    };

    const {control, formState: {errors}, handleSubmit, reset, getValues, setValue, watch} = useForm({defaultValues});

    watch('location_id');

    const getFormErrorMessage = (name: string) => {
        if (name.includes('.')) {
            const elements = name.split('.');
            const inputName = elements[1];
            // @ts-ignore
            if (errors['billing'] && errors['billing'][inputName]) {
                // @ts-ignore
                return (errors['billing'][inputName] &&
                    // @ts-ignore
                    <small className="p-error custom">{errors['billing'][inputName].message}</small>);
            }
        } else {
            // @ts-ignore
            return errors[name] && <small className="p-error custom">{errors[name].message}</small>;
        }
    };

    const _getLocation = () => {
        if (props.project && props.project.location_id) {
            getLocation(props.project.id, props.project.location_id).then(result => {
                if (result) {
                    setLocationCenter(result);
                    setSelectedLocations([result]);
                }
            });
        }
    };

    useEffect(() => {
        if (props.project) {
            const projectId = props.project.id;
            setProjectId(projectId);

            _getLocations();
            // Aktualisiere BaserLayerSelectList
            let workspace = 'DMT';
            if (props.project.baselayer_workspace !== null) {
                workspace = props.project.baselayer_workspace;
            }
            _getWmsBaseLayers(workspace);
        }

        _getLocation();
    }, [props.project]);

    useEffect(() => {
        if (getValues('location_id')) {
            getLocation(props.project.id, getValues('location_id')).then(result => {
                if (result) {
                    setLocationCenter(result);
                    setSelectedLocations([result]);
                }
            });
        } else {
            setSelectedLocations([]);
        }
    },[getValues('location_id')]);

    // Nachdem der LocationDialog mit abbrechen geschlossen wurde, werden die selectedLocations zurückgesetzt.
    // Das soll hier aber nicht passieren, deswegen setze danach wieder die selectedLocations
    useEffect(() => {
        if (!editLocation && locationCenter) {
            setSelectedLocations([locationCenter]);
        }
    }, [editLocation]);

    const _getLocations = () => {
        getLocations(props.project.id).then(locations => {
            setLocations(locations);
        });
    };


    const _getWmsBaseLayers = (workspace: string) => {
        callBaseLayerMap( workspace).then(result => {
            if (result && result.success === true) {
                const wmsLayers = result.data.wmsLayers.wmsLayer;
                const _workspaceBaseLayers = [];
                for (const layer of wmsLayers) {
                    _workspaceBaseLayers.push({
                        value: layer.name,
                        label: t('geomon_baseLayers_' + layer.name.toLowerCase())
                    });
                }
                setWorkspaceBaseLayers(_workspaceBaseLayers);
            }
        }).catch(err => {
            if (err && err.success === false) {
                showMessageOnError(t('error'), err.info);
                setWorkspaceBaseLayers([]);
            }
        });
    };

    const saveData = (data: any) => {
        const projectAddId = {
            'project_id': projectId,
            'id': projectId, // For updating at onFinished()
        };
        data = {...projectAddId, ...data};

        console.log('saveData: ', data);
        updateAdvancedProjectSettings(data).then(result => {
            if (result.error) {
                showMessageOnError(t('error'), result.error);
            } else {
                showMessageOnSuccess(t('success'), t('toasts_projectUpdated'));
                if (props.onFinished)
                    props.onFinished(data);
            }
        });

    };


    return (<>
        <form id='projectForm' onSubmit={handleSubmit(saveData)} style={{height: '100%'}}>
            <div className='grid' style={{height: '100%'}}>
                <div className='col-12 md:col-4' style={{height: '100%'}}>
                    <div className={'card'} style={{height: '100%'}}>
                        <h2>GIS</h2>
                        <div className='grid'>
                            <div className='col-12 md:col-10'>
                                {projectId &&
                                <LocationSelectList
                                    projectId={projectId}
                                    disabled={locations.length <= 0}
                                    locations={locations}
                                    label={t('loc_attribs_projectCenter')}
                                    tooltip={locations.length > 0 ? '' : t('projM_tooltip_center')}
                                    validationControl={control}
                                    validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: false}}
                                />}
                            </div>
                            <div className='col-12 md:col-2'>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    height: '7vh'
                                }}>
                                    <Button
                                        className={'p-button-outlined mt-auto'}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            setEditLocation(true);
                                        }}
                                    >
                                        <FontAwesomeIcon className='sidebar-icon' icon={faLocationDot}/>
                                    </Button>
                                </div>
                            </div>
                        </div>
                        {checkProjectType(ProjectType.GeoMonitoring) && <>
                            <Input
                                edit={checkPermission(permissions.editProject)}
                                label={t('projM_geoserver_workspace')}
                                name={'geoserver_workspace'} type={'text'}
                                validationControl={control} validationErrorMessage={getFormErrorMessage}
                                validationRules={{required: false}}
                            />
                            <Input
                                edit={checkPermission(permissions.editProject)}
                                label={t('projM_baselayer_workspace')}
                                tooltip={t('projM_tooltip_baselayer_ws')}
                                name={'baselayer_workspace'} type={'text'}
                                onBlur={(e: any) => {
                                    // Aktualisiere BaserLayerSelectList
                                    let workspace = 'DMT';
                                    if (e.target.value !== null && e.target.value !== '') {
                                        workspace = e.target.value;
                                    }
                                    _getWmsBaseLayers(workspace);
                                }}
                                validationControl={control} validationErrorMessage={getFormErrorMessage}
                                validationRules={{required: false}}
                            />
                            <BaseLayerSelectList
                                label={t('projM_baseLayer')}
                                name={'base_layer'} baseLayers={workspaceBaseLayers}
                                validationControl={control} validationErrorMessage={getFormErrorMessage}
                                validationRules={{required: false}}
                            />
                        </>}
                    </div>
                </div>
                <div className='col-12 md:col-4'>
                    <div className={'card'} style={{height: '100%'}}>
                        <h2>{t('projM_miscellaneous')}</h2>
                        <Input
                                edit={checkPermission(permissions.editProject)}
                                label={t('projM_wiki_url')}
                                tooltip={t('projM_tooltip_wiki_url')}
                                name={'wiki_url'} type={'text'}
                                validationControl={control} validationErrorMessage={getFormErrorMessage}
                                validationRules={{ ...{required: false}, ...urlValidation(t)}}
                        />
                    </div>
                </div>
            </div>
        </form>
        {
        <LocationDialogEditForm
            project={props.project}
            setSelectedLocations={setSelectedLocations}
            selectedLocations={selectedLocations}
            showSaveAndNewButton={false}
            setEditLocation={setEditLocation}
            editLocation={editLocation}
            isProjectCenter={true}
            setReloadLocations={(reload: boolean) => {
                if (reload) _getLocation();
            }}
        />}
    </>);
};

export default ProjectAdvancedSettingsForm;
