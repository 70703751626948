import {FunctionComponent, useContext, useEffect} from 'react';
import MapContext from '../map/MapContext';
import {Vector as VectorSource} from 'ol/source';
import VectorLayer from 'ol/layer/Vector';
import {Fill, Icon, Stroke, Style, Text} from 'ol/style';
import {useAppDispatch} from '../../../redux/hooks';
import {addLayerToCounterArray, setLayerArray} from '../../../redux/slices/GeomonitoringSlice';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {pinImageStyle, pointImageStyle} from '../../functions/Geo';
import geoSettings from '../../../config/geoSettings';


type overlayProps = {projectId:number};
const ClusterOverlayLayer: FunctionComponent<overlayProps> = (projectId) => {
    // @ts-ignore
    const {map} = useContext(MapContext);
    const dispatch = useAppDispatch();


    useEffect(() => {
        if (!map || Object.keys(map).length === 0) return;
        const source = new VectorSource({});

        const clusterOverlayLayer = new VectorLayer({
            source:source,
            style:pointImageStyle,
            zIndex:geoSettings.poiLayerKey+2
        });
        clusterOverlayLayer.set('id', 'clusterOverlayLayer');
        clusterOverlayLayer.set('toogleHidden',true);
        map.addLayer(clusterOverlayLayer);
        dispatch(setLayerArray({name:'clusterOverlayLayer',mainLayerId:'clusterOverlayLayer', id:'clusterOverlayLayer', type:'vector', toogleHidden:true, visible:true}));
        dispatch(addLayerToCounterArray(projectId.projectId));
       //     console.log('add overlaylayers')
        return () => {
            if (map) {
                map.removeLayer(clusterOverlayLayer);
            }
        };
    }, [map]);

    return null;
};

export default ClusterOverlayLayer;
