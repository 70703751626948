import React, {useEffect, useState} from 'react';
import {getElementType, getElementTypeName} from "../functions/Units";
import {Button} from "primereact/button";
import {confirmDialog} from "primereact/confirmdialog";
import {useGetElementTypesQuery} from "../../redux/rtk/injectedUnitsApi";
import {getUserFromLocalStorage} from "../functions/Global";
import {useTranslation} from "react-i18next";

type Props = {
    project: any,
    isPipeline: boolean,
    item: any,
    unitTree: any,
    setUnitTree: (args: any) => void,
    activeItem: any,
    setActiveItem: (treeItem: any, scrollIntoView: boolean) => void,
    onClickRemoveitem: (id: string) => void,
}

export const UnitExplorerTree = (props: Props) => {
    // console.log('render UnitExplorerTree');
    const {t} = useTranslation(['common']);
    const user = getUserFromLocalStorage();

    const elementTypesQuery = useGetElementTypesQuery({
        projectId: props.project.id,
        locale: user ? user.language_id : 'de'
    });

    const [result, setResult] = useState<any>(undefined);

    const toggleExpanded = (treeItem: any) => {
        treeItem.expanded = !treeItem.expanded;

        props.setUnitTree((prevState: any) => {
            if (prevState) {
                const temp = JSON.parse(JSON.stringify(prevState));

                for (let i = 0; i < temp.length; i++) {
                    const itemTemp = getTreeItem(temp[i], treeItem);

                    if (itemTemp) {
                        itemTemp.expanded = !itemTemp.expanded;
                        break;
                    }

                }
                return temp;
            }
        });
    };

    const getTreeItem = (treeItem: any, item: any): any => {
        if (treeItem == item) {
            return item;
        } else {
            if (treeItem.children) {
                for (let i = 0; i < treeItem.children.length; i++) {
                    const item1 = treeItem.children[i];
                    return getTreeItem(item1, item);
                }
            }
        }
        return undefined;
    };

    const getTree = (parent: any, treechildren: any[], index: number, lastItem: boolean, expanded: boolean) => {
        const resultTemp: any[] = [];

        let index1 = index;
        index1++;
        const style = {
            marginLeft: index1 * (props.isPipeline ? 5 : 20) + 'px',
            overflow: 'hidden',
        };

        let children_type_ids: any[] = [];

        if (treechildren) {
            for (let i = 0; i < treechildren.length; i++) {
                const treeItem = treechildren[i];

                let elementType = null;

                if (treeItem && elementTypesQuery.data) {
                    elementType = getElementType(treeItem.element_type_id, elementTypesQuery.data);

                    if (elementType) {
                        children_type_ids = elementType.children_type_ids;
                    }
                }

                const typeIconClass = treeItem.icon; // getElementTypeIconClass(treeItem.element_type_id, elementTypesQuery.data);

                const isPipelineWithParent = props.isPipeline && parent;
                const isPipelineWithoutParent = props.isPipeline && !parent;
                const isPiplineWithParentOrNotTree = props.isPipeline && (parent || (elementType && !elementType.show_as_tree));


                resultTemp.push(<div
                    id={treeItem.id}
                    className={'connect tree-item grid grid-nogutter' + (isPipelineWithParent ? '' : isPipelineWithoutParent ? ' mt-1 mb-0' : ' my-1') + (lastItem ? ' last' : '') + (treeItem.expanded ? ' expanded' : '') + ' inspection-status-' + treeItem.inspection_task_status + (treeItem.inspection_is_active_passive ? ' inspection-status-passive-' + treeItem.inspection_status_passive : '')}
                    style={style}>
                    {/* <Tooltip target={'.icon_' + item.id} content={item.name}/>*/}
                    <i className={'my-auto mr-1 ml-2 pi pi-angle-right folder-arrow'}
                       style={{
                           visibility: ((treeItem.children && treeItem.children.length)) ? 'visible' : 'hidden',
                           cursor: ((treeItem.children && treeItem.children.length)) ? 'pointer' : 'default'
                       }} onClick={() => {
                        toggleExpanded(treeItem);
                    }}></i>
                    <div
                        className={'flex ue_tree-item-content select-none' + (props.activeItem && props.activeItem.id === treeItem.id ? ' active' : '')}
                        style={{
                            cursor: 'pointer'
                        }}
                        onClick={(e) => {
                            // console.log('detail', e.detail);
                            if (e.detail > 1) {
                                toggleExpanded(treeItem);
                            } else {
                                if (!props.activeItem || props.activeItem.id !== treeItem.id) {
                                    props.setActiveItem(treeItem, false);
                                }
                            }
                        }}>
                        {elementType && <>
                            {(elementType.show_as_tree) &&
                                <><i className={'ue_tree-icon-content ' + typeIconClass}/>
                                </>}
                            {(!parent && !elementType.show_as_tree) &&
                                <i className={'ue_tree-icon-content-free'}>
                                    {/*<FontAwesomeIcon className='sidebar-icon mr-2' icon={faLinkSlash}/>,*/}
                                </i>}
                        </>}
                        {props.isPipeline &&
                            <>
                                <i className={'ue_tree-icon-content-pipeline_' + treeItem.element_type_id}/><i
                                className={'ue_tree-icon-content-pipeline-addition_' + treeItem.element_type_id}/>
                            </>}
                        <p className={'ue_icon-tree-text my-auto' + (isPiplineWithParentOrNotTree ? ' ml-3' : ' ml-2')}>{`${treeItem.name} (${getElementTypeName(treeItem.element_type_id, elementTypesQuery.data)} / ${treeItem.children ? treeItem.children.length : 0})`}</p>
                    </div>
                    {props.item != null && <div className={'flex justify-content-end w-full'}><Button
                        className={'p-0 h-auto p-button-icon p-button-sm p-button-danger p-button-rounded p-button-text ml-3'}
                        icon={'pi pi-times'}
                        onClick={() => {
                            confirmDialog({
                                header: t('dialogs_removeElementHeader'),
                                message: t('dialogs_removeElementMessage'),
                                icon: 'pi pi-exclamation-triangle',
                                acceptLabel: t('yes'),
                                rejectLabel: t('no'),
                                accept: () => {
                                    props.onClickRemoveitem(treeItem.id);
                                }
                            });
                        }}/></div>}
                </div>);

                if (treeItem.expanded) {
                    resultTemp.push(getTree(treeItem, treeItem.children, index1, treeItem.children ? i === treeItem.children.length - 1 : false, treeItem.expanded));
                }
            }
        }

        const style2 = {
            display: expanded ? 'inherit' : 'none',
        };

        return (<span key={(index + 1) + '' + (parent ? parent.id : '')}
                      className={'ue_content-block' + (lastItem ? ' last' : '')}
                      style={style2}>{resultTemp}</span>);
    }

    useEffect(() => {
        console.log('render tree effect')
        setResult(getTree(undefined, props.unitTree, 0, false, true));
    }, [props.activeItem, props.unitTree]);

    return <div>{result}</div>
}
