/*
 * ProjectEditForm.tsx
 * Author: lnappenfeld
 * Date: 25.05.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */


import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Input from '../global/Input';
import {useForm} from 'react-hook-form';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import {
    createProject,
    FormType,
    Project,
    updateProject,
    updateProjectImage,
    deleteProjectImage
} from '../functions/Project';
import ProjectTypeSelectlist from './ProjectTypeSelectList';
import CustomImage from '../global/CustomImage';
import CustomDialog from '../global/CustomDialog';
import FileManagement from '../global/FileManagement';
import {Button} from 'primereact/button';
import { getFile, Nullable} from '../functions/Global';
import {CustomConfirmDialog} from '../global/CustomConfirmDialog';

type Props = {
    project: Nullable<Project>,
    setProject?: (data: any) => void,
    type: FormType,
    mayEditProject?: boolean,
    onFinished?: (project: Nullable<Project>) => void,
}

/**
 * Form for editing and creating of projects
 * @param props
 * @constructor
 */
const ProjectEditForm = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);

    const [projectLogo, setProjectLogo] = useState<any>();
    const [projectPicture, setProjectPicture] = useState<any>();
    const [fileManagerVisible, setFileManagerVisible] = useState<boolean>(false);
    const [fileManagerTarget, setFileManagerTarget] = useState<string>('');
    const [selectedImageId, setSelectedImageId] = useState<string>();
    const projectId = props.project ? props.project.id : null;

    const defaultValues = {
        'project_type_id': props.project ? props.project.project_type_id : null,
        'is_active': props.project ? props.project.is_active : true,
        'name': props.project ? props.project.name : null,
        'comment': props.project ? (props.project.comment || null) : null,
        'customer_name': props.project ? props.project.customer_name : null,
        'customer_address': props.project ? props.project.customer_address : null,
        'logo_id': props.project ? props.project.logo_id : null,
        'picture_id': props.project ? props.project.picture_id : null,
        //additional
        //'number': props.project ? (props.project.number || null) : null,
    };

    const {control, formState: {errors}, handleSubmit, reset, getValues, setValue} = useForm({defaultValues});

    const getFormErrorMessage = (name: string) => {
        // @ts-ignore
        return errors[name] && <small className="p-error custom">{errors[name].message}</small>;
    };

    useEffect(() => {
        if (props.type === FormType.Edit && props.project) {
            reset(defaultValues);
            getFile(props.project.id, getValues('logo_id')).then(result => {
                setProjectLogo(result.data);
            });
            getFile(props.project.id, getValues('picture_id')).then(result => {
                setProjectPicture(result.data);
            });
        }
    }, [props.project]);


    useEffect(() => {
        // reset for changing between 'project information' and 'create project' within a project
        reset(defaultValues);
    }, [props.type]);

    const changeImage = (fileId: any) => {
        if (typeof fileId !== 'undefined') {
            switch (fileManagerTarget) {
                case 'project-logo':
                    if (props.project) {
                        getFile(props.project.id, fileId).then(result => {
                            setProjectLogo(result.data);
                        });
                        updateProjectImage(props.project.id, fileId, '').then(result => {
                            if (result.error) {
                                showMessageOnError(t('error'), result.error);
                                if (props.onFinished)
                                    props.onFinished(null);
                            } else {
                                showMessageOnSuccess(t('success'), t('projectM_toasts_projectLogoUpdated'));
                                if (props.onFinished)
                                    props.onFinished(props.project);
                            }
                        });
                    }
                    break;
                case 'project-picture':
                    if (props.project) {
                        getFile(props.project.id, fileId).then(result => {
                            setProjectPicture(result.data);
                        });
                        updateProjectImage(props.project.id, '', fileId).then(result => {
                            if (result.error) {
                                showMessageOnError(t('error'), result.error);
                                if (props.onFinished)
                                    props.onFinished(null);
                            } else {
                                showMessageOnSuccess(t('success'), t('projectM_toasts_projectPictureUpdated'));
                                if (props.onFinished)
                                    props.onFinished(props.project);
                            }
                        });
                    }
                    break;
            }
        }
        setSelectedImageId(undefined);
        setFileManagerVisible(false);
    };

    const saveData = (data: any) => {
        console.log('saveData: ', data);

        if (props.project) {
            const projectAddId = {
                'project_id': projectId,
                'id': projectId
            };

            data = {...projectAddId, ...data};

            updateProject(data).then(async result => {
                if (result.error) {
                    showMessageOnError(t('error'), result.error);
                } else {
                    showMessageOnSuccess(t('success'), t('toasts_projectUpdated'));

                    if (props.onFinished)
                        props.onFinished(data);
                    if (props.setProject)
                        props.setProject(data);

                    // window.location.reload();
                }
            });
        } else {
            const projectAddId = {
                'project_id': null,
            };
            data = {...projectAddId, ...data};

            createProject(data).then(result => {
                if (result.error) {
                    showMessageOnError(t('error'), result.error);
                    if (props.onFinished)
                        props.onFinished(null);
                } else {
                    showMessageOnSuccess(t('success'), t('projectM_toasts_projectCreated'));
                    if (props.onFinished)
                        props.onFinished(result);
                }
            });

        }
    };

    return (
        <form id="projectForm" onSubmit={handleSubmit(saveData)} style={{height: '100%'}}>
            <div className="grid" style={{height: '100%'}}>
                <div className="col-12 md:col-6 md:h-full">
                    <div className={'card'} style={{height: '100%'}}>
                        <ProjectTypeSelectlist
                            label={t('projM_projectTypeName')}
                            name={'project_type_id'}
                            disabled={typeof props.mayEditProject === 'boolean' ? !props.mayEditProject : false}
                            validationControl={control}
                            validationErrorMessage={getFormErrorMessage}
                            validationRules={{required: t('projM_projectTypeName') + ' ' + t('input_required')}}
                        />
                        <div className="grid">
                            <div className="col-12 md:col-6">
                                <Input
                                    edit={typeof props.mayEditProject === 'boolean' ? props.mayEditProject : true}
                                    label={t('name')}
                                    name={'name'} type={'text'}
                                    validationControl={control} validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: t('name') + ' ' + t('input_required')}}
                                />
                            </div>
                            {/*<div className="col-12 md:col-6">
                                <Input
                                    edit={typeof props.mayEditProject === 'boolean' ? props.mayEditProject : true}
                                    label={t('number')}
                                    name={'number'} type={'text'}
                                    validationControl={control} validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: false}}
                                />
                            </div>*/}
                        </div>
                        <Input
                            edit={typeof props.mayEditProject === 'boolean' ? props.mayEditProject : true}
                            label={t('description')}
                            name={'comment'} type={'textarea'}
                            validationControl={control} validationErrorMessage={getFormErrorMessage}
                            validationRules={{required: false}}
                        />
                        <div className="grid">
                            <div className="col-12 md:col-6">
                                <Input
                                    edit={typeof props.mayEditProject === 'boolean' ? props.mayEditProject : true}
                                    label={t('projM_customer')}
                                    name={'customer_name'} type={'textarea'}
                                    validationControl={control} validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: false}}
                                />
                            </div>
                            <div className="col-12 md:col-6">
                                <Input
                                    edit={typeof props.mayEditProject === 'boolean' ? props.mayEditProject : true}
                                    label={t('projM_address')}
                                    name={'customer_address'} type={'textarea'}
                                    validationControl={control} validationErrorMessage={getFormErrorMessage}
                                    validationRules={{required: false}}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 md:col-6 md:h-full">
                    <div className={'card'} style={{height: '100%'}}>
                        {props.type === FormType.Edit &&
                        <>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <h4>{t('projM_logo')}</h4>
                                {projectLogo && props.mayEditProject &&
                                <Button
                                    type={'button'}
                                    className="p-button-danger p-button-text m-1"
                                    style={{minWidth: '20px'}}
                                    onClick={() => {
                                        CustomConfirmDialog({
                                            message: t('projM_deleteProjectLogo_message'),
                                            header: t('confirmation'),
                                            translation: t,
                                            onConfirm: () => {
                                                if (props.project) {
                                                    const logoId = getValues('logo_id');
                                                    if (logoId) {
                                                        deleteProjectImage(props.project.id, logoId, '').then(result => {
                                                            if (result.error) {
                                                                showMessageOnError(t('error'), result.error);
                                                                if (props.onFinished)
                                                                    props.onFinished(null);
                                                            } else {
                                                                showMessageOnSuccess(t('success'), t('projM_deleteProjectLogo_confirmed'));
                                                                if (props.onFinished)
                                                                    props.onFinished(props.project);
                                                            }
                                                        });
                                                    }
                                                }
                                            }
                                        });
                                    }}
                                >
                                    <i className="pi pi-trash"/>
                                </Button>}
                                {props.mayEditProject &&
                                <Button
                                    type={'button'}
                                    className="p-button p-button-text m-1"
                                    style={{minWidth: '20px'}}
                                    onClick={() => {
                                        setFileManagerTarget('project-logo');
                                        setFileManagerVisible(true);
                                    }}
                                >
                                    <i className="pi pi-pencil"/>
                                </Button>}

                            </div>
                            <CustomImage
                                image={projectLogo}
                                maxHeight={'30%'}
                                showPlaceholder={true}
                            />
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <h4>{t('projM_picture')}</h4>
                                {projectPicture && props.mayEditProject &&
                                <Button
                                    type={'button'}
                                    className="p-button-danger p-button-text m-1"
                                    style={{minWidth: '20px'}}
                                    onClick={() => {
                                        CustomConfirmDialog({
                                            message: t('projM_deleteProjectPicture'),
                                            header: t('confirmation'),
                                            translation: t,
                                            onConfirm: () => {
                                                if (props.project) {
                                                    const pictureId = getValues('picture_id');
                                                    if (pictureId) {
                                                        deleteProjectImage(props.project.id, '', pictureId).then(result => {
                                                            if (result.error) {
                                                                showMessageOnError(t('error'), result.error);
                                                                if (props.onFinished)
                                                                    props.onFinished(null);
                                                            } else {
                                                                showMessageOnSuccess(t('success'), t('projM_projectPictureDeleted'));
                                                                if (props.onFinished)
                                                                    props.onFinished(props.project);
                                                            }
                                                        });
                                                    }
                                                }
                                            }
                                        });
                                    }}
                                >
                                    <i className="pi pi-trash"/>
                                </Button>}
                                {props.mayEditProject &&
                                <Button
                                    type={'button'}
                                    className="p-button p-button-text m-1"
                                    style={{minWidth: '20px'}}
                                    onClick={() => {
                                        setFileManagerTarget('project-picture');
                                        setFileManagerVisible(true);
                                    }}
                                >
                                    <i className="pi pi-pencil"/>
                                </Button>}
                            </div>
                            <CustomImage
                                image={projectPicture}
                                maxHeight={'50%'}
                                showPlaceholder={true}
                            />
                        </>}
                    </div>
                </div>
            </div>
            {
                <CustomDialog
                    header={t('dialogs_mediaLibrary_header')}
                    visible={fileManagerVisible}
                    onHide={() => setFileManagerVisible(false)}
                    onCancel={() => setFileManagerVisible(false)}
                    onClick={() => changeImage(selectedImageId)}
                    submitText={t('save')}
                >
                    <FileManagement project_id={props.project ? props.project.id : null}
                                    onSelect={(id: string, sizeInBytes: number) => {
                                        if (fileManagerTarget === 'project-logo') {
                                            if (sizeInBytes > 100000) {
                                                setSelectedImageId(undefined);
                                                return showMessageOnError(t('error'), t('error_projectLogoExceededSizeError'));
                                            }
                                        }

                                        setSelectedImageId(id);
                                    }}/>
                </CustomDialog>
            }
        </form>
    );

};

export default ProjectEditForm;
