/*
 * SystemSettingForm.tsx
 * Author: lnappenfeld
 * Date: 18.08.2023
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import Input from '../global/Input';
import {Nullable} from '../functions/Global';
import {createSystemSetting, SystemSetting, updateSystemSetting} from '../functions/System';

type Props = {
    onFinished: (success: boolean) => void,
    systemSetting: Nullable<SystemSetting>;
}

export const SystemSettingForm = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common', 'users', 'input', 'project']);

    const defaultValues = {
        'key': props.systemSetting ? props.systemSetting.key : null,
        'value': props.systemSetting ? props.systemSetting.value : null
    };

    const {control, formState: {errors}, handleSubmit} = useForm({defaultValues});

    const getFormErrorMessage = (name: string) => {
        // @ts-ignore
        return errors[name] && <small className="p-error custom">{errors[name].message}</small>;
    };

    const saveData = (data: any) => {
        if (props.systemSetting) {
            updateSystemSetting(data).then(result => {
                if (result.error) {
                    showMessageOnError(t('error'), result.error);
                } else {
                    showMessageOnSuccess(t('success'), t('sysM_toasts_systemSettingUpdated'));
                    props.onFinished(true);
                }
            });
        } else {
            createSystemSetting(data).then(result => {
                if (result.error) {
                    showMessageOnError(t('error'), result.error);
                } else {
                    showMessageOnSuccess(t('success'), t('sysM_toasts_systemSettingCreated'));
                    props.onFinished(true);
                }
            });
        }
    };

    return (
        <form id="systemSettingForm" onSubmit={handleSubmit(saveData)}>
            <div className={'card'}>
                <Input
                    edit={true} label={t('sysM_attribs_key')} name={'key'} type={'text'}
                    disabled={!!props.systemSetting}
                    validationControl={control} validationErrorMessage={getFormErrorMessage}
                    validationRules={{required: t('sysM_attribs_key') + ' ' + t('input_required')}}
                />
                <Input
                    edit={true} label={t('sysM_attribs_value')} name={'value'} type={'text'}
                    validationControl={control} validationErrorMessage={getFormErrorMessage}
                    validationRules={{required: t('sysM_attribs_value') + ' ' + t('input_required')}}
                />
            </div>
        </form>
    );
};