import React, {useEffect, useRef, useState} from 'react';
import {classNames} from 'primereact/utils';
import {Tooltip} from 'primereact/tooltip';

type Props = {
    id: string,
    value: number,
    info: string,
    light: boolean,
    horizontal: boolean,
    scale?: number,
    inactive?: boolean
}

const StatusLight = (props: Props) => {
    const [horizontal, setHorizontal] = useState<boolean>(props.horizontal);
    const [backgroundColor, setBackgroundColor] = useState<string>('black');

    const [style, setStyle] = useState<any>({
        wrapper: {
            zIndex: 1,
            position: 'relative'
        },
        statusLight: {
            position: 'relative',
            borderRadius: '50px',
            background: 'linear-gradient(0deg, rgba(145, 145, 145, 1) 0%, rgba(233, 233, 233, 1) 100%)',
            padding: '3px',
            maxWidth: '80px',
            display: 'inline-block',
        },
        scale: {
            transform: props.scale ? `scale(${props.scale})` : undefined
        },
        signal: {
            position: 'relative',
            background: 'radial-gradient(circle, rgba(255,255,255,1) 15%, rgba(200,200,200,1) 50%, rgba(150,150,150,1) 100%)',
            width: '14px',
            height: '14px',
            boxShadow: 'inset 0 0 2px #00000044',
            borderRadius: '50px',
            // border: 1px solid black;
            margin: '2px',
            // zIndex: '10'
        },
        overlay: {
            position: 'absolute',
            top: '0',
            left: '0',
            opacity: '0.5',
            height: '100%',
            width: '100%',
            margin: '0',
            zIndex: 10,
        },
        active: {
            background: 'radial-gradient(circle, rgba(255,255,255,1) 10%, rgba(180,180,180,1) 70%, rgba(0,0,0,1) 100%)',
            zIndex: '10'
        },
    });

    useEffect(() => {
        setBackgroundColor(props.inactive ? 'white' : 'black');
    }, [props.inactive]);

    const isActive = (nr: number) => {
        let result = false;
        switch (props.value) {
            case 1:
                result = nr === 0;
                break;
            case 2:
                result = nr === 1;
                break;
            case 3:
                result = nr === 2;
                break;
        }

        return result;
    };

    return (
        <i className={classNames(props.horizontal ? 'flex' : '', 'status-light')} style={style.wrapper}
           data-pr-tooltip={props.info} data-pr-position="right">
            <i id={props.id} className={horizontal ? 'flex' : ''}
               style={{...style.statusLight, ...style.scale, ...(horizontal ? {maxWidth: '81px'} : {maxWidth: '30px'})}}>
                <div
                    style={{...style.statusLight, ...style.overlay, ...(backgroundColor ? {background: backgroundColor} : {}), ...(horizontal ? {maxWidth: '81px'} : {maxWidth: '23px'})}}></div>
                <div style={{...style.signal, ...(isActive(0) ? style.active : {})}}>
                    <div style={{
                        ...style.signal, ...style.overlay, ...(isActive(0) ? {
                            background: '#fa3746',
                            opacity: props.light ? '0.4' : '1'
                        } : {})
                    }}></div>
                </div>
                <div style={{...style.signal, ...(isActive(1) ? style.active : {})}}>
                    <div style={{
                        ...style.signal, ...style.overlay, ...(isActive(1) ? {
                            background: '#ffeb00',
                            opacity: props.light ? '0.4' : '1'
                        } : {})
                    }}></div>
                </div>
                <div style={{...style.signal, ...(isActive(2) ? style.active : {})}}>
                    <div style={{
                        ...style.signal, ...style.overlay, ...(isActive(2) ? {
                            background: '#8cf000',
                            opacity: props.light ? '0.4' : '1'
                        } : {})
                    }}></div>
                </div>
                <Tooltip target={'#' + props.id}/>
            </i>
        </i>);
};

export default StatusLight;
