/*
 * UsersSelectList.tsx
 * Author: lnappenfeld
 * Date: 07.03.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import keycloakfetch from '../../functions/keycloakfetch';
import settings from '../../config/settings';
import Input from '../global/Input';

type Props = {
    type: string,
    label: string,
    projectId: string,
    value?: string;
    setValue?: (e: any) => void,
    onChange?: (e: any) => void,
    name?: string,
    validationControl?: any,
    validationErrorMessage?: any,
    validationRules?: any,
}
const UsersSelectList = (props: Props): JSX.Element => {
    const [list, setList] = useState([]);

    useEffect(() => {
        switch (props.type) {
            case 'project':
                getProjectUsers();
                break;
            case 'projectAndGlobalUsers':
                // Alle Projektbenutzer und globale Benutzer
                // z.B.: zum Anlegen von Aktionen
                getProjectUsersAndGlobalUsers();
                break;
            case 'noneProjectWithoutAdmins':
                // Alle Benutzer (auch globale Benutzer, außer Admins (permission_type = 0)) die nicht in dem Projekt sind
                // z.B.: um ein Benutzer zu einem Projekt hinzufügen zu können (Button: AddUser)
                getNoneProjectUsers(props.projectId);
                break;
            case 'noneGlobalUsers':
                // Alle Nicht-globalen Benutzer außerhalb eines Projektes
                // z.B.: um nicht-globale Benutzer zum globalen Benutzer zu machen (Button: AddAdmin)
                getNoneProjectUsers('none');
                break;
            default:
                getProjectUsers();
        }
    }, []);

    const getProjectUsers = () => {
        keycloakfetch.get(settings.apiUrl + '/getUsers?project_id=' + props.projectId).then(result => {
            const tmp: any = [];
            result.forEach((key: any) => {
                tmp.push({
                    value: key.id,
                    label: key.username,
                });
            });
            setList(tmp);
        });
    };

    const getProjectUsersAndGlobalUsers = () => {
        keycloakfetch.get(settings.apiUrl + '/getUsers?project_id=' + props.projectId + '&add_admins=true').then(result => {
            const tmp: any = [];
            result.forEach((key: any) => {
                tmp.push({
                    value: key.id,
                    label: key.username,
                });
            });
            setList(tmp);
        });
    };

    const getNoneProjectUsers = (projectId: string) => {
        keycloakfetch.get(settings.apiUrl + '/getNoneProjectUsers?project_id=' + projectId).then(result => {
            const tmp: any = [];
            result.forEach((key: any) => {
                tmp.push({
                    value: key.id,
                    label: key.username + ((key.last_name === '') ? '' : (' / ' +key.first_name + ' ' + key.last_name)),
                });
            });
            setList(tmp);
        });
    };

    return (
        <Input
            name={props.name ? props.name : 'user_id'}
            edit={true}
            label={props.label}
            value={props.value}
            dropdownOptions={list}
            scrollHeight={'500px'}
            type='dropdown'
            onChange={(e: any) => {
                if (props.setValue)
                    props.setValue(e.value);
                if (props.onChange)
                    props.onChange(e);
            }}
            validationControl={props.validationControl}
            validationErrorMessage={props.validationErrorMessage}
            validationRules={props.validationRules}
        />
    );
};
export default UsersSelectList;
