import React from 'react';
import {getColumnField, getFilterField, Nullable} from "./Global";
import {filterTriStateCheckBox} from "../global/filter";
import {ColumnObject, ColumnObjectType} from "../global/CustomDataTable";
import {Field} from "./Units";

export type Inspection = {
    id: string,
    name: string,
    element_id: string,
    element_name: string,
    elements: string [],
    inspection_file_id: null,
    inspection_type_name: string,
    timestamp: Nullable<Date>,
    project_id: string,
    mat_id: number,
    auf_tuev: number,
    vgn_tuev: number,
    aanr_id: string,
    erfassung_timestamp: number,
    dispo_timestamp: number,
    zuordnung_timestamp: number,
    einzelpruefungnachweis_timestamp: number,
    erfassung_user: any,
    dispo_user: any,
    zuordnung_user: any,
    einzelpruefungnachweis_user: any,
    expandableElement: any,
    has_children: boolean
}

export type InspectionElement = {
    element_id: string
    element_name: string,
    is_active: boolean,
    element_type_name: string,
    element_subtype_name: string
}

export type InspectionTaskType = {
    inspection_task_type_id: number,
    name: string,
    project_type_id: number,
    fields: Field[]
}

export type InspectionTask = {
    id: string,
    inspection_id: string,
    element_id: string,
    assigned_to: any,
    due_time: string,
    created_at: string,
    created_by: string,
    set_status_at: string,
    set_status_by: string,
    due_at: string,
    due_by: string,
    inspection_task_type_id: number,
    project_id: string,
    is_done_percent: number,
    element_name: string,
    inspection_task_type_name: string,
    has_passed: boolean,
    fields?: any[],
    newTask?: boolean
}

export const getInspectionColumns = (t: any, values: any[]) => {
    const filterCheckboxIsVisible = (options: any) => {
        return filterTriStateCheckBox(options, t('showAll'), t('yes'), t('no'));
    };

    const result: ColumnObject[] = [];
    result.push(getColumnField(t, 'inspection_status', 'inspection_columns_', true, {
        type: ColumnObjectType.StatusLights
    }));

    if (values && values.length) {
        let dateRegex = /^(?:\d{4})-(?:\d{2})-(?:\d{2})T(?:\d{2}):(?:\d{2}):(?:\d{2}(?:\.\d*)?)(?:(?:-(?:\d{2}):(?:\d{2})|Z)?)$/;

        for (let i = 0; i < Object.keys(values[0]).length; i++) {
            let key = Object.keys(values[0])[i];
            let value = values[0][key];
            if (!key.endsWith('id') && key !== 'id' && key !== 'expandableElement') {

                let options = {};

                if (value == null) {
                    for (let j = 0; j < values.length; j++) {
                        if (values[j][key]) {
                            value = values[j][key];
                            break;
                        }
                    }
                }


                if (value != null && value.toString().match(dateRegex)) {
                    options = {type: ColumnObjectType.Date};
                }

                if (value != null && (value.toString() === 'false' || value.toString() === 'true')) {
                    options = {type: ColumnObjectType.Boolean};
                }

                result.push(getColumnField(t, key, 'inspection_columns_', true, options));
            }
        }
    }

    // result.push(getColumnField(t, 'name', 'inspection_columns_', true));
    // result.push(getColumnField(t, 'element_name', 'inspection_columns_', true));
    // result.push(getColumnField(t, 'inspection_file_id', 'inspection_columns_', true));
    // result.push(getColumnField(t, 'inspection_type_name', 'inspection_columns_', true));
    // result.push(getColumnField(t, 'timestamp', 'inspection_columns_', true, {type: ColumnObjectType.Date}));
    // result.push(getColumnField(t, 'mat_id', 'inspection_columns_', true));
    // result.push(getColumnField(t, 'auf_tuev', 'inspection_columns_', true));
    // result.push(getColumnField(t, 'vgn_tuev', 'inspection_columns_', true));
    // result.push(getColumnField(t, 'aanr_id', 'inspection_columns_', true));
    // result.push(getColumnField(t, 'erfassung_timestamp', 'inspection_columns_', true, {type: ColumnObjectType.Date}));
    // result.push(getColumnField(t, 'dispo_timestamp', 'inspection_columns_', true, {type: ColumnObjectType.Date}));
    // result.push(getColumnField(t, 'zuordnung_timestamp', 'inspection_columns_', true, {type: ColumnObjectType.Date}));
    // result.push(getColumnField(t, 'einzelpruefungnachweis_timestamp', 'inspection_columns_', true, {type: ColumnObjectType.Date}));
    // result.push(getColumnField(t, 'erfassung_user', 'inspection_columns_', true));
    // result.push(getColumnField(t, 'dispo_user', 'inspection_columns_', true));
    // result.push(getColumnField(t, 'zuordnung_user', 'inspection_columns_', true));
    // result.push(getColumnField(t, 'einzelpruefungnachweis_user', 'inspection_columns_', true));


    return result;
};

export const getInspectionFilters = () => {
    return {
        ...getFilterField('name'),
        ...getFilterField('element_name'),
        ...getFilterField('inspection_file_id'),
        ...getFilterField('inspection_type_name'),
        ...getFilterField('timestamp'),
        ...getFilterField('mat_id'),
        ...getFilterField('auf_tuev'),
        ...getFilterField('vgn_tuev'),
        ...getFilterField('aanr_id'),
        ...getFilterField('erfassung_timestamp'),
        ...getFilterField('dispo_timestamp'),
        ...getFilterField('zuordnung_timestamp'),
        ...getFilterField('einzelpruefungnachweis_timestamp'),
        ...getFilterField('erfassung_user'),
        ...getFilterField('dispo_user'),
        ...getFilterField('zuordnung_user'),
        ...getFilterField('einzelpruefungnachweis_user'),
    };
};

export const getInspectionElementColumns = (t: any) => {
    const filterCheckboxIsVisible = (options: any) => {
        return filterTriStateCheckBox(options, t('showAll'), t('yes'), t('no'));
    };

    const result: ColumnObject[] = [];

    result.push(getColumnField(t, 'element_name', 'inspection_columns_', true));
    result.push(getColumnField(t, 'is_active', 'inspection_columns_', true));
    result.push(getColumnField(t, 'element_type_name', 'inspection_columns_', true));
    result.push(getColumnField(t, 'element_subtype_name', 'inspection_columns_', true));
    return result;
};

export const getInspectionElementFilters = () => {
    return {
        ...getFilterField('element_name'),
        ...getFilterField('is_active'),
        ...getFilterField('element_type_name'),
        ...getFilterField('element_subtype_name'),
    };
};
