import React from 'react';
import {confirmDialog} from 'primereact/confirmdialog';
import {Button} from 'primereact/button';

export function showReloadPageDialog(t: any, message: string){
    return (
        confirmDialog({
            message: message,
            header: t('reloadPage_header'),
            icon: 'pi pi-exclamation-triangle',
            className: 'hide-x',
            footer: (
                <React.Fragment>
                    <Button
                        label={'Ok'} icon="pi pi-check" className="p-button-text"
                        onClick={() => {window.location.reload();}}
                    />
                </React.Fragment>
            )
        })
    );
}