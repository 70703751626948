import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from "react-i18next";
import CustomDataTable from "../global/CustomDataTable";
import {getInspectionColumns, getInspectionFilters, Inspection} from "../functions/Inspection";
import {useGetInspectionsQuery} from "../../redux/rtk/injectedInspectionApi";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import {InspectionData} from "./InspectionData";
import exp from "node:constants";

type Props = {
    project: any
}

export const InspectionOverview = (props: Props) => {
    const {t} = useTranslation(['common']);
    const navigate = useNavigate();

    const [values, setValues] = useState<any>(undefined);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [expandedRows, setExpandedRows] = useState<any[]>([]);
    const [selectedInspectionId, setSelectedInspectionId] = useState<string>('');

    const inspectionQuery = useGetInspectionsQuery({projectId: props.project.id});

    useEffect(() => {
        const hash = window.location.hash.split('_');

        if (hash.length === 2) {
            setSelectedInspectionId(hash[1]);
        }
    }, []);

    useEffect(() => {
        if (inspectionQuery && inspectionQuery.data) {
            let temp = [...inspectionQuery.data];
            for (let i = 0; i < temp.length; i++) {
                let item = {...temp[i]};

                item['expandableElement'] = <InspectionData project={props.project} inspectionId={item.id}/>;

                temp[i] = item;
            }

            setValues(temp);

            // if (temp.length) {
            //     setExpandedRows([temp[2]])
            // }
            setDataLoaded(true);
        }
    }, [inspectionQuery])

    useEffect(() => {
        setDataLoaded(values != null);

        if (values && selectedInspectionId) {
            let expandedValue = values.find((item: any) => item.id === selectedInspectionId);
            if (expandedValue) {
                setExpandedRows([expandedValue]);
            }
        }
    }, [values]);


    const onRowExpand = useCallback((e) => {
        setExpandedRows([e.data]);
        window.location.hash = window.location.hash.split('_')[0] + '_' + e.data.id;
    }, []);

    const onRowCollapse = useCallback((e) => {
        window.location.hash = window.location.hash.split('_')[0];
    }, []);

    const expandedRowsLength = useMemo(() => {
        return expandedRows.length > 0
    }, [expandedRows]);

    const customButtons = useMemo(() => {
        return [{
            icons: [{key: null, value: null, icon: <FontAwesomeIcon icon={faList}/>}],
            tooltip: t('inspection_show_tree'),
            tooltipOptions: {position: 'top'},
            onClick: (data: any) => {
                const url = `/project/${props.project.id}/#unittree_${data['element_id']}`;

                navigate(url);
                // window.location.reload();
            },
            permitted: true,
        }]
    }, [])

    return (<div className={'h-full'}>
        <CustomDataTable
            id={'inspectinos_datatable'}
            columns={getInspectionColumns(t, values)}
            filters={getInspectionFilters()}
            editable={true}
            headerTitle={t('inspections_overview')}
            displayColumnFilter={true}
            dataloaded={dataLoaded}
            sortable={true}
            value={values}
            expandable={true}
            expandedRows={expandedRows}
            scrollToFirstExpanded={expandedRowsLength}
            scrollToFirstExpandedTimeout={500}
            onRowExpand={onRowExpand}
            onRowCollapse={onRowCollapse}
            // ableToUpdate={true}
            onEditClick={() => {
            }}
            customButtons={customButtons}/>

    </div>)
}


