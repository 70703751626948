/*
 * BaseLayerSelectList.tsx
 * Author: lnappenfeld
 * Date: 25.01.2023
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import Input from '../global/Input';

type Props = {
    label: string,
    baseLayers?: any,
    value?: number,
    setValue?: (e: any) => void,
    onChange?: (e: any) => void,
    name?: string,
    disabled?: boolean,
    validationControl?: any,
    validationErrorMessage?: (name: string) => void,
    validationRules?: any,
}

const BaseLayerSelectList = (props: Props): JSX.Element => {

    const options = [
        {value: 'atkis', label: 'Atkis'},
        {value: 'atkis_grey', label: 'Atkis (Grey)'},
        {value: 'atkis_light', label: 'Atkis (Light)'},
        {value: 'gm_layer_maps', label: 'Google (Maps)'},
        {value: 'gm_layer_sat', label: 'Google (Satellite)'},
        {value: 'openstreetmap', label: 'Open Street Map'},
        {value: 'osmger', label: 'Open Street Map (German)'}
    ];

    const [layers, setLayers] = useState(props.baseLayers ? props.baseLayers : options);

    useEffect(() => {
        if (props.baseLayers) {
            setLayers(props.baseLayers);
        }
    }, [props.baseLayers]);

    return (
        <Input
            name={props.name ? props.name : 'base_layer'}
            disabled={typeof props.disabled === 'boolean' ? props.disabled : false}
            edit={true}
            label={props.label}
            value={props.value}
            dropdownOptions={layers}
            type={'dropdown'}
            onChange={(e: any) => {
                const option = options.filter((x: any) => {
                    return x.value === e.value;
                })[0];
                if (props.setValue) props.setValue(option);
                if (props.onChange) props.onChange(option);
            }}
            validationControl={props.validationControl}
            validationErrorMessage={props.validationErrorMessage}
            validationRules={props.validationRules}
        />
    );

};

export default BaseLayerSelectList;

