import {FunctionComponent, useContext, useEffect} from 'react';
import MapContext from '../map/MapContext';
import {Vector as VectorSource} from 'ol/source';
import VectorLayer from 'ol/layer/Vector';
import {Fill, Icon, Stroke, Style, Text} from 'ol/style';
import {useAppDispatch} from '../../../redux/hooks';
import {addLayerToCounterArray, setLayerArray} from '../../../redux/slices/GeomonitoringSlice';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {pinImageStyle, pointImageStyle} from "../../functions/Geo";
import geoSettings from "../../../config/geoSettings";


type overlayProps = {projectId:number};
const OverlayLayer: FunctionComponent<overlayProps> = (projectId) => {
    // @ts-ignore
    const {map} = useContext(MapContext);
    const dispatch = useAppDispatch();


    useEffect(() => {
        if (!map || Object.keys(map).length === 0) return;
        const source = new VectorSource({});

        const overlayLayer = new VectorLayer({
            source:source,
            style:pinImageStyle,
            zIndex:geoSettings.poiLayerKey+1
        });
        overlayLayer.set('id', 'overlayLayer');
        overlayLayer.set('toogleHidden',true);
        map.addLayer(overlayLayer);
        dispatch(setLayerArray({name:'overlayLayer',mainLayerId:'overlayLayer', id:'overlayLayer', type:'vector', toogleHidden:true, visible:true}));
        dispatch(addLayerToCounterArray(projectId.projectId));
        //    console.log('add overlaylayers')
        return () => {
            if (map) {
                map.removeLayer(overlayLayer);
            }
        };
    }, [map]);

    return null;
};

export default OverlayLayer;
