import {FunctionComponent, useContext, useEffect, useRef, useState} from 'react';
import MapContext from '../map/MapContext';
import {Vector as VectorSource} from 'ol/source';
import VectorLayer from 'ol/layer/Vector';
import {useTranslation} from 'react-i18next';
import {useAppDispatch} from '../../../redux/hooks';
import geoSettings from '../../../config/geoSettings';
import { useLazyGetPolylineDataQuery} from '../../../redux/rtk/injectedGeoApi';
import {
    createFeatures,
    createPoiFeatures,
    createPolylineFeatures,
    getPolyLineBottomStyle,
    getPolyLineCornerStyle,
    getPolyLinePointsStyle,
    getPolyLineTopStyle,
    getPolyLineUpperStyle, getScaleValue
} from '../../functions/Geo';
import {addLayerToCounterArray, setLayerArray} from '../../../redux/slices/GeomonitoringSlice';
import {getVectorLayerObject, scaleElem} from "../../../functions/functionsOpenlayer";

type poiProps = {
    projectId: number,
    itemId?: any,
    zoomMap: number,
    projectTypeId:number
}

const PolylineLayer: FunctionComponent<poiProps> = ({projectId, itemId, zoomMap,projectTypeId}) => {
    // @ts-ignore
    const {map, setMap} = useContext(MapContext);
    const [zoom, setZoom] = useState(zoomMap);
    const refZoom = useRef(zoom);
    const {t} = useTranslation(['geomonitoring']);
    const dispatch = useAppDispatch();
    const [vLayer, setVlayer] = useState(null);
    const [triggerPolyline, {data: resultPolylineData, isPolylineLoading, isPolylineError, isSuccess}] = useLazyGetPolylineDataQuery();

    const [nSource, setNSource] = useState({
        removeFeature(feature: any) {
        },
        addFeature(mp: any) {
        },
        clear() {
        },
        addFeatures(features: any[]) {
        }
    });
    const refVLayer = useRef(vLayer);
    const refNSource = useRef(nSource);

    const getLineStyles = (feature: any, resolution: number) => {
        const scaleValue=getScaleValue(map.getView().getZoom());
        return [getPolyLineBottomStyle(scaleValue),getPolyLineUpperStyle(scaleValue),getPolyLineTopStyle(scaleValue), getPolyLinePointsStyle(scaleValue, feature)];
    };

    const createLayer = async (map: any) => {
        const layerId = 'polylineLayer';
        const source =  new VectorSource({});
        const vectorLayer = getVectorLayerObject(source, layerId, true,'vector',geoSettings.poiLayerKey,getLineStyles );
        map.addLayer(vectorLayer);
        polylineDispatches(layerId, true);
        setNSource(source);
        // @ts-ignore
        setVlayer(vectorLayer);
        setMap(map);
    };

    const polylineDispatches = (layerId: string, toogleVisibility: boolean) => {
        dispatch(setLayerArray({
            name: layerId,
            mainLayerId: layerId,
            id: layerId,
            type: 'vector',
            parentLayer: null,
            toogleHidden: toogleVisibility,
            visible: true,
            styleId: null,
            simpleLayer: true,
            layerParts: null,
            layerConfig: null,
            styles: null,
            title: 'Polyline'
        }));
        dispatch(addLayerToCounterArray(projectId));
    };


    useEffect(() => {
        // @ts-ignore
        if (vLayer !== null) {
            refVLayer.current = vLayer;
            refNSource.current=nSource;

            if (isSuccess ) {
                if (resultPolylineData && resultPolylineData.length >0) {
                    createPolylineFeatures(resultPolylineData).then(result => {
                        if (result.features.length > 0) {
                            if (itemId&&itemId!==null) {
                                const polyline = result.features[0];
                                refNSource.current.addFeature(polyline);
                            } else {
                                refNSource.current.addFeatures(result.features);
                                // @ts-ignore

                                const zoomMap=map.getView().getZoom();
                                map.getView().setZoom(zoomMap + 0.1);
                            }
                        }
                    });
                    createPoiFeatures(resultPolylineData, null).then(result => {

                        if (result.features.length > 0) {
                            refNSource.current.addFeatures(result.features);
                        }
                    });
                }
            }    }
    }, [isSuccess, resultPolylineData,vLayer]);


    useEffect(() => {
        if (!map || Object.keys(map).length === 0) return;
        // eslint-disable-next-line no-console

        createLayer(map).then(r => console.log('Polyline Layer erstellt'));

    }, [map]);


    useEffect(() => {
        if (projectTypeId===3) {
            triggerPolyline({project_id:projectId});
        }
    }, []);
    return null;
};

export default PolylineLayer;
