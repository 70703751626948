import {createRef} from 'react';

let customWaitAnimation: any = createRef();

export const updateWaitAnimationRef = (ref: any) => {
    customWaitAnimation = ref;
    console.log('>>>>waitAnimation updated<<<< Not null: ', customWaitAnimation);
};

export function showWaitAnimation() {
    if (customWaitAnimation.current){
        //console.log("+++++++++++++++show Animation", waitAnimation);
        customWaitAnimation.current.style.display = 'block';
    }

}
export function hideWaitAnimation() {
    if (customWaitAnimation.current){
        //console.log("---------------hide Animation", waitAnimation);
        customWaitAnimation.current.style.display = 'none';
    }

}
