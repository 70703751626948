import {baseApi} from './baseApi';

export const injectedPersonApi = baseApi.injectEndpoints({
    addTagTypes: ['Person'],
    endpoints: (builder) => ({
        getPerson: builder.query({
            query: (args) => {
                const {projectId, personId, commonData, mapping} = args;

                let url = `getPerson?project_id=${projectId}&common_data=${commonData}`;

                if (personId) {
                    url += `&person_id=${personId}`;
                }

                if (mapping) {
                    let key = Object.keys(mapping)[0];
                    let value = mapping[key];
                    url += `&${key}=${value}`;
                }

                return {url: url};
            },
            providesTags: ['Person']
        }),
        createPerson: builder.mutation({
            query: (user) => ({
                url: 'createPerson',
                method: 'POST',
                body: user,
            }),
            invalidatesTags: ['Person', 'Parcel', 'LandRegister']
        }),
        updatePerson: builder.mutation({
            query: (user) => ({
                url: 'updatePerson',
                method: 'PUT',
                body: user,
            }),
            invalidatesTags: ['Person', 'Parcel', 'LandRegister']
        }),
        deletePerson: builder.mutation({
            query: (user) => ({
                url: 'deletePerson',
                method: 'DELETE',
                body: user,
            }),
            invalidatesTags: ['Person', 'Parcel', 'LandRegister']
        }),
        checkImportPersons: builder.query({
            query: (args) => {
                const {projectId} = args;
                return {url: `checkImportData?project_id=${projectId}`};
            },
            providesTags: ['Person']
        }),
        deleteImportData: builder.mutation({
            query: (user) => ({
                url: 'deleteImportData',
                method: 'DELETE',
                body: user,
            }),
            invalidatesTags: ['Person']
        }),
        updateDoInsertPerson: builder.mutation({
            query: (query) => ({
                url: 'updateDoInsertPerson',
                method: 'PUT',
                body: query,
            }),
            invalidatesTags: ['Person']
        }),
        transferImportData: builder.query({
            query: (args) => {
                const {projectId} = args;

                return {url: `transferImportData?project_id=${projectId}`};
            },
            providesTags: ['Person']
        }),
        refetchErroredQueries: builder.mutation({
            queryFn: () => ({data: null}),
            invalidatesTags: ['UNKNOWN_ERROR'],
        }),
        getProjectList: builder.query({
            query: (args) => {
                const {projectId, mapping} = args;

                let url = `getProjectList?project_id=${projectId}`;

                if (mapping) {
                    let key = Object.keys(mapping)[0];
                    let value = mapping[key];
                    url += `&${key}=${value}`;
                }

                return {url: url};
            },
            providesTags: ['Project']
        }),
        getOutlookContact: builder.mutation({
            // query: (query) => ({
            //     url: 'getOutlookContact',
            //     method: 'POST',
            //     body: query,
            // }),
            queryFn: async (query, api, extraOptions, baseQuery) => {
                const result = await baseQuery({
                    method: 'POST',
                    url: `getOutlookContact`,
                    responseHandler: ((response) => response.blob()),
                    body: query
                });
                var hiddenElement = document.createElement('a');
                var url = window.URL || window.webkitURL;
                var blob = url.createObjectURL(result.data);
                hiddenElement.href = blob;
                hiddenElement.target = '_blank';
                hiddenElement.download = `contacts.zip`;
                hiddenElement.click();
                return {data: null};
            },
            invalidatesTags: ['Person']
        }),
        getAnrede: builder.query({
            query: (args) => {
                return {url: 'getAnrede'};
            },
            providesTags: ['Person']
        }),
        getKontaktTypes: builder.query({
            query: (args) => {
                return {url: 'getKontaktTypes'};
            },
            providesTags: ['Person']
        }),
        getLegalTypes: builder.query({
            query: (args) => {
                return {url: 'getLegalTypes'};
            },
            providesTags: ['Person']
        }),
        getLegalFormTypes: builder.query({
            query: (args) => {
                return {url: 'getLegalFormTypes'};
            },
            providesTags: ['Person']
        }),
        getCommentTypes: builder.query({
            query: (args) => {
                let {type} = args;

                return {url: 'getCommentTypes?type=' + type};
            },
            providesTags: ['Person']
        }),
    }),
});

export const {
    useGetPersonQuery,
    useLazyGetPersonQuery,
    useCreatePersonMutation,
    useUpdatePersonMutation,
    useDeletePersonMutation,
    useCheckImportPersonsQuery,
    useDeleteImportDataMutation,
    useUpdateDoInsertPersonMutation,
    useTransferImportDataQuery,
    useGetProjectListQuery,
    useGetOutlookContactMutation,
    useGetAnredeQuery,
    useGetKontaktTypesQuery,
    useGetLegalTypesQuery,
    useGetLegalFormTypesQuery,
    useGetCommentTypesQuery,
} = injectedPersonApi;
