/*
 * RoleDialog.tsx
 * Author: lnappenfeld
 * Date: 21.02.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useState, useEffect} from 'react';
import Input from '../global/Input';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import RolesSelectList from './RolesSelectList';
import RolePermissionList from './RolePermissionList';
import {
    createRole,
    getPermissionTypes,
    Permission,
    Role,
    updateRole
} from '../functions/UserManagement';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import {showReloadPageDialog} from '../global/ReloadPageDialog';


type Nullable<T> = T | null;

type Props = {
    projectId: string,
    role: Nullable<Role>,
    onReady:any,
}


const RoleForm = (props: Props): JSX.Element => {
    const {t} = useTranslation(['common', 'roles', 'input']);
    // selected template role id
    const [templateRoleId, setTemplateRoleId] = useState<string>();
    // list with template roles
    const [templateRoles, setTemplateRoles] = useState<Role[]>([]);
    const [mounted, setMounted] = useState<boolean>(false);
    // role to edit itself, if template is unselected
    // const [role, setRole] = useState<Nullable<Role>>(props.role);
    const [allPermissions, setAllPermissions] = useState<Permission[]>([]);
    const [selectedPermissions, setSelectedPermissions] = useState<Permission[]>([]);

    const roleId = props.role ? props.role.id : null;

    const defaultValues = {
        name: props.role ? props.role.name : ''
    };

    const {control, formState: {errors}, handleSubmit, reset, getValues, setValue, watch} = useForm({defaultValues});

    const getFormErrorMessage = (name: string) => {
        // @ts-ignore
        return errors[name] && <small className="p-error custom">{errors[name].message}</small>
    };

    useEffect(() => {
        console.log("mount")
        getPermissionTypes( props.projectId, t).then(result => {
            setAllPermissions(result);
            // Get selected permissions for role dialog
            if (props.role) {
                const _selectedPermissions = result.filter(function (e:any) {
                    // @ts-ignore
                    return this.indexOf(e.key) >= 0;
                },
                props.role.permissions);
                console.log('_selectedPermissions_', selectedPermissions)
                setSelectedPermissions(_selectedPermissions);
            }
        });
        setMounted(true);
    }, []);

    // Fill dialog if template role was selected
    useEffect(() => {
        if (!mounted)  return;

        const templateRole = templateRoles.filter(role => role.id === templateRoleId);

        if (templateRole.length === 1) {
            // Set role name
            setValue('name', templateRole[0].name);
            // Get selected permissions
            // format: {key: permissionId, label: permissionName}
            const selectedPermissions = allPermissions.filter(function (e) {
                // @ts-ignore
                return this.indexOf(e.key) >= 0;
            },
            templateRole[0].permissions);

            // Set Permissions
            setSelectedPermissions(selectedPermissions);
        } else {
            setValue('name', '');
            setSelectedPermissions([])
        }
    }, [templateRoleId]);

    const saveRole = () => {
        const permissions = [] as number[];
        if (selectedPermissions) {
            selectedPermissions.forEach((item, index) => {
                permissions.push(item.key);
            });
        }

        if (roleId) {
            updateRole(props.projectId, roleId, getValues('name'), permissions).then(result => {
                if (!result.error) {
                    showMessageOnSuccess(t('success'), t('userMgmt_toasts_roleUpdated'));
                    // Prüfe ob die eigene Rolle bearbeitet wurde: dann lade die Seite neu
                    if (result.ownRoleChanged) {
                        showReloadPageDialog(t, t('reloadPage_msgOwnRoleChanged'));
                    }
                } else {
                    showMessageOnError(t('error'),result.error);
                }
                props.onReady();
            });

        } else {
            createRole(props.projectId, getValues('name'), permissions).then(result => {
                if (result.error)
                    showMessageOnError(t('error'),result.error)
                else
                    showMessageOnSuccess(t('success'), t('userMgmt_toasts_roleCreated'));
                props.onReady();
            });
        }
    };

    return (
        <div>
            <RolesSelectList
                type={'templates'}
                label={t('userM_createRoleDialog_template')}
                projectId={props.projectId}
                value={templateRoleId}
                setValue={setTemplateRoleId}
                setTemplateRoles={setTemplateRoles}
                showClear={true}
            />
            <form id='formDialog' onSubmit={handleSubmit(saveRole)}>
                <Input
                    edit={true}
                    label={t('name')}
                    name={'name'}
                    validationControl={control}
                    validationRules={{required: t('name') + ' ' + t('input_required')}}
                    validationErrorMessage={getFormErrorMessage}
                />
            </form>
            <h3>{t('permission')}</h3>
            <RolePermissionList
                allPermissions={allPermissions}
                selectedPermissions={selectedPermissions}
                setSelectedPermissions={setSelectedPermissions}
            />
        </div>
    );
};

export default RoleForm;
