import {baseApi} from './baseApi';

export const injectedProjectTypes = baseApi.injectEndpoints({
    addTagTypes: ['ProjectTypes'],
    endpoints: (builder) => ({
        getElementTypesGlobal: builder.query({
            query: (args) => {
                const {locale, projectTypeId} = args;

                const url = `getElementTypesGlobal?locale=${locale || 'de'}&projectTypeId=${projectTypeId}`;

                return {url: url};
            },
            providesTags: ['ProjectTypes']
        }),
        getProjectStyleTypes: builder.query({
            query: () => {
                const url = 'getProjectStyleTypes';

                return {url: url};
            },
            providesTags: ['ProjectTypes']
        }),
        getElementDataTypes: builder.query({
            query: () => {
                const url = 'getElementDataTypes';

                return {url: url};
            },
            providesTags: ['ProjectTypes']
        }),
        getElementObjectTypes: builder.query({
            query: () => {
                const url = 'getElementObjectTypes';

                return {url: url};
            },
            providesTags: ['ProjectTypes']
        }),
        getElementSubtypes: builder.query({
            query: (args) => {
                const {element_type_id} = args;

                const url = `getElementSubtypes?&element_type_id=${element_type_id}`;

                return {url: url};
            },
            providesTags: ['ProjectTypes']
        }),

        /* getElementSubtypes: builder.query({
            query: (args) => {
                const {element_type_id, locale} = args;

                const url = `getElementSubtypes?&element_type_id=${element_type_id}&locale=${locale || 'de'}`;

                return {url: url};
            },
            providesTags: ['ProjectTypes']
        }), */

        addProjectType: builder.mutation({
            query: (unit) => ({
                url: 'addProjectType',
                method: 'POST',
                body: unit,
            }),
            invalidatesTags: ['ProjectTypes']
        }),
        updateProjectType: builder.mutation({
            query: ({ id, name, style_type }) => ({
                url: 'updateProjectType',
                method: 'PUT',
                body: { id, name, style_type },
            }),
            invalidatesTags: ['ProjectTypes']
        }),
        addElementType: builder.mutation({
            query: (unit) => ({
                url: 'addElementType',
                method: 'POST',
                body: unit,
            }),
            invalidatesTags: ['ProjectTypes']
        }),
        addElementFieldType: builder.mutation({
            query: (unit) => ({
                url: 'addElementFieldType',
                method: 'POST',
                body: unit,
            }),
            invalidatesTags: ['ProjectTypes']
        }),
    }),
});

export const {
    useLazyGetElementTypesGlobalQuery,
    useLazyGetProjectStyleTypesQuery,
    useLazyGetElementDataTypesQuery,
    useLazyGetElementObjectTypesQuery,
    useLazyGetElementSubtypesQuery,
    useAddProjectTypeMutation,
    useUpdateProjectTypeMutation,
    useAddElementTypeMutation,
    useAddElementFieldTypeMutation,
} = injectedProjectTypes;
