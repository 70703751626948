import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
// internationalization support
import i18next from 'i18next';
import backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
// import locale from './translations/locale.json';
import App from './App';
import {BrowserRouter as Router} from 'react-router-dom';
import store from './redux/store';
import { Provider } from 'react-redux';
import settings from './config/settings';
// init i18next

/* function makeLocale(obj: any, deObj: any, enObj: any, esObj: any) {
    for (const key in obj) {
        if (typeof deObj[key] === 'undefined') {
            deObj[key] = {};
            enObj[key] = {};
            esObj[key] = {};
        }
        if (typeof obj[key]['de'] === 'undefined') {
            [deObj[key], enObj[key], esObj[key]] = makeLocale(obj[key], deObj[key], enObj[key], esObj[key]);
        } else {
            // @ts-ignore
            deObj[key] = obj[key]['de'];
            // @ts-ignore
            enObj[key] = obj[key]['en'];
            // @ts-ignore
            esObj[key] = obj[key]['es'];
        }
    }
    return [deObj, enObj, esObj];
}

const [de, en, es] = makeLocale(locale, {}, {}, {});*/


i18next
    .use(backend)
    .use(initReactI18next)
    .init({
        lng: navigator.language, // First user browser language
        fallbackLng: 'de',
        debug: false,
        interpolation: {
            escapeValue: false,
        },
        react: {
            useSuspense: false //   <---- this will do the magic
        },
        backend: {
            loadPath: settings.apiUrl+'/getLocaleFile?lang={{lng}}',

        },
        // resources: { de, en, es },
        // ns: ['common', 'roles'],
        saveMissing: true,
        missingKeyHandler: (language, namespace, key, fallbackValue) => {
            //  console.log('Fehlende Übersetzung:', key);
        },
    });

const BaseName = '/';
ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Router basename={BaseName}>
                <Suspense fallback={<div>...</div>}>
                    <App/>
                </Suspense>
            </Router>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
