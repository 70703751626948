/*
 * InspectionManagement.tsx
 * Author: fwunderlich
 * Date: 02.08.2024
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import LayoutMainContentTabMenu from "../components/global/LayoutMainContentTabMenu";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGlasses} from "@fortawesome/free-solid-svg-icons";
import ViewButtons from "../components/global/ViewButtons";
import {buttonAddNewPlant, makeButtonItem} from "../components/global/viewButtonSelection";
import {useTranslation} from "react-i18next";
import {InspectionOverview} from "../components/inspectionManagement/InspectionOverview";

type Props = {
    project: any
}

export const InspectionManagement = (props: Props) => {
    const {t} = useTranslation();

    const [viewButtonsRerender, setViewButtonsRerender] = useState<boolean>(true);

    const menuItems = [
        {
            id: 'inspectionoverview',
            label: t('inspections_overview'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faGlasses}/>,
            component: <InspectionOverview project={props.project}/>,
            visible: props.project !== null,
            // buttons: (
            //     // <ViewButtons title={''} items={[
            //     //     makeButtonItem(buttonAddNewPlant, t, () => {
            //     //         setShowNewUnit(true);
            //     //     }, true, false),
            //     // ]} rerender={viewButtonsRerender}
            //     // />
            // )
        }
    ];

    return (<div><LayoutMainContentTabMenu items={menuItems} activeIndex={0} onTabChange={() => {
        setViewButtonsRerender(!viewButtonsRerender);
    }}/></div>)
}
