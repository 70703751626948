import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import testTreeData from '../../data/test-tree.json';

// import '../../style/UnitExplorer.scss';
import Input from '../global/Input';
import {useTranslation} from 'react-i18next';
import {UnitExplorerPath} from './UnitExplorerPath';
import {UnitExplorerContent} from './UnitExplorerContent';
import {OverlayPanel} from 'primereact/overlaypanel';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {
    useAddElementMutation,
    useDeleteElementMutation,
    useGetElementTreeQuery,
    useGetElementTypesQuery,
    useGetObjectTypesQuery, useLazyGetFieldsQuery, useUpdateElementConnectionMutation, useUpdateElementMutation
} from '../../redux/rtk/injectedUnitsApi';
import {
    ElementTypeItem, Field, flattenObjectForPipeline,
    getElementType,
    getElementTypeIconClass,
    getElementTypeName, getUnitTreeItemById, setUnitField, setUnitTreeItemById,
    UnitItem
} from '../functions/Units';
import {UnitObjectInput} from './UnitObjectInput';
import {v4 as uuidv4} from 'uuid';
import {Splitter, SplitterPanel} from 'primereact/splitter';
import {getUserFromLocalStorage, Nullable, setUserSettings} from '../functions/Global';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import {resetElementChanges} from '../../redux/slices/ElementChangesSlice';
import {useAppDispatch, useAppSelector} from '../../redux/hooks';
import {CustomConfirmDialog} from '../global/CustomConfirmDialog';
import CustomDialog from '../global/CustomDialog';
import {confirmDialog} from 'primereact/confirmdialog';
import {setEquipmentArray} from '../../redux/slices/GeomonitoringSlice';
import {setGeoData} from '../../functions/functionsOpenlayer';
import {UnitExplorerSearch} from "./UnitExplorerSearch";
import {ProgressSpinner} from "primereact/progressspinner";
import {resetApiCalls} from "../../redux/slices/ApiCallsSlice";
import {Menu} from "primereact/menu";
import {InspectionTask} from "../functions/Inspection";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLinkSlash} from '@fortawesome/free-solid-svg-icons';
import {UnitExplorerTree} from "./UnitExplorerTree";

type Props = {
    project: any,
    item: Nullable<any[]>
}

export const UnitExplorer = (props: Props) => {
    const {t} = useTranslation(['common']);
    // console.log(props)
    const user = getUserFromLocalStorage();

    const elementChanges: any[] = useAppSelector((state: any) => state.elementChangesData.elementChanges);
    const dispatch = useAppDispatch();

    const [project, setProject] = useState<any>(props.project);

    const [triggerGetFields, resultGetFields, lastPromiseGetFields] = useLazyGetFieldsQuery();

    const [addElement, resultAddElement] = useAddElementMutation();
    const [updateElement, resultUpdateElement] = useUpdateElementMutation();
    const [updateElementConnection, resultUpdateElementConnection] = useUpdateElementConnectionMutation();
    const [deleteElement, resultDeleteElement] = useDeleteElementMutation();

    const treeRef: any = useRef(null);
    const menu: any = useRef(null);

    const [menuItems, setMenuItems] = useState<any[]>([]);

    const scrollRef = useRef<any>(null);

    const [canEdit, setCanEdit] = useState<boolean>(true);
    const [editMode, setEditMode] = useState<boolean>(true);

    const [unitTree, setUnitTree] = useState<any>(null);
    const [useHash, setUseHash] = useState<boolean>(true);
    const [activeItem, setActiveItem] = useState<any>(undefined);
    const [activeContentItem, setActiveContentItem] = useState<any>(undefined);
    const [tempActiveItem, setTempActiveItem] = useState<any>(undefined);
    const [scrollIntoView, setScrollIntoView] = useState<boolean>(false);
    const [activeFields, setActiveFields] = useState<Nullable<Field[]>>(null);
    const [activePath, setActivePath] = useState<any[]>([]);
    const [addedElementId, setAddedElementId] = useState<string>('');
    const [showInfo, setShowInfo] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState<string>('');

    const [isPipeline, setIsPipeline] = useState<boolean>(false);
    const [splitterSizes, setSplitterSizes] = useState<any>(undefined);
    const [showLoading, setShowLoading] = useState<boolean>(false);

    const geoDataProject = useMemo(() => {
        return setGeoData(props)
    }, [props, setGeoData]);

    const elementTreeQuery = useGetElementTreeQuery({projectId: props.project.id}, {skip: props.item != null});
    const elementTypesQuery = useGetElementTypesQuery({
        projectId: props.project.id,
        locale: user ? user.language_id : 'de'
    });

    useEffect(() => {
        const user = getUserFromLocalStorage();

        if (user) {
            if (user.settings && user.settings[props.project.id]) {
                const splitter = user.settings[props.project.id].unit_tree_splitter;

                if (splitter) {
                    setSplitterSizes(JSON.parse(splitter));
                }
            }
        }

        _setMenuItems();
    }, []);

    useEffect(() => {
        _setMenuItems()
    }, [editMode]);

    useEffect(() => {
        setProject(props.project);
    }, [props.project]);

    // useEffect(() => {
    //     console.log('data to save', elementChanges);
    // }, [elementChanges]);

    useEffect(() => {
        if (project) {
            setIsPipeline(project.style_type === 'polyline');
        }
    }, [project]);

    useEffect(() => {
        //  console.log('aF', activeFields);
    }, [activeFields]);

    useEffect(() => {
        if (unitTree) {
            if (useHash) {
                const hash = window.location.hash.split('_');

                if (hash.length) {
                    getUnitByIdSetActive(hash[1], true);
                }

                if (props.item == null) {
                    setUseHash(false);
                }
            }

            if (addedElementId !== '') {
                getUnitByIdSetActive(addedElementId, false);
            }
        }
    }, [unitTree, addedElementId]);

    useEffect(() => {
        if (elementTreeQuery.data) {
            // if (isPipeline) {
            //     const temp = JSON.parse(JSON.stringify(elementTreeQuery.data));
            //
            //     const flat = [];
            //     for (let i = 0; i < temp.length; i++) {
            //         const item = temp[i];
            //         flat.push(flattenObjectForPipeline(item));
            //     }
            //     console.log('flat', flat);
            //     setUnitTree(flat);
            // } else {
            const temp = JSON.parse(JSON.stringify(elementTreeQuery.data));
            console.log(temp);
            for (let i = 0; i < temp.length; i++) {
                addPath(temp[i], []);
            }

            if (unitTree && unitTree.length) {
                setUnitTree((prevState: any) => {
                    let tempState: any[] = [];

                    if (prevState) {
                        tempState = JSON.parse(JSON.stringify(prevState));
                    }

                    if (temp.length === tempState.length) {
                        for (let i = 0; i < temp.length; i++) {
                            resetExpanded(temp[i], tempState[i]);
                        }
                    }

                    tempState = temp;
                    return tempState;
                });
            } else {
                setUnitTree(temp);
            }
            // }
        }
    }, [elementTreeQuery, isPipeline]);

    const resetExpanded = (newItem: any, oldItem: any) => {
        newItem.expanded = oldItem.expanded;
        if (newItem.children && oldItem.children && newItem.children.length === oldItem.children.length) {
            for (let i = 0; i < newItem.children.length; i++) {
                resetExpanded(newItem.children[i], oldItem.children[i]);
            }
        }
    };

    useEffect(() => {
        dispatch(resetApiCalls);
        if (props.item) {
            const temp = JSON.parse(JSON.stringify(props.item));

            for (let i = 0; i < temp.length; i++) {
                addPath(temp[i], []);
            }

            setUnitTree(temp);
        }
    }, [JSON.stringify(props.item)]);

    useEffect(() => {
        if (activeItem) {
            if (activeItem.parent) {
                setActivePath([...activeItem.parent, {id: activeItem.id, name: activeItem.name}]);

                setUnitTree((prevState: any) => {
                    if (prevState) {
                        const temp = JSON.parse(JSON.stringify(prevState));

                        for (let i = 0; i < activeItem.parent.length; i++) {
                            const parent = activeItem.parent[i];

                            for (let j = 0; j < temp.length; j++) {

                                const tempItem = _getUnitById(parent.id, temp[j]);

                                if (tempItem) {
                                    tempItem.expanded = true;
                                }
                            }
                        }

                        return temp;
                    }
                });
            }

            if (props.item) {
                const elementType = getElementType(activeItem.element_type_id, elementTypesQuery.data);
                if (elementType) {
                    setActiveFields(elementType.fields);
                }
            } else {

                triggerGetFields({projectId: project.id, elementId: activeItem.id});
            }

            const hash = window.location.hash.split('_');

            if (hash.length) {
                window.location.hash = hash[0] + (activeItem ? '_' + activeItem.id : '');
            }
        } else {
            // setShowLoading(true);
            setActiveFields(null);
            setActivePath([]);

            // const hash = window.location.hash.split('_');
            //
            // if (hash.length) {
            //     window.location.hash = hash[0];
            // }
        }
    }, [activeItem]);

    useEffect(() => {
        setShowLoading(false);
        if (resultGetFields.data) {
            if (activeItem) {
                const elementType = getElementType(activeItem.element_type_id, elementTypesQuery.data);
                if (elementType) {
                    const fields = JSON.parse(JSON.stringify(elementType.fields));

                    for (let i = 0; i < fields.length; i++) {
                        const field = fields[i];

                        if (field) {
                            const value = resultGetFields.data.find((item: any) => {
                                return item.name === field.name;
                            });

                            if (value) {
                                if (field.data_type === 'smallint') {
                                    field.value = parseInt(value.value);
                                } else {
                                    field.value = value.value;
                                }
                            } else {
                                field.value = '';
                            }
                        }
                    }
                    setActiveFields(fields);
                }
            }
        }
    }, [resultGetFields.data]);

    // useEffect(() => {
    //     console.log('unitTree', unitTree);
    //     if (activeItem) {
    //         getUnitByIdSetActive(activeItem.id, scrollIntoView);
    //     }
    //
    // }, [JSON.stringify(unitTree)]);

    useEffect(() => {
        setActiveContentItem(null);
        if (activeItem && activeFields) {
            setActiveContentItem((prevState: any) => {
                return {...activeItem, fields: activeFields}
            });
        }
    }, [activeFields]);

    useEffect(() => {
        // console.log(activeContentItem);
    }, [activeContentItem]);

    useEffect(() => {
        // console.log(resultUpdateElementConnection);
        if (resultUpdateElementConnection.data && resultUpdateElementConnection.data.status === 'ok') {
            window.location.reload();
        }
    }, [resultUpdateElement]);

    const addPath = (treeItem: any, parent: any[]) => {
        treeItem.parent = parent;

        const tempParent = JSON.parse(JSON.stringify(parent));
        tempParent.push({id: treeItem.id, name: treeItem.name});

        if (treeItem.children) {
            let lowestStatus = 3;

            for (let i = 0; i < treeItem.children.length; i++) {
                const unit = treeItem.children[i];

                if (unit.inspection_task_status > 0) {
                    treeItem.inspection_is_active_passive = true;
                    if (lowestStatus > unit.inspection_task_status) {
                        lowestStatus = unit.inspection_task_status;
                    }
                }

                if (i === treeItem.children.length - 1) {
                    unit.lastItem = true;
                }

                addPath(unit, tempParent);
            }
            if (treeItem.inspection_is_active_passive) {
                treeItem.inspection_status_passive = lowestStatus;
            }
        }
    };

    const getTreeItem = (treeItem: any, item: any): any => {
        if (treeItem == item) {
            return item;
        } else {
            if (treeItem.children) {
                for (let i = 0; i < treeItem.children.length; i++) {
                    const item1 = treeItem.children[i];
                    return getTreeItem(item1, item);
                }
            }
        }
        return undefined;
    };

    const getUnitTree = (parent: any, treechildren: any[], index: number, lastItem: boolean, expanded: boolean) => {
        console.log('render tree', index)
        let index1 = index;
        index1++;
        const result: any = [];

        //console.log('treeitem', treechildren);

        const style = {
            marginLeft: index1 * (isPipeline ? 5 : 20) + 'px',
            overflow: 'hidden',
        };

        const toggleExpanded = (treeItem: any) => {
            treeItem.expanded = !treeItem.expanded;

            setUnitTree((prevState: any) => {
                if (prevState) {
                    const temp = JSON.parse(JSON.stringify(prevState));

                    for (let i = 0; i < temp.length; i++) {
                        const itemTemp = getTreeItem(temp[i], treeItem);

                        if (itemTemp) {
                            itemTemp.expanded = !itemTemp.expanded;
                            break;
                        }

                    }
                    return temp;
                }
            });
        };

        let children_type_ids: any[] = [];

        if (treechildren) {
            for (let i = 0; i < treechildren.length; i++) {
                const treeItem = treechildren[i];

                let elementType = null;

                if (treeItem && elementTypesQuery.data) {
                    elementType = getElementType(treeItem.element_type_id, elementTypesQuery.data);

                    if (elementType) {
                        children_type_ids = elementType.children_type_ids;
                    }
                }

                const typeIconClass = treeItem.icon; // getElementTypeIconClass(treeItem.element_type_id, elementTypesQuery.data);

                const isPipelineWithParent = isPipeline && parent;
                const isPipelineWithoutParent = isPipeline && !parent;
                const isPiplineWithParentOrNotTree = isPipeline && (parent || (elementType && !elementType.show_as_tree));


                result.push(<div
                    id={treeItem.id}
                    className={'connect tree-item grid grid-nogutter' + (isPipelineWithParent ? '' : isPipelineWithoutParent ? ' mt-1 mb-0' : ' my-1') + (lastItem ? ' last' : '') + (treeItem.expanded ? ' expanded' : '') + ' inspection-status-' + treeItem.inspection_task_status + (treeItem.inspection_is_active_passive ? ' inspection-status-passive-' + treeItem.inspection_status_passive : '')}
                    style={style}>
                    {/* <Tooltip target={'.icon_' + item.id} content={item.name}/>*/}
                    <i className={'my-auto mr-1 ml-2 pi pi-angle-right folder-arrow'}
                       style={{
                           visibility: ((treeItem.children && treeItem.children.length)) ? 'visible' : 'hidden',
                           cursor: ((treeItem.children && treeItem.children.length)) ? 'pointer' : 'default'
                       }} onClick={() => {
                        toggleExpanded(treeItem);
                    }}></i>
                    <div
                        className={'flex ue_tree-item-content select-none' + (activeItem && activeItem.id === treeItem.id ? ' active' : '')}
                        style={{
                            cursor: 'pointer'
                        }}
                        onClick={(e) => {
                            // console.log('detail', e.detail);
                            if (e.detail > 1) {
                                toggleExpanded(treeItem);
                            } else {
                                if (!activeItem || activeItem.id !== treeItem.id) {
                                    _setActiveItem(treeItem, false);
                                }
                            }
                        }}>
                        {elementType && <>
                            {(elementType.show_as_tree) &&
                                <><i className={'ue_tree-icon-content ' + typeIconClass}/>
                                </>}
                            {(!parent && !elementType.show_as_tree) &&
                                <i className={'ue_tree-icon-content-free'}>
                                    {/*<FontAwesomeIcon className='sidebar-icon mr-2' icon={faLinkSlash}/>,*/}
                                </i>}
                        </>}
                        {isPipeline &&
                            <>
                                <i className={'ue_tree-icon-content-pipeline_' + treeItem.element_type_id}/><i
                                className={'ue_tree-icon-content-pipeline-addition_' + treeItem.element_type_id}/>
                            </>}
                        <p className={'ue_icon-tree-text my-auto' + (isPiplineWithParentOrNotTree ? ' ml-3' : ' ml-2')}>{`${treeItem.name} (${getElementTypeName(treeItem.element_type_id, elementTypesQuery.data)} / ${treeItem.children ? treeItem.children.length : 0})`}</p>
                    </div>
                    {props.item != null && <div className={'flex justify-content-end w-full'}><Button
                        className={'p-0 h-auto p-button-icon p-button-sm p-button-danger p-button-rounded p-button-text ml-3'}
                        icon={'pi pi-times'}
                        onClick={() => {
                            confirmDialog({
                                header: t('dialogs_removeElementHeader'),
                                message: t('dialogs_removeElementMessage'),
                                icon: 'pi pi-exclamation-triangle',
                                acceptLabel: t('yes'),
                                rejectLabel: t('no'),
                                accept: () => {
                                    onClickRemoveitem(treeItem.id);
                                }
                            });
                        }}/></div>}
                </div>);

                if (treeItem.expanded) {
                    result.push(getUnitTree(treeItem, treeItem.children, index1, treeItem.children ? i === treeItem.children.length - 1 : false, treeItem.expanded));
                }
            }
        }

        children_type_ids = [];

        if (parent && elementTypesQuery.data) {
            const elementType = getElementType(parent.element_type_id, elementTypesQuery.data);

            if (elementType) {
                children_type_ids = elementType.children_type_ids;
            }
        }

        // if (editMode) {
        //     if ((children_type_ids && children_type_ids.length) || (index === 0 && unitTree.length <= 0 && !parent)) {
        //         result.push(<UnitObjectInput
        //             className={activeItem && parent && activeItem.id === parent.id ? 'border-1 border-round border-primary' : ''}
        //             parentId={parent ? parent.id : ''}
        //             childrenIds={children_type_ids}
        //             elementTypes={elementTypesQuery ? elementTypesQuery.data : []}
        //             onClick={onClickAddObjectTypeButton}
        //             addOnSelect={true} style={style}/>);
        //     }
        // }


        const style2 = {
            display: expanded ? 'inherit' : 'none',

        };

        return (<span className={'ue_content-block' + (lastItem ? ' last' : '')}
                      style={style2}>{result}</span>);
    };

    const onClickAddObjectTypeButton = useCallback((parentId: string, objectTypeItem: ElementTypeItem | undefined) => {
        if (objectTypeItem) {
            const id = uuidv4();
            setUnitTree((prevState: any) => {
                let temp: any = undefined;

                if (prevState) {
                    temp = JSON.parse(JSON.stringify(prevState));
                }

                const value: UnitItem = {
                    id: id,
                    element_type_id: objectTypeItem.element_type_id,
                    element_subtype_id: objectTypeItem.element_subtype_id,
                    object_type_name: objectTypeItem.name,
                    children_type_ids: objectTypeItem.children_type_ids,
                    icon: objectTypeItem.icon,
                    inspection_is_active: false,
                    inspection_task_status: 0,
                    name: objectTypeItem.name,
                    fields: objectTypeItem.fields,
                    children: null,
                    expanded: true,
                    parent: [],
                    pthname: [],
                    internal_elements: []
                };

                if (parentId != '') {
                    const tempItem: UnitItem | undefined = getUnitById(parentId, temp);

                    if (tempItem) {
                        if (!tempItem.children) {
                            tempItem.children = [];
                        }
                        tempItem.children.push(value);
                    }
                } else {
                    if (temp) {
                        temp.push(value);
                    } else {
                        temp = [value];
                    }
                }

                for (let i = 0; i < temp.length; i++) {
                    addPath(temp[i], []);
                }

                addElement({
                    project_id: project.id,
                    element_type_id: value.element_type_id,
                    element_id: value.id,
                    name: value.name,
                    inspection_is_active: value.inspection_is_active,
                    inspection_task_status: 1,
                    element_parent_id: value.parent.length ? value.parent[value.parent.length - 1].id : ''
                });

                return temp;
            });
            // props.onChange();
            setAddedElementId(id);
        }
    }, [unitTree, addElement]);

    const onConnectPipelines = useCallback((startId: string, targetId: string) => {
        updateElementConnection({project_id: project.id, element_id: startId, parent_element_id: targetId});
    }, [updateElementConnection])

    const onClickRemoveitem = useCallback((id: string) => {
        setDeleteId(id);
    }, [])

    useEffect(() => {
        if (deleteId) {
            deleteElement({element_id: deleteId, project_id: project.id});
        }
    }, [deleteId]);

    useEffect(() => {
        if (resultDeleteElement) {
            switch (resultDeleteElement.status) {
                case 'fullified':
                    setUnitTree((prevState: any) => {
                        let temp = [];

                        if (prevState) {
                            temp = JSON.parse(JSON.stringify(prevState));
                        }
                        const item = getUnitById(deleteId, temp);

                        if (item) {
                            removeUnitItem(temp, item);
                        }

                        setActiveItem(undefined);
                        return temp;
                    });

                    if (activeItem && activeItem.id === deleteId) {
                        setActiveItem(undefined);
                    }
                    setDeleteId('');
                    break;
                case 'pending':
                    // Do nothing
                    break;

                case 'rejected':
                    showMessageOnError(t('error'), t('units_delete_element_error'));
                    setDeleteId('');
                    break;
            }
        }
    }, [resultDeleteElement]);

    const getUnitByIdSetActive = useCallback((id: string, scrollIntoView: boolean) => {
        if (id) {
            for (let i = 0; i < unitTree.length; i++) {
                const item = _getUnitById(id, unitTree[i]);

                if (item) {
                    if (!activeItem || (activeItem.id !== item.id || activeItem.name !== item.name || JSON.stringify(activeItem.children) !== JSON.stringify(item.children)) || JSON.stringify(activeItem.fields) !== JSON.stringify(item.fields)) {
                        _setActiveItem(item, scrollIntoView);
                    }

                    setAddedElementId('');
                    break;
                }
            }
        } else {
            _setActiveItem(undefined, false);
        }
    }, [unitTree, activeItem]);

    const getUnitById = (id: string, items: UnitItem[]) => {
        for (let i = 0; i < items.length; i++) {
            const item = _getUnitById(id, items[i]);

            if (item) {
                return item;
            }
        }
    };

    const _getUnitById = (id: string, item: any): any => {
        let result: any = undefined;
        if (item.id === id) {
            result = item;
        } else {
            if (item.children) {
                for (let i = 0; i < item.children.length; i++) {
                    const item1 = item.children[i];
                    result = _getUnitById(id, item1);
                    if (result) {
                        break;
                    }
                }
            }
        }
        return result;
    };

    const removeUnitItem = (items: UnitItem[], remove: UnitItem) => {
        const removed = false;

        for (let i = 0; i < items.length; i++) {
            const item = items[i];

            if (item.id === remove.id) {
                items = items.splice(i, 1);
            } else {
                item.children = item.children ? item.children.filter((x) => {
                    return x.id !== remove.id;
                }) : [];
            }

            if (item.children) {
                removeUnitItem(item.children, remove);
            }
        }
    };

    const _getSearchchildren = (value: string, item: any, array: any[]): any => {
        let result: any = array;
        if (item.name.toLowerCase().indexOf(value.toLowerCase()) >= 0) {
            result.push(item);
        } else {
            if (item.children) {
                for (let i = 0; i < item.children.length; i++) {
                    const item1 = item.children[i];
                    result = _getSearchchildren(value, item1, result);
                }
            }
        }
        return result;
    };

    const _setUnitTreeItemById = (id: string, key: string, value: any) => {
        setUnitTreeItemById(id, key, value, false, setUnitTree, undefined);
    };

    const _setActiveItem = (item: any, scrollIntoView: boolean) => {
        setScrollIntoView(false);
        if (elementChanges && elementChanges.length) {
            setTempActiveItem(item);
            setScrollIntoView(scrollIntoView);
        } else {
            setActiveItem(item);

            if (scrollIntoView) {
                setTimeout(() => {
                    const treeItem = document.getElementById(item.id);
                    if (treeItem) {
                        treeItem.scrollIntoView({block: 'start', behavior: 'smooth'});
                    }
                }, 500);
            }
        }
    };

    const confirmSaveDialog = useCallback(async () => {
        let error = false;
        for (let i = 0; i < elementChanges.length; i++) {
            const item = {...elementChanges[i]};
            item.project_id = project.id;
            await updateElement(item).then((result: any) => {
                if (result.error) {
                    error = true;
                } else {
                    if (item.name) {
                        _setUnitTreeItemById(item.element_id, 'name', item.name);
                    }
                }
            });
        }

        if (!error) {
            showMessageOnSuccess(t('success'), t('units_dialog_elementSaved'));
            dispatch(resetElementChanges());

            if (tempActiveItem) {
                setActiveItem(tempActiveItem);
            }
            setTempActiveItem(undefined);
        }
    }, [updateElement, addElement, tempActiveItem]);

    const cancelSaveDialog = () => {
        dispatch(resetElementChanges());
        setActiveItem(tempActiveItem);
        setTempActiveItem(undefined);
    };

    const hideDialogs = () => {
        setTempActiveItem(undefined);
        setShowInfo(false);
    };

    const toggleEdit = () => {
        setEditMode(!editMode);

        // <Button type='button' icon={editMode ? "pi pi-eye" : "pi pi-pencil"}
        //         className="p-button-outlined p-button-secondary ml-2"
        //         onClick={toggleEdit}/>
        _setMenuItems();
    }

    const _setMenuItems = () => {
        setMenuItems([
            {
                label: t('units_options'),
                items: [
                    {
                        label: editMode ? t('units_stop_edit_mode') : t('untis_start_edit_mode'),
                        icon: editMode ? "pi pi-eye" : "pi pi-pencil",
                        command: () => {
                            toggleEdit();
                        }
                    },
                ]
            },
        ]);
    }

    const onResizeEnd = useCallback((e) => {
        //  console.log(e);
        setUserSettings('unit_tree_splitter', JSON.stringify(e.sizes), project.id);
    }, []);

    const topElements = useMemo(() => {
        return unitTree ? unitTree.map((item: UnitItem) => {
            return {value: item.id, label: item.name};
        }) : []
    }, [unitTree])

    return (<>
        <div className={'grid ue_top-bar' + (editMode ? ' unit_new' : '')}>
            <div className={'col-6 md:col relative'}>
                <div className={'card mb-0 ue_path'}>
                    <UnitExplorerPath path={activePath} onClick={getUnitByIdSetActive}/>
                </div>
            </div>
            <div className={'col m-auto grid md:col-fixed relative h-full flex'} style={{width: '250px'}}>
                <div className='col card mb-0 ue_search-field h-full'>
                    <UnitExplorerSearch projectId={project.id} onClick={(id) => {
                        getUnitByIdSetActive(id, true);
                    }}/>
                </div>
                {/*{canEdit &&*/}
                {/*    <div className={'col-fixed card mb-0'}><Menu model={menuItems} popup ref={menu} id="popup_menu"/>*/}
                {/*        <Button className={'p-button p-button-outlined p-button-text'} icon="pi pi-ellipsis-v"*/}
                {/*                onClick={(event) => {*/}
                {/*                    if (menu && menu.current) {*/}
                {/*                        menu.current.toggle(event)*/}
                {/*                    }*/}
                {/*                }}*/}
                {/*                aria-controls="popup_menu" aria-haspopup/></div>*/}
                {/*}*/}
            </div>
            {/* {!props.item && <div className={'col-fixed m-auto'}>*/}
            {/*    <Button className={'p-button-primary'} name={'save'} icon={'pi pi-save'} onClick={clickSave}*/}
            {/*            disabled={!elementChanges || !elementChanges.length}></Button>*/}
            {/* </div>}*/}

            {/* <div className={'card my-auto'}>*/}
            {/*    <Button icon={'pi pi-info-circle'} onClick={() => {*/}
            {/*        setShowInfo(true);*/}
            {/*    }}/>*/}
            {/* </div>*/}
        </div>
        <div className={'w-full ue_content'}>
            <Splitter className={'h-full'} onResizeEnd={onResizeEnd}>
                <SplitterPanel className={'hidden md:inline-block'}
                               size={splitterSizes && splitterSizes.length ? splitterSizes[0] : undefined}>
                    <div className={'relative h-full w-full ue_tree'}>
                        <div ref={treeRef} className='card h-full w-full overflow-auto m-0 ue_icon-tree'>
                            {!unitTree &&
                                <div className={'flex justify-content-center w-full'} style={{marginTop: '30vh'}}>
                                    <ProgressSpinner
                                        className={'m-auto'}/>
                                </div>}
                            {/*{unitTree && getUnitTree(undefined, unitTree, 0, false, true)}*/}
                            {unitTree &&
                                <UnitExplorerTree project={props.project} isPipeline={isPipeline} item={props.item}
                                                  unitTree={unitTree} setUnitTree={setUnitTree} activeItem={activeItem}
                                                  setActiveItem={setActiveItem} onClickRemoveitem={onClickRemoveitem}/>}
                            {props.item == null && unitTree && unitTree.length <= 0 &&
                                <div>{t('units_no_units')}</div>}
                        </div>
                    </div>
                </SplitterPanel>
                <SplitterPanel>
                    <div className={'relative h-full'}>
                        <div className='card h-full w-full overflow-auto ue_file-system'>
                            <UnitExplorerContent
                                key={activeContentItem ? activeContentItem.id : '0'}
                                showLoading={showLoading}
                                project={project}
                                item={activeContentItem}
                                new={editMode}
                                // fields={[]}
                                elementTypes={elementTypesQuery.data ? elementTypesQuery.data : []}
                                topElements={topElements}
                                onClick={getUnitByIdSetActive}
                                onAddClick={onClickAddObjectTypeButton}
                                onConnectClick={onConnectPipelines}
                                saveData={confirmSaveDialog}
                                geoData={geoDataProject}
                                onDeleteClick={onClickRemoveitem}
                            />
                        </div>
                    </div>
                </SplitterPanel>
            </Splitter>
        </div>
        {tempActiveItem &&
            <CustomDialog visible={tempActiveItem} header={t('units_dialogs_unsavedChanges')} onCancel={hideDialogs}
                          onHide={hideDialogs}
                          customButtons={[
                              <Button className={'p-button p-button-text'}
                                      icon={'pi pi-check my-auto mr-2'}
                                      onClick={confirmSaveDialog}>{t('yes')}</Button>,
                              <Button className={'p-button p-button-text'}
                                      icon={'pi pi-times my-auto mr-2'}
                                      onClick={cancelSaveDialog}>{t('no')}</Button>,
                              <Button className={'p-button p-button-text'}
                                      onClick={hideDialogs}>{t('cancel')}</Button>]}>{t('units_dialogs_unsavedChangesMessage')}</CustomDialog>}
    </>);
};
