import React, {useState} from 'react';

import {useTranslation} from 'react-i18next';
import {checkPermission} from '../functions/functionLibrary';
import {permissions} from '../config/permissions';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import LayoutMainContentTabMenu from '../components/global/LayoutMainContentTabMenu';
import CustomDialog, {CustomDialogHeaderType} from '../components/global/CustomDialog';
import {LanguageForm} from '../components/languageManagement/LanguageForm';
import {faEarth} from '@fortawesome/free-solid-svg-icons';
import {LanguageOverview} from '../components/languageManagement/LanguageOverview';
import ViewButtons from '../components/global/ViewButtons';
import {buttonCreateTranslation, makeButtonItem} from '../components/global/viewButtonSelection';
import {Nullable} from '../components/functions/Global';
import {Language} from '../components/functions/Language';

export const LanguageManagement = (): JSX.Element => {

    const {t} = useTranslation(['common']);

    const [selectedLanguage, setSelectedLanguage] = useState<Nullable<Language>>(null);
    const [showLanguageDialog, setShowLanguageDialog] = useState<boolean>(false);
    const [reloadLanguage, setReloadLanguage] = useState<boolean>(false);

    const menuItems = [
        {
            id: 'languages',
            label: t('langM_menuName'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faEarth}/>,
            component: <LanguageOverview
                reload={reloadLanguage}
                setReload={setReloadLanguage}
                setSelectedLanguage={setSelectedLanguage}
                setShowLanguageDialog={setShowLanguageDialog}
            />,
            projectRequired: true,
            visible: checkPermission(permissions.isAdmin),
            buttons: checkPermission(permissions.isAdmin) ? (
                <ViewButtons
                    title={''}
                    items={[
                        makeButtonItem(buttonCreateTranslation, t, () => {
                            setSelectedLanguage(null);
                            setShowLanguageDialog(true);
                        }),
                    ]}
                />
            ) : null
        },
    ];

    return (
        <>
            <LayoutMainContentTabMenu items={menuItems}/>
            <CustomDialog
                onHide={() =>{ setShowLanguageDialog(false);}}
                visible={showLanguageDialog}
                header={selectedLanguage
                    ? t('langM_editTransDialog_header')
                    : t('langM_createTransDialog_header')}
                headerType={selectedLanguage ? CustomDialogHeaderType.Update : CustomDialogHeaderType.Create}
                onCancel={() => setShowLanguageDialog(false)}
                formName={'languageForm'}
                style={{ width: '50vw'}}
            >
                <LanguageForm
                    language={selectedLanguage}
                    onFinished={()=>{
                        setSelectedLanguage(null);
                        setShowLanguageDialog(false);
                        setReloadLanguage(true);
                    }}
                />
            </CustomDialog>
        </>)
    ;
};
