import {baseApi} from './baseApi';

export const injectedInspectionApi = baseApi.injectEndpoints({
    addTagTypes: ['Inspection'],
    endpoints: (builder) => ({
        getInspections: builder.query({
            query: (args) => {
                const {projectId, elementId} = args;

                let url = `getInspections?project_id=${projectId}`;

                if (elementId) {
                    url += '&element_id=' + elementId;
                }

                return {url: url};
            },
            providesTags: ['Inspection']
        }),
        getInspectionTasks: builder.query({
            query: (args) => {
                const {projectId, inspectionId, elementId} = args;

                let url = `getInspectionTasks?project_id=${projectId}${inspectionId ? `&inspection_id=${inspectionId}` : ''}&element_id=${elementId}`;

                return {url: url};
            },
            providesTags: ['Inspection']
        }),
        getInspectionType: builder.query({
            query: (args) => {
                let url = `getInspectionType`;

                return {url: url};
            },
            providesTags: ['Inspection']
        }),
        getInspectionTaskType: builder.query({
            query: (args) => {
                const {projectId, elementId} = args;

                let url = `getInspectionTaskType?project_id=${projectId}&element_id=${elementId}`;

                return {url: url};
            },
            providesTags: ['Inspection']
        }),
        getInspectionTaskFields: builder.query({
            query: (args) => {
                const {projectId, inspectionTaskId, locale} = args;

                let url = `getInspectionTaskFields?project_id=${projectId}&inspection_task_id=${inspectionTaskId}&locale=${locale}`;

                return {url: url};
            },
            providesTags: ['Inspection']
        }),
        updateInspectionTask: builder.mutation({
            query: (document) => ({
                url: 'updateInspectionTask',
                method: 'PUT',
                body: document,
            }),
            invalidatesTags: ['Inspection']
        }),
        getInspectionElements: builder.query({
            query: (args) => {
                const {projectId, inspectionId} = args;

                let url = `getInspectionElements?project_id=${projectId}&inspection_id=${inspectionId}`;

                return {url: url};
            },
            providesTags: ['Inspection']
        }),
        deleteInspectionTasks: builder.mutation({
            query: (unit) => ({
                url: 'deleteInspectionTasks',
                method: 'DELETE',
                body: unit,
            }),
            invalidatesTags: ['Inspection']
        }),
        getInspectionTaskDocuments: builder.query({
            query: (args) => {
                const {projectId, inspectionTaskId} = args;

                const url = `getInspectionTaskDocuments?project_id=${projectId}&inspection_task_id=${inspectionTaskId}`;

                return {url: url};
            },
            providesTags: ['Inspection']
        }),
        createInspectionTaskDocument: builder.mutation({
            query: (document) => ({
                url: 'createInspectionTaskDocument',
                method: 'POST',
                body: document,
            }),
            invalidatesTags: ['Inspection']
        }),
        setInspectionTaskDocumentPreview: builder.mutation({
            query: (document) => ({
                url: 'setInspectionTaskDocumentPreview',
                method: 'PUT',
                body: document,
            }),
            invalidatesTags: ['Inspection']
        }),
    }),
});

export const {
    useGetInspectionsQuery,
    useGetInspectionTasksQuery,
    useGetInspectionTypeQuery,
    useGetInspectionTaskTypeQuery,
    useLazyGetInspectionTaskFieldsQuery,
    useUpdateInspectionTaskMutation,
    useGetInspectionElementsQuery,
    useDeleteInspectionTasksMutation,
    useGetInspectionTaskDocumentsQuery,
    useCreateInspectionTaskDocumentMutation,
    useSetInspectionTaskDocumentPreviewMutation,
} = injectedInspectionApi;
