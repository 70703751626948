import {configureStore, isRejected, Middleware, MiddlewareAPI} from '@reduxjs/toolkit';
import configLayerReducer from './slices/GeomonitoringSlice';
import keycloakReducer from './slices/KeycloakSlice';
import elementChangesReducer from './slices/ElementChangesSlice';
import apiCallsReducer from './slices/ApiCallsSlice';
import {setupListeners} from '@reduxjs/toolkit/query';
import {baseApi} from './rtk/baseApi';
import {injectedGlobalApi} from "./rtk/injectedGlobal";
import {injectedPersonApi} from './rtk/injectedPersonApi';
import {injectedUnitsApi} from "./rtk/injectedUnitsApi";
import {injectedGeoApi} from "./rtk/injectedGeoApi";
import {showMessageOnError} from '../components/global/CustomToast';
import {injectedTranslationApi} from "./rtk/injectedTranslationApi";
import {injectedInspectionApi} from "./rtk/injectedInspectionApi";

export const rtkQueryErrorLogger: Middleware =
    (api: MiddlewareAPI) => (next) => (action) => {
        // isRejectedWithValue Or isRejected
        if (isRejected(action)) {
            // console.log(action); // get all data from rejected request
            switch (action.payload?.status) {
                case 400:
                case 401:
                case 403:
                    showMessageOnError('Error', JSON.stringify(action.payload.data));
                    break;
            }
        }

        return next(action);
    };

const store: any = configureStore({
    reducer: {
        configLayer: configLayerReducer,
        keycloakData: keycloakReducer,
        elementChangesData: elementChangesReducer,
        apiCallsData: apiCallsReducer,
        [baseApi.reducerPath]: baseApi.reducer,
        [injectedGlobalApi.reducerPath]: injectedGlobalApi.reducer,
        [injectedPersonApi.reducerPath]: injectedPersonApi.reducer,
        [injectedUnitsApi.reducerPath]: injectedUnitsApi.reducer,
        [injectedGeoApi.reducerPath]: injectedGeoApi.reducer,
        [injectedTranslationApi.reducerPath]: injectedTranslationApi.reducer,
        [injectedInspectionApi.reducerPath]: injectedInspectionApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            baseApi.middleware,
            injectedGlobalApi.middleware,
            injectedPersonApi.middleware,
            injectedUnitsApi.middleware,
            injectedGeoApi.middleware,
            injectedTranslationApi.middleware,
            injectedInspectionApi.middleware,
            rtkQueryErrorLogger),
});

setupListeners(store.dispatch);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
export default store;
