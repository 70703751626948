import React, {useEffect, useRef, useState} from 'react';
import {TabPanel, TabView} from 'primereact/tabview';


type Props = {
    items: any,
    activeIndex?: number,
    onTabChange?: any,
    showCard?: boolean,
}

const LayoutMainContentTabMenu = (props: Props): JSX.Element => {
    const ref = useRef(null);
    const [activeIndex, setActiveIndex] = useState(props.activeIndex ? props.activeIndex : 0);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        if (ref && ref.current) {
            //@ts-ignore
            setHeight(ref.current.clientHeight);
        }
    });
    useEffect(() => {
        _setActiveIndex();
    }, []);

    useEffect(() => {
        _setActiveIndex();
    }, [window.location.hash]);

    useEffect(() => {
        _setActiveIndex();
    }, [props.items]);

    const _setActiveIndex = () => {
        if (window.location.hash.indexOf('_') > -1) {
            const index = window.location.hash.substring(window.location.hash.indexOf('_') + 1, window.location.hash.length);
            if (!isNaN(Number(index)) && Number(index) <= props.items.length)
                setActiveIndex(Number(index));
            else {
                props.items.forEach((obj: any, i: number) => {
                    if (obj.id === index)
                        setActiveIndex(Number(i));

                });
            }
        }
    };

    return (
        <>
            <div className="card" ref={ref}>
                <TabView
                    // model={props.items}
                    activeIndex={activeIndex}
                    // scrollable
                    onTabChange={(e) => {
                        setActiveIndex(e.index);
                        if (window.location.hash.indexOf('_') > -1) {
                            window.location.hash = window.location.hash.substring(0, window.location.hash.indexOf('_')) + '_' + e.index;
                        } else {
                            window.location.hash = window.location.hash + '_' + e.index;
                        }
                        if (props.onTabChange) props.onTabChange(e);
                    }}
                >
                    {props.items.map((item: any) => {
                        if (item.visible !== false) {
                            return (<TabPanel key={item.id}
                                              header={item.icon ?
                                                  <span>{item.icon}{item.label}</span> : item.label}/>);
                        }
                    })}
                </TabView>
                {props.items.length > 0 && activeIndex > -1 && props.items[activeIndex] && props.items[activeIndex].buttons}
            </div>
            <div className={'scroll-card-main-content md:absolute' + (props.showCard === false ? '' : ' card')}
                 style={{'top': (height + 28) + 'px'}}>
                {(props.items.length > 0 && activeIndex > -1) && props.items[activeIndex] && props.items[activeIndex].component}
            </div>
        </>
    );
};

export default LayoutMainContentTabMenu;
