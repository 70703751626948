import React, {useEffect} from 'react';
import settings from '../config/settings';
import keycloakfetch from '../functions/keycloakfetch';
import proj4 from 'proj4';
// @ts-ignore
import proj4_2 from 'proj4-fully-loaded';
import {transform} from 'ol/proj';


/*  Funktion zum Transformieren der Koordinaten
* Wichtig: Vor Nutzung loadEpsgScripts einbinden!!!!
* @ param coordsArray:aka [x, y];
* @ param epsgTo: number. Hier wird davon ausgegangen, dass das Ziel-Epsg keine Sonderform ist, und daher in der proj4-Bibliothek vorliegt
* */
const transformCoords = (coordsArray: any[], epsgFrom: number, epsgTo: number) => {
    let coordsResult: any = [];
    const projector = require('ecef-projector');
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    try {
        if (epsgFrom === 4978) {
            const latlon = projector.unproject(coordsArray[0], coordsArray[1], coordsArray[2]);
            const newArrray=[latlon[1],latlon[0], latlon[2]];
            coordsResult = epsgTo === 4326 ? newArrray : transformNonEcef(4326, epsgTo, newArrray);

        } else {
            if (epsgTo === 4978) {
                const latLon = epsgFrom === 4326 ? coordsArray : transformNonEcef(epsgFrom, 4326, coordsArray);
                coordsResult = projector.project(latLon[1], latLon[0], 0);
            } else {
                coordsResult = transformNonEcef(epsgFrom, epsgTo, coordsArray);
            }
        }
        return coordsResult; 
    } catch (e) {
        console.log(e);
        return false;
    }
    
};


const transformNonEcef = (epsgFrom: number, epsgTo: number, coordsArray: any) => {
    let coordsResult: any = [];
    const epsgCodesDB = localStorage.getItem('epsgCodesDB');
    const parsedArray = epsgCodesDB ? JSON.parse(epsgCodesDB) : [];
    if (parsedArray.includes(epsgFrom)) {
        // console.log('Nehme DB Umrechnung')
        coordsResult = proj4('EPSG:' + epsgFrom, 'EPSG:' + epsgTo, coordsArray);
    } else {
        // console.log('Nehme proja reloaded  Umrechnung')
        coordsResult = proj4_2('EPSG:' + epsgFrom, 'EPSG:' + epsgTo).forward(coordsArray);
    }
    return coordsResult;
};

/*
Aktivieren wenn Postgis existiert
 */
const loadEpsgScripts = async () => {
    const url = settings.apiUrl + '/getEpsgCodes';

    const loadedEpsgCodes: any[] = [];
  /*  await keycloakfetch.get(url).then((result) => {
        if (result) {
            for (const epsgVar of result) {
                const transformation = epsgVar.transformation;
                if (transformation !== null) {
                    proj4.defs('EPSG:' + epsgVar.code, transformation);
                    loadedEpsgCodes.push(epsgVar.code);
                }
            }
        }
    });*/
    localStorage.setItem('epsgCodesDB', JSON.stringify(loadedEpsgCodes));
};

const transformCoordinateArray = (array: any, from: string, to: string, reverse: boolean) => {
    const CoordsArray = [];

    for (let coords of array) {
        if (reverse == true) {
            coords = coords.reverse();
        }
        const newCoords = transform(coords, from, to);
        if (Array.isArray(newCoords)) {
            CoordsArray.push(newCoords);
        }
    }
    return CoordsArray;
};

const mapScale = (dpi: number, map: any) => {
    const resolution = map.getView().getResolution();
    const inchesPerMetre = 39.37;
    return resolution * inchesPerMetre * dpi * 0.03;
};

const getDPI = () => {
    const div = document.createElement('div');
    div.style.height = '1in';
    div.style.width = '1in';
    div.style.top = '-100%';
    div.style.left = '-100%';
    div.style.position = 'absolute';
    document.body.appendChild(div);
    const result = div.offsetHeight;
    document.body.removeChild(div);
    return result;

};

export {
    loadEpsgScripts,
    mapScale,
    getDPI,
    transformCoords,
    transformCoordinateArray
};