import {createSlice, Draft, PayloadAction} from '@reduxjs/toolkit';
import {useSelector} from "react-redux";

// Define a type for the slice state
interface General{
    keycloak: any
}

// Define the initial state using that type
const initialState: General = {
    keycloak: {
        keycloakToken:'',
        keycloakRefreshToken:'',
        keycloakIdToken:'',
    }
};

export const keycloakSlice = createSlice({
    name: 'keycloak',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setKeycloakToken: (state, action: PayloadAction<any>) => {
            state.keycloak.keycloakToken=action.payload;
        },
        setKeycloakRefreshToken: (state, action: PayloadAction<any>) => {
            state.keycloak.keycloakRefreshToken=action.payload;
        },
        setKeycloakIdToken: (state, action: PayloadAction<any>) => {
            state.keycloak.keycloakIdToken=action.payload;
        }
    }
    ,
});

export const {
    setKeycloakToken,
    setKeycloakRefreshToken,
    setKeycloakIdToken
} = keycloakSlice.actions;

export default keycloakSlice.reducer;
