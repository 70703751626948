import React from 'react';
import {useNavigate} from 'react-router-dom';
import Menu from './Menu';
import {classNames} from 'primereact/utils';
import settings from "../../config/settings";

const Topbar = (props: any) => {
    const onTopbarSubItemClick = (event: any) => {
        event.preventDefault();
    };

    const navigate = useNavigate();

    const isOverlay = () => {
        return props.menuMode === 'overlay';
    };

    const isSidebar = () => {
        return props.menuMode === 'sidebar';
    };

    return (
        <>

            <div className="layout-topbar">
                <div className="layout-topbar-left">
                    <button className="topbar-menu-button p-link ml-0" onClick={props.onMenuButtonClick}>
                        <i className="pi pi-chevron-right"></i>
                    </button>
                </div>

                <div className={'menu-logo block'}>
                    <button className="p-link logo">
                        <img
                            src={settings.logoutRedirect + 'logo-dark.png'} alt='Logo'
                            onClick={() => navigate('/#projects')}/>
                    </button>
                    <button className={'p-link app-logo'}>
                        <img
                            src={settings.logoutRedirect + 'app-logo-dark.png'} alt='APP logo'
                            onClick={() => navigate('/#projects')}/>
                    </button>
                    {/*<button className="app-name p-link">*/}
                    {/*    <img src={window.location.origin + '/logo-dmt-new.png'} alt="app-name" onClick={() => navigate('/')} />*/}
                    {/*</button>*/}
                </div>
                {/*<div className="layout-topbar-left">*/}
                {/*    <button className="topbar-menu-button p-link" onClick={props.onMenuButtonClick}>*/}
                {/*        <i className="pi pi-bars"></i>*/}
                {/*    </button>*/}

                {/*    <button className="logo p-link" onClick={() => navigate('/')}>*/}
                {/*        <img src={`assets/layout/images/logo-${props.colorScheme === 'light' ? 'dark' : 'light'}.png`} alt="logo" />*/}
                {/*    </button>*/}

                {/*    <button className="p-link" onClick={() => navigate('/')}>*/}
                {/*        <img src={`assets/layout/images/appname-${props.colorScheme === 'light' ? 'dark' : 'light'}.png`} className="app-name" alt="app-name" />*/}
                {/*    </button>*/}
                {/*</div>*/}
                {/*<Menu*/}
                {/*    model={props.items}*/}
                {/*    menuMode={props.menuMode}*/}
                {/*    colorScheme={props.colorScheme}*/}
                {/*    menuActive={props.menuActive}*/}
                {/*    activeInlineProfile={props.activeInlineProfile}*/}
                {/*    onSidebarMouseOver={props.onSidebarMouseOver}*/}
                {/*    onSidebarMouseLeave={props.onSidebarMouseLeave}*/}
                {/*    toggleMenu={props.onToggleMenu}*/}
                {/*    onChangeActiveInlineMenu={props.onChangeActiveInlineMenu}*/}
                {/*    onMenuClick={props.onMenuClick}*/}
                {/*    onRootMenuItemClick={props.onRootMenuItemClick}*/}
                {/*    onMenuItemClick={props.onMenuItemClick}*/}
                {/*/>*/}
            </div>
        </>
    );
};

export default Topbar;
