import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {InspectionTask, InspectionTaskType,} from '../functions/Inspection';
import {
    useDeleteInspectionTasksMutation,
    useGetInspectionTaskTypeQuery,
    useLazyGetInspectionTaskFieldsQuery,
    useUpdateInspectionTaskMutation,
} from '../../redux/rtk/injectedInspectionApi';
import {Button} from 'primereact/button';
import Input from '../global/Input';
import {TabPanel, TabView} from 'primereact/tabview';
import {getUserFromLocalStorage, Nullable} from '../functions/Global';
import {useLazyGetCustomerTypesQuery} from '../../redux/rtk/injectedUnitsApi';
import {useAppDispatch, useAppSelector} from '../../redux/hooks';
import {changeField, resetElementChanges} from '../../redux/slices/ElementChangesSlice';
import {showMessageOnSuccess} from '../global/CustomToast';
import CustomDialog from '../global/CustomDialog';
import {UnitFields, UnitFieldType} from '../unitManagement/UnitFields';
import {types} from 'sass';
import {confirmDialog} from 'primereact/confirmdialog';
import moment from 'moment';
import {RadioButton} from 'primereact/radiobutton';
import {ElementFileManagement} from "../unitManagement/ElementFileManagement";
import settings from "../../config/settings";
import {classNames} from "primereact/utils";
import {Galleria} from "primereact/galleria";
import {useGetUsersQuery} from "../../redux/rtk/injectedGlobal";
import {User} from "../functions/UserManagement";

type Props = {
    project: any,
    inspectionId?: string,
    elementId: string,
    item: Nullable<InspectionTask>,
    editable: boolean,
    onFinished?: (saved: boolean) => void
}

export const InspectionElementTask = (props: Props) => {
    const {t} = useTranslation(['common']);
    const user = getUserFromLocalStorage();

    const elementChanges: any[] = useAppSelector((state: any) => state.elementChangesData.elementChanges);
    const dispatch = useAppDispatch();

    const [item, setItem] = useState<Nullable<InspectionTask>>(props.item);
    // const [itemWithFields, setItemWithFields] = useState<Nullable<InspectionTask>>(null);
    const [fields, setFields] = useState<any[]>([]);
    const [selectedTaskType, setSelectedTaskType] = useState<number>(0);
    const [hasPassed, setHasPassed] = useState<boolean | undefined>(undefined);

    const [showEditDialog, setShowEditDialog] = useState(!props.item);

    const [inspectionTaskTypes, setInspectionTaskTypes] = useState<InspectionTaskType[]>([]);

    const [statusAt, setStatusAt] = useState<Date | undefined>(undefined);
    const [statusBy, setStatusBy] = useState<string>('');
    const [dueAt, setDueAt] = useState<Date | undefined>(undefined);
    const [dueBy, setDueBy] = useState<string>('');

    const [status, setStatus] = useState<number>(0);
    const [filesCount, setFilesCount] = useState<number>(0);
    const [previewImages, setPreviewImages] = useState<any[]>([]);

    const [users, setUsers] = useState<User[]>([]);

    const inspectionTaskTypeQuery = useGetInspectionTaskTypeQuery({
        projectId: props.project.id,
        elementId: props.elementId
    });

    const usersQuery = useGetUsersQuery({projectId: props.project.id});

    const [triggerGetCustomerTpyes, resultCustomerTypes, lastPromiseCustomerTypes] = useLazyGetCustomerTypesQuery();
    const [triggerGetFields, resultGetFields, lastPromiseGetFields] = useLazyGetInspectionTaskFieldsQuery();

    const [updateInspectionTask] = useUpdateInspectionTaskMutation();
    const [deleteInspectionTask] = useDeleteInspectionTasksMutation();

    useEffect(() => {
        if (inspectionTaskTypeQuery && inspectionTaskTypeQuery.data) {
            setInspectionTaskTypes(inspectionTaskTypeQuery.data);
        }
    }, [inspectionTaskTypeQuery]);

    useEffect(() => {
        if (usersQuery.data) {
            setUsers(usersQuery.data);
        }
    }, [usersQuery.data])

    useEffect(() => {
        setItem(props.item);
        if (props.item) {
            setHasPassed(props.item.has_passed);
        }
    }, [JSON.stringify(props.item)]);

    useEffect(() => {
        if (item) {

            setSelectedTaskType(item.inspection_task_type_id);

            if (item.set_status_at) {
                setStatusAt(new Date(item.set_status_at));
            }

            if (item.set_status_by) {
                setStatusBy(item.set_status_by)
            }

            if (item.due_at) {
                setDueAt(new Date(item.due_at));
            }

            if (item.due_by) {
                setDueBy(item.due_by)
            }

            triggerGetFields({
                projectId: props.project.id,
                inspectionTaskId: item.id,
                locale: user ? user.language_id : 'de'
            });

            switch (item.has_passed) {
                case true:
                    setStatus(3);
                    break;

                case false:
                    setStatus(1);
                    break;

                default:
                    setStatus(2);
                    break;
            }
        }
    }, [item]);

    useEffect(() => {
        if (inspectionTaskTypes) {
            const taskType = inspectionTaskTypes.find((itemTaskType: InspectionTaskType) => {
                return itemTaskType.inspection_task_type_id === selectedTaskType;
            });

            if (taskType) {
                const fields = JSON.parse(JSON.stringify(taskType.fields));
                if (resultGetFields && item) {
                    if (resultGetFields.data) {
                        for (let i = 0; i < fields.length; i++) {
                            const field = fields[i];

                            const value = resultGetFields.data.find((item: any) => {
                                return item.name === field.name;
                            });

                            if (value) {
                                if (field.data_type === 'smallint') {
                                    field.value = parseInt(value.value);
                                } else {
                                    field.value = value.value;
                                }
                            } else {
                                field.value = '';
                            }
                        }
                    }
                }
                console.log('fields', fields);
                setFields(fields);
            }
        }
    }, [resultGetFields, inspectionTaskTypes, item]);

    const createdUser = useMemo(() => {
        if (item && users.length) {
            return users.find((user) => user.id === item.created_by);
        }
    }, [item, users]);

    // const dueUser = useMemo(() => {
    //     if (item && users.length) {
    //         return users.find((user) => user.id === item.due_by);
    //     }
    // }, [item, users]);
    //
    // const statusUser = useMemo(() => {
    //     if (item && users.length) {
    //         return users.find((user) => user.id === item.set_status_by);
    //     }
    // }, [item, users]);


    const onChangeTaskType = (e: any, value: any) => {
        setSelectedTaskType(e.value);

        dispatch(changeField({
            inspection_task_id: item ? item.id : '',
            key: 'inspection_task_type_id',
            value: e.value
        }));

        setFields((prevState: any[]) => {
            let temp = {...prevState};

            const taskType = inspectionTaskTypes.find((item: InspectionTaskType) => {
                return item.inspection_task_type_id === e.value;
            });

            if (taskType) {
                temp = taskType.fields;
            }
            return temp;
        });

    };

    const onChangeField = (value: any, fieldName: string) => {
        dispatch(changeField({
            inspection_task_id: item ? item.id : '',
            key: fieldName,
            value: value
        }));

        if (fieldName === 'has_passed') {
            setHasPassed(value);
        }
    };

    const onEditTask = () => {
        setShowEditDialog(true);
    };

    useEffect(() => {
        console.log(elementChanges)
    }, [elementChanges]);

    const onSaveTask = async () => {
        let error = false;
        for (let i = 0; i < elementChanges.length; i++) {
            const item = {...elementChanges[i]};
            item.project_id = props.project.id;
            item.inspection_id = props.inspectionId;
            item.inspection_task_type_id = selectedTaskType;
            item.element_id = props.elementId;
            await updateInspectionTask(item).then((result: any) => {
                if (result.error) {
                    error = true;
                }
            });
        }

        if (!error) {
            showMessageOnSuccess(t('success'), t('units_dialog_elementSaved'));
            dispatch(resetElementChanges());
            hideDialogs();
            if (props.onFinished) {
                props.onFinished(true);
            }
        }
    };

    const onRemoveTask = () => {
        confirmDialog({
            header: t('dialogs_removeTaskHeader'),
            message: t('dialogs_removeTaskMessage'),
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: t('yes'),
            rejectLabel: t('no'),
            accept: () => {
                if (item) {
                    deleteInspectionTask({
                        project_id: props.project.id,
                        inspection_task_id: item.id,
                    }).then((result: any) => {
                    });
                }
            }
        });
    };

    const hideDialogs = () => {
        setShowEditDialog(false);
        // props.onAbortNewTask(item);
    };

    const onClickOpenImage = useCallback((src: string) => {
            window.open(src);
        },
        []);

    const itemTemplate = (item: any) => {
        return (<div className={'relative'}><img src={item.src}
            // onError={(e: any) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}
                                                 alt={item.alt} style={{
            width: 'auto',
            maxWidth: '100%',
            maxHeight: '250px'
        }}/>
            <div className={'mt-2 my-auto flex'}><label className={'my-auto'}>{item.alt}</label>
                <div className={'p-button p-button-text ml-2 pi pi-external-link'} onClick={() => {
                    onClickOpenImage(item.src)
                }}></div>
            </div>
        </div>);
    };

    const thumbnailTemplate = (item: any) => {
        return (<img src={item.src}
            // onError={(e: any) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}
                     alt={item.alt} style={{width: '100%', maxHeight: '40px'}}/>);
    };

    const onChangeStatus = useCallback((e) => {
        onChangeField(e.value, 'has_passed');
        let date = new Date()

        onChangeField(date.toISOString(), 'set_status_at');
        onChangeField(user.id, 'set_status_by');

        setStatusAt(date);
        setStatusBy(user.id);

    }, []);

    const onChangeDueAt = useCallback((e) => {
        if (e.target.value) {
            onChangeField(e.target.value.toISOString(), 'due_at');
            setDueAt(e.target.value);
        }
    }, []);

    const onChangeDueBy = useCallback((e) => {
        if (e.target.value) {
            onChangeField(e.target.value, 'due_by');
            setDueBy(e.target.value);
        }
    }, []);

    const dropDownOptions = useMemo(() => {
        if (users) {
            return users.map((item) => {
                return {
                    label: item.first_name + ' ' + item.last_name,
                    value: item.id
                }
            });
        }
        return []
    }, [users]);

    return (
        <>{item && <div className={'card h-full w-full relative inspection-status-' + status}>
            <div className={'grid'}>
                <div className={'col'}>
                    <h5
                        className={'mb-0'}>{t('inspection_created_at') + ' ' + moment(item.created_at).format(t('datetimeFormat'))}</h5>
                    <div
                        className={'text-50'}>{createdUser ? createdUser.first_name + ' ' + createdUser.last_name : ''}</div>
                </div>

                <div className={'col-fixed'}>
                    <div className={'inspection-task-buttons'}>
                        {props.editable &&
                            <Button icon={'pi pi-trash'}
                                    className={'p-button-rounded p-button-danger p-button-text close-task'}
                                    onClick={onRemoveTask}/>}
                        <Button icon={props.editable ? 'pi pi-pencil' : 'pi pi-eye'}
                                className={'p-button-rounded  p-button-text edit-task'}
                                onClick={onEditTask}/>
                    </div>
                </div>

            </div>

            <div className={'col-12 grid'}>
                <div className={'col'}>{t('units_task_type')}</div>
                <div className={'col flex justify-content-end font-bold'}>{item.inspection_task_type_name}</div>
            </div>
            <div className={'col-12 grid'}>
                <div className={'col'}>{t('has_passed')}</div>
                <div
                    className={'col flex justify-content-end text-right font-bold'}>{item.has_passed == null ? t('inspection_has_passed_null') :
                    item.has_passed === false ? t('inspection_has_passed_false') : t('inspection_has_passed_true')
                }
                </div>
            </div>

            {/* <UnitFields project={props.project} fields={fields} id={item ? item.id : ''} type={'inspection_task'}*/}
            {/*            className={'col-12'} showOnly={true}/>*/}
        </div>}
            <CustomDialog onCancel={() => {
                hideDialogs();
                if (props.onFinished) {
                    props.onFinished(false);
                }
            }} visible={showEditDialog}
                          header={item ? t('inspection_created_at') + ' ' + moment(item.created_at).format(t('datetimeFormat')) + (createdUser ? ' ' + t('by') + ' ' + createdUser.first_name + ' ' + createdUser.last_name : '') : t('inspection_new_task')}
                          style={{width: '60vw', height: '60vw'}}>
                <div className={'card'}><TabView renderActiveOnly={false} className={'w-full'}>
                    <TabPanel
                        header={t('units_tabs_info')}>
                        <br/>
                        {previewImages.length > 0 &&
                            <div
                                className={'mb-3 col-12'}>
                                {/*<h5 className="input-label">{t('units_pictures')}</h5>*/}
                                <Galleria value={previewImages}
                                          className={'h-full'}
                                          thumbnailsPosition={'top'}
                                          showThumbnails={previewImages.length > 1}
                                          item={itemTemplate} thumbnail={thumbnailTemplate}
                                />
                            </div>}
                        <Input edit={props.editable} name={'task-type'} type={'dropdown'}
                               dropdownOptions={inspectionTaskTypes}
                               label={t('units_task_type')}
                               value={selectedTaskType}
                               onChange={(e: any) => onChangeTaskType(e, fields)}
                               options={{optionValue: 'inspection_task_type_id', optionLabel: 'name'}}/>
                        <div className={'mt-3'}>
                            <h5>{t('inspection_task_status')}</h5>
                            <div className={'grid'}>
                                <div className="field-radiobutton col">
                                    <RadioButton inputId="status1" name="status" value={null} disabled={!props.editable}
                                                 onChange={onChangeStatus}
                                                 checked={hasPassed == null}/>
                                    <label htmlFor="status1">{t('inspection_has_passed_null')}</label>
                                </div>
                                <div className="field-radiobutton col">
                                    <RadioButton inputId="status2" name="status" value={false}
                                                 disabled={!props.editable}
                                                 onChange={onChangeStatus}
                                                 checked={hasPassed === false}/>
                                    <label htmlFor="status2">{t('inspection_has_passed_false')}</label>
                                </div>
                                <div className="field-radiobutton col">
                                    <RadioButton inputId="status3" name="status" value={true} disabled={!props.editable}
                                                 onChange={onChangeStatus}
                                                 checked={hasPassed === true}/>
                                    <label htmlFor="status3">{t('inspection_has_passed_true')}</label>
                                </div>
                            </div>

                            <div className={'grid'}>
                                <div className={'col-6'}>
                                    <Input edit={false} label={t('changed')} name={'set_status_at'} type={'date'}
                                           value={statusAt}/>
                                </div>
                                <div className={'col-6'}>
                                    <Input edit={false} label={t('by')} name={'set_status_by'} type={'dropdown'}
                                           value={statusBy}
                                           dropdownOptions={dropDownOptions}/>
                                </div>
                            </div>
                            <div className={'grid'}>
                                <div className={'col-6'}>
                                    <Input edit={true} label={t('inspection_task_due_at')} name={'due_at'} type={'date'}
                                           value={dueAt}
                                           onChange={onChangeDueAt}/>
                                </div>
                                <div className={'col-6'}>
                                    <Input edit={true} label={t('inspection_task_due_by')} name={'due_by'}
                                           type={'dropdown'} value={dueBy}
                                           onChange={onChangeDueBy}
                                           dropdownOptions={dropDownOptions}/>
                                </div>
                            </div>
                        </div>

                        <UnitFields project={props.project}
                                    fields={fields}
                                    id={item ? item.id : ''}
                                    type={UnitFieldType.inspectionTask}
                                    showOnly={!props.editable}
                        />
                        {props.editable && <div className={'col-12 flex justify-content-end'}>
                            <Button icon={'pi pi-check my-auto mr-2'} onClick={onSaveTask}
                                    disabled={!elementChanges || !elementChanges.length}>{t('save')}</Button>
                        </div>}
                    </TabPanel>
                    {item && <TabPanel header={<div className={'flex'}><i
                        className={'mr-2 pi pi-file'}/>{t('units_tabs_files') + (filesCount > 0 ? ` (${filesCount})` : '')}
                    </div>}>
                        <br/>
                        <ElementFileManagement project={props.project} id={item.id}
                                               type={UnitFieldType.inspectionTask}
                                               editable={props.editable}
                                               setFilesCount={setFilesCount}
                                               setPreviewImages={setPreviewImages}
                                               showPreview={true} skeletonCount={1}
                        />
                    </TabPanel>}
                </TabView>
                </div>
            </CustomDialog></>
    );
};


