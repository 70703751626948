import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm} from 'react-hook-form';
import { Project } from '../functions/Project';
import { Nullable } from '../functions/Global';
import { Steps } from 'primereact/steps';
import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { buttonSaveProjectType } from '../global/viewButtonSelection';
import { useAddProjectTypeMutation, useUpdateProjectTypeMutation, useAddElementTypeMutation, useAddElementFieldTypeMutation  } from '../../redux/rtk/injectedProjectTypes';
import ProjectElementTypeEditForm from './ProjectElementTypeEditForm';
import ProjectElementFieldTypeEditForm from './ProjectElementFieldTypeEditForm';
import ProjectTypeForm from './ProjectTypeForm';
import keycloakfetch from '../../functions/keycloakfetch';
import settings from '../../config/settings';
import {ProgressSpinner} from 'primereact/progressspinner';

type Props = {
    project: Nullable<Project>,
}

type ProjectType = {
    id: number;
    name: string;
    style_type: string;
};

const CreateProjectTypeForm = (props: Props): JSX.Element => {
    const { t } = useTranslation(['common']);
    const [activeIndex, setActiveIndex] = useState(0);
    const [cardsData, setCardsData] = useState<any[]>([]);
    const [existingProjectTypes, setExistingProjectTypes] = useState<{ id: number, name: string, style_type: string }[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false); // true = non-stop-loading
    const [triggerLoadProjectTypes, setTriggerLoadProjectTypes] = useState<boolean>(false);
    const [addProjectType] = useAddProjectTypeMutation();
    const [updateProjectType] = useUpdateProjectTypeMutation();
    const [addElementType] = useAddElementTypeMutation();
    const [addElementFieldType] = useAddElementFieldTypeMutation();

    const interactiveItems = [
        {
            label: t('projM_projectTypeName'),
        },
        {
            label: t('elementType'),
        },
        {
            label: t('elementFieldType'),
        },
    ];

    const defaultValues = {
        'items': [],
    };

    const { control, handleSubmit, formState: { errors }, reset } = useForm({ defaultValues });

    const saveData = async (activeIndex: number) => {
        try {
            setIsLoading(true);
            switch (activeIndex) {
                case 0:
                    // Step 1 ProjectTypeForm
                    const result: ProjectType[] = await keycloakfetch.get(settings.apiUrl + '/getProjectTypes');
                    const existingNames = new Set(result.map((type: ProjectType) => type.name));

                    const projectTypesToAdd: { name: string, style_type: string }[] = [];
                    const projectTypesToUpdate: { id: number; name: string, style_type: string }[] = [];

                    cardsData.forEach(card => {
                        const existingType = existingProjectTypes.find(type => type.id === card.id);

                        if (existingType) {
                            projectTypesToUpdate.push({
                                id: existingType.id,
                                name: card.projectTypeName,
                                style_type: card.projectStyleTypes,
                            });
                        } else if (!existingNames.has(card.projectTypeName)) {
                            projectTypesToAdd.push({
                                name: card.projectTypeName,
                                style_type: card.projectStyleTypes,
                            });
                        }
                    });

                    const addPromises = projectTypesToAdd.length > 0 ? addProjectType(projectTypesToAdd) : Promise.resolve();
                    const updatePromises = projectTypesToUpdate.length > 0 ?
                        Promise.all(projectTypesToUpdate.map(update => updateProjectType(update))) :
                        Promise.resolve();

                    await Promise.all([addPromises, updatePromises]);
                    break;
                case 1:
                    // Step 2 ElementTypeForm
                    const elementTypes = cardsData.map(card => ({
                        name: card.element_type_id,
                        has_parent: card.checkboxes.hasParent,
                        project_type_id: card.projectTypeId,
                        children_type_ids: card.elements,
                        icon: card.selectedIcon,
                        multi_parents: card.checkboxes.multiParents,
                        // api_call_get_name: '',
                        // api_call_get_subtype: '',
                        location_supported: card.checkboxes.locationSupport,
                        show_as_tree: card.checkboxes.showAsTree
                    }));

                    await addElementType(elementTypes);
                    break;
                case 2:
                    // Step 3 ElementFieldTypeForm
                    const elementFieldTypes = cardsData.map(card => ({
                        element_type_id: card.projectElementTypeId,
                        name: card.elementFieldTypeName,
                        data_type: card.fieldTypeData,
                        object_type: card.fieldTypeObject,
                        count: card.elementFieldTypeCount,
                        check_value: card.checkValue,
                        subtype_id: card.fieldSubtype
                    }));

                    await addElementFieldType(elementFieldTypes);
                    break;

                default:
                    console.error('Unknown step index:', activeIndex);
                    break;
            }
        } catch (error) {
            console.error('Error saving data:', error);
        } finally {
            setIsLoading(false);
            setTriggerLoadProjectTypes(!triggerLoadProjectTypes);
        }
    };

    useEffect(() => {
        // Step wechsel = Formular zurücksetzen
        reset({ items: [] });
        setCardsData([]); // Karten-Daten zurücksetzen
    }, [activeIndex, reset]);

    const mainContentCardStyles: React.CSSProperties = {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        top: '100px',
        margin: '1rem',
        flexGrow: '1',
    };

    const stepperNavigationCardStyles: React.CSSProperties = {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        margin: '1rem',
    };

    return (
        <>
            {isLoading && <ProgressSpinner className="absolute left-0 right-0 top-0 bottom-0 m-auto z-1"/>}
            <form id="projectForm" style={{ height: '100%' }} onSubmit={handleSubmit(() => saveData(activeIndex), () => {console.log(errors)})}>
                <div className="grid" style={{ height: '100%' }}>
                    <div className="col-12">
                        {/* Card for stepper */}
                        <div className="card">
                            <Steps model={interactiveItems} activeIndex={activeIndex}
                                onSelect={(e) => setActiveIndex(e.index)} readOnly={false} />
                        </div>
                        {/* Card for main content */}
                        <div style={mainContentCardStyles}>
                            {activeIndex === 0 && <ProjectTypeForm triggerLoadProjectTypes={triggerLoadProjectTypes} setLoadedProjectTypes={setExistingProjectTypes} setCardsData={setCardsData} form={ {control, handleSubmit, formState: { errors } }} project="" />}
                            {activeIndex === 1 && <ProjectElementTypeEditForm setCardsData={setCardsData} form={ {control, handleSubmit, formState: { errors } }} project="" />}
                            {activeIndex === 2 && <ProjectElementFieldTypeEditForm setCardsData={setCardsData} form={ {control, handleSubmit, formState: { errors } }} project="" />}
                        </div>
                        {/* Card for stepper navigation */}
                        <div className="card" style={stepperNavigationCardStyles}>
                            <div className="flex justify-content-end">
                                <Button className={buttonSaveProjectType.className}>
                                    <FontAwesomeIcon icon={buttonSaveProjectType.icon} className="mr-2" />
                                    {t(buttonSaveProjectType.label)}
                                </Button>
                                {/* forward/back navigation */}
                                {/* <div>
                                    {activeIndex > 0 && <Button onClick={() => {
                                        setActiveIndex(activeIndex - 1);
                                    }}>{t('back')}</Button>}
                                    {activeIndex < steps.length - 1 && <Button onClick={() => {
                                        setActiveIndex(activeIndex + 1);
                                    }}>{t('next')}</Button>}
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};

export default CreateProjectTypeForm;
