import React, {FunctionComponent, useContext, useEffect, useRef, useState} from 'react';

import MapContext from '../map/MapContext';
import Select from 'ol/interaction/Select.js';
import {
    createFeatures,
    getFeatureView, getLayerById, pointImageStyle
} from '../../functions/Geo';
import {useLazyGetElementGeoDataQuery} from '../../../redux/rtk/injectedGeoApi';
import {useAppDispatch} from '../../../redux/hooks';
import {baseApi} from '../../../redux/rtk/baseApi';

type poiProps = {
    projectId: any
};
const PoiTypeInteraction: FunctionComponent<poiProps> = (props) => {
    // @ts-ignore
    const {map} = useContext(MapContext);
    const dispatch = useAppDispatch();
    const [triggerElements, {
        data: resultData,
        isLoading,
        isError,
        isSuccess
    }, lastPromiseInfo] = useLazyGetElementGeoDataQuery();
    const [overlayLayer, setOverlayLayer] = useState({});
    const [overlayClusterLayer, setOverlayClusterLayer] = useState({});
    const [selectedFeature, setSelectedFeature] = useState({
        getGeometry() {

        },
        reverse() {

        }
    });
    const [overlaySource, setOverlaySource] = useState({
        clear() {
        },
        addFeature(feature: any) {
        }
    });
    const [overlayClusterSource, setOverlayClusterSource] = useState({
        clear() {
        },
        addFeature(feature: any) {
        }
    });
    const refOverlayLayer = useRef(overlayLayer);
    const refOverlaySource = useRef(overlaySource);
    const refOverlayClusterLayer = useRef(overlayClusterLayer);
    const refOverlayClusterSource = useRef(overlayClusterSource);
    const refSelectedFeature = useRef(selectedFeature);

    const getOverlayLayer = () => {
        getLayerById(map, 'overlaylayer').then(layerById => {
            if (layerById.found) {
                // @ts-ignore
                setOverlayLayer(layerById.layer);
                // @ts-ignore
                setOverlaySource(layerById.layer.getSource());
                // @ts-ignore
                refOverlayLayer.current = layerById.layer;
                // @ts-ignore
                refOverlaySource.current = layerById.layer.getSource();
            }
        });
        getLayerById(map, 'clusterOverlayLayer').then(layerById => {
            if (layerById.found) {
                // @ts-ignore
                setOverlayClusterLayer(layerById.layer);
                // @ts-ignore
                setOverlayClusterSource(layerById.layer.getSource());
                // @ts-ignore
                refOverlayClusterLayer.current = layerById.layer;
                // @ts-ignore
                refOverlayClusterSource.current = layerById.layer.getSource();
            }
        });
    };

    const selectAndTrigger = (selectedFeature: any) => {
        // @ts-ignore
        const selectedGeom = selectedFeature.getGeometry().getCoordinates();
        const geomArray = [selectedGeom[0], selectedGeom[1]];
        // @ts-ignore
        setSelectedFeature(geomArray);
        // @ts-ignore
        refSelectedFeature.current = geomArray;
        triggerElements({project_id:props.projectId,mapping: {parent_element_id: selectedFeature.get('id')}}).unwrap()
            .then((res: any) => {

                if (res.length > 0) {
                    createFeatures(res, refSelectedFeature.current).then(result => {
                        const cluster = result.cluster;
                        if (cluster.length > 0 || result.features.length > 0) {
                            const overlaySource = refOverlaySource.current;
                            overlaySource.clear();
                            const overlayClusterSource = refOverlayClusterSource.current;
                            overlayClusterSource.clear();
                            const altCenter = cluster.length > 0 ? cluster[0].getGeometry().getCoordinates() : geomArray;
                            const mainCoordsCluster = selectedFeature.get('cluster') ? selectedFeature.get('parentcoords') : altCenter;

                            getFeatureView(result.features, null, map, refOverlaySource.current);
                            if (cluster.length > 0) {
                                //    console.log('addtoCluster')
                                getFeatureView(cluster, mainCoordsCluster, map, refOverlaySource.current);
                            }
                        }
                    });
                }
            })
            .catch((error: any) => console.log(error));
    };
    const getInteraction = () => {
        getOverlayLayer();// DrawingLayer

        const select = new Select({
            // @ts-ignore
            filter: function (feature: any | undefined, layer: any | undefined) {
                if (layer &&feature&&((layer.get('id') === 'poiLayer' && feature.get('element_type_id') == 1) || (layer.get('id') === 'overlayLayer'||layer.get('id') === 'clusterOverlayLayer') && feature.getId() !== 'shadow-layer'&& feature.get('id') !== 'drawFeature')) {
                    return true;
                } else if (layer &&feature&& (layer.get('id') === 'poiLayer' && feature.get('features') && feature.get('features').length == 1)) {
                    const features = feature.get('features');
                    if (features[0].get('element_type_id') === 1) {
                        return true;
                    }

                }
            },
            condition: function (evt) {
                return evt.type === 'singleclick';
            },
            style: pointImageStyle

        });

        select.on('select', function (evt) {
            if (evt.selected[0]) {
                const features = evt.selected[0].get('features');
                let feature = evt.selected[0];
                if (features && features.length > 0) {
                    feature = features[0];
                }
                const id = feature.getId();
                const layerKind = feature.get('layerKind');
                if (id && (layerKind === 'poiLayer' || layerKind === 'overlayLayer'|| layerKind === 'overlayClusterLayer')) {
                    selectAndTrigger(feature);
                }
            }
        });
        return select;
    };

    useEffect(() => {
        if (!map || Object.keys(map).length === 0) return;
        const selectInteraction = getInteraction();
        map.addInteraction(selectInteraction);
        map.getViewport().addEventListener('contextmenu', function () {
            selectInteraction.getFeatures().forEach((f: any) => f.setStyle(null));
            selectInteraction.getFeatures().clear();
            if (refOverlayLayer.current) {
                refOverlaySource.current.clear();
            }
        });

        return () => map.interactions.remove(selectInteraction);
    }, [map]);

    return null;
};

export default PoiTypeInteraction;
