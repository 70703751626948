/*
 * CurrentUserSettings.tsx
 * Author: lnappenfeld
 * Date: 08.02.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import keycloakfetch from '../../functions/keycloakfetch';
import settings from '../../config/settings';
import LanguageSelectList from '../global/LanguageSelectList';
import {Button} from 'primereact/button';
import Input from '../global/Input';
import {Card} from 'primereact/card';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import {getUserFromLocalStorage} from '../functions/Global';
import TimezoneSelectList from '../global/TimezoneSelectList';
import {Checkbox} from 'primereact/checkbox';

const CurrentUserSettings = (): JSX.Element => {

    const {t, i18n} = useTranslation(['common', 'userManagement']);

    const [username, setUsername] = useState<string>(getUserFromLocalStorage().username);
    const [firstname, setFirstname] = useState<string>(getUserFromLocalStorage().first_name || '');
    const [lastname, setLastname] = useState<string>(getUserFromLocalStorage().last_name || '');
    const [email, setEmail] = useState<string>(getUserFromLocalStorage().email);
    const [sms, setSms] = useState<string>(getUserFromLocalStorage().sms || '');
    const [company, setCompany] = useState<string>(getUserFromLocalStorage().company || '');
    const [languageId, setLanguageId] = useState<string>(getUserFromLocalStorage().language_id);
    const [timezone, setTimezone] = useState<string>(getUserFromLocalStorage().timezone);
    const [saveFilters, setSaveFilters] = useState<boolean>(getUserFromLocalStorage().is_saving_userfilters_enabled);
    console.log('getUserFromLocalStorage().username', getUserFromLocalStorage().username);
    const saveUserSettings = () => {
        interface User extends Record<string, any> {
            'first_name'?: string,
            'last_name'?: string,
            email?: string,
            enabled?: boolean,
            sms?: string,
            company?: string,
            'language_id'?: string,
            'timezone'?: string,
            'is_saving_userfilters_enabled': boolean
        }

        const user: User = getUserFromLocalStorage();
        {
            user.first_name = firstname;
            user.last_name = lastname;
            user.email = email;
            user.enabled = true;
            user.sms = sms;
            user.company = company;
            user.language_id = languageId;
            user.timezone = timezone;
            user.is_saving_userfilters_enabled = saveFilters;
        }
        keycloakfetch.post(settings.apiUrl + '/updateMe', user).then(result => {
            if (result.error) {
                showMessageOnError(t('error'), result.error);
            } else {
                // Set i18next language
                i18n.changeLanguage(languageId).then();
                // Update localStorage
                localStorage.setItem('user', JSON.stringify(user));

                showMessageOnSuccess(t('success'), t('userMgmt_userUpdated'));
                window.location.reload();
            }
        });
    };

    return (
        <>
            <div className="userSettings">
                <div className="grid">
                    <div className="col-12 md:col-6 md:col-offset-3">
                        <Card>
                            <div className="rowContainer">
                                <h1>{t('userM_userSettings')}</h1>
                            </div>
                            <Input
                                edit={true} label={t('userM_attribs_username')} disabled={true}
                                name={'username'}
                                onChange={(e: any) => setUsername(e.target.value)} type={'text'} value={username}
                            />
                            <Input
                                edit={true} label={t('userM_attribs_firstname')} name={'surname'}
                                onChange={(e: any) => setFirstname(e.target.value)} type={'text'} value={firstname}
                            />
                            <Input
                                edit={true} label={t('userM_attribs_lastname')} name={'name'}
                                onChange={(e: any) => setLastname(e.target.value)} type={'text'} value={lastname}
                            />
                            <Input
                                edit={true} label={'Email'} disabled={true} name={'email'}
                                onChange={(e: any) => setEmail(e.target.value)} type={'text'} value={email}
                            />
                            <Input
                                edit={true} label={'SMS'} name={'sms'} onChange={(e: any) => setSms(e.target.value)}
                                type={'text'} value={sms}
                            />
                            <Input
                                edit={true} label={t('userM_attribs_company')} name={'company'}
                                onChange={(e: any) => setCompany(e.target.value)} type={'text'} value={company}/>
                            <div className="field-checkbox">
                                <Checkbox inputId="binary" checked={saveFilters}
                                          onChange={e => setSaveFilters(e.checked)}/>
                                <label htmlFor="binary">{t('userM_attribs_saveFilters')}</label>
                            </div>
                            <LanguageSelectList label={t('language')} value={languageId} setValue={setLanguageId}/>
                            <TimezoneSelectList
                                label={t('timezone')}
                                value={timezone}
                                onChange={(e: any) => {
                                    setTimezone(e.value);
                                }}
                            />
                            <div className="flex justify-content-end mt-3">
                                <Button className={'p-button p-button-outlined'} onClick={saveUserSettings}>
                                    <i className="pi pi-save mr-2"/>{t('save')}
                                </Button>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CurrentUserSettings;
