import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    InspectionTask,
} from '../functions/Inspection';
import {
    useGetInspectionTasksQuery,
} from '../../redux/rtk/injectedInspectionApi';
import {Button} from 'primereact/button';
import {InspectionElementTask} from './InspectionElementTask';

import {v4 as uuidv4} from 'uuid';
import {changeField, removeElement, resetElementChanges} from '../../redux/slices/ElementChangesSlice';
import {useAppDispatch, useAppSelector} from '../../redux/hooks';

type Props = {
    project: any,
    elementId: string,
    elementName: string,
    inspectionId?: string,
    showAddButton?: boolean
    showHeader?: boolean
}

export const InspectionElementTasks = (props: Props) => {
    const {t} = useTranslation(['common']);

    const [values, setValues] = useState<InspectionTask[]>([]);
    const [showNewTask, setShowNewTask] = useState(false);
    const [editable, setEditable] = useState<boolean>(props.inspectionId != null);


    const elementChanges: any[] = useAppSelector((state: any) => state.elementChangesData.elementChanges);
    const dispatch = useAppDispatch();

    const inspectionElementTasksQuery = useGetInspectionTasksQuery({
        projectId: props.project.id,
        inspectionId: props.inspectionId,
        elementId: props.elementId
    });

    useEffect(() => {
        dispatch(resetElementChanges());
    }, []);

    useEffect(() => {
        if (inspectionElementTasksQuery && inspectionElementTasksQuery.data) {
            setValues(inspectionElementTasksQuery.data);
        }
    }, [inspectionElementTasksQuery]);

    const onAddTask = () => {
        setShowNewTask(true);
        // setValues((prevState: any) => {
        //     const temp: InspectionTask[] = [...prevState];
        //     temp.push({
        //         id: uuidv4(),
        //         inspection_id: '',
        //         element_id: '',
        //         assigned_to: '',
        //         due_time: '',
        //         created_at: '',
        //         created_by: '',
        //         inspection_task_type_id: 0,
        //         project_id: props.project.id,
        //         is_done_percent: 0,
        //         element_name: '',
        //         inspection_task_type_name: '',
        //         fields: [],
        //         newTask: true
        //     });
        //     return temp;
        // });
    };

    return (
        <div className={'col-12 h-full'}>
            <div className={'grid'}>
                {props.showHeader !== false && <div className={'col'}>
                    <h5>{`${t('inspection_element_tasks_for')} ${props.elementName} (${values.length})`}</h5>
                </div>}
                {props.showAddButton !== false && <div className={'col-fixed'}><Button
                    className={'p-button-outlined p-button-success p-button-icon-only'}
                    icon={'pi pi-plus'} onClick={onAddTask}/></div>}
            </div>
            <div className={'grid'}>
                {values && values.map((item: any) => {
                    return (<div className={'col-12 lg:col-6 xl:col-4'}><InspectionElementTask
                        project={props.project}
                        inspectionId={props.inspectionId}
                        elementId={props.elementId} item={item}
                        editable={editable}
                        onFinished={(saved) => {
                            dispatch(resetElementChanges());
                        }}/>
                    </div>);
                })}
            </div>
            {editable && showNewTask &&
                <InspectionElementTask project={props.project}
                                       editable={true}
                                       inspectionId={props.inspectionId}
                                       elementId={props.elementId} item={null} onFinished={(saved) => {
                    setShowNewTask(false);
                    dispatch(resetElementChanges());
                }}/>}
        </div>);
};


