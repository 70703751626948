import keycloakfetch from '../../functions/keycloakfetch';
import settings from '../../config/settings';
import {Nullable} from './Global';

export enum FormType {
    Edit = 1,
    Create = 2,
}

export type Project = {
    'id': string,
    'project_type_id': Nullable<number>,
    'project_type_name': Nullable<string>,
    'location_id': Nullable<string>,
    'location_zoom': Nullable<number>,
    'last_name': Nullable<string>,
    'is_active': Nullable<boolean>,
    'name': Nullable<string>,
    'number': Nullable<string>,
    'comment': Nullable<string>,
    'customer_name': Nullable<string>,
    'customer_address': Nullable<string>,
    'logo_id': Nullable<string>,
    'logo': Nullable<string>,
    'picture_id': Nullable<string>,
    'geoserver_workspace': Nullable<string>
    'baselayer_workspace': Nullable<string>
    'base_layer': Nullable<string>
    'wiki_url': Nullable<string>
}

export type Billing = {
    'project_id':   string;
    'beginning':    Nullable<string | Date>;
    'ending':       Nullable<string | Date>;
    'factor':       Nullable<number>;
    'sap_number':   Nullable<string>;
    'sap_position': Nullable<number>;
    'sap_contract': Nullable<string>;
}

export async function getProjectTypes() {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getProjectTypes').then((result) => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getPortMasters() {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getPortMasters').then((result) => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getProjects() {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getProjects').then((result) => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getProject(projectId: string) {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getProject?project_id=' + projectId).then((result) => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function getProjectExportSettings() {
    let retValue: any = [];
    await keycloakfetch.get(settings.apiUrl + '/getProjectExportSettings').then((result) => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function exportProject(projectId: string, conf: string[]) {
    let retValue: any = [];
    const data = {'project_id': projectId, settings: conf};
    await keycloakfetch.post(settings.apiUrl + '/exportProject', data).then((result) => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function duplicateProject(projectId: string, conf: string[]) {
    let retValue: any = [];
    const data = {'project_id': projectId, settings: conf};
    await keycloakfetch.post(settings.apiUrl + '/duplicateProject', data).then((result) => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function toggleProjectStatus(projectId: string) {
    let retValue: any;
    const data = {'project_id': projectId};
    await keycloakfetch.post(settings.apiUrl + '/toggleProjectStatus', data).then((result) => {
        if (typeof result === 'boolean' || typeof result === 'string')
            retValue = result;
    });
    return retValue;
}

export async function updateProject(data: any) {
    let retValue: any = [];
    await keycloakfetch.post(settings.apiUrl + '/updateProject', data).then((result) => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function updateAdvancedProjectSettings(data: any) {
    let retValue: any = [];
    await keycloakfetch.post(settings.apiUrl + '/updateAdvancedProjectSettings', data).then((result) => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function createProject(data: any) {
    let retValue: any = [];
    await keycloakfetch.put(settings.apiUrl + '/createProject', data).then((result) => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function updateProjectImage(projectId: string, logo_id: string, picture_id: string) {
    let retValue: any = [];
    const data = {
        'project_id': projectId,
        'logo_id': logo_id,
        'picture_id': picture_id
    };
    await keycloakfetch.post(settings.apiUrl + '/updateProjectImage', data).then((result) => {
        if (result)
            retValue = result;
    });
    return retValue;
}

export async function deleteProjectImage(projectId: string, logo_id: string, picture_id: string) {
    let retValue: any = [];
    const data = {
        'project_id': projectId,
        'logo_id': logo_id,
        'picture_id': picture_id
    };
    await keycloakfetch.delete(settings.apiUrl + '/deleteProjectImage', data).then((result) => {
        if (result)
            retValue = result;
    });
    return retValue;
}
